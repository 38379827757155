import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing, Integrations } from '@sentry/tracing';
import App from './App';
import packageJson from '../package.json';
const isProduction = process.env.NODE_ENV !== 'development';
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (isProduction && sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    release: packageJson.version,
    tracesSampler: (context) => {
      if (context.exception) return 1;
      return 0; // block all but exceptions
    }
  });
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<App />);
