import { Button, Grid, Hidden, Paper, TextField, Tooltip } from '@mui/material';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete } from '@mui/material';
import { GoogleMap, StandaloneSearchBox } from '@react-google-maps/api';
import React, { Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdSettingsBackupRestore } from 'react-icons/md';
import { RiSave3Fill } from 'react-icons/ri';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCodePlateTypes, getCodeState } from 'reducers/CodeReducer';
import {
  handleVehicleCheckboxChange,
  quickPersonSearcher,
  quickVehicleSearcher
} from 'reducers/NcicReducer';
import {
  handleCheckboxChange,
  handlePersonQuickSearch
} from 'reducers/PersonSearchReducer';
import { setQuickSearchType } from 'reducers/SettingsReducer';
import {
  clearVehicleForm,
  handleVehicleQuickSearch
} from 'reducers/VehicleSearchReducer';
import { mapDay, mapNight } from '../../config/configureMap';
import {
  restoreMapPositionReducer,
  saveMapPositionReducer,
  setMap
} from '../../reducers/MapReducer';
import {
  hideMapQuickSearchBox,
  hideMapSearchBox,
  setSearchActiveTab
} from '../../reducers/UiReducer';
import MarkerSearchedPlace from './MarkerSearchedPlace';
import MarkersEvents from './MarkersEvents';
import MarkersUnits from './MarkersUnits';
import PageviewIcon from '@mui/icons-material/Pageview';
import { getAccessPermission } from 'reducers/PermissionsReducer';
const RenderMap = (props) => {
  const {
    restoreMapPositionReducer,
    saveMapPositionReducer,
    themeMode,
    websocket,
    setMap,
    mapcenter,
    mapzoom,
    units,
    events,
    map,
    user,
    mapSearchBoxVisibility,
    mapQuickSearchBoxVisibility,
    hideMapSearchBox,
    hideMapQuickSearchBox,
    vehicleFilterValues,
    viewType,
    statesCodes,
    plateTypesCodes
  } = props;
  var [searchedPlace, setSearchedPlace] = useState(null);
  var [searchedPlaceAddress, setSearchedPlaceAddress] = useState(null);
  var [searchBox, setSearchBox] = useState(null);
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const { APP_DEFAULT_STATE, YEAR_REQUIRED } = useSelector(
    (state) => state.appInfo.appEnvInfo
  );

  const [quickSearchPersonForm, setQuickSearchPersonForm] = useState({
    State: APP_DEFAULT_STATE || ''
  });
  const [quickSearchVehicleForm, setQuickSearchVehicleForm] = useState({
    State: APP_DEFAULT_STATE || '',
    ...(YEAR_REQUIRED == true && {
      LicensePlateYear: vehicleFilterValues?.LicensePlateYear?.value || ''
    }),
    LicensePlateTypeCode: vehicleFilterValues?.LicensePlateTypeCode?.value || ''
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const persmissions = useSelector((state) => state.permissions.globals);
  const [canExecNcic, setCanExecNcic] = useState('');
  React.useEffect(() => {
    setCanExecNcic(getAccessPermission('globals', 'Execute NCIC Queries'));
  }, [persmissions]);
  useEffect(() => {
    if (websocket.websocket !== null) {
      dispatch(getCodeState());
      dispatch(getCodePlateTypes());
    }
  }, [websocket]);
  const SaveMapPosition = () => {
    return (
      <Fab
        onClick={() => {
          saveMapPositionReducer();
        }}
        style={{ marginBottom: '10px', backgroundColor: '#FFF' }}
        className="MuiButtonBase-root MuiFab-root MuiFab-sizeSmall"
        aria-label="savemapview">
        <RiSave3Fill />
      </Fab>
    );
  };

  const RestoreMapPosition = () => {
    return (
      <Fab
        onClick={() => {
          restoreMapPositionReducer();
        }}
        style={{
          marginBottom: '10px',
          marginLeft: '10px',
          backgroundColor: '#FFF'
        }}
        className="MuiButtonBase-root MuiFab-root MuiFab-sizeSmall"
        aria-label="restoremapview">
        <MdSettingsBackupRestore />
      </Fab>
    );
  };

  const mapOptions = {
    styles: themeMode === 'day' ? mapDay : mapNight,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: window.google.maps.ControlPosition.BOTTOM_CENTER
    },
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.BOTTOM_CENTER
    }
  };

  const onLoad = React.useCallback(function onLoad(map) {
    setMap(map, units, events);
    const saveMapButtonDiv = document.createElement('div');
    ReactDOM.render(
      <Fragment>
        <SaveMapPosition />
        <RestoreMapPosition />
      </Fragment>,
      saveMapButtonDiv
    );
    map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
      saveMapButtonDiv
    );
  });

  const onLoadSearchBox = (ref) => setSearchBox(ref);

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces();
    if (place.length > 0) {
      let position = {
        lat: parseFloat(place[0].geometry.location.lat()),
        lng: parseFloat(place[0].geometry.location.lng())
      };
      let address = place[0].formatted_address;

      map.setCenter(position);
      map.setZoom(16);

      setSearchedPlace(position);
      setSearchedPlaceAddress(address);
    }
  };

  const closeMapSearchBox = () => {
    hideMapSearchBox();
  };
  const closeMapQuickSearchBox = () => {
    hideMapQuickSearchBox();
  };

  const closeMapSearchedPlaceMarker = () => {
    setSearchedPlace(null);
  };
  const handleQuickSearch = (value) => {
    if (canExecNcic) {
      if (value == 'person') {
        if (quickSearchPersonForm.OperatorLicenseNumber) {
          dispatch(setSearchActiveTab(1));
          dispatch(handleCheckboxChange(true));
          navigate('/Search');
          dispatch(quickPersonSearcher(quickSearchPersonForm, 'quick'));
        } else {
          alert('Operator License Number Required');
        }

        // dispatch(handlePersonQuickSearch(quickSearchValue));
      } else if (value == 'vehicle') {
        if (
          quickSearchVehicleForm.LicensePlateNumber &&
          quickSearchVehicleForm.LicensePlateTypeCode
        ) {
          if (
            YEAR_REQUIRED != true ||
            quickSearchVehicleForm.LicensePlateYear
          ) {
            dispatch(setSearchActiveTab(0));
            dispatch(handleVehicleCheckboxChange(true));

            navigate('/Search');
            dispatch(clearVehicleForm());
            dispatch(quickVehicleSearcher(quickSearchVehicleForm, 'quick'));
          } else {
            alert(
              'LicensePlateNumber and LicensePlateTypeCode and LicensePlateYear are required fields'
            );
          }
        } else {
          alert(
            'LicensePlateNumber and LicensePlateTypeCode and LicensePlateYear are required fields'
          );
        }
      }
    } else {
      if (value == 'person') {
        dispatch(setSearchActiveTab(1));
        dispatch(handleCheckboxChange(false));
        navigate('/Search');
        dispatch(
          handlePersonQuickSearch(
            quickSearchPersonForm.OperatorLicenseNumber,
            quickSearchPersonForm.State
          )
        );
      } else if (value == 'vehicle') {
        dispatch(setSearchActiveTab(0));
        dispatch(handleVehicleCheckboxChange(false));
        navigate('/Search');
        dispatch(handleVehicleQuickSearch(quickSearchVehicleForm));
      }
    }
  };
  const handleSearchTypeChange = (event) => {
    dispatch(setQuickSearchType(event.target.value));
  };

  const handlePersonSearch = (event, name = '', newValue = '') => {
    const newForm = { ...quickSearchPersonForm };
    if (name) {
      newForm[name] = newValue;
    } else {
      newForm[event.target.name] = event.target.value;
    }
    setQuickSearchPersonForm(newForm);
  };
  const handleVehicleSearch = (event, name = '', newValue = '') => {
    const newForm = { ...quickSearchVehicleForm };
    if (name) {
      newForm[name] = newValue;
    } else {
      newForm[event.target.name] = event.target.value;
    }
    setQuickSearchVehicleForm(newForm);
  };
  // console.log('quickSearchType persom', quickSearchPersonForm);
  // console.log('quickSearchType vahicle', quickSearchVehicleForm);
  return (
    <Fragment>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}>
        <GoogleMap
          id="map"
          center={mapcenter}
          zoom={mapzoom}
          clickableIcons={false}
          options={mapOptions}
          mapContainerStyle={{
            height: '100%',
            width: '100%'
          }}
          onLoad={onLoad}>
          {mapSearchBoxVisibility && (
            <StandaloneSearchBox
              onLoad={onLoadSearchBox}
              onPlacesChanged={onPlacesChanged}>
              <div
                style={{
                  left: '50%',
                  height: `35px`,
                  marginLeft: '-150px',
                  marginTop: `40px`,
                  position: 'fixed',
                  display: `flex`,
                  alignItems: `center`
                }}>
                <Paper elevation={3} style={{ padding: '1.2rem' }}>
                  <TextField
                    size="small"
                    label="Address Search"
                    variant="outlined"
                    style={{ width: 260 }}
                  />
                  <AiOutlineCloseCircle
                    onClick={closeMapSearchBox}
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '3px',
                      top: '3px',
                      fontSize: '1.1rem'
                    }}
                  />
                </Paper>
              </div>
            </StandaloneSearchBox>
          )}
          <Hidden mdDown>
            {mapQuickSearchBoxVisibility && (
              <div
                style={{
                  margin:
                    viewType == 'complex' ? '0px 300px' : '0px 400px 0px 550px',
                  borderBottom: '1px solid #333'
                }}>
                <div
                  style={{
                    // height: `35px`,

                    // position: 'fixed',
                    // left: '300px',
                    width: '100%',
                    alignItems: `center`,
                    borderBottom: '1px solid #333'
                  }}>
                  <Paper
                    variant="outlined"
                    square
                    style={{
                      backgroundColor: theme.palette.bgElements,
                      padding: '5px 10px',
                      borderBottom: '1px solid #888'
                    }}>
                    <h6 style={{ color: 'rgb(11, 85, 161)' }}>Quick Search</h6>
                    <Grid
                      container
                      spacing={2}
                      className="pb-2"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                      <Grid item sm={12} lg={7}>
                        <Grid container spacing={2}>
                          <Grid item sm={3}>
                            <TextField
                              size="small"
                              label="LPN"
                              name="LicensePlateNumber"
                              value={
                                quickSearchVehicleForm['LicensePlateNumber']
                              }
                              onChange={handleVehicleSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <Autocomplete
                              id="state"
                              name="State"
                              autoHighlight
                              autoSelect
                              size="small"
                              value={
                                { Code: quickSearchVehicleForm['State'] } || ''
                              }
                              onChange={(event, newValue) => {
                                handleVehicleSearch(
                                  event,
                                  'State',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...statesCodes
                              ]}
                              getOptionLabel={(option) => option.Code || ''}
                              isOptionEqualToValue={() => true}
                              renderOption={(
                                innerProps,
                                option,
                                { selected }
                              ) => (
                                <li {...innerProps}>
                                  <small>
                                    <b>{option.Code || ''} </b> -
                                    {option.Description || ''}
                                  </small>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="State"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={2}>
                            <TextField
                              size="small"
                              label="Year"
                              name="LicensePlateYear"
                              value={quickSearchVehicleForm['LicensePlateYear']}
                              onChange={handleVehicleSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <Autocomplete
                              id="LPN Type"
                              name="LicensePlateTypeCode"
                              size="small"
                              autoHighlight
                              autoSelect
                              isOptionEqualToValue={() => true}
                              value={{
                                Code:
                                  quickSearchVehicleForm[
                                    'LicensePlateTypeCode'
                                  ] || ''
                              }}
                              onChange={(event, newValue) => {
                                handleVehicleSearch(
                                  event,
                                  'LicensePlateTypeCode',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...plateTypesCodes
                              ]}
                              getOptionLabel={(option) => option.Code || ''}
                              renderOption={(
                                innerProps,
                                option,
                                { selected }
                              ) => (
                                <li {...innerProps}>{option.Code || '-'}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="LPN Type"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={1}>
                            <Tooltip title="Vehicle Search">
                              <Button
                                onClick={() => handleQuickSearch('vehicle')}
                                variant="contained"
                                size="small"
                                style={{
                                  minWidth: '38px',
                                  minHeight: '38px',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                                color="primary">
                                <SearchIcon style={{ fontSize: '1.5rem' }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} lg={5}>
                        <Grid container spacing={2}>
                          <Grid item sm={6}>
                            <TextField
                              size="small"
                              label="OLN"
                              name="OperatorLicenseNumber"
                              value={
                                quickSearchPersonForm['OperatorLicenseNumber']
                              }
                              onChange={handlePersonSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <Autocomplete
                              id="state"
                              name="State"
                              autoHighlight
                              autoSelect
                              size="small"
                              isOptionEqualToValue={() => true}
                              value={
                                { Code: quickSearchPersonForm['State'] } || ''
                              }
                              onChange={(event, newValue) => {
                                handlePersonSearch(
                                  event,
                                  'State',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...statesCodes
                              ]}
                              getOptionLabel={(option) => option.Code || ''}
                              renderOption={(
                                innerProps,
                                option,
                                { selected }
                              ) => (
                                <li {...innerProps}>
                                  <small>
                                    <b>{option.Code || ''} </b> -{' '}
                                    {option.Description || ''}
                                  </small>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="State"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={2}>
                            <Tooltip title="Person Search">
                              <Button
                                onClick={() => handleQuickSearch('person')}
                                variant="contained"
                                size="small"
                                style={{
                                  minWidth: '38px',
                                  minHeight: '38px',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                                color="primary">
                                <SearchIcon style={{ fontSize: '1.5rem' }} />
                              </Button>
                            </Tooltip>
                            {/* <Fab
                              size="small"
                              backgroundColor={'red'}
                              // color={theme.palette.primary}
                              aria-label="add"
                              onClick={() => handleQuickSearch('person')}>
                              <SearchIcon />
                            </Fab> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </div>
            )}
          </Hidden>
          <Hidden mdUp>
            {mapQuickSearchBoxVisibility && (
              <div
                style={{
                  margin: '0px',
                  borderBottom: '1px solid #333'
                }}>
                <div
                  style={{
                    width: '100%',
                    alignItems: `center`,
                    borderBottom: '1px solid #333'
                  }}>
                  <Paper
                    variant="outlined"
                    square
                    style={{
                      backgroundColor: theme.palette.bgElements,
                      padding: '5px 10px',
                      borderBottom: '1px solid #888'
                    }}>
                    <h6 style={{ color: 'rgb(11, 85, 161)' }}>Quick Search</h6>
                    <Grid container spacing={2} className="pb-2">
                      <Grid item sm={12} lg={7}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              label="LPN"
                              name="LicensePlateNumber"
                              value={
                                quickSearchVehicleForm['LicensePlateNumber']
                              }
                              onChange={handleVehicleSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              id="state"
                              name="State"
                              size="small"
                              autoHighlight
                              autoSelect
                              isOptionEqualToValue={() => true}
                              value={quickSearchVehicleForm['State']}
                              onChange={(event, newValue) => {
                                handleVehicleSearch(
                                  event,
                                  'State',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...statesCodes
                              ]}
                              getOptionLabel={(option) => option.Code || ''}
                              renderOption={(
                                innerProps,
                                option,
                                { selected }
                              ) => (
                                <li {...innerProps}>
                                  <b>{option.Code || ''} </b> -{' '}
                                  {option.Description || ''}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="State"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              label="Year"
                              name="LicensePlateYear"
                              value={quickSearchVehicleForm['LicensePlateYear']}
                              onChange={handleVehicleSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={10} sm={5}>
                            <Autocomplete
                              id="Plate Type"
                              name="LicensePlateTypeCode"
                              size="small"
                              autoHighlight
                              autoSelect
                              isOptionEqualToValue={() => true}
                              value={
                                quickSearchVehicleForm['LicensePlateTypeCode']
                              }
                              onChange={(event, newValue) => {
                                handleVehicleSearch(
                                  event,
                                  'LicensePlateTypeCode',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...plateTypesCodes
                              ]}
                              getOptionLabel={(option) => option.Code || ''}
                              renderOption={(
                                innerProps,
                                option,
                                { selected }
                              ) => (
                                <li {...innerProps}>{option.Code || '-'}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="LPN Type"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={2} sm={1}>
                            <Tooltip title="Vehicle Search">
                              <Button
                                onClick={() => handleQuickSearch('vehicle')}
                                variant="contained"
                                size="small"
                                style={{
                                  minWidth: '38px',
                                  minHeight: '38px',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                                color="primary">
                                <SearchIcon style={{ fontSize: '1.5rem' }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} lg={5}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              label="OLN"
                              name="OperatorLicenseNumber"
                              value={
                                quickSearchPersonForm['OperatorLicenseNumber']
                              }
                              onChange={handlePersonSearch}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={10} sm={4}>
                            <Autocomplete
                              id="state"
                              name="State"
                              size="small"
                              autoHighlight
                              autoSelect
                              isOptionEqualToValue={() => true}
                              value={quickSearchPersonForm['State']}
                              onChange={(event, newValue) => {
                                handlePersonSearch(
                                  event,
                                  'State',
                                  newValue?.Code
                                );
                              }}
                              options={[
                                { Code: '', Description: '' },
                                ...statesCodes
                              ]}
                              getOptionLabel={(option) => option.Code || ''}
                              renderOption={(
                                innerProps,
                                option,
                                { selected }
                              ) => (
                                <li {...innerProps}>
                                  <b>{option.Code || ''} </b> -{' '}
                                  {option.Description || ''}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ fontSize: '1 rem' }}
                                  variant="outlined"
                                  label="State"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <Tooltip title="Person Search">
                              <Button
                                onClick={() => handleQuickSearch('person')}
                                variant="contained"
                                size="small"
                                style={{
                                  minWidth: '38px',
                                  minHeight: '38px',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                                color="primary">
                                <SearchIcon style={{ fontSize: '1.5rem' }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </div>
            )}
          </Hidden>
          {searchedPlace && (
            <MarkerSearchedPlace
              closeMapSearchedPlaceMarker={closeMapSearchedPlaceMarker}
              searchedPlace={searchedPlace}
              searchedPlaceAddress={searchedPlaceAddress}
            />
          )}
          <MarkersEvents />
          <MarkersUnits />
        </GoogleMap>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  websocket: state.websocket,
  map: state.map.map,
  mapcenter: state.map.mapcenter,
  mapzoom: state.map.mapzoom,
  mapbounds: state.map.mapbounds,
  units: state.units.units,
  events: state.events.events,
  quickSearchType: state.settings.quickSearchType,
  mapSearchBoxVisibility: state.ui.mapSearchBoxVisibility,
  mapQuickSearchBoxVisibility: state.ui.mapQuickSearchBoxVisibility,
  mapDefaultView: state.settings.mapDefaultView,
  filterValues: state.personSearch.personForm.filters,
  user: state.user,
  ncicPickerValues: state.ncic.personForm.pickers,
  ncicFilterValues: state.ncic.personForm.filters,
  rid: state.ncic.rid,
  plateTypesCodes: state.codes.plateTypesCodes,
  statesCodes: state.codes.statesCodes,
  ori: state.ncic.ori,
  vehicleFilterValues: state.ncic.vehicleForm.filters,
  metadata: state.ncic.metadata,
  viewType: state.settings.viewType
});

export default connect(mapStateToProps, {
  setMap,
  hideMapSearchBox,
  hideMapQuickSearchBox,
  saveMapPositionReducer,
  restoreMapPositionReducer
})(RenderMap);
