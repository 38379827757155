import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles()((theme) => ({
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  filterDropDown: {
    margin: '0px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0px'
    }
  }
}));

const AlertTypeFilter = (props) => {
  const { classes } = useStyles();
  const [change, setChange] = useState(false);

  const handleFilterOlnStateChange = (event, value) => {
    props.setAlertType('');
    if (value) props.setAlertType(value);
  };

  useEffect(() => {
    setChange(!change);
  }, [props.selectedAlertType]);

  const typesArray = props.alertTypes.map((alertType) => alertType.Code);

  return (
    <FormControl
      size="small"
      className={`${classes.filterDropDown} ${classes.filterDropDown}`}
      variant="outlined">
      <Autocomplete
        key={change}
        label="plate-state"
        autoSelect
        autoHighlight
        value={props.selectedAlertType ? props.selectedAlertType : ''}
        onChange={handleFilterOlnStateChange}
        options={['', ...typesArray]}
        getOptionLabel={(option) => option || ''}
        isOptionEqualToValue={() => true}
        renderOption={(innerProps, option, { selected }) => (
          <li {...innerProps}>{option || '-'}</li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            className={classes.filter}
            label="Bulletin Type"
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export default AlertTypeFilter;
