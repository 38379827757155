import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from 'utils/functions';
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// import { setMode } from '../../reducers/ThemeReducer';

import {
  setIsPasswordValid,
  setSecondFactor,
  setUserUnitAndLogin,
  validateCredentials,
  setUserUnits,
  setUnitSelected
} from 'reducers/UserReducer';
import { checkForUpdates, getApiVer } from 'reducers/AppInfoReducer';

import projectLogo from 'assets/images/icon-MOBILE.svg';
import splashScreen from 'assets/images/login-splash.jpg';
import ptsLogo from 'assets/images/PTS-logo.png';
import { sendPassResetLink } from 'reducers/UserReducer';
import { notify } from 'reducers/NotifierReducer';
import TextField2 from 'components/TextField2';
import { Autocomplete } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  wrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  card: {
    width: 480,
    maxWidth: '95%',
    background: '#fff',
    ['& img']: {
      width: '100%'
    }
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#037015',
    ['& img']: {
      width: '150px !important',
      height: 'auto'
    }
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    ['& img']: {
      height: 50,
      width: 'auto !important'
    }
  },
  actions: {
    textAlign: 'center',
    marginTop: 30,
    ['& button']: {
      background: '#037015',
      color: '#fff',
      paddingLeft: '4em',
      paddingRight: '4em',
      textTransform: 'uppercase',
      ['&:hover']: {
        background: '#025c11'
      },
      ['&.Mui-disabled']: {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)'
      }
    }
  },
  input: {
    color: '#333 !important',
    ['& input']: {
      boxShadow: 'none !important',
      color: '#333 !important',
      textFillColor: '#333 !important'
    }
  },

  textField: {
    width: '100%'
  },
  formWrap: {
    ['& .MuiInputBase-root']: {
      color: '#333'
    },
    ['& .MuiFormLabel-root']: {
      color: '#999',
      ['&.MuiInputLabel-shrink']: {
        color: theme.palette.primary.main
      }
    },
    ['& .MuiOutlinedInput-notchedOutline']: {
      borderColor: 'rgba(0, 0, 0, 0.23)'
    },
    ['&:hover .MuiOutlinedInput-notchedOutline']: {
      borderColor: 'rgba(0, 0, 0, 0.23)'
    },
    ['& .MuiOutlinedInput-input:-webkit-autofill']: {
      boxShadow: 'none',
      textFillColor: 'inherit'
    },
    ['& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline']: {
      borderColor: theme.palette.primary.main
    }
  },
  passReset: {
    textAlign: 'right',
    ['& span']: {
      fontSize: 13,
      color: '#333',
      cursor: 'pointer',
      ['&:hover']: {
        textDecoration: 'underline'
      }
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const LoginForm = (props) => {
  const {
    userUnits,
    isPasswordValid,
    secondFactor,
    setUserUnitAndLogin,
    validateCredentials,
    setIsPasswordValid,
    setSecondFactor,
    setUserUnits,
    websocket,
    appInfo,
    user
  } = props;

  const { classes, cx } = useStyles();
  const [username, setUsername] = useState('');
  const [unitId, setUnitId] = useState(0);
  const [unitName, setUnitName] = useState('');
  const [removeDialog, setRemoveDialog] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [init, setInit] = useState(false);
  const [view, setView] = useState('login'); // login, pass-reset, login-second-factor
  const [email, setEmail] = useState('');
  const [secondFactorToken, setSecondFactorToken] = useState('');
  let navigate = useNavigate();
  const agencyBasedUnitSetting = useSelector(
    (state) => state.settings.agencyBasedUnitSetting
  );
  const appInfodata = useSelector((state) => state.appInfo.appEnvInfo);
  const { SAVE_PASS } = appInfodata;
  const savePass = appInfodata.SAVE_PASS;
  // console.log('SAVE_PASS', savePass);
  const [loading, setLoading] = useState(true);
  const [loadingUnit, setLoadingUnit] = useState(false);
  useEffect(() => {
    props.getApiVer();
    props.checkForUpdates();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (SAVE_PASS == true || savePass == false) {
      setLoading(false);
    }
  }, [SAVE_PASS]);
  useEffect(() => {
    if (agencyBasedUnitSetting) {
      console.log('user unit', userUnits);
      setUnitName(userUnits[0]);
      setUnitId(userUnits[0]?.ptsUnitID || '');
    }
  }, [userUnits.length, agencyBasedUnitSetting]);
  useEffect(() => {
    const tokenLogin = async () => {
      if (
        websocket.websocket !== null &&
        window.location.search &&
        init === false
      ) {
        const token = window.location.search.split('=')[1];
        if (token) {
          const currentToken = localStorage.getItem('auth');
          if (currentToken !== token) {
            localStorage.setItem('auth', token);
          }
          setInit(true);
          await validateCredentials(null, null, true);
        }
      }
    };
    tokenLogin();
  }, [websocket, validateCredentials, init]);

  useEffect(() => {
    return () => {
      setIsPasswordValid(false);
      setUserUnits(0);
    };
  }, [setIsPasswordValid, setUserUnits]);

  useEffect(() => {
    setSecondFactor(false);
  }, []);
  useEffect(() => {
    if (
      agencyBasedUnitSetting &&
      isPasswordValid === true &&
      userUnits.length > 0
    ) {
      dispatch(setUnitSelected(false));
    } else if (!agencyBasedUnitSetting && userUnits && userUnits.length > 1) {
      dispatch(setUnitSelected(false));
    }
  }, [isPasswordValid, userUnits]);

  useEffect(() => {
    if (secondFactor === true) setView('login-second-factor');
    if (secondFactor === false) setView('login');
  }, [secondFactor]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleUnitChange = (value) => {
    // console.log('value', value);
    setUnitName(value);
    setUnitId(value?.ptsUnitID || '');
  };
  const handleResourceRemoveChange = (value) => {
    setRemoveDialog(false);

    submitForm(value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSecondFactorTokenChange = (event) => {
    setSecondFactorToken(event.target.value);
  };
  const submitForm = async (event) => {
    if (event.target) {
      event.preventDefault();
    }
    if (isPasswordValid === false) {
      validateCredentials(username, password);
    } else {
      const unit = userUnits.filter((u) => u.ptsUnitID === unitId);
      dispatch(setUnitSelected(true));
      if (agencyBasedUnitSetting) {
        try {
          setLoadingUnit(true);
          const service = websocket?.websocket.service('cad-resources');
          service.timeout = 30000;
          const { AgencyID, Username, ptsPersonID, FullName } =
            user?.userData?.user || {};
          // console.log('ser?.userData?.user.', user?.userData?.user);

          if (event === true) {
            const removedResources = await service.patch(
              null,
              { Ending: new Date(), UpdatedBy: Username },

              {
                query: { ptsParentID: unitId, ParentType: 'Unit' }
              }
            );
          }
          const ResourceExist = await service.find({
            query: {
              ptsParentID: unitId,
              ParentType: 'Unit',
              ptsChildID: ptsPersonID
            }
          });
          // console.log('ResourceExist', ResourceExist);
          if (ResourceExist.data.length == 0) {
            const date = new Date();
            // date.setDate(date.getDate() - 1);
            const addedResource = await service.create({
              ComponentName: FullName,
              ptsParentID: unit[0].ptsUnitID,
              ParentType: 'Unit',
              ptsChildID: ptsPersonID,
              ChildType: 'Person',
              ptsPlaceID: 0,
              CreatedBy: Username,
              Beginning: date
            });
            // console.log('addedResource', addedResource);

            unit[0].UnitResources = addedResource.ComponentName;
            unit[0].CurrentAssignResourceID = addedResource.ptsResourceID;
          } else {
            unit[0].UnitResources = ResourceExist.data[0].ComponentName;
            unit[0].CurrentAssignResourceID =
              ResourceExist.data[0].ptsResourceID;
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoadingUnit(false);
        }
      }
      // console.log('unit[0]', unit[0]);
      setUserUnitAndLogin(unit[0]);
      navigate('/Main');
    }
  };

  const passResetSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendPassResetLink(email);
      setEmail('');
      setView('login');
      notify(
        'Reset password link will be sent to the email provided, if it exists in our database.',
        'info',
        '600px'
      );
    } catch {
      notify(
        'Error sending password reset link',
        'error',
        '300px',
        'small-toast'
      );
    }
  };

  const secondFactorSubmit = async (event) => {
    event.preventDefault();
    validateCredentials(username, password, false, secondFactorToken);
  };
  const checkAllResources = async () => {
    const unit = userUnits?.filter((u) => u.ptsUnitID === unitId);

    try {
      setLoadingUnit(true);
      const service = websocket?.websocket.service('cad-resources');
      service.timeout = 30000;
      const { ptsPersonID } = user?.userData?.user || {};
      // console.log('ser?.userData?.user.', user?.userData?.user);
      const ResourceList = await service.find({
        query: {
          ptsParentID: unitId,
          ParentType: 'Unit'
        }
      });
      if (ResourceList.total > 0) {
        if (ResourceList.total == 1) {
          const userId = ResourceList.data[0].ptsChildID;
          if (userId == ptsPersonID) {
            submitForm(false);
          } else {
            setRemoveDialog(true);
          }
        } else {
          setRemoveDialog(true);
        }
      } else {
        submitForm(false);
      }

      // console.log('ResourceExist', ResourceExist);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingUnit(false);
    }
  };
  const handleSubmit = (event) => {
    if (event.target) {
      event.preventDefault();
    }
    if (isPasswordValid === false) {
      submitForm(event);
    } else {
      if (agencyBasedUnitSetting) {
        checkAllResources();
      } else {
        submitForm(event);
      }
    }
  };
  console.log('unit name', unitName);
  const renderLoginForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop className={classes.backdrop} open={loadingUnit}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          open={removeDialog}
          onClose={() => setRemoveDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle id="alert-dialog-title">
            Want To Remove All The Existing Resources
          </DialogTitle>

          <DialogActions>
            <Button onClick={() => handleResourceRemoveChange(false)}>
              NO
            </Button>
            <Button onClick={() => handleResourceRemoveChange(true)} autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
        {isPasswordValid === false && (
          <>
            <div className={classes.formWrap}>
              <TextField2
                className={classes.textField}
                required
                type="text"
                variant="outlined"
                label="Username"
                placeholder="Enter your username"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div className={classes.formWrap}>
              <TextField2
                className={classes.textField}
                required
                variant="outlined"
                label="Password"
                placeholder="Enter your password"
                type={SAVE_PASS ? 'password' : 'mask'}
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className={classes.passReset}>
              <span onClick={() => setView('pass-reset')}>Password reset</span>
            </div>
          </>
        )}
        {isPasswordValid === true && userUnits.length > 0 && (
          <div className={cx('mb-3', classes.formWrap)}>
            <Autocomplete
              id="units-select"
              size="small"
              value={unitName || ''}
              onChange={(event, newValue) => {
                handleUnitChange(newValue);
              }}
              autoHighlight
              autoSelect
              options={userUnits}
              getOptionLabel={(option) => option.Unit || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  variant="outlined"
                  label="Select Unit"
                />
              )}
            />
            {/* <TextField
              fullWidth
              required
              id="units-select"
              select
              label="Select Unit"
              value={unitId}
              size="small"
              onChange={handleUnitChange}
              InputProps={{
                className: classes.input
              }}
              variant="outlined">
              {userUnits.map(unit => (
                <MenuItem key={unit.ptsUnitID} value={unit.ptsUnitID}>
                  {unit.Unit}
                </MenuItem>
              ))}
            </TextField> */}
          </div>
        )}
        {isPasswordValid === false && (
          <div className={classes.actions}>
            <Button
              className={classes.btn}
              type="submit"
              variant="contained"
              size="large">
              Login
            </Button>
          </div>
        )}
        {isPasswordValid === true && userUnits.length > 0 && (
          <div className={classes.actions}>
            <Button
              className={classes.btn}
              disabled={unitId ? false : true}
              type="submit"
              variant="contained"
              size="large">
              Select Unit
            </Button>
          </div>
        )}
      </form>
    );
  };

  const renderPassReset = () => {
    const valid = validateEmail(email);
    const error = valid || !email ? '' : 'Please enter valide e-mail address';
    return (
      <form onSubmit={passResetSubmit}>
        <div className={classes.formWrap}>
          <TextField2
            required
            variant="outlined"
            label="E-mail address"
            fullWidth
            placeholder="Enter your e-mail address"
            value={email}
            onChange={(ev, val) => setEmail(val.toLowerCase())}
            autoComplete="email"
            size="small"
            type="email"
            className={classes.textField}
            error={error}
          />
        </div>
        <div className={classes.passReset}>
          <span
            onClick={() => {
              setView('login');
              setSecondFactor(false);
            }}>
            Sign in
          </span>
        </div>
        <div className={classes.actions}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            disabled={!valid}
            className={classes.btn}>
            Send reset link
          </Button>
        </div>
      </form>
    );
  };

  const renderSecondFactor = () => {
    return (
      <form onSubmit={secondFactorSubmit}>
        <div className={cx('mb-3', classes.formWrap)}>
          <FormControl className="w-100">
            <TextField
              required
              variant="outlined"
              label="Token"
              fullWidth
              placeholder="Enter token"
              type="text"
              size="small"
              value={secondFactorToken}
              onChange={handleSecondFactorTokenChange}
              InputProps={{
                className: classes.input
              }}
            />
          </FormControl>
        </div>
        <div className={classes.passReset}>
          <span
            onClick={() => {
              setView('login');
              setSecondFactor(false);
            }}>
            Sign in
          </span>
        </div>
        <div className={classes.actions}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            className={classes.btn}>
            Login
          </Button>
        </div>
      </form>
    );
  };
  // console.log('userUnits', userUnits);

  return (
    <Fragment>
      <div className={cx('app-wrapper min-vh-100', classes.wrap)}>
        <Container maxWidth="sm">
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              lg={12}
              className="d-flex flex-column align-items-center">
              <Card className={classes.card}>
                <div className={classes.branding}>
                  <img src={projectLogo} alt="MOBILE icon" />
                </div>
                <div className={classes.branding2}>
                  <img src={ptsLogo} alt="PTS logo" />
                </div>
                <CardContent className={classes.content}>
                  {view === 'pass-reset' && renderPassReset()}
                  {view === 'login-second-factor' && renderSecondFactor()}
                  {view === 'login' && renderLoginForm()}
                </CardContent>
                <div
                  style={{
                    marginBottom: '5px',
                    marginLeft: '5px',
                    fontWeight: '500'
                  }}>
                  v. {appInfo.appVersion}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  isPasswordValid: state.user.isPasswordValid,
  secondFactor: state.user.secondFactor,
  userUnits: state.user.userUnits,
  websocket: state.websocket,
  appInfo: state.appInfo,
  user: state.user
});

export default connect(mapStateToProps, {
  validateCredentials,
  setUserUnitAndLogin,
  setIsPasswordValid,
  setSecondFactor,
  setUserUnits,
  checkForUpdates,
  getApiVer
})(LoginForm);
