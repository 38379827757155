import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//import localforage from 'localforage';
// import storage from 'redux-persist-indexeddb-storage';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';
import localforage from 'localforage';
import createRootReducer from '../reducers';
import { createReduxHistoryContext, reachify } from 'redux-first-history';
// export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory()
    //other options if needed
  });
const persistConfig = {
  key: `root`,
  whitelist: [
    'offline',
    'theme',
    'user',
    'permissions',
    'settings',
    'codes',
    'data',
    'alerts',
    'ncic',
    'personSearch',
    'vehicleSearch',
    'closeEvents',
    'gunSearch',
    'time',
    'appInfo',
    'CitationReducer'
  ],
  storage: localforage
};
// const persistConfig = {
//   key: 'root',
//   storage: storage('myDB')
// };
const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore
} = createOffline({
  ...offlineConfig,
  persist: false,
  persistAutoRehydrate: false
});

const persistedReducer = persistReducer(
  persistConfig,
  offlineEnhanceReducer(createRootReducer(routerReducer))
);
let store;
if (process.env.NODE_ENV === 'development') {
  store = createStore(
    persistedReducer,
    compose(
      offlineEnhanceStore,
      composeEnhancers(
        applyMiddleware(ReduxThunk, offlineMiddleware, routerMiddleware)
      )
    )
  );
} else {
  store = createStore(
    persistedReducer,
    compose(
      offlineEnhanceStore,
      applyMiddleware(ReduxThunk, offlineMiddleware, routerMiddleware)
    )
  );
}
// let store = createStore(
//   persistedReducer,
//   compose(
//     offlineEnhanceStore,
//     applyMiddleware(ReduxThunk, offlineMiddleware, routerMiddleware(history))
//   )
// );
let persistor = persistStore(store);
export const history = createReduxHistory(store);

export default { store, persistor };
