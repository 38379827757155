
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8homealarm100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="100" height="100"><path d="M32 3 1 28l.127.17C2.074 29.433 3.437 30 5 30h3v26h48V30h3c1.563 0 2.926-.567 3.873-1.83L63 28 32 3zm-1.44 17.01h2.88c.99 0 1.71.96 1.44 1.93l-.51 1.9c3.75.58 5.63 2.71 5.63 4.92 0 5.65 1.18 8.89 1.89 10.36l-20.18.78c.45-.8 2.29-4.09 2.29-11.14 0-2.21 1.88-4.34 5.63-4.92l-.51-1.9c-.27-.97.45-1.93 1.44-1.93zM20 42h24s2 .444 2 4H35a3 3 0 1 1-6 0H18c0-3.556 2-4 2-4z"/></SvgIcon>
      );
    }
  
    export default icons8homealarm100Icon;