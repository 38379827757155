import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles()(theme => ({
  formSection: {
    paddingBottom: '10px'
  }
}));

const ModifiersFilter = props => {
  const { classes } = useStyles();

  const handleFilterChange = (event, values) => {
    if (values) props.setSelectedModifier(values);
  };

  const modifiersArray = props.modifiers.map(modifier => ({
    code: modifier.Code
  }));

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <Autocomplete
        label="modifiers"
        multiple
        autoSelect
        autoFocus
        filterSelectedOptions={true}
        value={props.selectedModifiers || ''}
        isOptionEqualToValue={() => true}
        onChange={handleFilterChange}
        options={[{ Code: '', Description: '' }, ...modifiersArray]}
        getOptionLabel={option => option.code || ''}
        renderOption={option => option.code || '-'}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="Modifiers"
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export default ModifiersFilter;
