import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';

const useStyles = makeStyles()((theme) => ({
  col: {
    padding: '16px 16px 0'
  },
  row: {
    margin: `0 -4px`,
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 8px)',
    '& .MuiFormHelperText-root': errStyle
  },
  innerRow: {
    margin: `0 -4px`,
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 8px)',
    alignItems: 'flex-start',
    '& .MuiFormHelperText-root': errStyle
  }
}));

export function Row(props) {
  const { classes, cx } = useStyles();
  const { className, children } = props;
  return <div className={cx(classes.row, className)}>{children}</div>;
}

export function RowInner(props) {
  const { classes, cx } = useStyles();
  const { className, children } = props;
  return <div className={cx(classes.innerRow, className)}>{children}</div>;
}

export function ColCard(props) {
  const { classes, cx } = useStyles();
  const { className, children, minWidth = 1, maxWidth = '100%' } = props;
  return (
    <Card
      className={cx(classes.col, className)}
      variant="outlined"
      style={gridStyle(minWidth, maxWidth)}>
      {children}
    </Card>
  );
}

export function Fills(props) {
  const { className, no = 5 } = props;
  const arr = Array.from(Array(no).keys());
  return (
    <>
      {arr.map((n) => (
        <div
          className={className}
          key={n}
          style={{ marginTop: 0, marginBottom: 0 }}
        />
      ))}
    </>
  );
}

export const fieldMargin = '0 4px 8px';
export const errStyle = {
  margin: '2px 0 0 4px',
  lineHeight: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export default {
  // form row
  row: {
    margin: `0 -4px`,
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 8px)',
    '& .MuiFormHelperText-root': errStyle
  },
  rowSpaces: {
    margin: `0 -4px`,
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 8px)',
    justifyContent: 'space-between',
    '& .MuiFormHelperText-root': errStyle
  },
  // 100% width
  w100pr: {
    width: '100%',
    margin: fieldMargin
  },
  // 50% width
  w50pr: gridStyle(50, '50%'),
  autocomplete: {
    margin: '0 4px 8px'
  },
  option: {
    fontSize: 14
  }
};

/* Used in styling flex forms */
export function gridStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: fieldMargin
  };
}
