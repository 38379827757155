import React from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash.throttle';

import { RiMapPin2Line } from 'react-icons/ri';
import { GrMap } from 'react-icons/gr';
import { GrMapLocation } from 'react-icons/gr';

const autocompleteService = { current: null, details: null };

const useStyles = makeStyles()((theme) => ({
  icon: {
    marginRight: theme.spacing(2)
  }
}));

function AddressFilter(props) {
  const { wsClient, ptsPlaces, ptsAddresses, googleAddresses, map } = props;
  const { classes } = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [addressType, setAddressType] = React.useState('none');

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (googleAddresses) {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
        autocompleteService.details =
          new window.google.maps.places.PlacesService(map);
      }
      if (!autocompleteService.current) {
        return undefined;
      }
      if (!autocompleteService.details) {
        return undefined;
      }
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (ptsAddresses === true && ptsPlaces === true) {
      (async () => {
        try {
          const service = wsClient.websocket.service('ptsaddresslistview');
          service.timeout = 20000;
          const addresses = await service.find({
            query: {
              FullAddressText: inputValue
            }
          });
          if (addresses.length > 0) {
            setAddressType('pts');
            setOptions(addresses);
          } else {
            if (googleAddresses) {
              if (inputValue.length > 2) {
                setAddressType('google');
                fetch({ input: inputValue }, (results) => {
                  if (active) {
                    let newOptions = [];

                    if (value) {
                      newOptions = [value];
                    }

                    if (results) {
                      newOptions = [...newOptions, ...results];
                    }
                    setOptions(newOptions);
                  }
                });
              } else {
                setOptions([]);
              }
            } else {
              setOptions([]);
            }
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }

    if (ptsAddresses === true && ptsPlaces === false) {
      (async () => {
        try {
          const service = wsClient.websocket.service('ptsaddresslistview');
          service.timeout = 20000;
          const addresses = await service.find({
            query: {
              FullAddressText: inputValue,
              PlaceID: null
            }
          });
          if (addresses.length > 0) {
            setAddressType('pts');
            setOptions(addresses);
          } else {
            if (googleAddresses) {
              if (inputValue.length > 2) {
                setAddressType('google');
                fetch({ input: inputValue }, (results) => {
                  if (active) {
                    let newOptions = [];

                    if (value) {
                      newOptions = [value];
                    }

                    if (results) {
                      newOptions = [...newOptions, ...results];
                    }
                    setOptions(newOptions);
                  }
                });
              } else {
                setOptions([]);
              }
            } else {
              setOptions([]);
            }
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }

    if (ptsAddresses === false && ptsPlaces === true) {
      (async () => {
        try {
          const service = wsClient.websocket.service('ptsaddresslistview');
          service.timeout = 20000;
          const addresses = await service.find({
            query: {
              FullAddressText: inputValue,
              PlaceID: 'notnull'
            }
          });
          if (addresses.length > 0) {
            setAddressType('pts');
            setOptions(addresses);
          } else {
            if (googleAddresses) {
              if (inputValue.length > 2) {
                setAddressType('google');
                fetch({ input: inputValue }, (results) => {
                  if (active) {
                    let newOptions = [];

                    if (value) {
                      newOptions = [value];
                    }

                    if (results) {
                      newOptions = [...newOptions, ...results];
                    }
                    setOptions(newOptions);
                  }
                });
              } else {
                setOptions([]);
              }
            } else {
              setOptions([]);
            }
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }

    if (ptsAddresses === false && ptsPlaces === false) {
      if (googleAddresses) {
        if (inputValue.length > 2) {
          setAddressType('google');
          fetch({ input: inputValue }, (results) => {
            if (active) {
              let newOptions = [];

              if (value) {
                newOptions = [value];
              }

              if (results) {
                newOptions = [...newOptions, ...results];
              }
              setOptions(newOptions);
            }
          });
        } else {
          setOptions([]);
        }
      } else {
        setOptions([]);
      }
    }

    return () => {
      active = false;
    };
  }, [
    value,
    inputValue,
    fetch,
    wsClient.websocket,
    googleAddresses,
    ptsAddresses,
    ptsPlaces,
    map
  ]);

  return (
    <Autocomplete
      id="destination"
      getOptionLabel={(option) => {
        if (option.FullAddressText) {
          if (option.PlaceName !== null) {
            return `${option.PlaceName} - ${option.FullAddressText}`;
          } else {
            return option.FullAddressText;
          }
        } else {
          return option.formatted_address;
        }
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={() => true}
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue !== null && newValue.place_id) {
          const service = autocompleteService.details;
          service.getDetails(
            {
              placeId: newValue.place_id
            },
            (place, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setValue(place);
              }
            }
          );
        } else {
          setValue(newValue);
          if (newValue && newValue.ptsPlaceID !== null) {
            props.onPlaceValueSet(newValue);
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add a destination"
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(innerProps, option, { selected }) => {
        if (addressType === 'google' && option.structured_formatting) {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
          return (
            <li {...innerProps}>
              <Grid container alignItems="center">
                <Grid item>
                  <RiMapPin2Line
                    className={classes.icon}
                    style={{ color: 'black', fontSize: '24px' }}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        } else {
          if (option.PlaceName === null) {
            return (
              <li {...innerProps}>
                <Grid container alignItems="center">
                  <Grid item>
                    <GrMap
                      className={classes.icon}
                      style={{ color: 'green', fontSize: '24px' }}
                    />
                  </Grid>
                  <Grid item xs>
                    <span style={{ fontWeight: 400 }}>
                      {option.FullAddressText}
                    </span>

                    <Typography variant="body2" color="textSecondary">
                      {option.PlaceName}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          } else {
            return (
              <li {...innerProps}>
                <Grid container alignItems="center">
                  <Grid item>
                    <GrMapLocation
                      className={classes.icon}
                      style={{ color: 'red', fontSize: '24px' }}
                    />
                  </Grid>
                  <Grid item xs>
                    <span style={{ fontWeight: 400 }}>
                      {option.FullAddressText}
                    </span>

                    <Typography variant="body2" color="textSecondary">
                      {option.PlaceName}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }
        }
      }}
    />
  );
}

AddressFilter.defaultProps = {
  ptsPlaces: true,
  ptsAddresses: true,
  googleAddresses: true
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  map: state.map.map
});

export default connect(mapStateToProps)(AddressFilter);
