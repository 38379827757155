import React, { useState } from 'react';
import { Badge, Button, IconButton, useTheme } from '@mui/material';
import Hotkeys from 'react-hot-keys';

import { jsPDF } from 'jspdf';
import nl2br from 'react-newline-to-break';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import printJS from 'print-js';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import PrintIcon from '@mui/icons-material/Print';
import PageviewIcon from '@mui/icons-material/Pageview';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import SearchForm from 'global-components/NcicSearchComponents/SearchForm';
const ShortcutsFunctionality = props => {
  const [url, setUrl] = useState('');
  const theme = useTheme();

  const [showSearchbar, setShowSearchbar] = useState(false);
  const {
    searchText,
    setSearchText,
    parentClassName,
    fontSize,
    setFontSize
  } = props;
  const onKeyUp = (keyName, e, handle) => {
    e.preventDefault();
    if (keyName == '1') {
      handleSave();
    } else if (keyName == '2') {
      handlePrint();
    } else if (keyName == '3') {
      setShowSearchbar(!showSearchbar);
    } else if (keyName == '4') {
      handleFontSize(4);
    } else if (keyName == '5') {
      handleFontSize(5);
    }
  };
  const handleSave = () => {
    const element = document.createElement('a');
    const file = new Blob([props.value], {
      type: 'text/plain'
    });
    element.href = URL.createObjectURL(file);
    element.download = 'NCIC-Result.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const handlePrint = () => {
    printJS('print-item', 'html');
  };
  const handleFontSize = from => {
    if (from == 4) {
      if (fontSize < 30) {
        setFontSize(fontSize + 1);
      }
    } else if (from == 5) {
      if (fontSize > 8) {
        setFontSize(fontSize - 1);
      }
    }
  };
  return (
    <div>
      <div>
        {/* {url && (
          <embed
            src={url}
            style={{ position: 'absolute', width: '100%', height: '800px' }}
          />
        )} */}
        <p id="print-item" style={{ visibility: 'hidden', maxHeight: '10px' }}>
          {nl2br(props.value)}
        </p>
        <div
          style={{
            backgroundColor: theme.palette.bgElements,
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '25px',

              justifyContent: 'center'
            }}>
            <IconButton color="secondary" component="span" onClick={handleSave} size="large">
              <AssignmentReturnedIcon fontSize="large" />
            </IconButton>
            {/* <button type="button" onclick="">
              Print Form
            </button> */}
            <IconButton color="secondary" component="span" onClick={handlePrint} size="large">
              <PrintIcon fontSize="large" />
            </IconButton>
            <IconButton
              color="secondary"
              component="span"
              onClick={() => setShowSearchbar(!showSearchbar)}
              size="large">
              <PageviewIcon fontSize="large" />
            </IconButton>
            <IconButton
              color="secondary"
              component="span"
              onClick={() => handleFontSize(4)}
              size="large">
              <LocalHospitalIcon fontSize="large" />
            </IconButton>
            <IconButton
              color="secondary"
              component="span"
              onClick={() => handleFontSize(5)}
              size="large">
              <IndeterminateCheckBoxIcon fontSize="large" />
            </IconButton>
          </div>
          {parentClassName == 'ncic-history' && (
            <Button
              style={{
                width: '180px',
                height: '40px',
                padding: '0px'
              }}
              size="small"
              startIcon={<DeleteOutlineIcon />}
              onClick={() => props.clearResults()}
              variant="contained"
              color="primary">
              Clear History
            </Button>
          )}
        </div>
        {showSearchbar && (
          <SearchForm
            parentClassName={parentClassName}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        )}
      </div>
      <Hotkeys
        keyName="1"
        // onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
      <Hotkeys
        keyName="2"
        // onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
      <Hotkeys
        keyName="3"
        // onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
      <Hotkeys keyName="4" onKeyUp={onKeyUp} />
      <Hotkeys keyName="5" onKeyUp={onKeyUp} />
    </div>
  );
};

export default ShortcutsFunctionality;
