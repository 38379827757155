/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box
} from '@mui/material';
import Slide from '@mui/material/Slide';

import CloseIcon from '@mui/icons-material/Close';

import Info from './Info';
import RawData from './RawData';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function VehicleDetails(props) {
  const theme = useTheme();
  const { classes } = useStyles();
  const { data, themeMode, ownerDetails, owners } = props;

  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState(0);

  //const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const fullScreen = true;

  const handleClose = () => {
    props.close();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    const setTabsConfig = () => {
      const _tabs = [];
      let tabKey = 0;
      let _parsedNcicData =
        data.NcicResponseData && data.NcicResponseData !== null
          ? JSON.parse(data.NcicResponseData)
          : null;
      let _parsedStateData =
        data.StateResponseData && data.StateResponseData !== null
          ? JSON.parse(data.StateResponseData)
          : null;

      // Info tab
      _tabs.push({
        label: 'Info',
        data: data,
        key: tabKey,
        component: 'Info'
      });
      tabKey++;
      // Raw data tab
      if (
        (data.NcicResponseData && data.NcicResponseData !== null) ||
        (data.StateResponseData && data.StateResponseData !== null)
      ) {
        _tabs.push({
          label: 'NCIC/State',
          data: data.RawData,
          key: tabKey,
          component: 'RawData'
        });
        tabKey++;
      }
      setTabs(_tabs);
    };
    setTabsConfig();
  }, [data]);

  const generateTab = (data, idx) => {
    return <Tab key={idx} label={data.label} />;
  };

  const generateTabs = () => {
    return (
      <Tabs
        value={tab}
        variant="scrollable"
        scrollButtons
        onChange={handleTabChange}
        aria-label="Tabs"
        allowScrollButtonsMobile>
        {tabs.map((row, idx) => {
          return generateTab(row, idx);
        })}
      </Tabs>
    );
  };

  const generateTabContent = (data, idx) => {
    if (data.component === 'Info') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <Info
            data={data.data}
            themeMode={themeMode}
            owners={owners}
            ownerDetails={ownerDetails}
          />
        </TabPanel>
      );
    } else if (data.component === 'RawData') {
      return (
        <TabPanel key={idx} value={tab} index={data.key}>
          <RawData
            data={data.data}
            themeMode={themeMode}
            handleClose={handleClose}
          />
        </TabPanel>
      );
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={true}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={'md'}>
      <DialogTitle
        style={{ padding: '10px 0px 0px 0px' }}
        id="scroll-dialog-title">
        {generateTabs()}
      </DialogTitle>
      <DialogContent
        dividers={true}
        className={classes.body}
        id="person-dialog">
        {tabs.map((row, idx) => {
          return generateTabContent(row, idx);
        })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          startIcon={<CloseIcon />}
          onClick={handleClose}
          color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VehicleDetails;
