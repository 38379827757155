import React from 'react';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';

const maskStyle = {
  lineHeight: 1,
  boxSizing: 'border-box',
  position: 'absolute',
  inset: 13,
  overflow: 'hidden',
  pointerEvents: 'none',
  color: '#999',
}

function TextField2(props) {
  const { disabled, error, value, className, label, multiline, rows, min, max } = props;
  const inputType = props.type;
  let inputClass = '';
  let type = inputType;
  let InputProps = {
    ...props.inputProps,
    autoComplete: 'new-password',
  };
  let passValue = '';
  if (type === 'mask') {
    const len = value.length;
    type = 'text';
    inputClass = 'login-pass';
    passValue = "".padStart(len, '●');
  }
  if (type === 'number' && (max !== undefined || min !== undefined)) {
    InputProps = { inputProps: { min: 0, max: 10 } };
  }
  const onChange = (ev) => {
    const val = ev.target.value;
    if (val === '') return props.onChange(ev, val);
    if (type === 'number') {
      if (val === '-') return props.onChange(ev, val);
      const intVal = parseFloat(val);
      if (max !== undefined && intVal > max) return;
      if (min !== undefined && intVal < min) return;
      return props.onChange(ev, val);
    } else {
      if (max !== undefined && val.length > max) return props.onChange(ev, val.substr(0, max));
    }
    props.onChange(ev, val);
  }

  const input = (
    <TextField
      error={!!error}
      helperText={error ? error : ' '}
      onChange={onChange}
      className={type !== 'mask' ? clsx(className, inputClass) : null}
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
      size="small"
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      type={type}
      InputProps={InputProps}
    />
  );

  if (inputType === 'mask') {
    return (
      <div style={{ position: 'relative' }} className={clsx(className, inputClass)}>
        {input}
        <div style={maskStyle}>{passValue}</div>
      </div>
    );
  }

  return input;
}

export default TextField2;
