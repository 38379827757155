import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles()((theme) => ({
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  filterDropDown: {
    margin: '0px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0px'
    }
  }
}));

const PlateStateFilter = (props) => {
  const { classes } = useStyles();
  const [change, setChange] = useState(false);

  const handleFilterPlateStateChange = (event, value) => {
    props.setSelectedPlateState('');
    if (value) props.setSelectedPlateState(value?.Code || '');
  };

  useEffect(() => {
    setChange(!change);
  }, [props.selectedPlateState]);

  const statusArray = props.states.map((state) => state.Code);

  return (
    <FormControl
      size="small"
      className={`${classes.filterDropDown} ${classes.filterDropDown}`}
      variant="outlined">
      <Autocomplete
        key={change}
        label="plate-state"
        onChange={handleFilterPlateStateChange}
        value={{
          Code: props.selectedPlateState ? props.selectedPlateState : ''
        }}
        options={[{ Code: '', Description: '' }, ...props.states] || []}
        getOptionLabel={(option) => option.Code || ''}
        isOptionEqualToValue={() => true}
        renderOption={(innerProps, option, { selected }) => (
          <li {...innerProps}>
            <b>{option.Code || ''} </b> - {option.Description || ''}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            className={classes.filter}
            label="Plate State"
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export default PlateStateFilter;
