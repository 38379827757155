import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  Button,
  FormControlLabel,
  FormControl,
  ListItem,
  Checkbox,
  TextField,
  Popover,
  Tabs,
  Box,
  Typography,
  Tab,
  Grow
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import {
  setAgencyFilterAll,
  setAgencyFilterNull,
  setAgencyFilter,
  setAgencyUnitFilter,
  setdivisionUnitFilter,
  setZoneFilter,
  setZoneUnitFilter,
  setAgencyEventFilterInitial,
  setAgencyUnitFilterInitial
} from '../../reducers/SettingsReducer';
import { getAgenciesPermissions } from 'reducers/PermissionsReducer';
const useStyles = makeStyles()((theme) => ({
  panel: {
    width: '100%'
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      style={{ minWidth: '100%' }}
      hidden={value !== index}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};
const AgenciesFilter = (props) => {
  const {
    setAgencyFilterAll,
    setAgencyFilterNull,
    setAgencyFilter,
    agencies,
    divisions,
    filterAgencies,
    filterAgenciesUnit,
    setdivisionUnitFilter,

    filterDivision,
    filterDivisionUnit,
    zonesEvent,
    zonesUnit,
    filterZones,
    filterZonesUnit,
    setZoneFilter,
    setZoneUnitFilter
  } = props;
  const [search, setSearch] = React.useState('');
  const [searchDivision, setSearchDivision] = React.useState('');
  const permissions = useSelector((state) => state.permissions);

  const [searchZone, setSearchZone] = React.useState('');
  const dispatch = useDispatch();
  const [filterType, setFilterType] = React.useState(props.separate || 'event');
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (agencies && permissions && permissions['groups']) {
      const ag = [];
      agencies.forEach((agency) => {
        const isPermitted = getAgenciesPermissions(agency.AgencyID);
        if (isPermitted) {
          ag.push(agency);
        }
      });
      // dispatch(setAgencyEventFilterInitial(ag));
      // dispatch(setAgencyUnitFilterInitial(ag));
    }
  }, [
    agencies,
    permissions,
    permissions['groups'],
    permissions['groups'].length
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const selectAll = () => {
    if (value == 0) {
      setAgencyFilterAll(filterType, 'agency');
    } else if (value == 1) {
      setAgencyFilterAll(filterType, 'division');
    } else if (value == 2) {
      setAgencyFilterAll(filterType, 'zone');
    }
  };

  const deSelectAll = () => {
    if (value == 0) {
      setAgencyFilterNull(filterType, 'agency');
    } else if (value == 1) {
      setAgencyFilterNull(filterType, 'division');
    } else if (value == 2) {
      setAgencyFilterNull(filterType, 'zone');
    }
  };

  const onSearchCahnge = (e) => {
    if (value == 0) {
      setSearch(e.target.value);
    } else if (value == 1) {
      if (filterType !== 'unit') {
        setSearchZone(e.target.value);
      }
      setSearchDivision(e.target.value);
    } else if (value == 2) {
      setSearchZone(e.target.value);
    }
  };

  const isAgencyChecked = (agency) => {
    let filter;
    if (filterType == 'event') {
      filter = filterAgencies;
    } else if (filterType == 'unit') {
      filter = filterAgenciesUnit;
    }

    if (!filter) {
      return true;
    } else {
      for (let i = 0; i < filter.length; i++) {
        if (agency === filter[i].AgencyID) {
          return true;
        }
      }
      return false;
    }
  };
  const isDivisionChecked = (division) => {
    let filter;
    if (filterType == 'event') {
      filter = filterDivision;
    } else if (filterType == 'unit') {
      filter = filterDivisionUnit;
    }

    if (!filter) {
      return true;
    } else {
      for (let i = 0; i < filter.length; i++) {
        if (division === filter[i].Code) {
          return true;
        }
      }
      return false;
    }
  };
  const handleAgencyChange = (event, agency, isChecked) => {
    if (filterType == 'event') {
      setAgencyFilter(event, agency, isChecked);
    } else if (filterType == 'unit') {
      dispatch(setAgencyUnitFilter(event, agency, isChecked));
    }
  };
  const handleDivisionChange = (event, agency, isChecked) => {
    if (filterType == 'unit') {
      setdivisionUnitFilter(event, agency, isChecked);
    }
  };
  const filterAgency = agencies.filter((a) => {
    // const isPermitted = getAgenciesPermissions(a.AgencyID);
    // if (!isPermitted) return false;
    if (search !== '') {
      return a.AgencyID.toLowerCase().includes(search.toLowerCase());
    } else {
      return true;
    }
  });
  const filterDivison = divisions.filter((a) => {
    if (searchDivision !== '') {
      return a.Code.toLowerCase().includes(searchDivision.toLowerCase());
    } else {
      return true;
    }
  });
  const zonesFilterUnit = zonesUnit.filter((a) => {
    if (searchZone !== '') {
      return a.ZoneCode.toLowerCase().includes(searchZone.toLowerCase());
    } else {
      return true;
    }
  });
  const zonesFilterEvent = zonesEvent.filter((a) => {
    if (searchZone !== '') {
      return a.ZoneCode.toLowerCase().includes(searchZone.toLowerCase());
    } else {
      return true;
    }
  });
  const isZonesChecked = (zone) => {
    let filter;
    if (filterType == 'event') {
      filter = filterZones;
    } else if (filterType == 'unit') {
      filter = filterZonesUnit;
    }

    if (!filter) {
      return true;
    } else {
      for (let i = 0; i < filter.length; i++) {
        if (zone === filter[i].ZoneCode) {
          return true;
        }
      }
      return false;
    }
  };

  const handleZoneChange = (event, agency, isChecked) => {
    if (filterType == 'event') {
      setZoneFilter(event, agency, isChecked);
    } else if (filterType == 'unit') {
      setZoneUnitFilter(event, agency, isChecked);
    }
  };
  // useEffect(() => {}, [searchDivision]);
  // console.log('zones', zones);
  return (
    <Popover
      id="agencies-filter"
      keepMounted
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={() => props.handleAgeciesFilterClose()}>
      <div
        style={{
          maxHeight: '440px',
          minHeight: '440px',
          width: '270px'
        }}>
        <Grow in={true}>
          <Tabs
            value={value}
            size="small"
            variant="fullWidth"
            textColor="primary"
            centered={true}
            TabIndicatorProps={{
              style: {
                display: 'none'
              }
            }}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="Agency" sx={{ minWidth: '33%' }} />
            {filterType == 'unit' && (
              <Tab label="Division" sx={{ minWidth: '33%' }} />
            )}

            <Tab label="Zone" sx={{ minWidth: '33%' }} />
          </Tabs>
        </Grow>

        <div style={{ display: 'flex', padding: '10px' }}>
          <Button
            size="medium"
            color="inherit"
            startIcon={<CheckIcon />}
            onClick={selectAll}>
            Select All
          </Button>
          <Button
            size="medium"
            color="inherit"
            startIcon={<ClearIcon />}
            onClick={deSelectAll}>
            Deselect All
          </Button>
        </div>
        <TabPanel value={value} index={0}>
          <FormControl component="fieldset" style={{ minWidth: '100%' }}>
            <div style={{ padding: '10px' }}>
              <TextField
                fullWidth
                type="search"
                size="small"
                variant="outlined"
                label="Search"
                value={search}
                onChange={(e) => onSearchCahnge(e)}
              />
            </div>
            <div
              style={{
                marginTop: '10px',
                height: '260px',
                overflowY: 'auto'
              }}>
              {filterAgency.map((agency) => {
                return (
                  <ListItem
                    button
                    key={agency.AgencyID}
                    style={{
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      paddingLeft: '10px',
                      paddingRight: '0px'
                    }}>
                    <FormControlLabel
                      key={agency.AgencyID}
                      control={
                        <Checkbox
                          checked={isAgencyChecked(agency.AgencyID)}
                          name={agency.AgencyID}
                          onClick={(event) =>
                            handleAgencyChange(
                              event,
                              agency,
                              isAgencyChecked(agency.AgencyID)
                            )
                          }
                        />
                      }
                      label={agency.Description}
                    />
                  </ListItem>
                );
              })}
            </div>
          </FormControl>
        </TabPanel>
        {filterType == 'unit' && (
          <TabPanel value={value} index={1}>
            <FormControl component="fieldset" style={{ minWidth: '100%' }}>
              <div style={{ padding: '10px' }}>
                <TextField
                  fullWidth
                  type="search"
                  size="small"
                  variant="outlined"
                  label="Search"
                  value={searchDivision}
                  onChange={(e) => onSearchCahnge(e)}
                />
              </div>
              <div
                style={{
                  marginTop: '10px',
                  height: '260px',
                  overflowY: 'auto'
                }}>
                {filterDivison.map((division) => {
                  return (
                    <ListItem
                      button
                      key={division.Code}
                      style={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '10px',
                        paddingRight: '10px'
                      }}>
                      <FormControlLabel
                        key={division.Code}
                        control={
                          <Checkbox
                            checked={isDivisionChecked(division.Code)}
                            name={division.Code}
                            onClick={(event) =>
                              handleDivisionChange(
                                event,
                                division,
                                isDivisionChecked(division.Code)
                              )
                            }
                          />
                        }
                        label={division.Code}
                      />
                    </ListItem>
                  );
                })}
              </div>
            </FormControl>
          </TabPanel>
        )}
        <TabPanel value={value} index={filterType == 'unit' ? 2 : 1}>
          <FormControl component="fieldset" style={{ minWidth: '100%' }}>
            <div style={{ padding: '10px' }}>
              <TextField
                fullWidth
                type="search"
                size="small"
                variant="outlined"
                label="Search"
                value={searchZone}
                onChange={(e) => onSearchCahnge(e)}
              />
            </div>
            <div
              style={{
                marginTop: '10px',
                height: '260px',
                overflowY: 'auto'
              }}>
              {(filterType == 'unit'
                ? [...zonesFilterUnit]
                : [...zonesFilterEvent]
              ).map((zone) => {
                return (
                  <ListItem
                    button
                    key={zone.ZoneCode}
                    style={{
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      paddingLeft: '10px',
                      paddingRight: '10px'
                    }}>
                    <FormControlLabel
                      key={zone.ZoneCode}
                      control={
                        <Checkbox
                          checked={isZonesChecked(zone.ZoneCode)}
                          name={zone.ZoneCode}
                          onClick={(event) =>
                            handleZoneChange(
                              event,
                              zone,
                              isZonesChecked(zone.ZoneCode)
                            )
                          }
                        />
                      }
                      label={zone.ZoneCode}
                    />
                  </ListItem>
                );
              })}
            </div>
          </FormControl>
        </TabPanel>
      </div>
    </Popover>
  );
};

const mapStateToProps = (state) => ({
  agencies: state.data.agencies,
  divisions: state.data.divisions,
  zonesEvent: state.data.zonesForEvents,
  zonesUnit: state.data.zonesForUnits,
  filterAgencies: state.settings.agencies,
  filterAgenciesUnit: state.settings.agenciesUnit,
  filterDivision: state.settings.divisions,
  filterDivisionUnit: state.settings.divisionsUnit,
  filterZones: state.settings.zones,
  filterZonesUnit: state.settings.zonesUnit
});

export default connect(mapStateToProps, {
  setAgencyFilterAll,
  setAgencyFilterNull,
  setAgencyFilter,
  setdivisionUnitFilter,

  setZoneFilter,
  setZoneUnitFilter
})(AgenciesFilter);
