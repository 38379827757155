import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import { saveUserSettings } from 'reducers/UserReducer';
import { saveNcicCredentials } from '../../reducers/NcicReducer';
import {
  setAgencyFilter,
  setAgencyFilterAll,
  setAgencyFilterNull,
  setAgencyFilterSelect,
  setAgencyTypeFilter,
  setAgencyUnitFilter,
  setViewType,
  toggleDialog
} from '../../reducers/SettingsReducer';
import { setMode } from '../../reducers/ThemeReducer';
import ChangeNcicPass from './ChangeNcicPass';
import ChangeUserPass from './ChangeUserPass';

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  },
  color: {
    background: theme.palette.bgElements
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function DialogSettings(props) {
  const theme = useTheme();
  const { classes } = useStyles();
  const {
    isOpen,
    toggleDialog,
    themeMode,
    setMode,
    agencies,
    viewType,
    setViewType,
    filterAgencies,
    filterAgenciesTypes,
    setAgencyFilter,
    setAgencyFilterAll,
    setAgencyFilterNull,
    setAgencyFilterSelect,
    setAgencyTypeFilter,
    saveNcicCredentials,
    filterAgenciesUnit,
    rid,
    ori,
    user
  } = props;

  const [tab, setTab] = useState(0);
  const [ridF, setRid] = React.useState('');
  const [oriF, setOri] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [filterType, setFilterType] = React.useState(props.separate || 'event');
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const persmissions = useSelector(state => state.permissions.globals);
  const [canExecNcic, setCanExecNcic] = useState('');
  const { APP_DEFAULT_STATE } = useSelector(state => state.appInfo.appEnvInfo);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  React.useEffect(() => {
    setCanExecNcic(getAccessPermission('globals', 'Execute NCIC Queries'));
  }, [persmissions]);
  React.useEffect(() => {
    setRid(rid);
    setOri(ori);
  }, [persmissions]);

  const handleClose = () => {
    toggleDialog();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleThemeModeChange = event => {
    setMode(event.target.value);
  };

  const handleViewTypeChange = event => {
    setViewType(event.target.value);
  };

  const handleAgencyChange = (event, agency, isChecked) => {
    if (filterType == 'event') {
      setAgencyFilter(event, agency, isChecked);
    } else if (filterType == 'unit') {
      dispatch(setAgencyUnitFilter(event, agency, isChecked));
    }
  };

  const handleAgencyChangeSelect = (event, value) => {
    setAgencyFilterSelect(value);
  };

  const isAgencyChecked = agency => {
    let filter;
    if (filterType == 'event') {
      filter = filterAgencies;
    } else if (filterType == 'unit') {
      filter = filterAgenciesUnit;
    }
    if (filter === null) {
      return true;
    } else {
      for (let i = 0; i < filter.length; i++) {
        if (agency === filter[i].AgencyID) {
          return true;
        }
      }
      return false;
    }
  };

  const handleAgencyTypeChange = event => {
    const filter = filterAgenciesTypes;
    switch (filter) {
      case 0:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(1);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(2);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(4);
        }
        break;
      case 1:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(0);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(3);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(5);
        }
        break;
      case 2:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(3);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(0);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(4);
        }
        break;
      case 3:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(2);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(1);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(7);
        }
        break;
      case 4:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(5);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(6);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(0);
        }
        break;
      case 5:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(4);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(7);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(1);
        }
        break;
      case 6:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(7);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(4);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(2);
        }
        break;
      case 7:
        if (event.target.name === 'police') {
          setAgencyTypeFilter(6);
        } else if (event.target.name === 'fire') {
          setAgencyTypeFilter(5);
        } else if (event.target.name === 'ems') {
          setAgencyTypeFilter(3);
        }
        break;
      default:
      // default
    }
  };

  const isAgencyTypeChecked = agencyType => {
    /*
      Nothing checked => 0
      Police => 1
      Fire => 2
      Police + Fire => 3
      Ems => 4
      Police + Ems => 5
      Fire + Ems => 6
      All checked => 7
    */
    const filter = filterAgenciesTypes;
    switch (filter) {
      case 0:
        return false;
      case 1:
        if (agencyType === 'police') {
          return true;
        } else {
          return false;
        }
      case 2:
        if (agencyType === 'fire') {
          return true;
        } else {
          return false;
        }
      case 3:
        if (agencyType === 'fire') {
          return true;
        } else if (agencyType === 'police') {
          return true;
        } else {
          return false;
        }
      case 4:
        if (agencyType === 'ems') {
          return true;
        } else {
          return false;
        }
      case 5:
        if (agencyType === 'police') {
          return true;
        } else if (agencyType === 'ems') {
          return true;
        } else {
          return false;
        }
      case 6:
        if (agencyType === 'fire') {
          return true;
        } else if (agencyType === 'ems') {
          return true;
        } else {
          return false;
        }
      case 7:
        return true;
      default:
      // default
    }
  };
  const handleTypeChange = e => {
    setFilterType(e.target.value);
  };
  const handleRidChange = event => {
    setRid(event.target.value);
  };

  const handleOriChange = event => {
    setOri(event.target.value);
  };

  const ncicSubmitForm = event => {
    event.preventDefault();
    saveNcicCredentials(ridF, oriF);
    dispatch(saveUserSettings());
  };

  const selectAll = () => {
    setAgencyFilterAll(filterType);
  };

  const deSelectAll = () => {
    setAgencyFilterNull(filterType);
  };

  const onSearchCahnge = e => {
    setSearch(e.target.value);
  };

  const a = agencies.filter(a => {
    if (search !== '') {
      return a.AgencyID.toLowerCase().includes(search.toLowerCase());
    } else {
      return true;
    }
  });

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={'sm'}>
      <DialogTitle
        style={{ padding: '10px 0px 0px 0px' }}
        id="scroll-dialog-title">
        <Tabs
          value={tab}
          variant="scrollable"
          scrollButtons
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="person details tabs"
          allowScrollButtonsMobile>
          <Tab label="Change Password" value={0} />
          {canExecNcic && <Tab label="Ncic State" value={1} />}
        </Tabs>
      </DialogTitle>
      <DialogContent
        dividers={true}
        className={classes.body}
        id="settings-dialog">
        <TabPanel value={tab} index={0}>
          <ChangeUserPass />
          {canExecNcic && <ChangeNcicPass />}
        </TabPanel>
        {canExecNcic && (
          <TabPanel value={tab} index={1}>
            <FormLabel component="legend">Ncic State</FormLabel>
            <form onSubmit={ncicSubmitForm}>
              <FormControl className="w-100 mb-2">
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  label="ORI"
                  fullWidth
                  placeholder="Enter ORI"
                  type="text"
                  value={oriF}
                  onChange={handleOriChange}
                />
              </FormControl>
              <FormControl className="w-100">
                <TextField
                  required={APP_DEFAULT_STATE === 'MS'}
                  size="small"
                  variant="outlined"
                  label="RID"
                  fullWidth
                  placeholder="Enter RID"
                  type="text"
                  value={ridF}
                  onChange={handleRidChange}
                />
              </FormControl>
              <div className="text-center">
                <Button
                  required
                  fullWidth
                  type="submit"
                  size="small"
                  color="primary"
                  variant="contained"
                  size="small"
                  className="my-2">
                  Save
                </Button>
              </div>
            </form>
          </TabPanel>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
          className="mr-2">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  isOpen: state.settings.isOpen,
  user: state.user,
  themeMode: state.theme.mode,
  agencies: state.data.agencies,
  filterAgencies: state.settings.agencies,
  filterAgenciesUnit: state.settings.agenciesUnit,
  viewType: state.settings.viewType,
  filterAgenciesTypes: state.settings.agencyTypes,
  rid: state.ncic.rid,
  ori: state.ncic.ori
});

export default connect(mapStateToProps, {
  toggleDialog,
  setMode,
  setViewType,
  setAgencyFilter,
  setAgencyFilterAll,
  setAgencyFilterNull,
  setAgencyFilterSelect,
  setAgencyTypeFilter,
  saveNcicCredentials
})(DialogSettings);
