import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function icons8deadmaninacoffin100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M18 0a1 1 0 0 0-.87.504l-8 14a1 1 0 0 0-.109.697l7 34A1 1 0 0 0 17 50h16a1 1 0 0 0 .979-.799l7-34a1 1 0 0 0-.11-.697l-8-14A1 1 0 0 0 32 0H18zm.58 2h12.84l7.525 13.168L32.185 48h-14.37l-6.76-32.832L18.58 2zM25 4a4 4 0 0 0 0 8 4 4 0 0 0 0-8zm-4 10a4 4 0 0 0-4 4v11.5a1.5 1.5 0 0 0 3 0v14.393c0 .996.681 1.92 1.664 2.08A2.002 2.002 0 0 0 24 44V33a1 1 0 0 1 2 0v10.893c0 .996.681 1.92 1.664 2.08A2.002 2.002 0 0 0 30 44V29.5a1.5 1.5 0 0 0 3 0V18a4 4 0 0 0-4-4h-8z" />
    </SvgIcon>
  );
}

export default icons8deadmaninacoffin100Icon;
