import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import GetApp from '@mui/icons-material/GetApp';
import moment from 'moment-timezone';

import { removeEventAttachment } from '../../../reducers/EventsReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    fontSize: '0.8rem'
  },
  date: {
    fontStyle: 'italic',
    margin: '0 10px'
  },
  fileName: {
    fontWeight: 800,
    textAlign: 'right'
  },
  fileType: {
    textAlign: 'right',
    fontWeight: 400,
    paddingLeft: '12px'
  },
  user: {
    fontWeight: 'bold'
  }
}));

export default function AttachmentList({ info }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [deleteClicked, setDeleteClicked] = useState(false);
  const { websocket: client } = useSelector(state => {
    return state.websocket;
  });

  const deleteButtonHandler = () => {
    dispatch(removeEventAttachment(info.ptsAttachmentID));
  };

  function bufferToBlob(buffer, mime) {
    return new Blob([buffer], { type: mime });
  }

  const downloadButtonHandler = async () => {
    const service = client.service('download-attachment');
    service.timeout = 15000;

    const res = await service.get(info.ptsAttachmentID);
    const blob = bufferToBlob(res.buffer, res.type);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = info.FileName;
    link.click();
  };
  return (
    <Fragment key={info.ptsAttachmentID}>
      <li>
        <div className={classes.root}>
          <Dialog
            open={deleteClicked}
            onClick={() => setDeleteClicked(false)}
            fullWidth
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Delete Attachment</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this attachment?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setDeleteClicked(false)}>
                NO
              </Button>

              <Button color="primary" onClick={deleteButtonHandler} autoFocus>
                YES
              </Button>
            </DialogActions>
          </Dialog>

          <IconButton size="small" onClick={() => setDeleteClicked(true)}>
            <Delete fontSize="inherit" color="primary" />
          </IconButton>
          <IconButton size="small" onClick={downloadButtonHandler}>
            <GetApp fontSize="inherit" color="primary" />
          </IconButton>
          <div className={classes.date}>{displayDateTime(info.Attached)}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}>
            <div>
              <span className={classes.fileName}>{info.FileName}</span>
              <span className={classes.fileType}>{info.FileType}</span>
            </div>
            <span className={classes.user}>{info.CreatedBy}</span>
          </div>
        </div>

        <div style={{ fontSize: '0.8rem' }}>{info.Description}</div>
      </li>

      <div className="divider my-1" />
    </Fragment>
  );
}
