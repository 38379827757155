import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { checkForUpdates, getApiVer } from '../../reducers/AppInfoReducer';
import Button from '@mui/material/Button';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '2px',
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    width: 320,
    minHeight: 150
  },
  footer: {
    fontSize: 13,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    '& p': {
      marginBottom: 0
    }
  },
  action: {
    marginBottom: 10
  },
  body: {
    background: theme.palette.bgElements
  }
}));

const DialogTitle = withStyles(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const DialogContent = withStyles(MuiDialogContent, theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

function AboutDialog(props) {
  const { classes } = useStyles();
  const {
    apiVersion,
    buildNo,
    buildTime,
    availBuildNo,
    availBuildTime,
    appVersion
  } = props.appInfo;
  const updateAvail = buildNo !== availBuildNo || buildTime !== availBuildTime;
  useEffect(() => {
    props.getApiVer();
    props.checkForUpdates();
    // eslint-disable-next-line
  }, []);

  const reload = () => {
    const ncic = window.localStorage.getItem('ncic');
    window.localStorage.clear();
    window.localStorage.setItem('ncic', ncic);
    window.indexedDB.deleteDatabase('localforage');
    window.location.reload();
  };

  return (
    <Dialog onClose={props.close} open={true}>
      <DialogTitle id="scroll-dialog-title" onClose={props.close}>
        About
      </DialogTitle>
      <DialogContent dividers className={classes.body}>
        <div className={classes.content}>
          <div className={classes.header}>
            <p>
              <strong>PTS CAD Mobile</strong>
            </p>
          </div>
          <div className={classes.body}>
            <p>
              App version: {appVersion} <br />
              Api version: {apiVersion} <br /> Build no: {buildNo} <br /> Build
              date: {buildTime} <br />
              {updateAvail && <span>(update available)</span>}
              {!updateAvail && <span>(up to date)</span>}
            </p>
            {updateAvail && (
              <div className={classes.action}>
                <Button
                  size="small"
                  style={{ color: 'red', borderColor: 'red' }}
                  variant="outlined"
                  className={classes.margin}
                  onClick={reload}>
                  Update
                </Button>
              </div>
            )}
            {!updateAvail && (
              <div className={classes.action}>
                <Button
                  size="small"
                  style={{ color: 'red', borderColor: 'red' }}
                  variant="outlined"
                  className={classes.margin}
                  onClick={reload}>
                  Clear Storage
                </Button>
              </div>
            )}
          </div>
          <div className={classes.footer}>
            <p>
              &copy; 2021{' '}
              <a href="https://ptssolutions.com">ptssolutions.com</a>, All
              Rights Reserved
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    appInfo: state.appInfo
  };
};

export default connect(mapStateToProps, { checkForUpdates, getApiVer })(
  AboutDialog
);
