
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8hospital100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="100" height="100"><path d="M120 46H82V8c0-1.7-1.3-3-3-3H49c-1.7 0-3 1.3-3 3v38H8c-1.7 0-3 1.3-3 3v30c0 1.7 1.3 3 3 3h38v38c0 1.7 1.3 3 3 3h30c1.7 0 3-1.3 3-3V82h38c1.7 0 3-1.3 3-3V49c0-1.7-1.3-3-3-3zM56.6 74.5l15.9 9.2c1.4.8 1.9 2.7 1.1 4.1-.6 1-1.6 1.5-2.6 1.5-.5 0-1-.1-1.5-.4L68 88v8c0 1.7-1.3 3-3 3s-3-1.3-3-3V84.5l-8.4-4.8c-2.5-1.4-3.8-4.1-3.4-6.9s2.3-5.1 5-5.9L68.9 63c.6-.2.7-.6.7-1s-.1-.8-.7-1l-13.7-3.9c-2.7-.8-4.7-3-5-5.9s1-5.5 3.4-6.9l8.4-4.8V28c0-1.7 1.3-3 3-3s3 1.3 3 3v8l1.5-.9c1.4-.8 3.3-.3 4.1 1.1.8 1.4.3 3.3-1.1 4.1l-15.9 9.2c-.5.3-.5.7-.5 1 0 .2.2.7.7.8l13.7 3.9c3 .9 5.1 3.6 5.1 6.7s-2 5.9-5.1 6.7l-13.7 3.9c-.6.2-.7.6-.7.8 0 .5 0 .9.5 1.2z"/></SvgIcon>
      );
    }
  
    export default icons8hospital100Icon;