import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { TextField, Button, Badge } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  filter: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  datePicker: {
    marginTop: 10,
    width: 200,
    margin: '16px 10px 10px',
    paddingTop: 6,
    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const SearchForm = props => {
  const { classes } = useStyles();
  const { searchText, setSearchText, parentClassName } = props;
  const [count, setCount] = React.useState(0);
  const [allElements, setAllElements] = React.useState([]);
  let foundElement = 0;

  React.useEffect(() => {
    const a = document.querySelectorAll(`.${parentClassName}`);
    setAllElements(document.querySelectorAll(`.${parentClassName}`));
    setCount(document.querySelectorAll(`.${parentClassName}`).length);
    foundElement = 0;
  }, [searchText]);

  const onSearchChange = e => {
    setSearchText(e.target.value);
  };
  const clearResults = () => {
    props.clearNiciHistory();
  };

  const submitForm = e => {
    e.preventDefault();
    if (allElements.length > 0) {
      if (foundElement !== allElements.length) {
        window.scrollTo(0, allElements[foundElement].offsetTop);
        foundElement++;
      } else {
        foundElement = 0;
        window.scrollTo(0, allElements[foundElement].offsetTop);
      }
    }
  };

  return (
    <div className={classes.searchWrap}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '70%' }}>
          <form onSubmit={submitForm} className="p-3">
            <Badge
              overlap="rectangular"
              style={{ width: '100%' }}
              badgeContent={count}
              color="secondary">
              <TextField
                type="search"
                size="small"
                autoFocus
                variant="outlined"
                label="Search"
                className={classes.filter}
                value={searchText}
                onChange={e => onSearchChange(e)}
              />
            </Badge>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  themeMode: state.theme.mode
});

export default connect(mapStateToProps, {})(SearchForm);
