import { getService } from './service';
import { handleError } from './ErrorReducer';
// import { getDictionaries } from './DictionaryReducer';
import store from 'config/configureStore';

export const DICTIONARY_PERMISSIONS = 'DICTIONARY_PERMISSIONS';
const PERMISSIONS = 'PERMISSIONS';

const getfalsePermissions = () => ({
  Create: false,
  Read: false,
  Edit: false,
  Delete: false,
  ViewDeleted: false
});

const defaultState = {
  dictionary: {},
  globals: {},
  modules: {},
  groups: [],
  default: getfalsePermissions()
};

export const getFullPermissions = (permGroup, permName) => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p) return getfalsePermissions();
  if (!p[permGroup]) return getfalsePermissions();
  const permissions = p[permGroup][permName];
  if (permissions) return permissions;
  if (p.default) return p.default;
  return getfalsePermissions();
};
export const getAgenciesPermissions = agency => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p) return false;
  const groups = p['groups'];
  if (!groups || groups.length === 0) return false;
  // console.log('complex', agency);
  let flag = false;
  groups.forEach(group => {
    if (group.AgencyID == agency || group.AgencyID == null) {
      // console.log('inside', group.AgencyID);
      flag = true;
    }
  });

  return flag ? true : false;
};
export const getAccessPermission = (permGroup, permName) => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p[permGroup]) return false;
  const access = p[permGroup][permName];
  // return true;
  return Boolean(access);
};

export const getGroups = () => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p.groups) return [];
  return p.groups.map(g => g.Label);
};

export const getProfile = () => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p.profile) return 'Unknown';
  return p.profile.Label;
};

let permissionsService;
export const subscribePermissions = () => {
  return async dispatch => {
    try {
      permissionsService = getService('permissions');
      permissionsService.on('updated', () => {
        dispatch(getUserPermissions());
        // dispatch(getDictionaries());
      });
      permissionsService.on('error', () =>
        console.log('Error in permissions service listener')
      );
      permissionsService.on('unhandledRejection', (reason, p) => {
        console.log(
          'PermissionsReducer Unhandled Rejection at: Promise ',
          p,
          ' reason: ',
          reason
        );
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const unsubscribePermissions = () => dispatch => {
  if (permissionsService) {
    try {
      permissionsService.off('created');
      permissionsService.off('error');
      permissionsService.off('unhandledRejection');
      permissionsService = false;
    } catch (error) {
      console.log(
        'PermissionsReducer/unsubscribePermissions: error: ',
        error,
        error.code
      );
    }
  }
};

export const notifyUpdatedPermissions = () => {
  const service = getService('permissions');
  return service.get(0);
};

export const getUserPermissions = () => async dispatch => {
  try {
    const service = getService('permissions');
    const permissions = await service.find({
      query: {
        options: ['modules', 'default', 'globals', 'groups', 'agencies', 'cad']
      }
    });
    dispatch({ type: PERMISSIONS, payload: permissions });
  } catch (err) {
    dispatch(handleError(err));
  }
};

// export const setDictionaryPermissions = (permissions) => (dispatch) => {
//   dispatch({ type: DICTIONARY_PERM, payload: permissions });
// }

export default function(state = defaultState, action) {
  switch (action.type) {
    // case DICTIONARY_PERMISSIONS:
    //   return { ...state, dictionary: { ...state.dictionary, ...action.payload } }
    case PERMISSIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
