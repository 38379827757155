import MomentUtils from '@date-io/moment';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import {
//   KeyboardDateTimePicker,
//   MuiPickersUtilsProvider
// } from '@material-ui/pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Fragment } from 'react';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import {
  handleDateChange,
  handleFilterChange,
  findClosedEvents,
  clearResults,
  clearFilters,
  handleClosedEventFilltersAutoFill
} from '../../../../reducers/ClosedEventSearchReducer';
import { getCodeEventType } from 'reducers/CodeReducer';
import { Autocomplete } from '@mui/material';
import { getAgenciesPermissions } from 'reducers/PermissionsReducer';
import saveSearchSuggestions from 'utils/saveSearchSuggestions';
import SearchHistory from 'global-components/SearchHistory';
const useStyles = makeStyles()((theme) => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  filter: {
    marginTop: '10px',
    margin: '16px 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  datePicker: {
    marginTop: '10px',
    width: '200px',
    margin: '16px 10px 10px',

    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    marginTop: '10px',
    margin: '16px 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  select2: {
    marginTop: '10px',
    margin: '16px 10px 10px',
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  inputWidth: {
    width: '100%'
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const SearchForm = (props) => {
  const { classes } = useStyles();

  const {
    filterValues,
    pickerValues,
    handleFilterChange,
    handleDateChange,
    wsClient,
    agencies,
    user,
    filterAgencies,
    selectedSearchHistory
  } = props;
  const [filters, setFilters] = useState(props.localformdefinition.display);
  const [loading, setLoading] = useState(false);
  const eventTypes = useSelector((state) => state.codes.eventTypeCodes);
  const [searchPersonId, setSearchPersonId] = useState(
    user.userData.user.ptsPersonID
  );
  const [agencyID, setAgencyID] = useState(user.userData.user.AgencyID);
  const [people, setPeopleState] = React.useState([]);
  const [personOptionLabel, setPersonOptionLabel] = useState(null);
  const dispatch = useDispatch();
  const filterRefs = {};
  const { loaded } = props.closeEvents;

  useEffect(() => {
    if (searchPersonId && searchPersonId === user.userData.user.ptsPersonID) {
      makeOptionLabel({ Current: true });
    }
  }, [searchPersonId]);
  useEffect(() => {
    dispatch(getCodeEventType());
  }, []);
  useEffect(() => {
    loaded && loading && setLoading(false);
  }, [loaded, loading]);

  useEffect(() => {
    if (
      selectedSearchHistory &&
      Object.keys(selectedSearchHistory).length > 0
    ) {
      if (selectedSearchHistory?.AgencyID) {
        setAgencyID(selectedSearchHistory.AgencyID?.search);
      } else {
        setAgencyID(null);
      }
      if (
        selectedSearchHistory.personOptionLabel &&
        selectedSearchHistory.ptsPersonID
      ) {
        setSearchPersonId(selectedSearchHistory.ptsPersonID?.search);
        setPersonOptionLabel(selectedSearchHistory.personOptionLabel);
      } else {
        setSearchPersonId(null);
        setPersonOptionLabel(null);
      }
    } else {
      makeOptionLabel({ Current: true });
      setAgencyID(user.userData?.user?.AgencyID || null);
      setSearchPersonId(user.userData.user.ptsPersonID);
    }
  }, [selectedSearchHistory, JSON.stringify(selectedSearchHistory || {})]);

  Object.values(filters).forEach(({ dbName }) => {
    // eslint-disable-next-line
    filterRefs[dbName] = useRef();
  });

  const getTextFilter = (filter, idx) => (
    <Grid item xs={12} md={4} lg={2} key={idx}>
      <TextField
        type="search"
        size="small"
        variant="outlined"
        label={filter.label}
        key={idx}
        className={classes.inputWidth}
        value={
          filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''
        }
        onChange={(e) => handleFilterChange(e, filter)}
        inputRef={filterRefs[filter.dbName]}
      />
    </Grid>
  );

  const clearForm = () => {
    dispatch(handleClosedEventFilltersAutoFill({}));
    makeOptionLabel({ Current: true });
    setAgencyID(user.userData?.user?.AgencyID || null);
    setSearchPersonId(user.userData.user.ptsPersonID);
    Object.values(filterRefs).forEach(({ current }) => {
      if (current) current.value = '';
    });

    dispatch(clearFilters());
  };
  const submitForm = (e) => {
    e.preventDefault();
    const { orderBy, rowsPerPage, orderDirection } = props.closeEvents;
    const newFilterValues = { ...filterValues };
    const ag = [];
    // console.log('agencies', filterAgencies);
    // agencies.forEach(agency => {
    //   const isPermitted = getAgenciesPermissions(agency.AgencyID);
    //   if (isPermitted) {
    //     ag.push(agency.AgencyID);
    //   }
    // });
    if (Object.entries(pickerValues).length < 1) {
      newFilterValues['e.Created'] = {
        type: 'date',
        from: moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00.0',
        to: moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59.0'
      };
    }
    Object.entries(pickerValues).forEach(([key, val]) => {
      newFilterValues[key] = {
        type: 'date',
        from: moment(val.from).format('YYYY-MM-DD') + ' 00:00:00.0',
        to: moment(val.to).format('YYYY-MM-DD') + ' 23:59:59.0'
      };
    });
    if (searchPersonId) {
      newFilterValues['ptsPersonID'] = {
        type: 'exact',
        search: searchPersonId
      };
    } else {
      delete newFilterValues['ptsPersonID'];
    }
    if (agencyID) {
      newFilterValues['AgencyID'] = {
        type: 'exact',
        search: agencyID
      };
    } else {
      delete newFilterValues['AgencyID'];
    }
    saveSearchSuggestions('closedEventSearch', {
      ...newFilterValues,
      personOptionLabel
    });

    const options = {
      pageNo: 0,
      orderBy,
      rowsPerPage,
      orderDirection,
      filters: newFilterValues
      // agencies: filterAgencies
    };
    // console.log('final options', options);
    setLoading(true);
    dispatch(findClosedEvents(options));
  };
  const getDateFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    const minToValue = value.from ? value.from : undefined;
    const maxFromValue = new Date();
    return (
      <>
        <Grid item xs={12} md={4} lg={2} key={`${idx}-from`}>
          <DateTimePicker
            className={classes.inputWidth}
            // clearable

            size="small"
            key={`${idx}-from`}
            value={
              value.from ||
              moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00.0'
            }
            label="Start Date"
            placeholder={`${filter.label} From`}
            inputFormat="MM/dd/yyyy HH:mm:ss"
            maxDate={maxFromValue}
            onChange={(date) => handleDateChange(date, filter.dbName, 'from')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2} key={`${idx}-to`}>
          <DateTimePicker
            className={classes.inputWidth}
            // clearable
            variant="inline"
            inputVariant="outlined"
            size="small"
            key={`${idx}-to`}
            value={
              value.to ||
              moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59.0'
            }
            label="End Date"
            placeholder={`${filter.label} To`}
            inputFormat="MM/dd/yyyy HH:mm:ss"
            minDate={minToValue}
            maxDate={new Date()}
            onChange={(date) => handleDateChange(date, filter.dbName, 'to')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
      </>
    );
  };

  const getSelectFilter = (filter, idx) => (
    <Grid item xs={12} md={4} lg={2} key={idx}>
      <Autocomplete
        variant="outlined"
        size="small"
        className={classes.inputWidth}
        key={idx}
        autoHighlight
        autoSelect
        value={{
          Code: filterValues[filter.dbName]
            ? filterValues[filter.dbName].search
            : ''
        }}
        onChange={(event, newValue) => {
          handleFilterChange(newValue?.Code || '', filter);
        }}
        options={[{ Code: '', Description: '' }, ...eventTypes] || []}
        getOptionLabel={(option) => option.Code || ''}
        isOptionEqualToValue={() => true}
        renderOption={(props, option, { selected }) => (
          <li {...props}>{option.Code || '-'}</li>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={filter.label} />
        )}
      />
      {/* <FormControl
        variant="outlined"
        size="small"
        className={classes.inputWidth}
        key={idx}>
        <InputLabel id={`filter-${filter.dbName}`}>{filter.label}</InputLabel>
        <Select
          className={classes.selectInput}
          labelId={`filter-${filter.dbName}`}
          value={
            filterValues[filter.dbName]
              ? filterValues[filter.dbName].search
              : ''
          }
          onChange={e => handleFilterChange(e, filter)}>
          <MenuItem value=""> </MenuItem>

          {eventTypes?.length > 0 &&
            eventTypes.map(menu => (
              <MenuItem value={menu.Code} key={menu.Code}>
                 {menu.Code}
              </MenuItem>
            ))}
        </Select>
      </FormControl> */}
    </Grid>
  );
  let timer;

  const peopleSearchOnChange = (event) => {
    event.persist();

    if (!wsClient) {
      return;
    }

    if (timer) clearTimeout(timer);

    timer = setTimeout(async () => {
      const response = await wsClient.websocket.service('persons').find({
        query: {
          IsDeleted: false,
          detail: true,
          FullName: {
            $like: `%${event.target.value}%`,
            $ne: null
          },
          $select: [
            'FirstName',
            'LastName',
            'MiddleName',
            'FullName',
            'Suffix',
            'Race',
            'Sex',
            'Ethnicity',
            'Age',
            'Born',
            'AgeRangeIncrement',
            'EyeColor',
            'HairColor',
            'HairStyle',
            'HairLength',
            'BloodType',
            'Handed',
            'Height',
            'Weight',
            'ptsPersonID'
          ]
        }
      });

      const filteredData = response.data.filter(
        (x) =>
          x.PersonCredential.some(
            (y) => y.Number !== 0 && y.Number !== '0' && y.Number !== 'null'
          ) || x.Born !== null
      );

      if (filteredData.length > 0) {
        setPeopleState(filteredData);
      }
    }, 500);
  };
  const [labelValue, setLabelValue] = useState('');
  const handlePeopleSelect = (newValue) => {
    if (newValue) {
      setSearchPersonId(newValue.ptsPersonID);
      setPersonOptionLabel(makeOptionLabel(newValue) || null);
    } else {
      setSearchPersonId(null);
      setPersonOptionLabel(null);
    }
  };
  const makeOptionLabel = (option) => {
    if (!option) return null;
    if (typeof option === 'string') {
      return option;
    }
    if (option.Current) {
      const { ptsPersonID, FullName, DBO, Sex, Race, SSN, OLN } =
        user.userData.user;
      const Born = option.Born ? moment(DBO).format('MM/DD/YYYY') : null;
      const lb = `${FullName} SSN: ${SSN} OLN: ${OLN} Race: ${Race} Sex: ${Sex} DOB: ${Born}`;
      setPersonOptionLabel(lb);
      return lb;
    }
    const fullName = option.FullName;
    let ssn = '';
    let oln = '';
    if (Array.isArray(option.PersonCredential)) {
      option.PersonCredential.forEach((e) => {
        if (e.Type === 'SSN') ssn = e.Number;
        if (e.Type === 'OLN') oln = e.Number;
      });
    }
    const race = option.Race;
    const sex = option.Sex;
    const born = option.Born ? moment(option.Born).format('MM/DD/YYYY') : null;
    return `${fullName} SSN: ${ssn} OLN: ${oln} Race: ${race} Sex: ${sex} DOB: ${born}`;
  };
  // console.log('filters', filterValues);
  // console.log('pickers', pickerValues);
  // console.log('agency', agencyID);
  // console.log('selectedSearchHistory', selectedSearchHistory);
  return (
    <div className={classes.searchWrap}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={submitForm}>
          <Grid container spacing={2} sx={{ margin: '-8px !important' }}>
            {Object.values(filters).map((filter, idx) => {
              switch (filter.type) {
                case 'date':
                  return getDateFilter(filter, idx);

                case 'select':
                  return getSelectFilter(filter, idx);
                default:
                  return getTextFilter(filter, idx);
              }
            })}
            <Grid item xs={12} md={4} lg={2} key="ag-id">
              <Autocomplete
                id="combo-box-demo"
                size="small"
                autoHighlight
                autoSelect
                value={{ AgencyID: agencyID }}
                onChange={(event, newValue) => {
                  setAgencyID(newValue?.AgencyID || null);
                }}
                options={[{ AgencyID: '' }, ...agencies]}
                getOptionLabel={(option) => option.AgencyID || ''}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option.AgencyID || '-'}</li>
                )}
                isOptionEqualToValue={() => true}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Agency" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4} key="name-search">
              <Autocomplete
                size="small"
                className={classes.inputWidth}
                value={personOptionLabel}
                id="combo-search-person"
                getOptionLabel={(option) => {
                  const label = makeOptionLabel(option);
                  return label || '';
                }}
                isOptionEqualToValue={() => true} // HACK TO SUPPRESS WARNING
                options={[
                  { current: true, ...(user?.userData?.user || {}) },
                  ...people
                ]}
                onChange={(event, newValue) => {
                  handlePeopleSelect(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Person"
                    variant="outlined"
                    onChange={peopleSearchOnChange}
                  />
                )}
              />
            </Grid>
          </Grid>
          <div className={classes.filterAction}>
            <SearchHistory loading={loading} name="closedEventSearch" />

            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              className={classes.reset}
              color="inherit"
              onClick={() => dispatch(clearResults())}>
              Clear Results
            </Button>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              className={classes.reset}
              disabled={loading}
              color="inherit"
              onClick={clearForm}>
              Clear Form
            </Button>
            <div className={classes.searchBtnWrap}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon />}
                type="submit"
                disabled={loading}>
                Search
              </Button>
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </form>
      </LocalizationProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  agencies: state.data.agencies,
  filterAgencies: state.settings.agencies,
  themeMode: state.theme.mode,
  user: state.user,
  pickerValues: state.closeEvents.closeEventsForm.pickers,
  filterValues: state.closeEvents.closeEventsForm.filters,
  selectedSearchHistory: state.closeEvents.selectedSearchHistory
});
export default connect(mapStateToProps, {
  handleDateChange,
  handleFilterChange
})(SearchForm);
