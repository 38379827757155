import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles()((theme) => ({
  formSection: {
    paddingBottom: '10px'
  }
}));

const OlnStateFilter = (props) => {
  const { classes } = useStyles();

  const handleFilterOlnStateChange = (event, value) => {
    props.setSelectedOlnState(value?.Code || '');
  };

  const statusArray = props.states.map((state) => state.Code);

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <Autocomplete
        label="plate-state"
        value={{ Code: props.selectedOlnState || '' }}
        onChange={handleFilterOlnStateChange}
        options={[{ Code: '', Description: '' }, ...props.states] || []}
        getOptionLabel={(option) => option.Code || ''}
        isOptionEqualToValue={() => true}
        renderOption={(innerProps, option, { selected }) => (
          <li {...innerProps}>
            <b>{option.Code || ''} </b> - {option.Description || ''}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label="OLN State"
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export default OlnStateFilter;
