import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { OverlayView } from '@react-google-maps/api';
import { FaMapPin } from 'react-icons/fa';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import DirectionsIcon from '@mui/icons-material/Directions';
import CancelIcon from '@mui/icons-material/Cancel';

const positionOverlayView = (width, height) => ({
  x: -13,
  y: -26
});

const MarkerSearchedPlace = props => {
  const {
    searchedPlace,
    searchedPlaceAddress,
    closeMapSearchedPlaceMarker,
    user
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMapPinClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMapPinClose = () => {
    setAnchorEl(null);
    closeMapSearchedPlaceMarker();
  };

  let googlePath = '';
  if (user && user.location && user.location != null) {
    googlePath = `https://www.google.com/maps/dir/${user.location.latitude},${user.location.longitude}/${searchedPlace.lat},${searchedPlace.lng}/`;
  }

  const renderMarker = searchedPlace => {
    return (
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={searchedPlace}
        getPixelPositionOffset={positionOverlayView}>
        <Fragment>
          <div
            style={{
              position: 'sticky',
              marginLeft: '-15px',
              border: '1px solid white',
              padding: 0,
              background: 'rgba(188,255,188,0.6)',
              borderTopRightRadius: 5,
              borderBottomLeftRadius: 5
            }}>
            {searchedPlaceAddress}
          </div>
          <FaMapPin
            onClick={handleMapPinClick}
            style={{ cursor: 'pointer' }}
            size={26}
            color="blue"
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMapPinClose}
          >
            {googlePath && (
              <MenuItem>
                <div style={{ alignSelf: 'center' }}>
                  <Link
                    to={{
                      pathname: googlePath
                    }}
                    target="_blank"
                    onClick={handleMapPinClose}>
                    <IconButton
                      style={{
                        bottom: '3px',
                        padding: '0px',
                        margin: '0px 6px'
                      }}
                      aria-label="navigation"
                      size="large">
                      <DirectionsIcon />
                    </IconButton>
                    Get Directions
                  </Link>
                </div>
              </MenuItem>
            )}
            <MenuItem onClick={handleMapPinClose}>
              <div style={{ alignSelf: 'center' }}>
                <IconButton
                  style={{
                    bottom: '3px',
                    padding: '0px',
                    margin: '0px 6px'
                  }}
                  aria-label="navigation"
                  size="large">
                  <CancelIcon />
                </IconButton>
                Close
              </div>
            </MenuItem>
          </Menu>
        </Fragment>
      </OverlayView >
    );
  };

  if (searchedPlace) return renderMarker(searchedPlace);
  else return null;
};

const mapStateToProps = state => ({
  user: state.user
});
export default connect(mapStateToProps)(MarkerSearchedPlace);
