import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {
  Hidden,
  Drawer,
  List,
  IconButton,
  FormControl,
  Select,
  Badge,
  InputLabel,
  Zoom,
  Fab,
  Card,
  TextField,
  useTheme,
  MenuItem,
  Paper
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

import { makeStyles } from 'tss-react/mui';

import { setUnitsDrawerDesktopState } from '../../reducers/UiReducer';
import { setUnitInfowindow } from '../../reducers/MapReducer';
import { toggleDialog as ToggleUnitDialog } from '../../reducers/UnitStatusReducer';
import {
  unitsSortBy,
  unitsSortOrder,
  unitsDragReorder,
  unitsFilterKeywordChange,
  setUnitGroupBy,
  makeUnitGroup,
  makeUnitGroupForPin,
  toggleAccrodinExpanded
} from '../../reducers/UnitsReducer';
import { getTimeFormatted, toggleDialog } from '../../reducers/SettingsReducer';

import UnitItem from './Unit';
import FilterButton from 'layout-components/DrawerEventsDesktop/FilterButton';
import { withStyles } from 'tss-react/mui';

const drawerWidth = 300;
const drawerHeight = 80;
const Accordion = withStyles(MuiAccordion, (theme, _params, classes) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
      backgroundColor: theme.palette.bgElements
    }
  },
  expanded: {}
}));

const AccordionSummary = withStyles(
  MuiAccordionSummary,
  (theme, _params, classes) => ({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      [`&.${classes.expanded}`]: {
        minHeight: 56
      }
    },
    content: {
      [`&.${classes.expanded}`]: {
        margin: '12px 0'
      }
    },
    expanded: {}
  })
);

const useStyles = makeStyles()((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,

      flexShrink: 0
    },

    zIndex: 98
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
    padding: '0px 6px',
    overflow: 'hidden'
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  topHeaderBar: {
    position: 'fixed',
    backgroundColor: theme.palette.bgElements,
    zIndex: 99,
    width: drawerWidth - 10
  },
  select: {
    margin: '10px'
  }
}));

const DrawerUnitsDesktop = (props) => {
  const {
    state,
    setUnitsDrawerDesktopState,
    units,
    sortBy,
    groupedUnit,
    sortOrder,
    unitsSortBy,
    unitsSortOrder,
    accrodinExpand,
    unitsDragReorder,
    stateAgencies,
    isEmpty,
    toggleDialog,
    unitsFilterKeywordChange,
    filterKeyword,
    groupBy
  } = props;
  const theme = useTheme();
  const { classes } = useStyles();
  const topRef = useRef();
  const dispatch = useDispatch();
  const [isButtonVisible, setButtonVisible] = React.useState(false);
  const [showIcon, setShowIcon] = useState(false);
  useEffect(() => {
    dispatch(makeUnitGroup(groupBy));
  }, [groupBy]);
  // useEffect(() => {
  //   dispatch(makeUnitGroupForPin());
  // }, [sortBy, sortOrder]);
  useEffect(() => {
    dispatch(getTimeFormatted());
    unitsFilterKeywordChange(filterKeyword);
  }, []);
  const handleScroll = () => {
    topRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const handleListScroll = (e) => {
    let element = e.target;
    // At the bootom of the list
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //   console.log('show button');
    // }
    // After start scrolling
    if (element.scrollTop > 100) {
      setButtonVisible(true);
    } else {
      setButtonVisible(false);
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    const a = isExpanded ? panel : -1;

    dispatch(toggleAccrodinExpanded(a));
  };
  const renderUnits = () => {
    if (stateAgencies?.length == 0 && units.length == 0) {
      return (
        <div style={{ paddingTop: `${showIcon ? 190 : 70}px` }}>
          <Card className="card-box bg-neutral-light border-1 border-dark text-center">
            <div className="py-2 px-2">
              <p className="opacity-6 font-size-md mb-2 mt-2">
                <b>No agencies selected</b>
                <br />
                You can select agencies from <b>Agency Filter</b> Option
              </p>
            </div>
          </Card>
        </div>
      );
    } else if (units && units.length > 0) {
      let ui =
        groupBy != 'none' ? (
          <div
            style={{
              maxHeight: `${
                showIcon ? window.innerHeight - 255 : window.innerHeight - 150
              }px`,
              overflowY: 'auto',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              paddingRight: '10px',
              marginTop: `${showIcon ? 0 : 40}px`
            }}
            onScroll={handleListScroll}>
            <p ref={topRef}></p>
            {groupedUnit &&
              Object.entries(groupedUnit).map(([key, value]) => {
                return (
                  <Accordion
                    style={{ borderLeft: 'none', borderRight: 'none' }}
                    expanded={accrodinExpand == key}
                    onChange={handleChange(key)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                        {key}
                        {/* <span
                          style={{
                            float: 'right',
                            backgroundColor: theme.palette.primary,
                            padding: '2px 7px',
                            borderRadius: '50%',
                            color: 'white'
                          }}>
                          {value.length}
                        </span> */}
                        <Badge
                          overlap="rectangular"
                          style={{ marginTop: '12px', marginRight: '10px' }}
                          badgeContent={value.length}
                          color="primary"></Badge>
                      </Typography>
                    </AccordionSummary>

                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppableUnits">
                        {(provided) => (
                          <List
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {value.map((unit, index) => {
                              return (
                                <UnitItem
                                  key={index}
                                  unit={unit}
                                  index={index}
                                  pined={unit.Pined}
                                  expanded={unit.expanded}
                                />
                              );
                            })}
                            {provided.placeholder}
                            <Zoom in={isButtonVisible}>
                              <Fab
                                style={{
                                  position: 'fixed',
                                  bottom: '10px',
                                  right: '10px',
                                  zIndex: '100'
                                }}
                                onClick={handleScroll}
                                color="primary"
                                size="large"
                                aria-label="scroll back to top">
                                <KeyboardArrowUp />
                              </Fab>
                            </Zoom>
                          </List>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Accordion>
                );
              })}
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableUnits">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    maxHeight: `${
                      showIcon
                        ? window.innerHeight - 250
                        : window.innerHeight - 125
                    }px`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    paddingRight: '10px',
                    scrollbarWidth: 'thin',
                    marginTop: `${showIcon ? 0 : 25}px`
                  }}
                  onScroll={handleListScroll}>
                  <p ref={topRef}></p>
                  {units.map((unit, index) => {
                    if (filterKeyword !== '') {
                      if (
                        unit.UnitResources?.toLowerCase().includes(
                          filterKeyword
                        ) ||
                        unit.Unit?.toLowerCase().includes(filterKeyword) ||
                        unit.UnitStatus?.toLowerCase().includes(
                          filterKeyword
                        ) ||
                        unit.Division?.toLowerCase().includes(filterKeyword) ||
                        unit.Zone?.toLowerCase().includes(filterKeyword)
                      ) {
                        return (
                          <UnitItem
                            key={index}
                            unit={unit}
                            index={index}
                            pined={unit.Pined}
                            expanded={unit.expanded}
                          />
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <UnitItem
                          key={index}
                          unit={unit}
                          index={index}
                          pined={unit.Pined}
                          expanded={unit.expanded}
                        />
                      );
                    }
                  })}
                  {provided.placeholder}
                  <Zoom in={isButtonVisible}>
                    <Fab
                      style={{
                        position: 'fixed',
                        bottom: '10px',
                        right: '10px',
                        zIndex: '100'
                      }}
                      onClick={handleScroll}
                      color="primary"
                      size="large"
                      aria-label="scroll back to top">
                      <KeyboardArrowUp />
                    </Fab>
                  </Zoom>
                </List>
              )}
            </Droppable>
          </DragDropContext>
        );
      return ui;
    } else {
      return (
        <div style={{ paddingTop: `${showIcon ? 190 : 70}px` }}>
          {isEmpty ? (
            <Card className="card-box bg-neutral-light border-1 border-dark text-center">
              <div className="py-2 px-2">
                <p className="opacity-6 font-size-md mb-2 mt-2">
                  <b>No units found</b>
                </p>
              </div>
            </Card>
          ) : (
            <Fragment>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Fragment>
          )}
        </div>
      );
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    unitsDragReorder(destination, source, draggableId);
  };

  const drawer = (
    <div>
      <div className={classes.topHeaderBar} style={{ position: 'relative' }}>
        <div className="divider my-1" style={{ background: '#0b55a1' }} />
        <div
          className="text-center"
          style={{ display: 'flex', justifyContent: 'space-between' }}></div>
        <div className="divider my-1" style={{ background: '#0b55a1' }} />
        {showIcon ? (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.select}>
              <InputLabel
                htmlFor="unit-sort-options"
                className={classes.selectLabel}>
                Sort By
              </InputLabel>
              <Select
                native
                value={sortBy}
                onChange={(ev) => unitsSortBy(ev.target.value)}
                label="Sort By"
                inputProps={{ id: 'unit-sort-options' }}>
                <option value="Unit">Name</option>
                <option value="UnitStatus">Status</option>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.select}>
              <InputLabel
                htmlFor="unit-sort-options"
                className={classes.selectLabel}>
                Order
              </InputLabel>
              <Select
                native
                value={sortOrder}
                onChange={(ev) => unitsSortOrder(ev.target.value)}
                label="Order"
                inputProps={{ id: 'unit-sort-options' }}>
                <option value="DESC">DESC</option>
                <option value="ASC">ASC</option>
              </Select>
            </FormControl>
            <FormControl>
              <TextField
                style={{ width: '201px' }}
                variant="outlined"
                size="small"
                label="Filter units"
                placeholder="Filer units"
                size="small"
                type="search"
                value={filterKeyword}
                onChange={unitsFilterKeywordChange}
                // onBlur={() => setShowIcon(!showIcon)}
              />
            </FormControl>
          </div>
        ) : null}
      </div>
      {renderUnits()}
    </div>
  );

  return (
    <Hidden mdDown>
      <div
        className={classes.drawerPaper}
        style={{
          position: 'absolute',
          top: 80,
          right: 0,
          zIndex: 99,

          borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
        }}>
        <div className="divider my-1" style={{ background: '#0b55a1' }} />
        <Paper
          className="text-center"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'transparent'
          }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
              style={{ color: '#0b55a1' }}
              onClick={() => setUnitsDrawerDesktopState()}
              size="small"
              className="btn-inverse">
              <MenuOpenRoundedIcon style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <Badge
              variant="dot"
              badgeContent={1}
              invisible={filterKeyword == ''}
              color="error"
              overlap="circular">
              <IconButton
                size="small"
                style={{ color: '#0b55a1' }}
                onClick={() => setShowIcon(!showIcon)}>
                {/* {showIcon ? <FaSortAlphaUpAlt /> : <FaSortAlphaDownAlt />} */}
                {showIcon ? (
                  <ExpandLessIcon fontSize="medium" color="primary" />
                ) : (
                  <ExpandMoreIcon fontSize="medium" color="primary" />
                )}
              </IconButton>
            </Badge>
            <FilterButton type="unit" />
            <FormControl size="small" className="pl-1">
              <Select
                variant="outlined"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  size: 'small'
                }}
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                size="small"
                style={{ minWidth: '110px' }}
                value={groupBy}
                onChange={(ev) => dispatch(setUnitGroupBy(ev.target.value))}>
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="agency">Agency</MenuItem>
                <MenuItem value="division">Division</MenuItem>
                <MenuItem value="status">Status</MenuItem>
                <MenuItem value="zone">Zone</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              alignSelf: 'center',
              textAlign: 'center',
              color: '#0b55a1',
              fontSize: '1.1rem',
              marginRight: '10px'
            }}
            className="font-weight-bold font-size-md mb-0">
            Units {'  '}
            {units !== null ? (
              <Badge
                overlap="rectangular"
                style={{ marginLeft: '10px' }}
                badgeContent={units.length}
                color="primary"></Badge>
            ) : (
              <Badge
                overlap="rectangular"
                badgeContent={0}
                color="primary"></Badge>
            )}
          </div>
        </Paper>
        <div className="divider my-1" style={{ background: '#0b55a1' }} />
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        open={state}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}>
        <div>
          <div className={classes.toolbar} />
          {drawer}
        </div>
      </Drawer>
    </Hidden>
  );
};

const mapStateToProps = (state) => ({
  state: state.ui.unitsDrawerDesktopState,
  units: state.units.units,
  groupBy: state.units.groupBy,
  groupedUnit: state.units.groupedUnit,
  sortBy: state.units.sortBy,
  sortOrder: state.units.sortOrder,
  accrodinExpand: state.units.accrodinExpand,
  filterKeyword: state.units.filterKeyword,
  themeMode: state.theme.mode,
  unitInfowindow: state.map.unitInfowindow,
  user: state.user,
  stateAgencies: state.settings.agenciesUnit,
  isEmpty: state.units.isEmpty
});

export default connect(mapStateToProps, {
  setUnitsDrawerDesktopState,
  setUnitInfowindow,
  ToggleUnitDialog,
  unitsSortBy,
  unitsSortOrder,
  unitsDragReorder,
  toggleDialog,
  unitsFilterKeywordChange
})(DrawerUnitsDesktop);
