import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';

import { Paper } from '@mui/material';

import SearchForm from './SearchForm';
import SearchResults from './SearchResults';

import { clearRawData } from 'reducers/NcicReducer';

const SearchNcicHistory = props => {
  const { ncicHistory, clearRawData } = props;
  const [searchText, setSearch] = React.useState('');
  const themeMode = useSelector(state => state.theme.mode);
  const handleSearchChange = ev => {
    setSearch(ev.target.value);
  };

  return (
    <Fragment>
      {/* <Paper
        square
        className="m-3 p-3"
        style={{
          background: themeMode === 'day' ? 'white' : '#424242',
          position: '-webkit-sticky',
          position: 'sticky',
          top: 85,
          bottom: 20,

          paddingBottom: '20px',
          zIndex: 5
        }}>
        <SearchForm
          searchText={searchText}
          onSearchCahnge={handleSearchChange}
          ncicHistory={ncicHistory}
          clearNiciHistory={clearRawData}
        />
      </Paper> */}
      <Paper square className="m-3 p-3">
        <SearchResults
          searchText={searchText}
          data={ncicHistory}
          clearNiciHistory={clearRawData}
        />
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  ncicHistory: state.ncic.rawDataHistory
});

export default connect(mapStateToProps, { clearRawData })(SearchNcicHistory);
