
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8judo100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="100" height="100"><path d="M10.5 3A2.5 2.5 0 0 0 8 5.5 2.5 2.5 0 0 0 10.5 8 2.5 2.5 0 0 0 13 5.5 2.5 2.5 0 0 0 10.5 3zm7.518 1.986a1 1 0 0 0-1.004 1.178l1 6a1 1 0 0 0 .515.719l3.332 1.775-1.875 1.854-4.695-3.451-6.38-3.641-.02.004A2.5 2.5 0 0 0 7.5 9a2.5 2.5 0 0 0-2.088 1.127 2.5 2.5 0 0 0-.002 0l-.008.014a2.5 2.5 0 0 0-.025.04l-2.965 4.946A2.5 2.5 0 0 0 2 16.5a2.5 2.5 0 0 0 .145.828l1.01 3.62-2.839 4.304-.097.123a1 1 0 1 0 1.562 1.25l4-5a1 1 0 0 0 .217-.693l-.129-1.871 2.656 2.609 1.498 4.57.006-.002a1 1 0 1 0 1.951-.433l-1-5a1 1 0 0 0-.123-.315 1 1 0 0 0 0-.002l-.003-.004a1 1 0 0 0-.127-.168 1 1 0 0 0-.008-.01l-3.117-4.12 1.97-3.291a2.5 2.5 0 0 0 .157-.26L14 14.91l5.584 5.195a2.5 2.5 0 0 0 .004.006A2.5 2.5 0 0 0 21.5 21a2.5 2.5 0 0 0 1.842-.813h.002l3.918-3.916a2.5 2.5 0 0 0 .047-.046l.03-.032v-.006A2.5 2.5 0 0 0 28 14.5a2.5 2.5 0 0 0-.213-1.004v-.004L25.951 9.72l3.412-2.772.15-.09a1 1 0 0 0-.486-1.865 1 1 0 0 0-.54.15l-5 3a1 1 0 0 0-.446 1.143l.037.123.66 2.592-3.306-1.59-1.46-4.639a1 1 0 0 0-.954-.785zM16.5 20a2.5 2.5 0 0 0-2.5 2.5 2.5 2.5 0 0 0 2.5 2.5 2.5 2.5 0 0 0 2.5-2.5 2.5 2.5 0 0 0-2.5-2.5z"/></SvgIcon>
      );
    }
  
    export default icons8judo100Icon;