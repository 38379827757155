import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function icons8doorsensorchecked100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M24.934.002a.983.983 0 0 0-.233.045l-16 5A.997.997 0 0 0 8 6v39a1 1 0 0 0 .758.97l16 4a.997.997 0 0 0 .857-.183A.996.996 0 0 0 26 49V1c0-.318-.15-.618-.406-.807a1.001 1.001 0 0 0-.66-.191zM28 4v2h8v22.697a11.907 11.907 0 0 1 2-.517V5a1 1 0 0 0-1-1h-9zm-6 19c.552 0 1 .672 1 1.5s-.448 1.5-1 1.5-1-.672-1-1.5.448-1.5 1-1.5zm18 7c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm4.3 5.4 1.5 1.3-6.7 7.7-4.7-3.6 1.2-1.6 3.3 2.5 5.4-6.3z" />
    </SvgIcon>
  );
}

export default icons8doorsensorchecked100Icon;
