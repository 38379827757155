
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function BurglaryIcon(props) {
      return (
        <SvgIcon {...props} viewBox="0 0 50 50"><path d="M1 0C.45 0 0 .45 0 1v33a1 1 0 0 0 1 1h7.063c-.102-.156-.2-.297-.282-.469-.234-.48-.351-1.004-.406-1.531H2V2h29.594v-.063l.031-.03A4.352 4.352 0 0 1 32.969 0Zm37.344 0a4.28 4.28 0 0 1 1.031 1.25c.133.238.23.496.313.75H48v31H35.219l.625.969c.222.32.41.676.562 1.031H49c.555 0 1-.445 1-1V1a1 1 0 0 0-1-1ZM35.75 1.031c-1.02-.05-1.898.602-2.25 1.563L31 10.406l-13.313 6.281c-1.101.5-2 1.399-2.5 2.5L9.5 31.595c-.5 1.3.105 2.82 1.406 3.218 1.102.399 2.281-.117 2.781-1.218L18.5 23.187 20.906 29a11.366 11.366 0 0 0 3.688 4.688L30 37.593l-2.906 8.312c-.5 1.602.71 3.188 2.312 3.188 1.102 0 1.98-.688 2.282-1.688l3-8.812c.398-1.2.199-2.5-.5-3.5L32.156 32H46c.555 0 1-.445 1-1V4a1 1 0 0 0-1-1h-6.125c.031.496-.008.992-.156 1.469L39.562 5H45v25h-6.875L31 25.812 27.5 18l6.094-3.313c1-.5 1.605-1.374 1.906-2.374l2.313-8.407c.398-1.199-.426-2.511-1.626-2.812a2.148 2.148 0 0 0-.437-.063ZM4 3c-.55 0-1 .45-1 1v27a1 1 0 0 0 1 1h3.375c.047-.379.11-.762.25-1.125l.063-.125.343-.75H5V5h8.813A6.49 6.49 0 0 1 18.5 3Zm14.5 0a6.5 6.5 0 0 1 4.688 2h7.437l.656-2Zm0 2C16 5 14 7 14 9.5s2 4.5 4.5 4.5S23 12 23 9.5 21 5 18.5 5Zm-.938 25-.906 2h3.625c-.43-.637-.812-1.3-1.125-2Zm-1.375 3-.656 1.438c-.09.199-.195.382-.312.562h7.844a13.856 13.856 0 0 1-2.032-2Z"/></SvgIcon>
      );
    }
  
    export default BurglaryIcon;