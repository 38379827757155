import { Paper, useTheme } from '@mui/material';
import React from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import WarrantCard from './WarrantCard';

const SearchResult = props => {
  const { localtableconfig, warrants } = props;
  const themMode = useSelector(state => state.theme.mode);
  // const theme = useTheme();
  const { loading, loaded, rows, error } = warrants;

  if (rows.length > 0) {
    return (
      <Fragment>
        <Paper square className="m-3" style={{ background: 'none' }}>
          <WarrantCard
            data={warrants}
            config={localtableconfig}
            themMode={themMode}
          />
        </Paper>
      </Fragment>
    );
  } else {
    if (loading && rows.length === 0)
      return (
        <Paper square className="m-3 p-3">
          <LoadingData />
        </Paper>
      );
    if (!loaded && !loading && rows.length === 0)
      return (
        <Paper square className="m-3 p-3">
          <StartSearching />
        </Paper>
      );
    if (loaded && !loading && rows.length === 0 && !error)
      return (
        <Paper square className="m-3 p-3">
          <NoResults />
        </Paper>
      );
    if (loaded && !loading && rows.length === 0 && error)
      return (
        <Paper square className="m-3 p-3">
          <ErrorLoading />
        </Paper>
      );
    return null;
  }
};
function NoResults() {
  return 'Sorry, no results match your search criteria.';
}

function LoadingData() {
  return 'Loading the data, please wait...';
}

function StartSearching() {
  return 'Please define your search criteria.';
}

function ErrorLoading() {
  return 'There was an error while receiving the data.';
}

export default SearchResult;
