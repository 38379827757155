import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  },
  headerItem: {
    fontSize: '1em !important'
  }
}));

function Events(props) {
  const { data, themeMode, close } = props;
  const { classes, cx } = useStyles();

  if (data.label === 'Events') {
    if (data.data !== null) {
      return (
        <Card className="p-4 mt-3">
          <div className="card-header-alt d-flex justify-content-between">
            <div></div>
            <IconButton onClick={() => close()} aria-label="close" size="large">
              <CloseIcon variant="contained" />
            </IconButton>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div className="table-responsive">
            <table
              className={cx(
                'table',
                themeMode === 'night' && 'table-pts-night',
                themeMode === 'day' && 'table-pts-day',
                'text-nowrap',
                'mb-0'
              )}>
              <thead>
                <tr>
                  <th className={classes.headerItem}>Date</th>
                  <th className={classes.headerItem}>EventID</th>
                  <th className={classes.headerItem}>Type</th>
                  <th className={classes.headerItem}>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.data.map((row, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        {' '}
                        {row.Created
                          ? moment(row.PlateCreated).format('MM/DD/YYYY')
                          : ''}
                      </td>
                      <td>{row.EventID}</td>
                      <td>{row.CallType}</td>
                      <td>{row.Status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="card-box bg-neutral-warning border-1 card-border-top border-warning text-center p-3">
          <h4 style={{ marginBottom: 0, color: 'black' }}>
            Sorry, no events found.
          </h4>
        </Card>
      );
    }
  } else {
    return null;
  }
}

export default Events;
