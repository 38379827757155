import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  Grid,
  Card,
  Box,
  Avatar,
  Badge,
  Button,
  IconButton
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import avatarPlaceholder from '../../../../../assets/images/avatars/avatar-526x526.png';

import RegistrationsComponent from '../Registrations';
import EventsComponent from '../Events';
import TowingsComponent from '../Towings';
import AlertsComponent from '../Alerts';
import { useNavigate } from 'react-router-dom';
import { setVehicleCitaionFlag } from 'reducers/VehicleSearchReducer';
import { useDispatch } from 'react-redux';
import PersonDetails from 'components/Search/Persons/SearchDetails';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500,
    fontSize: '0.95rem'
  },
  itemLabel: {
    fontWeight: 200
  },
  customBadge: {
    'MuiBadge-badge': {
      width: '200px'
    }
  }
}));

function Info(props) {
  const { data, themeMode, owners, ownerDetails } = props;
  const { classes } = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [mainMugshot, setMainMugshot] = useState(() => {
    return avatarPlaceholder;
  });

  const Badges = (props) => {
    function IsWantedBadge(props) {
      if (props.isWanted && props.isWanted === 'true') {
        return (
          <Badge
            overlap="rectangular"
            className="customBagde"
            badgeContent="WANTED"
            color="error"></Badge>
        );
      } else {
        return null;
      }
    }

    function NcicBadge(props) {
      if (props.warnings && props.warnings !== null) {
        return props.warnings.map((item, id) => {
          if (item.Banner) {
            return (
              <div key={id} style={{ marginBottom: '10px' }}>
                <Badge
                  overlap="rectangular"
                  className="customBagde"
                  badgeContent={item.Banner.toUpperCase()}
                  color="error"></Badge>
              </div>
            );
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    }

    return (
      <Fragment>
        <NcicBadge warnings={props.warnings} />
      </Fragment>
    );
  };

  let displayType = 'local';
  const [details, setDetails] = useState(false);
  let ownerParsedData =
    data.OwnerInfoResponse && JSON.parse(data.OwnerInfoResponse);
  let _parsedNcicData =
    data.StateResponseData && data.StateResponseData !== null
      ? JSON.parse(data.StateResponseData)
      : null;

  if (_parsedNcicData === null) {
    _parsedNcicData =
      data.NcicResponseData && data.NcicResponseData !== null
        ? JSON.parse(data.NcicResponseData)
        : null;
  }

  let _parsedWarnings =
    data.Warnings && data.Warnings !== null ? JSON.parse(data.Warnings) : null;

  if (_parsedWarnings !== null) {
    if (data.IsWanted === true) _parsedWarnings.push({ Banner: 'Wanted' });
  } else {
    if (data.IsWanted === true) _parsedWarnings = [{ Banner: 'Wanted' }];
  }

  if (data.ptsVehicleID === null && _parsedNcicData !== null)
    displayType = 'ncic';
  if (data.ptsVehicleID !== null && _parsedNcicData && _parsedNcicData !== null)
    displayType = 'combined';

  // Universal Vehicle data
  let Vin =
    displayType === 'ncic'
      ? _parsedNcicData.data.VehicleIdentificationNumber
      : data.VIN;
  let MakeModel =
    displayType === 'ncic'
      ? `${_parsedNcicData.data.VehicleMakeCode} ${_parsedNcicData.data.VehicleModelCode}`
      : `${data.MakeCode !== null ? data.MakeCode : ''} ${
          data.ModelCode !== null ? data.ModelCode : ''
        }`;

  // Vehicle Items
  const vehicleItems = [
    {
      data: data.Events !== null ? JSON.parse(data.Events) : null,
      label: 'Events'
    },
    {
      data: data.Towing !== null ? JSON.parse(data.Towing) : null,
      label: 'Towings'
    },
    {
      data: data.Alerts !== null ? JSON.parse(data.Alerts) : null,
      label: 'Alerts'
    },
    {
      data:
        data.vehicleRegistration !== null
          ? JSON.parse(data.Registration)
          : null,
      label: 'Registrations'
    }
  ];

  function RenderLocalCard() {
    if (displayType === 'local' || displayType === 'combined') {
      // Vehicle data
      let _registration =
        data.Registration !== null ? JSON.parse(data.Registration) : null;
      let PlateNumber =
        _registration !== null ? _registration[0].PlateNumber : 'Unknown';
      let PlateState =
        _registration !== null ? _registration[0].PlateStateCode : 'Unknown';
      let BrandCode = data.BrandCode !== null ? data.BrandCode : 'Unknown';
      let MakeCode = data.MakeCode !== null ? data.MakeCode : 'Unknown';
      let ModelCode = data.ModelCode !== null ? data.ModelCode : 'Unknown';
      let Year = data.Year !== null ? data.Year : 'Unknown';
      let PrimaryColorCode =
        data.PrimaryColorCode !== null ? data.PrimaryColorCode : 'Unknown';
      let StyleCode = data.StyleCode !== null ? data.StyleCode : 'Unknown';
      let DoorQuantity =
        data.DoorQuantity !== null ? data.DoorQuantity : 'Unknown';

      return (
        <Card className="mb-4 p-4">
          <div className="card-header-alt d-flex justify-content-between">
            <div>
              <h6 className="font-weight-bold font-size-lg mb-1  ">
                {MakeModel}
              </h6>
              <p className="text-black-50 mb-0">{Vin}</p>
              <div style={{ marginLeft: '75px', marginBottom: '10px' }}>
                <Badges warnings={_parsedWarnings} />
              </div>
            </div>
            <Box className="d-flex align-items-center">
              <div>
                <Avatar alt="Avatar">L</Avatar>
              </div>
            </Box>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div
            style={{ justifyContent: 'space-between' }}
            className="d-block d-md-flex align-items-center my-2">
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Plate Number</span>
              <div className="m-1">{PlateNumber}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Plate State</span>
              <div className="m-1">{PlateState}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Brand</span>
              <div className="m-1">{BrandCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Make</span>
              <div className="m-1">{MakeCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Model</span>
              <div className="m-1">{ModelCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Year</span>
              <div className="m-1">{Year}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Color</span>
              <div className="m-1">{PrimaryColorCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Style</span>
              <div className="m-1">{StyleCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Door Quantity</span>
              <div className="m-1">{DoorQuantity}</div>
            </span>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
        </Card>
      );
    } else {
      return null;
    }
  }

  function RenderOwnerDetails() {
    return (
      <>
        <PersonDetails
          data={ownerDetails.data}
          images={owners.images}
          showDetails={ownerDetails.isShowing}
          themeMode={themeMode}
          origin="VehicleRegistration"
        />
      </>
    );
  }
  function RenderNcicCard() {
    if (displayType === 'ncic' || displayType === 'combined') {
      // Vehicle data
      let PlateNumber =
        _parsedNcicData && _parsedNcicData.data.LicensePlateNumber
          ? _parsedNcicData.data.LicensePlateNumber
          : 'Unknown';
      let PlateState =
        _parsedNcicData && _parsedNcicData.data.ImpliedLicensePlateStateCode
          ? _parsedNcicData.data.ImpliedLicensePlateStateCode
          : 'Unknown';
      let PlateType =
        _parsedNcicData && _parsedNcicData.data.LicensePlateTypeCode
          ? _parsedNcicData.data.LicensePlateTypeCode
          : 'Unknown';
      let BrandCode =
        _parsedNcicData && _parsedNcicData.data.VehicleBrandCode
          ? _parsedNcicData.data.VehicleBrandCode
          : 'Unknown';
      let MakeCode =
        _parsedNcicData && _parsedNcicData.data.VehicleMakeCode
          ? _parsedNcicData.data.VehicleMakeCode
          : 'Unknown';
      let ModelCode =
        _parsedNcicData && _parsedNcicData.data.VehicleModelCode
          ? _parsedNcicData.data.VehicleModelCode
          : 'Unknown';
      let Year =
        _parsedNcicData && _parsedNcicData.data.VehicleYear
          ? _parsedNcicData.data.VehicleYear
          : 'Unknown';
      let PrimaryColorCode =
        _parsedNcicData && _parsedNcicData.data.VehicleColorCode
          ? _parsedNcicData.data.VehicleColorCode
          : 'Unknown';
      let StyleCode =
        _parsedNcicData && _parsedNcicData.data.VehicleStyleCode
          ? _parsedNcicData.data.VehicleStyleCode
          : 'Unknown';
      let DoorQuantity =
        _parsedNcicData && _parsedNcicData.data.DoorQuantity
          ? _parsedNcicData.data.DoorQuantity
          : 'Unknown';
      let Address = `${_parsedNcicData?.data?.AddressStreet}, ${_parsedNcicData?.data?.AddressStateCode} ${_parsedNcicData?.data?.AddressZipCode} ${_parsedNcicData?.data?.AddressCity} ${_parsedNcicData?.data?.AddressCounty}`;
      let AddressStreet =
        _parsedNcicData && _parsedNcicData.data.AddressStreet
          ? _parsedNcicData.data.AddressStreet
          : '';
      let AddressStateCode =
        _parsedNcicData && _parsedNcicData.data.AddressStateCode
          ? _parsedNcicData.data.AddressStateCode
          : '';
      let AddressZipCode =
        _parsedNcicData && _parsedNcicData.data.AddressZipCode
          ? _parsedNcicData.data.AddressZipCode
          : '';
      let AddressCity =
        _parsedNcicData && _parsedNcicData.data.AddressCity
          ? _parsedNcicData.data.AddressCity
          : '';
      let AddressCounty =
        _parsedNcicData && _parsedNcicData.data.AddressCounty
          ? _parsedNcicData.data.AddressCounty
          : '';
      let InsuranceCode =
        _parsedNcicData && _parsedNcicData.data.AutoInsuranceActivityCode
          ? _parsedNcicData.data.AutoInsuranceActivityCode
          : 'Unknown';
      let InsuranceCarrier =
        _parsedNcicData && _parsedNcicData.data.AutoInsuranceCarrier
          ? _parsedNcicData.data.AutoInsuranceCarrier
          : 'Unknown';
      let AutoInsurancePolicyNumber =
        _parsedNcicData && _parsedNcicData.data.AutoInsurancePolicyNumber
          ? _parsedNcicData.data.AutoInsurancePolicyNumber
          : 'Unknown';
      let InsuranceExpirationDate =
        _parsedNcicData && _parsedNcicData.data.ExpirationDate
          ? _parsedNcicData.data.ExpirationDate
          : 'Unknown';
      let RegistrationStatusCode =
        _parsedNcicData && _parsedNcicData.data.RegistrationStatusCode
          ? _parsedNcicData.data.RegistrationStatusCode
          : 'Unknown';
      let RegistrationTypeCode =
        _parsedNcicData && _parsedNcicData.data.RegistrationTypeCode
          ? _parsedNcicData.data.RegistrationTypeCode
          : 'Unknown';
      let Name =
        _parsedNcicData && _parsedNcicData.data.Name
          ? _parsedNcicData.data.Name
          : 'Unknown';
      let IssueDate =
        _parsedNcicData && _parsedNcicData.data.IssueDate
          ? _parsedNcicData.data.IssueDate
          : 'Unknown';

      return (
        <>
          <Card className="mb-4 p-4">
            <div className="card-header-alt d-flex justify-content-between">
              <div>
                <h6 className="font-weight-bold font-size-lg mb-1  ">
                  {MakeModel}
                </h6>
                <div style={{ marginLeft: '75px', marginBottom: '10px' }}>
                  <Badges warnings={_parsedWarnings} />
                </div>
                {/* <p className="text-black-50 mb-0">{Vin}</p> */}
              </div>
              <Box className="d-flex align-items-center">
                <Avatar alt="Avatar">N</Avatar>
              </Box>
            </div>
            <div
              className="divider"
              style={{ marginLeft: '0', marginRight: '0' }}
            />
            <div>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">LIC</span>
                <span className="m-1">{PlateNumber}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">LIT</span>
                <span className="m-1">{PlateType}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">EXP</span>
                <span className="m-1">{InsuranceExpirationDate}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">ISSUED</span>
                <span className="m-1">{IssueDate}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">TAG STATUS</span>
                <span className="m-1">{RegistrationStatusCode}</span>
              </span>
              <br />
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">VIN</span>
                <span className="m-1">{Vin}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">Y</span>
                <span className="m-1">{Year}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">MAKE</span>
                <span className="m-1">{MakeCode}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">MODEL</span>
                <span className="m-1">{ModelCode}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">MODEL</span>
                <span className="m-1">{ModelCode}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">STYLE</span>
                <span className="m-1">{StyleCode}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">SOLOR</span>
                <span className="m-1">{PrimaryColorCode}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">TYPE</span>
                <span className="m-1">{PlateType}</span>
              </span>
              <br />
              <br />
              <span className="align-items-center">
                <span className="m-1">
                  <b>{Name}</b>
                </span>
              </span>
              <br />
              <span className="align-items-center">
                {AddressStreet}
                <br />
                {AddressCity} {AddressStateCode} {AddressZipCode}
              </span>
              <br />
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">CO</span>
                <span className="m-1">{AddressCounty}</span>
              </span>
              <br />
              <br />
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">
                  INSURANCE STATUS
                </span>
                <span className="m-1">{InsuranceCode}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">INSURER</span>
                <span className="m-1">{InsuranceCarrier}</span>
              </span>
              <span className="align-items-center">
                <span className="m-1 badge badge-secondary">POLICY</span>
                <span className="m-1">{AutoInsurancePolicyNumber}</span>
              </span>
            </div>
          </Card>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={12}>
              {ownerDetails.data && RenderOwnerDetails()}
            </Grid>
          </Grid>
        </>
      );
    } else {
      return null;
    }
  }

  function RenderVehicleItem(value, idx) {
    const handleCardClick = (e, card) => {
      setDetails(card);
    };

    if (value.data !== null) {
      if (details === false) {
        return (
          <Grid item xs={12} md={6} key={idx}>
            <Card
              className="card card-box card-box-hover-rise card-box-hover align-box-row align-items-start p-2"
              onClick={(e) => handleCardClick(e, value)}>
              <div>
                <div className="display-3">{value.data.length}</div>
                <div className="divider mt-2 mb-3 border-2 w-25 rounded" />
                <div className="font-weight-bold font-size-sm text-uppercase">
                  {value.label}
                </div>
              </div>
              <div className="ml-auto card-hover-indicator align-self-center">
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  className="font-size-xl"
                />
              </div>
            </Card>
          </Grid>
        );
      } else {
        return null;
      }
    }
  }

  function cardClose() {
    setDetails(false);
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={12}>
        {RenderLocalCard()}
        {RenderNcicCard()}
        <Grid container className={classes.root} spacing={2}>
          {vehicleItems.map((data, idx) => {
            return RenderVehicleItem(data, idx);
          })}
        </Grid>

        {details !== false && (
          <Fragment>
            <RegistrationsComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />

            <EventsComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <TowingsComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
            <AlertsComponent
              data={details}
              themeMode={themeMode}
              close={cardClose}
            />
          </Fragment>
        )}
      </Grid>
      {/* <IconButton onClick={() => close()} aria-label="close">
        <CloseIcon variant="contained" />
      </IconButton> */}
      {displayType !== 'local' && (
        <Button
          variant="contained"
          color="primary"
          style={{
            position: 'absolute',
            bottom: '8px',
            background: 'blue !important',
            right: '120px'
          }}
          onClick={() => {
            dispatch(setVehicleCitaionFlag(true));
            navigate('/Ecitations/traffic');
          }}>
          E-Citation
        </Button>
      )}
    </Grid>
  );
}

export default Info;
