
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8smarthomechecked100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M25 2.025a.997.997 0 0 0-.615.213l-23 17.948a.998.998 0 0 0-.172 1.402.998.998 0 0 0 1.402.174L4 20.682v26.29a1 1 0 0 0 1 1h29.002A9.918 9.918 0 0 0 40 50c5.5 0 10-4.5 10-10 0-3.251-1.579-6.144-4-7.973V20.682l1.385 1.08a.995.995 0 0 0 1.404-.172 1.002 1.002 0 0 0-.174-1.404L41 14.242V7h-6v2.56l-9.385-7.322A.997.997 0 0 0 25 2.025zm-5 26.95h10V46H20V28.975zM40 32c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm4.3 3.4-5.4 6.3-3.3-2.5-1.2 1.6 4.7 3.6 6.7-7.7-1.5-1.3zM32 45.963c.009.012.017.025.027.037H32v-.037z"/></SvgIcon>
      );
    }
  
    export default icons8smarthomechecked100Icon;