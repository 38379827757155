import store from '../config/configureStore';

export const getService = (serviceName = 'cad') => {
  let service;
  try {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    service = client.service(serviceName);
    service.timeout = 20000;
  } catch (err) {
    throw new Error('Error, service not ready.');
  }

  return service;
};
