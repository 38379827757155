import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Paper } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import VehicleDetails from './SearchDetails/index';

import {
  findVehicles,
  sortVehicles,
  findVehicle
} from '../../../reducers/VehicleSearchReducer';
import { findResultsVehicle, finishedResponses } from 'reducers/NcicReducer';
const useStyles = makeStyles()((theme) => ({
  filterDiv: {
    position: '-webkit-sticky',
    position: 'sticky',
    top: 130,
    bottom: 20,
    padding: '24px',
    margin: '24px',
    paddingBottom: '20px',

    zIndex: 5,
    [theme.breakpoints.down('lg')]: {
      top: 0,
      bottom: 0,
      position: 'relative'
    }
  }
}));
const SearchVehicles = (props) => {
  const {
    ncicformdefinition,
    localformdefinition,
    localtableconfig,
    themeMode
  } = props;
  const { classes, cx } = useStyles();
  const { vehicles, vehicleDetails, owners, ownerDetails } =
    props.vehicleSearch;

  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    if (vehicleDetails.data !== null && vehicleDetails.isShowing === true) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [vehicleDetails]);

  useEffect(() => {
    props.finishedResponses(0);
  }, []);

  const getVehiclesData = (options) => {
    props.findVehicles(options);
  };

  const getVehiclesNcicData = (options) => {
    props.findResults(options);
  };

  const sortVehiclesData = (name) => {
    props.sortVehicles(name);
  };

  const tableRowClick = (row) => {
    props.findVehicle(row.ptsVehicleID);
  };

  const closeDetails = () => {
    props.findVehicle(null);
  };

  return (
    <Fragment>
      <Paper
        square
        className={cx('m-3 p-3', classes.filterDiv)}
        sx={{ background: themeMode === 'day' ? 'white' : '#424242' }}>
        <SearchForm
          getVehiclesData={getVehiclesData}
          getResultsData={getVehiclesNcicData}
          localformdefinition={localformdefinition}
          ncicformdefinition={ncicformdefinition}
          name={ncicformdefinition.name}
          vehicles={vehicles}
          ncicCheckbox={props.ncicSearch.vehicleForm.ncic}
        />
      </Paper>
      <SearchResults
        getVehiclesData={getVehiclesData}
        getResultsData={getVehiclesNcicData}
        sortPersonsData={sortVehiclesData}
        tableRowClick={tableRowClick}
        localtableconfig={localtableconfig}
        vehicles={vehicles}
      />
      {showDetails && (
        <VehicleDetails
          data={vehicleDetails.data}
          showDetails={showDetails}
          close={closeDetails}
          themeMode={themeMode}
          owners={owners}
          ownerDetails={ownerDetails}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  list: state.ncic.responsesVehicles,
  ncicSearch: state.ncic,
  vehicleSearch: state.vehicleSearch
});

export default connect(mapStateToProps, {
  findVehicles,
  sortVehicles,
  findVehicle,
  findResultsVehicle,
  finishedResponses
})(SearchVehicles);
