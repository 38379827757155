
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8ambulance100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M26 0c-.55 0-1 .45-1 1v2a1 1 0 1 0 2 0V1a1 1 0 0 0-1-1Zm-7.063 2.938c-.253 0-.523.085-.718.28a.99.99 0 0 0 0 1.407l1.406 1.438c.195.195.46.28.719.28.258 0 .523-.085.718-.28a1.028 1.028 0 0 0 0-1.438l-1.437-1.406a.944.944 0 0 0-.688-.281Zm14.125 0a.944.944 0 0 0-.687.28l-1.438 1.407a1.03 1.03 0 0 0 0 1.438c.196.195.461.28.72.28.257 0 .523-.085.718-.28l1.406-1.438a.99.99 0 0 0 0-1.406 1.015 1.015 0 0 0-.718-.281ZM26 6c-2.207 0-4 1.793-4 4v1h8v-1c0-2.207-1.793-4-4-4ZM16 9c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1-.45 1-1s-.45-1-1-1Zm18 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2ZM2.781 13C1.254 13 0 14.234 0 15.75v24.5C0 41.766 1.254 43 2.781 43H7c0 3.309 2.691 6 6 6s6-2.691 6-6h14c0 3.309 2.691 6 6 6s6-2.691 6-6h2.219C48.746 43 50 41.766 50 40.25v-7c0-1.926-1.371-3.887-1.5-4.063L39.469 15.22a1.489 1.489 0 0 0-.094-.125l-.188-.188C38.414 13.976 37.642 13 35.97 13ZM32 18h6.844L46 29H32Zm-18 3h2v4h4v2h-4v4h-2v-4h-4v-2h4Zm-1 18c2.207 0 4 1.793 4 4s-1.793 4-4 4-4-1.793-4-4 1.793-4 4-4Zm26 0c2.207 0 4 1.793 4 4s-1.793 4-4 4-4-1.793-4-4 1.793-4 4-4Z"/></SvgIcon>
      );
    }
  
    export default icons8ambulance100Icon;