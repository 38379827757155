import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import { Alert, AlertTitle } from '@mui/material';
import TextField2 from 'components/TextField2';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { notify } from 'reducers/NotifierReducer';
import { changeNCICPassword, hidePassExpiration } from 'reducers/UserReducer';
import { RowInner } from 'utils/formStyles';
import { getFormStyle, passValidationErrors } from 'utils/functions';
const useStyles = makeStyles()(theme => ({
  form: {
    margin: `0 -${theme.spacing(0.5)} ${theme.spacing(1)}`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  w150x250: {
    ...getFormStyle(150, 250)
  },
  alert: {
    marginBottom: 16,
    '& ul': {
      padding: 0
    }
  }
}));
const ChangeNcicPass = props => {
  const { classes } = useStyles();
  const { Username, websocket } = props;

  const [NCICPassword, setNCicPassword] = useState({
    password: '',
    confirmNewPass: ''
  });

  const [loading2, setLoading2] = useState(false);

  const [NCICError, setNCICError] = useState(false);
  const [errors, setErrors] = useState([]);
  const { NCIC_PASS_VALIDATE } = useSelector(state => state.appInfo.appEnvInfo);

  const ncicDisable = Boolean(
    NCICError ||
      !NCICPassword.password ||
      !NCICPassword.confirmNewPass ||
      loading2 ||
      NCICPassword.password !== NCICPassword.confirmNewPass
  );

  const repeatPassErr2 =
    NCICPassword.password === NCICPassword.confirmNewPass
      ? false
      : "Passwords don't match";

  useEffect(() => {
    if (NCIC_PASS_VALIDATE && NCIC_PASS_VALIDATE === true) {
      const validationErrors = passValidationErrors(
        null,
        NCICPassword.password || ''
      );

      if (NCICPassword.password && validationErrors.length > 0) {
        setNCICError(validationErrors[0]);
      } else {
        if (NCICError) setNCICError(false);
      }
    }
  }, [NCICPassword.password]);

  const handleNcicPasswordChange = async () => {
    setLoading2(true);
    try {
      const res = await changeNCICPassword(NCICPassword.password);
      await websocket.websocket.reAuthenticate();

      notify('NCIC Password updated!', 'success', '300px');

      setNCicPassword({ password: '', confirmNewPass: '' });

      setErrors([]);
    } catch (err) {
      if (err.errors && err.errors.length) {
        setErrors(err.errors);
      } else {
        const message = err.message
          ? err.message
          : 'Error NCIC updating password';
        setErrors([message]);
      }
    } finally {
      setLoading2(false);
    }
  };

  return (
    <div>
      {errors.length > 0 && (
        <Alert severity="error" className={classes.alert}>
          <AlertTitle>NCIC Password validation errors</AlertTitle>
          <ul>
            {errors.map((err, idx) => (
              <li key={idx}>{err}</li>
            ))}
          </ul>
        </Alert>
      )}
      <h4 className={'py-2'}>NCIC Change password</h4>
      <RowInner>
        <TextField2
          className={classes.w150x250}
          label="New NCIC Password"
          value={NCICPassword.password}
          onChange={(ev, val) =>
            setNCicPassword({ ...NCICPassword, password: ev.target.value })
          }
          type="mask"
          error={NCICError}
        />
      </RowInner>
      <RowInner>
        <TextField2
          className={classes.w150x250}
          label="Confirm NCIC Password"
          value={NCICPassword.confirmNewPass}
          onChange={(ev, val) =>
            setNCicPassword({
              ...NCICPassword,
              confirmNewPass: ev.target.value
            })
          }
          type="mask"
          error={NCICPassword.confirmNewPass ? repeatPassErr2 : false}
        />
      </RowInner>
      <RowInner>
        <Button
          className={classes.w150x250}
          color="secondary"
          variant="contained"
          autoFocus
          onClick={handleNcicPasswordChange}
          disabled={ncicDisable}>
          Change Password
        </Button>
      </RowInner>
      {loading2 && (
        <CircularProgress
          style={{
            marginLeft: 8,
            position: 'absolute',
            top: '50%',
            left: '40%'
          }}
        />
      )}
    </div>
  );
};
const mapStateToProps = state => ({
  Username: state.user.userData.user.Username,
  websocket: state.websocket
});

export default connect(mapStateToProps, {
  //handleError,
  hidePassExpiration
})(ChangeNcicPass);
