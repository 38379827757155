import MomentUtils from '@date-io/moment';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  Select,
  TextField,
  useTheme
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import {
//   KeyboardDatePicker,
//   KeyboardDateTimePicker,
//   MuiPickersUtilsProvider
// } from '@material-ui/pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Fragment } from 'react';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import {
  handleDateChange,
  handleFilterChange,
  findWarrants,
  clearResults,
  clearFilters,
  handleWarrantFilltersAutoFill,
  searchPeople
} from '../../../../reducers/WarrantsReducer';
import { getCodeEventType } from 'reducers/CodeReducer';
import { Autocomplete } from '@mui/material';
import { getZones } from 'reducers/DataReducer';
import AddressSearch from 'global-components/AddressSearch';
import saveSearchSuggestions from 'utils/saveSearchSuggestions';
import SearchHistory from 'global-components/SearchHistory';
import { displayDate } from 'reducers/TimeReducer';
const useStyles = makeStyles()((theme) => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  filter: {
    marginTop: '10px',
    margin: '16px 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  datePicker: {
    marginTop: '10px',
    width: '200px',
    margin: '16px 10px 10px',

    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    marginTop: '10px',
    margin: '16px 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  select2: {
    marginTop: '10px',
    margin: '16px 10px 10px',
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  inputWidth: {
    width: '100%'
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));
const processOptionLabel = (option) => {
  if (!option) return null;
  if (typeof option === 'string') {
    return option;
  }
  const fullName = option.FullName;
  let ssn = option.SSN || '';
  let oln = option.OLN || '';

  const race = option.Race || '';
  const sex = option.Sex || '';
  const born = option.BirthDate || '';
  return `${fullName} SSN: ${ssn} OLN: ${oln} Race: ${race} Sex: ${sex} DOB: ${born}`;
};
const SearchForm = (props) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const {
    filterValues,
    pickerValues,
    handleFilterChange,
    handleDateChange,
    warrants,
    wsClient,
    codeCities,
    zones,
    selectedSearchHistory
  } = props;
  const [filters, setFilters] = useState(props.localformdefinition.display);
  const [loading, setLoading] = useState(false);

  const [searchPersonId, setSearchPersonId] = useState(
    selectedSearchHistory?.ptsPersonID?.search || null
  );
  const [personLoading, setPersonLoading] = useState(false);
  const [people, setPeopleState] = React.useState([]);
  const dispatch = useDispatch();
  const [searchAddressID, setSearchAddressID] = React.useState(
    selectedSearchHistory?.ptsAddressID?.search || null
  );
  const [cityId, setCityId] = React.useState(
    selectedSearchHistory?.City?.search || ''
  );

  const [peopleOptionLable, setPeopleOptionLable] = useState('');
  const [addressOptionLable, setAddressOptionLable] = useState(
    selectedSearchHistory?.addressInfo || ''
  );
  console.log('addressOptionLable', addressOptionLable);
  const filterRefs = {};
  const { loaded } = warrants;
  const codes = {
    zone: [
      { Code: '', Description: '' },
      ...zones.map((z) => {
        return {
          ...z,
          Code: z.ZoneCode
        };
      })
    ],
    city: [
      { Code: '', Description: '' },
      ...codeCities?.map((ct) => {
        return {
          ...ct,
          Code: ct.CityDescription
        };
      })
    ],
    status: [
      { Code: 'ACTIVE', Description: 'ACTIVE' },
      { Code: 'DEACTIVE', Description: 'DEACTIVE' }
    ]
  };

  useEffect(() => {
    if (!wsClient) {
      return;
    }
    dispatch(getZones());
  }, [wsClient]);
  useEffect(() => {
    if (selectedSearchHistory?.personInfo) {
      setPeopleOptionLable(selectedSearchHistory?.personInfo);
    } else {
      setPeopleOptionLable('');
    }
    if (selectedSearchHistory?.ptsPersonID) {
      setSearchPersonId(selectedSearchHistory?.ptsPersonID.search);
    }
    if (selectedSearchHistory?.addressInfo) {
      setAddressOptionLable(selectedSearchHistory?.addressInfo || '');
    } else {
      setAddressOptionLable('');
    }

    if (selectedSearchHistory?.ptsAddressID) {
      setSearchAddressID(selectedSearchHistory?.ptsAddressID.search);
    }
  }, [selectedSearchHistory?.addressInfo, selectedSearchHistory?.personInfo]);

  useEffect(() => {
    loaded && loading && setLoading(false);
  }, [loaded, loading]);

  Object.values(filters).forEach(({ dbName }) => {
    // eslint-disable-next-line
    filterRefs[dbName] = useRef();
  });

  const clearForm = () => {
    dispatch(handleWarrantFilltersAutoFill({}));
    setSearchAddressID(null);
    setPeopleOptionLable('');
    setSearchPersonId(null);
    setAddressOptionLable('');
    Object.values(filterRefs).forEach(({ current }) => {
      if (current) current.value = '';
    });

    dispatch(clearFilters());
  };
  const submitForm = (e) => {
    e.preventDefault();
    const { orderBy, rowsPerPage, orderDirection } = props.warrants;
    const newFilterValues = JSON.parse(JSON.stringify(filterValues));

    Object.entries(pickerValues).forEach(([key, val]) => {
      newFilterValues[key] = {
        type: 'date',
        from: moment(val.from).format('YYYY-MM-DD') + ' 00:00:00.0',
        to: moment(val.to).format('YYYY-MM-DD') + ' 23:59:59.0'
      };
    });

    if (searchPersonId && peopleOptionLable) {
      newFilterValues['ptsPersonID'] = {
        type: 'exact',
        search: searchPersonId
      };
    } else {
      delete newFilterValues?.ptsPersonID;
    }

    if (searchAddressID && addressOptionLable) {
      newFilterValues['ptsAddressID'] = {
        type: 'exact',
        search: searchAddressID.ptsAddressID
      };
    } else {
      delete newFilterValues?.ptsAddressID;
    }
    let cityDescription = '';
    if (newFilterValues.City) {
      const item = codeCities.find(
        (city) =>
          city.CityDescription.toLowerCase() ===
          newFilterValues.City.search.toLowerCase()
      );
      cityDescription = newFilterValues?.City?.search || '';
      if (item && item.ptsCityID) {
        newFilterValues.City['search'] = item.ptsCityID;
      }
    }
    saveSearchSuggestions('warrantSearch', {
      ...newFilterValues,
      personInfo: peopleOptionLable,
      cityDescription: cityDescription,
      addressInfo: addressOptionLable
    });

    const options = {
      pageNo: 0,
      orderBy: 'ptsWarrantID',
      rowsPerPage,
      orderDirection,
      filters: newFilterValues
    };
    // console.log('final Option', options);
    setLoading(true);
    dispatch(findWarrants(options));
  };
  const getTextFilter = (filter, idx) => (
    <Grid item xs={12} md={4} lg={2} key={idx}>
      <TextField
        type="search"
        size="small"
        variant="outlined"
        label={filter.label}
        key={idx}
        className={classes.inputWidth}
        value={
          filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''
        }
        onChange={(e) => handleFilterChange(e, filter)}
        inputRef={filterRefs[filter.dbName]}
      />
    </Grid>
  );
  const getDateFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    const minToValue = value.from ? value.from : undefined;
    const maxFromValue = new Date();
    return (
      <>
        <Grid item xs={12} md={4} lg={2} key={idx}>
          <DateTimePicker
            className={classes.inputWidth}
            clearable
            size="small"
            value={value.from || new Date()}
            label="Start Date"
            placeholder={`${filter.label} From`}
            inputFormat="MM/dd/yyyy HH:mm:ss"
            maxDate={maxFromValue}
            onChange={(date) => handleDateChange(date, filter.dbName, 'from')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2} key={idx}>
          <DateTimePicker
            className={classes.inputWidth}
            clearable
            size="small"
            value={value.to || new Date()}
            label="End Date"
            placeholder={`${filter.label} To`}
            inputFormat="MM/dd/yyyy HH:mm:ss"
            minDate={minToValue}
            maxDate={new Date()}
            onChange={(date) => handleDateChange(date, filter.dbName, 'to')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
      </>
    );
  };
  const getDateSingleFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    return (
      <Grid item xs={12} md={4} lg={2} key={idx}>
        <DatePicker
          className={classes.inputWidth}
          key={idx}
          size="small"
          value={value.from}
          label={filter.label}
          onChange={(date) => {
            handleDateChange(date, filter.dbName, 'single');
          }}
          inputFormat="MM/dd/yyyy"
          renderInput={(params) => (
            <TextField {...params} fullWidth variant="outlined" size="small" />
          )}
        />
      </Grid>
    );
  };

  const getSelectFilter = (filter, idx) => {
    return (
      <Grid item xs={12} md={4} lg={2} key={idx}>
        <Autocomplete
          variant="outlined"
          size="small"
          className={classes.inputWidth}
          key={idx}
          // autoHighlight
          // autoSelect
          value={{
            Code: filterValues[filter.dbName]
              ? filterValues[filter.dbName].search
              : ''
          }}
          onChange={(event, newValue) => {
            if (filter.label.toLowerCase() == 'city') {
              setCityId(newValue?.ptsCityID || '');
            }
            handleFilterChange(newValue?.Code || '', filter);
          }}
          options={codes[filter.label.toLowerCase()] || []}
          getOptionLabel={(option) => option.Code || ''}
          isOptionEqualToValue={() => true}
          renderOption={(props, option, { selected }) => {
            return <li {...props}>{option.Code || '-'} </li>;
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={filter.label} />
          )}
        />
      </Grid>
    );
  };
  let timer;
  const peopleSearchOnChange = (event) => {
    event.persist();

    if (!wsClient) {
      return;
    }

    if (timer) clearTimeout(timer);

    timer = setTimeout(async () => {
      const searchText = event.target.value;
      if (searchText && searchText.length > 0) {
        let longetsword = '';
        let wordList = [];
        const words = searchText
          .split(' ')
          .map((w) => w.trim())
          .filter((w) => Boolean(w));
        if (!words.length) {
          longetsword = '';
          wordList = [];
        } else if (words.length === 1) {
          longetsword = words[0];
          wordList = [];
        } else {
          const longestIdx = words.reduce(
            (result, val, idx) =>
              val.length > words[result].length ? idx : result,
            0
          );
          longetsword = words[longestIdx];
          words.splice(longestIdx, 1);
          wordList = words;
        }
        search(longetsword, wordList);
      }
    }, 500);
  };
  const [labelValue, setLabelValue] = useState('');
  const handlePeopleSelect = (newValue) => {
    if (newValue) {
      setSearchPersonId(newValue.ptsPersonID);
      const label = processOptionLabel(newValue);

      setPeopleOptionLable(label);
    } else {
      setSearchPersonId(null);
      setPeopleOptionLable(null);
    }
  };
  const search = async (text, wordList) => {
    if (text.length < 3) return;
    if (text.indexOf('/') !== -1) text = convertDate(text);
    setPeopleState([]);
    setPersonLoading(true);
    searchPeople(text)
      .then((result) => {
        const res = processData(result);
        const filtered = filterPersons(wordList, res);
        filtered.sort(lastNameSorter);

        setPeopleState(filtered);
      })
      .catch((err) => {
        console.log('error', err);
      })
      .finally(() => {
        setPersonLoading(false);
      });
  };
  const lastNameSorter = (a, b) => {
    if (a.LastName < b.LastName) return -1;
    if (a.LastName > b.LastName) return 1;
    return a.FullName > b.FullName ? 1 : b.FullName > a.FullName ? -1 : 0;
  };

  const filterPersons = (filters, persons) => {
    if (filters.length === 0 || !persons) return persons;
    const result = [];
    for (const person of persons) {
      const { FullName } = person;
      let match = true;
      for (const filter of filters) {
        if (FullName.toLocaleLowerCase().indexOf(filter) === -1) {
          match = false;
          break;
        }
      }
      if (match) result.push(person);
    }
    return result;
  };
  const convertDate = (text) => {
    const regex = /[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$/;
    if (!text.match(regex)) return text;
    return moment(text, 'L').format('YYYY-MM-DD');
  };

  const processData = (data) => {
    return data.map((row, idx) => ({
      ...row,
      id: idx,
      BirthDate: displayDate(row.BirthDate)
    }));
  };
  // console.log('search filter', filterValues);
  // console.log('search picker', pickerValues);
  // console.log('ptsCityID', cityId);
  // console.log('selectedSearchHistory', selectedSearchHistory);
  return (
    <div className={classes.searchWrap}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={submitForm}>
          <Grid container spacing={2} sx={{ margin: '-8px !important' }}>
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                size="small"
                className={classes.inputWidth}
                value={peopleOptionLable || ''}
                loading={personLoading}
                loadingText="Searching for the Person..."
                noOptionsText="NO MATCH FOUND"
                id="combo-search-person"
                getOptionLabel={(option) => {
                  const label = processOptionLabel(option);

                  return label || '';
                }}
                renderOption={(props, option, { selected }) => {
                  const label = processOptionLabel(option);
                  return <li {...props}> {label} || ''</li>;
                }}
                isOptionEqualToValue={() => true} // HACK TO SUPPRESS WARNING
                options={people}
                onChange={(event, newValue) => {
                  handlePeopleSelect(newValue);
                }}
                filterOptions={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Person"
                    variant="outlined"
                    onChange={peopleSearchOnChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <AddressSearch
                setAddressOptionLable={setAddressOptionLable}
                addressOptionLable={addressOptionLable}
                searchAddressID={searchAddressID}
                setSearchAddressID={setSearchAddressID}
              />
            </Grid>
            {Object.values(filters).map((filter, idx) => {
              switch (filter.type) {
                case 'date':
                  return getDateFilter(filter, idx);
                case 'date-single':
                  return getDateSingleFilter(filter, idx);
                case 'select':
                  return getSelectFilter(filter, idx);
                default:
                  return getTextFilter(filter, idx);
              }
            })}
          </Grid>
          <div className={classes.filterAction}>
            <SearchHistory loading={loading} name="warrantSearch" />
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              className={classes.reset}
              color="inherit"
              onClick={() => dispatch(clearResults())}>
              Clear Results
            </Button>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              className={classes.reset}
              disabled={loading}
              color="inherit"
              onClick={() => clearForm()}>
              Clear Form
            </Button>
            <div className={classes.searchBtnWrap}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon />}
                type="submit"
                disabled={loading}>
                Search
              </Button>
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </form>
      </LocalizationProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  themeMode: state.theme.mode,
  zones: state.data.zones,
  codeCities: state.codes.codeCities,
  warrants: state.warrants.warrants,
  pickerValues: state.warrants.warrantsForm.pickers,
  filterValues: state.warrants.warrantsForm.filters,
  selectedSearchHistory: state.warrants.selectedSearchHistory
});
export default connect(mapStateToProps, {
  handleDateChange,
  handleFilterChange
})(SearchForm);
