import React from 'react';
import { connect } from 'react-redux';
import { setIsWarningAccepted } from '../../reducers/UserReducer';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { AiOutlineWarning } from 'react-icons/ai';

const useStyles = makeStyles()(theme => ({
  warningContainer: {
    width: '100%',
    background: '#262626',
    color: '#fff',
    minHeight: '100%',
    height: '100%'
  },
  warningBody: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    '@media (max-width:768px)': {
      flexDirection: 'column',
      minHeight: '100%'
    },
    '@media (max-width:480px)': {
      minHeight: '100%',
      height: '100%'
    },
    '@media (max-height:823px) and (min-height:823px)': {
      minHeight: '100%',
      height: '100vh'
    }
  },
  logo: {
    padding: '20px',
    display: 'flex'
  },
  logoImg: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    textAlign: 'center',
    '@media (max-width:768px)': {
      width: '70%'
    }
  },
  text: {
    margin: '40px',
    textAlign: 'center',
    '@media (max-width:768px)': {
      margin: '5px'
    },
    fontSize: '1rem',
    lineHeight: '28px'
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headingText: {
    color: 'red',
    padding: '10px 0px'
  },
  headingIcon: {
    fontSize: '1.6rem',
    margin: '0px 7px 4px 0px',
    color: 'red'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 10, 0.5)
  },
  btnWrap: {
    '& button': {
      background: '#037015',
      color: '#fff',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#025c11'
      }
    }
  }
}));

const PagesWarning = props => {
  const { setIsWarningAccepted } = props;
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const accept = () => {
    setIsWarningAccepted(true);
    navigate('/Login');
  };

  const reject = () => {
    setIsWarningAccepted(false);
    handleOpen();
  };

  return (
    <div className={classes.warningContainer}>
      <div className={classes.warningBody}>
        <div className={classes.logo}>
          <img className={classes.logoImg} src="/logo192.png" alt="" />
        </div>
        <div className={classes.text}>
          <div className={classes.heading}>
            <AiOutlineWarning className={classes.headingIcon} />
            <h2 className={classes.headingText}>Warning</h2>
          </div>
          <p>
            Only authorized law enforcement personnel may access this restricted
            information system. This computer system is provided for the
            processing of offical law enforcement information only. All data
            accessed with this computer system may be audited, intercepted,
            recorded, read, copied or captured in any manner and disclosed in
            any manner, by authorized personnel. THERE IS NO RIGHT OF PRIVACY
            USING THIS SYSTEM. Unauthorized use of the system is prohibited and
            may be subject to criminal and/or civil penalties. USE OF THIS
            SYSTEM BY ANY USER, AUTHORIZED OR UNAUTHORIZED, CONSTITUTES CONSENT
            TO THIS AUDITING, INTERCEPTION, RECORDING, READING, COPYING,
            CAPTURING, AND DISCLOSURE OR COMPUTER ACTIVITY.
          </p>
          <div className={classes.btnWrap}>
            <Button onClick={accept} variant="contained">
              Accept
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { setIsWarningAccepted })(PagesWarning);
