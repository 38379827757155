
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8tractioncontrol100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M18.844 2a5.009 5.009 0 0 0-4.157 2.219L11.5 9h-3a1.5 1.5 0 0 0 0 3h1l-.344.5c-.344.46-.629.965-.844 1.5H15v3H8.125c.25 2.398.797 6.535.875 7.125l.125.875h.063l.156 1.25a1.999 1.999 0 0 0 2 1.75H14a2 2 0 0 0 2-2v-1h18v1a2 2 0 0 0 2 2h2.719a1.999 1.999 0 0 0 2-1.75l.125-1.25h.031l.125-.875c.078-.59.625-4.734.875-7.125H35v-3h6.688a6.575 6.575 0 0 0-.813-1.469L40.531 12h.969a1.5 1.5 0 0 0 0-3h-3l-3.188-4.781A5.009 5.009 0 0 0 31.157 2Zm0 2h12.312c1 0 1.91.512 2.469 1.344L36.75 10h-23.5l3.125-4.656C16.934 4.512 17.844 4 18.844 4ZM12 30s4 1.89 4 4.75c0 2.34-7 4.852-7 8.531C9 45.813 13 48 13 48h5s-6-2.871-6-4.75c0-2 7-4.57 7-8.531C19 31.89 16 30 16 30Zm23 0s4 1.89 4 4.75c0 2.34-7 4.852-7 8.531C32 45.813 36 48 36 48h5s-6-2.871-6-4.75c0-2 7-4.57 7-8.531C42 31.89 39 30 39 30Z"/></SvgIcon>
      );
    }
  
    export default icons8tractioncontrol100Icon;