import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
    const [open, setOpen] = React.useState(props.alertBox);

    const handleYesButtonClick = () => {
        props.change();
    }

    const handleNoButtonClick = () => {
        props.closeAlertBox();
    }

    React.useEffect(() => {
        setOpen(props.alertBox);
    }, [props.alertBox])

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleNoButtonClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.alertDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNoButtonClick} color="primary">
                        No
                    </Button>
                    <Button autoFocus onClick={handleYesButtonClick} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
