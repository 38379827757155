import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import EventIcon from './EventIcon';

const avatarWidth = 44;

const useStyles = makeStyles()((theme) => ({
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    height: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: avatarWidth,
      height: avatarWidth,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.5em',
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4
    }
  }
}));

function EventAvatar(props) {
  const { classes, cx } = useStyles();
  const { event, className, color } = props;
  const CallTypes = props.CallTypes ? props.CallTypes : [];
  const evType = CallTypes.find((type) => type.Code === event.CallType);

  return (
    <div className={cx(classes.avatar, className)}>
      <div>
        {Boolean(evType) && <EventIcon evType={evType} color={color} />}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    CallTypes: state.codes.CallTypes
  };
};

export default connect(mapStateToProps)(EventAvatar);
