
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8emergencycall100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.793 479.793" width="100" height="100"><path d="M439.793 2.407h-214c-22.06 0-40 17.94-40 40v140.13c0 22.004 17.876 40 40.02 40l44.39-.11c-2.142 12.698-10.125 23.259-20.81 30.3-6.233 4.089-3.916 13.839 3.56 14.64 30.774 3.249 64.329-10.973 75.15-44.83h111.69c22.06 0 40-17.94 40-40V42.407c0-22.06-17.94-40-40-40zM303.856 140.305c0 19.76-16.076 35.835-35.835 35.835s-35.835-16.075-35.835-35.835a8 8 0 0 1 16 0c0 10.938 8.898 19.835 19.835 19.835s19.835-8.897 19.835-19.835V84.767c0-10.864-8.838-19.703-19.703-19.703s-19.703 8.839-19.703 19.703 8.839 19.702 19.703 19.702a8 8 0 0 1 0 16c-19.687 0-35.703-16.016-35.703-35.702s16.017-35.703 35.703-35.703 35.703 16.017 35.703 35.703v55.538zm64.154 27.57a8 8 0 0 1-16 0V71.747l-15.398 10.265a8 8 0 0 1-8.875-13.313l27.835-18.557c5.313-3.543 12.438.283 12.438 6.656v111.077zm65.126 0a8 8 0 0 1-16 0V71.747l-15.398 10.265a8 8 0 0 1-8.875-13.313l27.835-18.557c5.313-3.542 12.438.282 12.438 6.656v111.077zM306.653 435.397c-12.733 12.898-12.31 12.46-12.31 12.46-39.231 39.231-103.168 39.482-142.57.1l-122.27-122.28c-39.337-39.337-39.339-102.991 0-142.33l12.55-12.55c15.168-15.189 39.791-15.21 54.98 0l34.39 34.39c15.16 15.16 15.16 39.82.01 54.98l-14.38 14.38c-6.126 6.126-6.125 16.034 0 22.16l63.69 63.69c6.11 6.11 16.05 6.11 22.16 0l14.38-14.37c15.169-15.19 39.791-15.21 54.98 0l34.39 34.39c15.19 15.169 15.21 39.79 0 54.98z"/></SvgIcon>
      );
    }
  
    export default icons8emergencycall100Icon;