import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getEvents } from 'reducers/ClosedEventSearchReducer';
import SearchForm from './SearchForm';
import SearchResult from './SearchResults';
import EventDetails from './EventDetails';
import tableConfigClosedEvents from '../../../config/table-config-closed-events';
const useStyles = makeStyles()(theme => ({
  filterDiv: {
    position: '-webkit-sticky',
    position: 'sticky',
    top: 130,
    bottom: 20,
    padding: '24px',
    margin: '24px',
    paddingBottom: '20px',
    zIndex: 5,
    [theme.breakpoints.down('lg')]: {
      top: 0,
      bottom: 0,
      position: 'relative',
      position: 'relative'
    }
  }
}));
const SearchClosedEvents = props => {
  const closeEvents = useSelector(state => state.closeEvents.closeEvents);
  const themeMode = useSelector(state => state.theme.mode);
  const { classes, cx } = useStyles();
  const closedEventsDetails = useSelector(
    state => state.closeEvents.closedEventsDetails
  );
  return (
    <div>
      <Fragment>
        <Paper
          square
          className={cx('m-3 p-3', classes.filterDiv)}
          style={{ background: themeMode === 'day' ? 'white' : '#424242' }}>
          <SearchForm
            localformdefinition={props.localformdefinition}
            closeEvents={closeEvents}
          />
        </Paper>

        <SearchResult
          localtableconfig={tableConfigClosedEvents}
          closeEvents={closeEvents}
        />
        {closedEventsDetails.isShow && closedEventsDetails.event != null && (
          <EventDetails event={closedEventsDetails.event} />
        )}
      </Fragment>
    </div>
  );
};
const mapStateToProps = state => ({
  themeMode: state.theme.mode
});

export default connect(mapStateToProps, {})(SearchClosedEvents);
