import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from 'tss-react/mui';
import { CalculateLastTimeDif } from 'utils/functions';
import Draggable from 'react-draggable';

import {
  Card,
  CardContent,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Tooltip,
  Badge,
  IconButton
} from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import CloseIcon from '@mui/icons-material/Close';

import { setUnitInfowindow } from '../../../reducers/MapReducer';
import { toggleDialog as ToggleUnitDialog } from '../../../reducers/UnitStatusReducer';

import { getUnitColor } from '../../../utils/uiUtils';

import AgencyIcon from '../AgencyIcon';
import { displayDateTime } from 'reducers/TimeReducer';

const avatarWidth = 44;
const iconWidth = 35;
const useStyles = makeStyles()((theme) => ({
  eventCard: {
    borderWidth: '5px'
  },
  listItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dedfe3',
      borderRadius: '15%',
      width: 45,
      height: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#000',
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4
    }
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block'
    }
  },
  button: {
    border: '1px solid #000',
    borderRadius: '7px',
    width: '80px',
    minWidth: '40px',
    height: '40px',
    marginRight: '10px'
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const DialogUnit = (props) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const {
    unitInfowindow,
    setUnitInfowindow,
    ToggleUnitDialog,
    myUnit,
    colors
  } = props;

  const { classes } = useStyles();

  let unit = unitInfowindow.data;

  // let googlePath = '';

  // if (unit) {
  //   let unitLatitude = `${
  //     unit.UnitLatitudeSign === '-' ? unit.UnitLatitudeSign : ''
  //   }${unit.UnitLatitudeDegree}`;

  //   let unitLongitude = `${
  //     unit.UnitLongitudeSign === '-' ? unit.UnitLongitudeSign : ''
  //   }${unit.UnitLongitudeDegree}`;

  //   googlePath = `https://www.google.com/maps/dir/Current+Location/${unitLatitude},${unitLongitude}`;
  // }

  const history = unitInfowindow.data
    ? JSON.parse(unitInfowindow.data.UnitStatuses)
    : [];

  const handleUnitInfoWindowClose = (state, infowindow) => {
    setUnitInfowindow(state, infowindow);
  };

  const onButtonClick = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleUnitStatusClick = () => {
    ToggleUnitDialog();
  };
  const idleTime = (unit) => {
    const min = CalculateLastTimeDif(
      unit.LastChangedLocationTime,
      unit.Flags,
      true
    );
    if (!min || min < 10) {
      return false;
    }
    const day = Math.floor(min / 24 / 60);
    const hr = Math.floor((min / 60) % 24);
    const remainingMin = Math.floor(min % 60);
    let timeString = '';
    if (day > 0) {
      timeString += day + 'd ';
    }
    if (hr > 0) {
      timeString += hr + 'h ';
    }
    if (remainingMin > 0) {
      timeString += remainingMin + 'm ';
    }
    return timeString;
  };
  if (unitInfowindow.open === true && unitInfowindow.data) {
    return (
      <Draggable handle=".handle" positionOffset={{ x: '-50%', y: '-50%' }}>
        <div
          className="pts-unit-draggable"
          style={{
            position: 'absolute',
            zIndex: 999,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            padding: '0 5px'
          }}>
          <Card className="card-box pts-unit-box">
            <IconButton
              style={{
                position: 'absolute',
                right: '-10px',
                top: '-10px'
              }}
              onClick={() => handleUnitInfoWindowClose(false, null)}
              aria-label="close"
              size="large">
              <CloseIcon variant="contained" />
            </IconButton>
            <div
              className="card-indicator"
              style={{
                backgroundColor: getUnitColor(unit, colors)
              }}
            />
            <CardContent>
              <div
                className="handle"
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  alignContent: 'flex-end',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  cursor: 'grab'
                }}>
                <Tooltip arrow title={unit.AgencyID}>
                  <div className={classes.avatar}>
                    <div>
                      <AgencyIcon
                        color={getUnitColor(unit, colors)}
                        AgencyID={unit.AgencyID}
                      />
                    </div>
                  </div>
                </Tooltip>
                <div className="card-header--title">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      alignContent: 'flex-end',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}>
                    <div>
                      <div style={{ lineHeight: '21px' }}>
                        {unit.Flags !== null && unit?.Flags[0] == 'M' ? (
                          <DirectionsCarIcon
                            style={{
                              color: getUnitColor(unit, colors),
                              fontSize: '25px',
                              paddingRight: '4px'
                            }}
                          />
                        ) : (
                          <DirectionsCarOutlinedIcon
                            style={{
                              fontSize: '25px',
                              paddingRight: '4px'
                            }}
                          />
                        )}
                        <b>{unit.Unit}</b>
                        {unit.Division && <b> | {unit.Division}</b>}
                      </div>
                      <div style={{ lineHeight: '10px' }}>
                        <div>
                          <small>
                            {displayDateTime(unit.LastActionOccurred)}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="badge"
                        style={{
                          background: getUnitColor(unit, colors),
                          width: '90px'
                        }}>
                        {unit.UnitStatus}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',

                      flexDirection: 'column'
                    }}>
                    <div className="pt-1">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                        <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>
                          Last Location
                        </h6>
                        {idleTime(unit) && (
                          <p style={{ color: 'red' }}>
                            in same area for {idleTime(unit)}
                          </p>
                        )}
                      </div>

                      <div>
                        {unit.CoordsUpdated && (
                          <small
                            style={{ fontSize: '13px', paddingTop: '5px' }}>
                            {displayDateTime(unit.CoordsUpdated)} :
                            {' ' + unit.UnitLatitudeSign + ' '}
                            {unit?.UnitLatitudeDegree?.toFixed(10)},
                            {' ' + unit.UnitLongitudeSign + ' '}
                            {unit.UnitLongitudeDegree?.toFixed(10)}
                          </small>
                        )}
                      </div>
                    </div>
                    <div style={{ lineHeight: '9px', paddingTop: '10px' }}>
                      <b>{unit.UnitResources}</b>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: '5px',
                  padding: '5px 0px',
                  justifyContent: 'left',
                  alignContent: 'flex-start',
                  borderBottom: '1px solid rgba(7, 9, 25, 0.125)',
                  borderBottomRightRadius: '0',
                  borderBottomLeftRadius: '0'
                }}
                className="card-footer d-flex align-items-center bg-transparent p-2">
                <Button
                  onClick={(ev) => onButtonClick(ev, 0)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-sm">{0}</b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        Details
                      </span>
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={(ev) => onButtonClick(ev, 1)}
                  className={classes.button}>
                  <div className="text-center my-1 p-1">
                    <div className="line-height-sm">
                      <b className="font-size-sm">{history?.length}</b>
                      <span
                        style={{ fontWeight: '200', fontSize: '11px' }}
                        className="d-block">
                        History
                      </span>
                    </div>
                  </div>
                </Button>
              </div>
              <TabPanel value={activeTab} index={0}>
                <div>
                  <span
                    style={{ width: '100px' }}
                    className="m-1 badge badge-secondary">
                    Location
                  </span>{' '}
                  {unitInfowindow.data.Location}
                </div>
                <div>
                  <span
                    style={{ width: '100px' }}
                    className="m-1 badge badge-secondary">
                    Plate
                  </span>{' '}
                  {unitInfowindow.data.LastActionPlate}{' '}
                  {unitInfowindow.data.LastActionPlateState}
                </div>
                <div>
                  <span
                    style={{ width: '100px' }}
                    className="m-1 badge badge-secondary">
                    OLN
                  </span>{' '}
                  {unitInfowindow.data.LastActionOLN}{' '}
                  {unitInfowindow.data.LastActionOLNState}
                </div>
                <div>
                  <span
                    style={{ width: '100px' }}
                    className="m-1 badge badge-secondary">
                    Mileage
                  </span>{' '}
                  {unitInfowindow.data.LastActionMileage}
                </div>
                <div>
                  <span
                    style={{ width: '100px' }}
                    className="m-1 badge badge-secondary">
                    Trip Number
                  </span>{' '}
                  {unitInfowindow.data.LastActionTripNumber}
                </div>
                <div>
                  <span
                    style={{ width: '100px' }}
                    className="m-1 badge badge-secondary">
                    Notes
                  </span>{' '}
                  {unitInfowindow.data.LastActionNotes}
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    minHeight: 255,
                    maxHeight: 255,
                    overflow: 'auto'
                  }}>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {history?.map((unit, index) => (
                      <li key={index}>
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            alignItems: 'center',
                            paddingBottom: '5px',
                            paddingTop: '5px',
                            backgroundColor:
                              index % 2 == 0
                                ? 'rgba(0, 0, 0, 0.02)'
                                : 'transparent'
                          }}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={classes.icon}>
                              <div>
                                <AgencyIcon
                                  color={getUnitColor(unit, colors)}
                                  AgencyID={unit.AgencyID}
                                />
                              </div>
                            </div>
                            <div>
                              <div
                                className="font-weight-bold"
                                style={{
                                  lineHeight: '10px',
                                  minWidth: '260px'
                                }}>
                                <div>
                                  <span>
                                    {unit.EventID !== null
                                      ? unit.EventID
                                      : unit.EventID}
                                  </span>
                                </div>
                                <div>
                                  <small>
                                    {displayDateTime(unit.Occurred)}
                                  </small>
                                </div>
                                {unit.Location !== null && (
                                  <div>
                                    <small>
                                      <b>Location: </b>
                                      {unit.Location}
                                    </small>
                                  </div>
                                )}
                                {unit.OLN !== null && (
                                  <div>
                                    <small>
                                      <b>OLN: </b>
                                      {unit.OLN}{' '}
                                      {unit.OLNState !== null
                                        ? unit.OLNState
                                        : ''}
                                    </small>
                                  </div>
                                )}
                                {unit.Plate !== null && (
                                  <div>
                                    <small>
                                      <b>Plate: </b>
                                      {unit.Plate}{' '}
                                      {unit.PlateState !== null
                                        ? unit.PlateState
                                        : ''}
                                    </small>
                                  </div>
                                )}
                                {unit.Notes !== null && (
                                  <div>
                                    <small>
                                      <b>Notes: </b>
                                      {unit.Notes}
                                    </small>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className="badge"
                              style={{
                                background: getUnitColor(unit, colors),
                                width: '90px'
                              }}>
                              {unit.UnitStatus}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </TabPanel>

              {myUnit.Unit == unit.Unit && (
                <Button
                  onClick={handleUnitStatusClick}
                  style={{
                    position: 'absolute',
                    bottom: '24px',
                    width: '92%',
                    padding: '10px 0px'
                  }}
                  variant="contained"
                  color="primary">
                  Status Change
                </Button>
              )}
            </CardContent>
          </Card>
        </div>
      </Draggable>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  network: state.offline,
  unitInfowindow: state.map.unitInfowindow,
  myUnit: state.user.userUnit,
  colors: state.codes.colorsCodes
});

export default connect(mapStateToProps, {
  setUnitInfowindow,
  ToggleUnitDialog
})(DialogUnit);
