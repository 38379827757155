import moment from 'moment';
import { displayDateTime } from 'reducers/TimeReducer';
import store from '../config/configureStore';

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

export const CalculateLastTimeDif = (time, Flags, isRawMinutes = false) => {
  // return false;
  if (!time || !Flags || Flags?.trim() !== 'M') {
    return false;
  }
  const lastTime = displayDateTime(time);

  const now = new Date();
  const diff = new Date(now).getTime() - new Date(lastTime).getTime();
  const totalMin = Math.floor(diff / 60000);
  // console.log(unit.UnitResources, t);
  if (isRawMinutes == true) {
    return totalMin;
  }
  if (totalMin > 10) {
    return true;
  }
  return false;
};

export function formatDate(date) {
  const format24 = store.store.getState().settings.isFormat24;

  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';

  return format24 ? m.format('L HH:mm') : m.format('L LT');
}
export function formatUTCdate(date) {
  const format24 = store.store.getState().settings.isFormat24;

  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';

  return format24 ? m.utc().format('L HH:mm') : m.utc().format('L LT');
}
export function formatIfDate(str) {
  if (isValidDate(str)) return formatDate(str);
  return str;
}

export function getTime(date) {
  const format24 = store.store.getState().settings.isFormat24;
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return format24 ? m.utc().format('HH:mm') : m.utc().format('LT');
  // return m.format('LT');
}

export function getDate(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('L');
}

export function isValidDate(date) {
  if (typeof date !== 'string' || date.length > 24) return false;
  var dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (date.match(dateReg) !== null && moment(date).isValid()) return true;
  return false;
}

/** Fixes issue in some dates in database - adds Z at the end */
function addZtoDate(date) {
  return date + (date.substr(-1) === 'Z' ? '' : 'Z');
}

export function getInitials(status, statusDescription) {
  if (!status) return '';
  // if (statusDescription) {
  //   const words = statusDescription.split(' ');
  //   if (words.length > 1) {
  //     return words[0][0] + words[words.length - 1][0];
  //   }
  // }
  const words2 = status.split('-');
  if (words2.length > 1) {
    return words2[0][0] + words2[1][0];
  }
  let nw = '';
  for (let i = 0; i < status.length; i++) {
    const ch = status[i];
    if (i === 0) {
      nw += ch;
    } else if (!isVowel(ch) && nw.length < 2) nw += ch;
  }
  if (nw.length === 2) return nw;
  return status.substr(0, 2);
}

export function isVowel(char) {
  if (char.length === 1) {
    return /[aeiouyAEIOUY]/.test(char);
  }
}

export function sortObjArr(arr, key, ascDesc = 'ASC') {
  if (!Array.isArray(arr)) return arr;
  const newArr = [...arr];
  newArr.sort((a, b) => {
    let valA = a[key];
    let valB = b[key];
    if (isValidDate(valA) && isValidDate(valB)) {
      if (ascDesc === 'ASC') {
        return moment(valB).valueOf() - moment(valA).valueOf();
      } else {
        return moment(valA).valueOf() - moment(valB).valueOf();
      }
    } else {
      if (typeof valA === 'string' && typeof valB === 'string') {
        valA = valA.toLocaleLowerCase();
        valB = valB.toLocaleLowerCase();
      }
      if (ascDesc === 'ASC') {
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      } else {
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      }
    }
  });
  return newArr;
}

export const asyncForEach = async (array, callback) => {
  if (!array) return;
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

/** Used in processing input phone number to format (123) 123-4567 ext 890 */
export const processInputPhoneNo = value => {
  const maxLength = 24;
  let digits = '';
  value.split('').forEach(ch => {
    const reg = /^\d+$/;
    if (ch.match(reg)) digits += ch;
  });
  const digitsArr = digits.split('');
  const len = digitsArr.length;
  len > 0 && digitsArr.splice(0, 0, '(');
  len > 3 && digitsArr.splice(4, 0, ') ');
  len > 6 && digitsArr.splice(8, 0, '-');
  len > 10 && digitsArr.splice(13, 0, ' ext ');
  const output = digitsArr.join('').substr(0, maxLength);
  return output;
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const areObjEqual = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const tableComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getTableComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => tableComparator(a, b, orderBy)
    : (a, b) => -tableComparator(a, b, orderBy);
};

export const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

/**
 * The insertText() method changes the content of a string by removing a range of
 * characters and/or adding new characters.
 *
 * @this {String}
 * @param {string} text to modify.
 * @param {number} position Index at which to start changing the string.
 * @param {number} delCount An integer indicating the number of old chars to remove.
 * @param {string} newSubStr The String that is spliced in.
 * @return {string} A new string with the spliced substring.
 */
export const insertText = (string, position, newSubStr) => {
  return string.slice(0, position) + newSubStr + string.slice(position);
};

export const getExcerpt = (text, length = 60) => {
  text = text.replace(/\n/g, ' ');
  if (text.length > length) {
    text = text.substr(0, length) + '...';
  }
  return text;
};

/**
 * Convert integer number to byte string
 * @param {*} no - number to convert
 * @param {*} pad number of total string length e.g. (1, 4) = "0001" [default 8]
 */
export const decBin = (no, pad = 8) => {
  if (no < 0) {
    no = 0xffffffff + no + 1;
  }
  return parseInt(no, 10)
    .toString(2)
    .padStart(pad, '0');
};

export const formatDateFrom = date => {
  return moment(date).format('YYYY-MM-DD') + ' 00:00:00.0';
};

export const formatDateTo = date => {
  return moment(date).format('YYYY-MM-DD') + ' 23:59:59.0';
};

/**
 * returns array of filtered results - designed to work witgh DataGrid or XGrid widgets
 *
 * @param {string} filter e.g 'tango'
 * @param {array} data to be filtered
 * @param {array} columns - column definitions
 */
export const filterResults = (filter, arr, columns) => {
  filter = filter.toLocaleLowerCase();
  const result = [];
  arr.forEach(row => {
    let match = false;
    for (let i = 0; i < columns.length - 1; i++) {
      const col = columns[i].field;
      let val = row[col];
      if (typeof val === 'number') val = '' + val;
      if (typeof val === 'string') {
        if (val.toLocaleLowerCase().indexOf(filter) !== -1) {
          match = true;
          break;
        }
      }
    }
    match && result.push(row);
  });
  return result;
};

/* Used in styling flex forms */
export function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`
  };
}

export const calculateDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

export function passValidationErrors(UserName, pass) {
  const errors = [];
  if (UserName === pass) errors.push('Cannot be the same as User ID');
  if (pass.length < 8) errors.push('Must be at least 8 characters');
  if (pass.length > 128) errors.push('Password is too long');
  if (pass.search(/[a-z]/) < 0) errors.push('Lower case character missing');
  if (pass.search(/[A-Z]/) < 0) errors.push('Upper case character missing');
  if (pass.search(/[0-9]/) < 0) errors.push('Include at least one digit');
  if (pass.search(/[!@#\$%\^&\*\.,\-+!<>=_\(\)\[\]{};:\|\?]/) < 0)
    errors.push('Special character missing or invalild');

  return errors;
}
export const mapCode = (val, codeName) => {
  const codes = store.store.getState().codes;
  const code = codes[codeName];
  val = val.toUpperCase();

  const original = code.filter(cd => cd.Code == val || cd.Description == val);
  if (original.length > 0) {
    return original[0].Code;
  }
  const secondary = code.filter(
    cd => cd.Code2 == val || cd.Description2 == val
  );
  if (secondary.length > 0) {
    return secondary[0].Code2 || secondary[0].Description2;
  }

  return val;
};
export const formatDateForCitation = (date, origin = '') => {
  if (date.length < 8) {
    return date.split('-')[1];
  }
  if (date?.toString().includes('-') || date?.toString().includes('/')) {
    return moment(date).format('MM/DD/YYYY');
  }
  return moment(date, 'YYYYMMDD').format('MM/DD/YYYY');
};
export const parseName = name => {
  let nameParts = {
    firstName: '',
    lastName: '',
    middleName: ''
  };

  const isCommaPresent = name.indexOf(',');
  if (isCommaPresent === -1) {
    const splitName = name.split(' ');
    if (splitName.length === 3) {
      nameParts.firstName = splitName[0] ? splitName[0] : '';
      nameParts.middleName = splitName[1] ? splitName[1] : '';
      nameParts.lastName = splitName[2] ? splitName[2] : '';
    } else if (splitName.length === 2) {
      nameParts.firstName = splitName[0] ? splitName[0] : '';
      nameParts.lastName = splitName[1] ? splitName[1] : '';
    }
  } else {
    nameParts.lastName = name.substring(0, isCommaPresent);
    const restWithSpace = name.substring(isCommaPresent + 1).split(' ');
    const rest = restWithSpace.filter(n => n.length > 0);
    if (rest.length === 2) {
      nameParts.firstName = rest[0];
      nameParts.middleName = rest[1];
    } else if (rest.length === 1) {
      nameParts.firstName = rest[0];
    }
  }
  return nameParts;
};

export async function fetchWithTimeout(resource) {
  const options = { timeout: 20000 };
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), 20000);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
}
export const compairCitaionData = curretData => {
  const oldData = store.store.getState().CitationReducer
    .compairableCitationData;
  const oldJson = JSON.stringify(oldData);
  const curretJson = JSON.stringify(curretData);
  // console.log('old', oldJson);
  // console.log('current', curretJson);
  if (oldJson === curretJson) {
    return true;
  }
  return false;
};
