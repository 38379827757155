import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Hidden,
  Drawer,
  IconButton,
  Box,
  Tooltip,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Badge
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';

import { TiHome } from 'react-icons/ti';
import { FaSearch, FaBell } from 'react-icons/fa';
import { BsPencilSquare } from 'react-icons/bs';
import { MdLocationSearching } from 'react-icons/md';

import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';

import {
  setNavigationDrawerMobileState,
  showMapSearchBox
} from '../../reducers/UiReducer';
import projectLogo from '../../assets/images/logo.png';

const drawerWidth = 240;
const drawerHeight = 80;

const useStyles = makeStyles()(theme => ({
  link: {
    color: theme.palette.colorFont + '!important'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 99
    }
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  logoText: {
    color: theme.palette.colorFont
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0
  }
}));

const StyledBadge = withStyles(Badge, theme => ({
  badge: {
    right: -18,
    top: 13,
    padding: '0 4px'
  }
}));

const DrawerNavigation = props => {
  const {
    state,
    setNavigationDrawerMobileState,
    showMapSearchBox,
    unreadAlertsCount
  } = props;
  const { classes } = useStyles();
  const { FEATURES_CITATIONS } = useSelector(state => state.appInfo.appEnvInfo);

  return (
    <Hidden mdUp>
      <Drawer
        variant="temporary"
        anchor="left"
        open={state}
        onClose={() => setNavigationDrawerMobileState(false)}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}>
        <Fragment>
          <div
            className="app-sidebar-header"
            style={{
              width: '240px',
              height: '80px',
              background: 'transparent',
              borderBottom: '1px solid #fff'
            }}>
            <Box className="header-logo-wrapper" title="CAD Mobile">
              <Link to="/Main" className="header-logo-wrapper-link">
                <IconButton
                  color="primary"
                  size="medium"
                  style={{ background: 'transparent' }}
                  className="header-logo-wrapper-btn">
                  <img className="app-sidebar-logo" alt="" src={projectLogo} />
                </IconButton>
              </Link>
              <Box
                className={classes.logoText}
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  color: '#0b55a1'
                }}>
                CAD Mobile
              </Box>
            </Box>
            <Box className="app-sidebar-header-btn">
              <Tooltip title="Toggle Navigation" placement="right">
                <IconButton
                  style={{ color: '#0b55a1' }}
                  onClick={() => setNavigationDrawerMobileState()}
                  size="medium">
                  <MenuOpenRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="app-sidebar-header-btn-mobile">
              <Tooltip title="Toggle Navigation" placement="right">
                <IconButton
                  style={{ color: '#0b55a1' }}
                  onClick={() => setNavigationDrawerMobileState()}
                  size="medium">
                  <MenuOpenRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </div>
          <div>
            <List>
              <Link
                onClick={() => setNavigationDrawerMobileState()}
                className={classes.link}
                to="/Main">
                <ListItem button key="home">
                  <ListItemIcon>
                    <TiHome style={{ fontSize: '2.5rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </Link>
              <Link
                onClick={() => setNavigationDrawerMobileState()}
                className={classes.link}
                to="/Search">
                <ListItem button key="search">
                  <ListItemIcon>
                    <FaSearch style={{ fontSize: '1.9rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Search" />
                </ListItem>
              </Link>
              {FEATURES_CITATIONS && FEATURES_CITATIONS == true && (
                <Link
                  onClick={() => setNavigationDrawerMobileState()}
                  className={classes.link}
                  to="/Ecitations/traffic">
                  <ListItem button key="ecitations">
                    <ListItemIcon>
                      <BsPencilSquare style={{ fontSize: '1.9rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Ecitations" />
                  </ListItem>
                </Link>
              )}
              <Link
                onClick={() => setNavigationDrawerMobileState()}
                className={classes.link}
                to="/Alerts">
                <ListItem button key="bulletin">
                  <ListItemIcon>
                    <FaBell style={{ fontSize: '1.9rem' }} />
                  </ListItemIcon>
                  <span className="btn-wrapper--icon">
                    <StyledBadge badgeContent={unreadAlertsCount} color="error">
                      <ListItemText primary="Bulletin" />
                    </StyledBadge>
                  </span>
                </ListItem>
              </Link>
              <Link
                onClick={() => {
                  setNavigationDrawerMobileState();
                  showMapSearchBox();
                }}
                className={classes.link}
                to="/Main">
                <ListItem button key="map">
                  <ListItemIcon>
                    <MdLocationSearching style={{ fontSize: '1.9rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Search Address" />
                </ListItem>
              </Link>
            </List>
          </div>
        </Fragment>
      </Drawer>
    </Hidden>
  );
};

const mapStateToProps = state => ({
  state: state.ui.navigationDrawerMobileState,
  unreadAlertsCount: state.alerts.unreadAlertsCount
});

export default connect(mapStateToProps, {
  setNavigationDrawerMobileState,
  showMapSearchBox
})(DrawerNavigation);
