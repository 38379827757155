import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import { MenuItem } from 'react-contextmenu';
import { useDispatch } from 'react-redux';
import { handleClosedEventFilltersAutoFill } from 'reducers/ClosedEventSearchReducer';
import { handleGunFilltersAutoFill } from 'reducers/GunSearchReducer';
import {
  handleGunNCICFilltersAutoFill,
  handlePersonNCICFilltersAutoFill,
  handleVehicleNCICFilltersAutoFill
} from 'reducers/NcicReducer';
import { handlePersonFilltersAutoFill } from 'reducers/PersonSearchReducer';
import { handleVehicleFilltersAutoFill } from 'reducers/VehicleSearchReducer';
import { handleWarrantFilltersAutoFill } from 'reducers/WarrantsReducer';

const SearchHistory = (props) => {
  const { name: key, loading } = props;
  const [lastSearchOptions, setLastSearchOptions] = useState([]);

  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const filterHandlers = {
    vehicleLocal: handleVehicleFilltersAutoFill,
    vehicleNCIC: handleVehicleNCICFilltersAutoFill,
    personLocal: handlePersonFilltersAutoFill,
    personNCIC: handlePersonNCICFilltersAutoFill,
    gunLocal: handleGunFilltersAutoFill,
    gunNCIC: handleGunNCICFilltersAutoFill,
    warrantSearch: handleWarrantFilltersAutoFill,
    closedEventSearch: handleClosedEventFilltersAutoFill
  };
  // console.log('name', formValue[name]);
  useEffect(() => {
    const jsonData = localStorage.getItem(key);

    setLastSearchOptions(JSON.parse(jsonData || '[]'));
  }, [loading]);
  useEffect(() => {
    if (!selected) {
      const handler = filterHandlers[key];
      dispatch(handler(JSON.parse(JSON.stringify({}))));
    }
  }, [selected]);
  const onInputChange = (newValue) => {
    const handler = filterHandlers[key];

    if (typeof newValue !== 'object' || !newValue) {
      dispatch(handler(JSON.parse(JSON.stringify({}))));
      setSelected(null);
    } else {
      setSelected(makeRenderOptions(key, newValue));
    }

    if (typeof handler === 'function') {
      dispatch(handler(JSON.parse(JSON.stringify(newValue))));
    }
  };

  return (
    <Autocomplete
      id="Search History"
      // loading={loading}
      noOptionsText="No Options"
      size="small"
      sx={{
        width: '400px',
        display: 'inline-block',
        marginRight: '20px',
        marginTop: '2px',
        verticalAlign: 'top'
      }}
      isOptionEqualToValue={() => true}
      key="search-history"
      onChange={(event, newValue) => {
        onInputChange(newValue || '');
      }}
      // value={selected}
      options={lastSearchOptions || []}
      getOptionLabel={(option) => {
        const label = makeRenderOptions(key, option);

        return label || '';
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={'Search History'} />
      )}
    />
  );
};

export default SearchHistory;

const makeRenderOptions = (searchType, option) => {
  if (searchType === 'vehicleLocal' || searchType === 'vehicleNCIC') {
    let lpn = '';
    let state = '';
    let year = 0;
    let type;
    let make = '';
    let model = '';
    let vin = '';
    let vyear = '';
    if (searchType === 'vehicleNCIC') {
      lpn = option['LicensePlateNumber']?.value || '';
      state = option['State']?.value || '';
      year = option['LicensePlateYear']?.value || '';
      type = option['LicensePlateTypeCode']?.value || '';
      vin = option['VehicleIdentificationNumber']?.value || '';
      make = option['VehicleMakeCode']?.value || '';
      vyear = option['VehicleYear']?.value || '';
      const lb = fillerEmptyItem({
        LPN: lpn,
        State: state,
        Year: year,
        VIN: vin,
        Type: type,
        Make: make,
        V_Year: vyear
      });
      return lb;
      // return `LPN:${lpn}, State:${state}, Year:${year}, VIN:${vin}, Type:${type}, Make:${make}, V, Year:${vyear}`;
    } else {
      lpn = option['v.PlateNumber']?.search || '';
      state = option['v.PlateState']?.search || '';
      year = option['v.Year']?.search || '';

      vin = option['v.VIN']?.search || '';
      make = option['v.Make']?.search || '';
      model = option['v.Model']?.search || '';
      const lb = fillerEmptyItem({
        LPN: lpn,
        State: state,
        Year: year,
        VIN: vin,
        Make: make,
        Model: model
      });
      return lb;
      // return `LPN:${lpn}, State:${state}, Year:${year}, VIN:${vin}, Make:${make}, Model:${model}`;
    }
  } else if (searchType === 'personLocal' || searchType === 'personNCIC') {
    let oln = '';
    let state = '';
    let lname = '';
    let fname = '';
    let mname = '';
    let suffix = '';
    let dob = '';
    let sex = '';
    let race = '';
    let ssn = '';
    if (searchType === 'personNCIC') {
      oln = option['OperatorLicenseNumber']?.value || '';
      state = option['State']?.value || '';
      lname = option['Last']?.value || '';
      fname = option['First']?.value || '';
      mname = option['Middle']?.value || '';
      suffix = option['Suffix']?.value || '';
      dob = option['BirthDate']
        ? moment(option['BirthDate'].from).format('YYYY-MM-DD')
        : '';
      sex = option['SexCode']?.value || '';
      const lb = fillerEmptyItem({
        OLN: oln,
        State: state,
        ln: lname,
        fn: fname,
        mn: mname,

        Sex: sex,
        DOB: dob
      });
      return lb;
      // return `OLN: ${oln}, State:${state}, ln:${lname},fn:${fname}, mn:${mname},Sex: ${sex}, DOB: ${dob}`;
    } else {
      oln = option['OLN.Number']?.search || '';
      state = option['OLN.State']?.search || '';
      lname = option['p.LastName']?.search || '';
      fname = option['p.FirstName']?.search || '';
      mname = option['p.MiddleName']?.search || '';
      suffix = option['p.Suffix']?.search || '';
      dob = option['p.Born']?.search || '';
      sex = option['p.Sex']?.search || '';
      race = option['p.Race']?.search || '';
      ssn = option['SSN.Number']?.search || '';
      const lb = fillerEmptyItem({
        OLN: oln,
        State: state,
        ln: lname,
        fn: fname,
        mn: mname,
        suff: suffix,
        SSN: ssn,
        Race: race,
        Sex: sex,
        DOB: dob
      });
      return lb;
      // return `OLN: ${oln}, State:${state}, ln:${lname},fn:${fname}, mn:${mname}, suff:${suffix}, SSN:${ssn}, Race:${race}, Sex: ${sex}, DOB: ${dob}`;
    }
  } else if (searchType === 'gunLocal' || searchType === 'gunNCIC') {
    let serial;
    let make;
    let caliber;
    let model;
    if (searchType === 'gunNCIC') {
      serial = option['GunSerialNumber']?.value || '';
      make = option['GunMake']?.value || '';
      caliber = option['GunCaliber']?.value || '';
      const lb = fillerEmptyItem({
        Serial: serial,
        Make: make,
        Caliber: caliber
      });
      return lb;
      // return `Serial: ${serial}, Make:${make}, Caliber:${caliber}`;
    } else {
      serial = option['fa.SerialNumber']?.search || '';
      make = option['fa.Make']?.search || '';
      caliber = option['fa.Caliber']?.search || '';
      model = option['fa.Model']?.search || '';
      const lb = fillerEmptyItem({
        Serial: serial,
        Make: make,
        Model: model,
        Caliber: caliber
      });
      return lb;
      // return `Serial:${serial}, Make:${make}, Model:${model}, Caliber:${caliber}`;
    }
  } else if (searchType === 'warrantSearch') {
    console.log('option', option);
    let personInfo =
      option['personInfo']?.length > 0
        ? option['personInfo'].slice(0, 20) + '...'
        : '';
    let addressInfo =
      option['addressInfo']?.length > 0
        ? option['addressInfo']?.slice(0, 20) + '...'
        : '';
    let city = option['City']?.search || '';
    let cityDescription = option['cityDescription'] || '';
    let zone = option['Zone']?.search || '';
    let street = option['Street']?.search || '';
    let isActive = option['IsActive']?.search || '';
    let born = option['Born']?.from
      ? moment(option['Born'].from).format('YYYY-MM-DD')
      : '';
    const lb = fillerEmptyItem({
      person: personInfo,
      City: cityDescription,
      Born: born,
      Zone: zone,
      Street: street,
      IsActive: isActive,
      Address: addressInfo
    });
    return lb;
    // return `person:${personInfo}, City:${cityDescription}, Zone:${zone}, Street:${street}, IsActive:${isActive}, Address:${addressInfo}`;
  } else if (searchType === 'closedEventSearch') {
    const created = option['e.Created']
      ? moment(option['e.Created'].from).format('YYYY-MM-DD')
      : '';
    const callType = option['e.CallType']?.search || '';
    const agencyId = option['AgencyID']?.search || '';
    let personInfo =
      option['personOptionLabel']?.length > 0
        ? option['personOptionLabel'].slice(0, 40) + '...'
        : '';
    const lb = fillerEmptyItem({
      Created: created,
      Type: callType,
      Agency: agencyId,
      person: personInfo
    });
    return lb;
    // return `Created : ${created}, Type: ${callType}, Agency: ${agencyId}, ${personInfo}`;
  }
};
const fillerEmptyItem = (obj) => {
  let st = '';
  Object.entries(obj).forEach(([key, value], index) => {
    if (value) {
      if (index != 0 && st.length > 0) {
        st += ', ';
      }
      st += `${key}: ${value}`;
    }
  });
  return st;
};
