
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function fireDEPIcon(props) {
      return (
        <SvgIcon {...props} height="512pt" viewBox="0 -64 512.001 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="M509.77 249.879a14.993 14.993 0 0 0-9.723-6.82L436 229.773V217.98c0-42.164-15.031-83.234-42.316-115.644-24.22-28.77-56.79-49.277-92.72-58.621C300.282 19.496 280.384 0 256 0s-44.285 19.496-44.965 43.715c-35.93 9.344-68.5 29.851-92.719 58.617C91.023 134.746 75.996 175.82 76 217.98l.004 11.793-64.05 13.29A14.996 14.996 0 0 0 .512 261.628c10.678 39.863 42.714 72.289 92.64 93.77 42.335 18.218 97.538 28.253 155.44 28.253 60.173 0 118.876-10.539 165.302-29.675 53.578-22.086 87.328-54.02 97.593-92.348 1.07-3.988.45-8.238-1.718-11.75zm-107.31 76.36c-42.882 17.675-97.526 27.41-153.866 27.41-53.914 0-104.91-9.165-143.586-25.81-24.79-10.667-55.317-29.163-69.883-58.948l40.883-8.48v12.57c0 8.28 6.719 14.996 15 14.996 8.285 0 15-6.715 15-15l-.004-55C105.996 151.875 149.195 94.34 211 74.907v76.59c0 8.284 6.719 15 15 15 8.285 0 15-6.716 15-15V44.995c0-8.27 6.73-15 15-15 8.273 0 15 6.73 15 15v106.5c0 8.285 6.719 15 15 15 8.285 0 15-6.715 15-15v-76.59c61.8 19.434 105 76.965 105 143.074v53.997c0 8.285 6.715 15 15 15s15-6.715 15-15V260.41l40.566 8.418c-14.957 28.024-47.472 46.43-74.105 57.41zm0 0"/><path d="M256 196.477c-32.523 0-58 19.77-58 45 0 25.234 25.477 45 58 45s58-19.766 58-45c0-25.23-25.477-45-58-45zm0 60.003c-17.09 0-28-8.886-28-15 0-6.117 10.91-15 28-15 17.094 0 28 8.883 28 15 0 6.114-10.906 15-28 15zm0 0"/></SvgIcon>
      );
    }
  
    export default fireDEPIcon;