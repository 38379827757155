
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8crashedcar100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="100" height="100"><path d="M26.559 13.848 25 13.489l-1.008-3.855c-.323-1.624-1.701-3.661-3.429-3.93L9.629 4a4.005 4.005 0 0 0-3.776 2.678l-2.058 4.59A4.005 4.005 0 0 0 2 14.614V23a2 2 0 1 0 4 0v-2.551s4.755 1.164 8.31 1.652c4.393.603 8.583.195 8.583.195l-.195.444c-.171 1.059.602 2.059 1.726 2.234 1.124.175 2.174-.541 2.345-1.599l1.098-6.036c.213-1.317-.302-2.624-1.308-3.491zM6.595 9.323l1.146-1.984A2 2 0 0 1 9.629 6l10.625 1.621c.865.135 1.553 1.534 1.714 2.347l.88 1.248c.104.522-.413.951-.948.782-1.947-.614-3.475.535-6.667.037-3.191-.497-5.888-2.082-7.85-1.766a.714.714 0 0 1-.788-.946zM6.5 16.71a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 6.5 16.71zm11.019.093L12 15.71a1 1 0 0 1 0-2l5.829 1.176c.562.088.949.588.863 1.117-.086.529-.611.887-1.173.8zm5.14 1.573c-.734-.589-.876-1.625-.318-2.313l1.284 1.126L25 18.195c-.559.689-1.607.77-2.341.181z"/></SvgIcon>
      );
    }
  
    export default icons8crashedcar100Icon;