
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function police1Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="850" height="850" viewBox="-64.684 -0.001 850 850">
          <path d="M703.216 355.755c-22.084-66.178-22.979-136.421-2.582-203.136l19.469-63.685L631.169 0l-16.58 9.571c-81.285 46.931-160.316 46.931-241.602 0L360.513 2.37l-12.475 7.201c-81.285 46.931-160.317 46.931-241.603 0L89.855 0 .919 88.937l19.469 63.685c20.397 66.713 19.504 136.958-2.583 203.136-55.595 166.563 22.789 347.501 182.323 420.87L360.51 850.39l160.38-73.762c159.539-73.369 237.922-254.307 182.326-420.873zM500.044 731.293l-139.532 64.17-139.532-64.17C84.62 668.58 17.62 513.928 65.142 371.554c25.39-76.079 26.417-156.827 2.97-233.523l-10.705-35.013 41.026-41.026c87.043 44.323 175.063 43.552 262.077-2.318 87.014 45.868 175.034 46.642 262.081 2.318l41.025 41.026-10.705 35.013c-23.447 76.694-22.42 157.444 2.971 233.523 47.522 142.374-19.476 297.026-155.838 359.739z"/>
        </SvgIcon>
      );
    }
  
    export default police1Icon;