
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8detain100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46" width="100" height="100"><path d="M32.451-.002a.49.49 0 0 0-.387.201.359.359 0 0 0 .012.428l1.914 2.361a4.98 4.98 0 0 0-.02.028L31.2 4.389a.294.294 0 0 0-.184.375.466.466 0 0 0 .445.236h1.637a4.98 4.98 0 0 0-.098.977A5 5 0 0 0 42.994 6H43V1.2a.427.427 0 0 0-.418-.4L32.5 0a.49.49 0 0 0-.049-.002zM5 1.977a4.98 4.98 0 0 0-5 5 5 5 0 1 0 5-5zm7 9a3.984 3.984 0 0 0-4 4 4 4 0 0 0 .254 1.39l-.006.002L12 28l-1.77 6.191A6.006 6.006 0 0 0 10 35.84v7.66a2.5 2.5 0 0 0 2.5 2.5 2.5 2.5 0 0 0 2.5-2.5V35l3.04-6.074a6 6 0 0 0 .48-4.031l-1.874-7.727 2.68 1.256 1.75 6.125a2 2 0 1 0 3.848-1.098l-2-7a2 2 0 0 0-1.074-1.262l-7.887-3.697-.002-.002a4 4 0 0 0-.477-.222 4 4 0 0 0-.011-.006 2 2 0 0 0-.545-.172 4 4 0 0 0-.928-.113zm30 1a3.984 3.984 0 0 0-2.877 1.21 2 2 0 0 0-.219.25 2 2 0 0 0-.015.02 3.984 3.984 0 0 0-.125.166l-4.99 6.678-5.407 1.803a2 2 0 1 0 1.266 3.792l6-2a2 2 0 0 0 .969-.699L38 21.327v22.095A2.578 2.578 0 0 0 40.578 46a2.579 2.579 0 0 0 2.572-2.418L44 30a6.826 6.826 0 0 0 2-4.828V16h-.004A4 4 0 0 0 42 11.977z"/></SvgIcon>
      );
    }
  
    export default icons8detain100Icon;