
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8dog100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100" height="100"><path d="M15 0v3l-2.5 5H5.4l-2-2L2 7.4l2 2V13l-1 2-1 6h2.25L5 17l2-2h6.557l2.293 6h2.14l-1.49-5.186V15l1.5-3V7h2l2-2V3h-2l-1-1h-1.5L16 0h-1zM7.828 17l-.392.393L8.75 21H11l-1.365-4H7.828z"/></SvgIcon>
      );
    }
  
    export default icons8dog100Icon;