import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Paper } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import GunDetails from './SearchDetails/index';

import {
  findGuns,
  sortGuns,
  findGun
} from '../../../reducers/GunSearchReducer';
import { findResultsGun, finishedResponses } from 'reducers/NcicReducer';
const useStyles = makeStyles()(theme => ({
  filterDiv: {
    position: '-webkit-sticky',
    position: 'sticky',
    top: 130,
    bottom: 20,
    padding: '24px',
    margin: '24px',
    paddingBottom: '20px',
    zIndex: 5,
    [theme.breakpoints.down('lg')]: {
      top: 0,
      bottom: 0,
      position: 'relative',
      position: 'relative'
    }
  }
}));
const SearchGuns = props => {
  const {
    ncicformdefinition,
    localformdefinition,
    localtableconfig,
    themeMode
  } = props;
  const { guns, gunDetails } = props.gunSearch;
  const [showDetails, setShowDetails] = useState(false);
  const { classes, cx } = useStyles();
  useEffect(() => {
    if (gunDetails.data !== null && gunDetails.isShowing === true) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [gunDetails]);

  useEffect(() => {
    props.finishedResponses(0);
  }, []);

  const getGunsData = options => {
    props.findGuns(options);
  };

  const getGunsNcicData = options => {
    props.findResults(options);
  };

  const sortGunsData = name => {
    props.sortGuns(name);
  };

  const tableRowClick = row => {
    props.findGun(row.ptsFirearmID);
  };

  const closeDetails = () => {
    props.findGun(null);
  };

  return (
    <Fragment>
      <Paper
        square
        className={cx('m-3 p-3', classes.filterDiv)}
        style={{ background: themeMode === 'day' ? 'white' : '#424242' }}>
        <SearchForm
          getGunsData={getGunsData}
          getResultsData={getGunsNcicData}
          localformdefinition={localformdefinition}
          ncicformdefinition={ncicformdefinition}
          name={ncicformdefinition.name}
          guns={guns}
          ncicCheckbox={props.ncicSearch.gunForm.ncic}
        />
      </Paper>
      <SearchResults
        getGunsData={getGunsData}
        getResultsData={getGunsNcicData}
        sortGunsData={sortGunsData}
        tableRowClick={tableRowClick}
        localtableconfig={localtableconfig}
        guns={guns}
      />
      {showDetails && (
        <GunDetails
          data={gunDetails.data}
          showDetails={showDetails}
          close={closeDetails}
          themeMode={themeMode}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  list: state.ncic.responsesGuns,
  ncicSearch: state.ncic,
  gunSearch: state.gunSearch
});

export default connect(mapStateToProps, {
  findGuns,
  sortGuns,
  findGun,
  findResultsGun,
  finishedResponses
})(SearchGuns);
