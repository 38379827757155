
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8fraud100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="100" height="100"><path d="M32 6C19.368 6 13 23.348 13 41c0 2.537 1.41 4.584 3.275 6.2L5 52.1l.443 2.558A4 4 0 0 0 9.39 58h45.22a4 4 0 0 0 3.946-3.342L59 52.1l-11.275-4.9C49.59 45.583 51 43.536 51 41c0-17.652-6.368-35-19-35zm0 14c2.556 0 6.626 1.506 9.033 5.715A58.456 58.456 0 0 0 32 25c-3.48 0-6.559.327-9.033.715C25.374 21.505 29.444 20 32 20zm-6.344 7.633c1.336.055 2.44 1.647 3.95 1.857 0 0-.48 1.51-2.12 1.51-2.272 0-3.265-2.074-3.265-2.803.52-.43.99-.583 1.435-.564zm12.688 0c.445-.019.915.134 1.435.564 0 .73-.994 2.803-3.265 2.803-1.638 0-2.12-1.51-2.12-1.51 1.51-.21 2.614-1.802 3.95-1.857zm-17.098 3.219C21.91 32.009 23.452 34 26.3 34c2.47 0 4.285-2 5.701-2 1.416 0 3.23 2 5.701 2 2.847 0 4.39-1.99 5.053-3.148.101.656.166 1.352.201 2.082-.2.216-.361.37-.361.37l-.362.274-.148.43c-.918 2.637-3.07 4.976-5.098 6.607a22.141 22.141 0 0 1-2.722 1.881c-.366.211-.673.367-.873.46-.055.024-.073.03-.108.044h-2.566c-.035-.014-.053-.02-.108-.045-.2-.092-.507-.248-.873-.459a22.141 22.141 0 0 1-2.722-1.88c-2.028-1.632-4.18-3.971-5.098-6.608l-.148-.43-.362-.273s-.162-.155-.361-.371c.035-.73.1-1.426.201-2.082zm3.652 11.904c.078.064.157.135.235.197a24.923 24.923 0 0 0 3.103 2.14c.434.25.808.447 1.127.593.16.072.305.132.457.185.152.053.231.129.68.129h3c.449 0 .528-.076.68-.129.152-.053.297-.113.457-.185.32-.146.693-.342 1.127-.592a24.95 24.95 0 0 0 3.103-2.14c.078-.063.157-.134.235-.198-2.13 4.019-4.664 8.079-6.409 9.031-.23.073-.456.152-.693.213-.237-.06-.464-.14-.693-.213-1.745-.952-4.279-5.012-6.409-9.031z"/></SvgIcon>
      );
    }
  
    export default icons8fraud100Icon;