
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function CircumstanceIcon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><circle cx="245.4" cy="356.4" r="30.3"/><path d="m261.6 406.2-52.9-22.8-11.7-26.3 9.6 12.2c.3-2.7-.1-5.4-.9-8.1l-3-10.8c-6.6-24-32.1-37.4-55.7-29.3l-48 16.8c-35 12.2-40.2 59.6-8.6 79l5.2 3.1H66.5c-9.2 0-16.8 7.4-16.9 16.7-.1 9.3 7.5 17.2 16.7 17.3h89.4c8.1 0 15-5.5 16.5-13.6 1.3-7-2.1-13.9-8.1-17.6l-33.8-20.7 38 11.3c10.5 3.1 17.5 12.2 18.5 22.5h9.6c7.2 0 13.5-4.6 15.9-11.3l-23.1-10-3.9-1.7c-4.2-1.8-7-5.6-7.7-9.8l-5.9-34.7 13.8 31.2c1.4 3.2 3.9 5.7 7.1 7l58.1 25.2c1.8.8 3.7 1.2 5.5 1.2 5.5 0 10.8-3.4 13-9 2.8-7.1-.8-14.9-7.6-17.8zm84.2-256.4c18.8 0 33.6-15.9 32.2-34.6-1.3-17.8-16.8-31.1-34.6-29.9-17.8 1.3-31.1 16.8-29.9 34.6 1.4 17 15.5 29.9 32.3 29.9zm64.6 90.6c-3.2 7.8.5 16.7 8.2 20 7.8 3.3 16.7-.5 20-8.2l22.7-54.7c2.9-7.1.1-15.4-6.6-19.1-8.6-4.8-37.1-20.7-50.8-28.4-6-4.1-13.1-6.2-20.6-5.7l-5.6.3c-9.1 10.7-23 16.2-37.1 14.3l-29.8 42.6-52.1 31.9c-7.2 4.4-9.5 13.8-5 21 4.4 7.1 13.8 9.5 21 5l54.8-33.6c1.8-1.1 3.4-2.5 4.5-4.3l8.5-12.1 3.3 70.4-16.7 66.7c-.2.8-.3 1.6-.4 2.3l-9.6 85.3c-1.2 10.6 7 19.7 17.7 19.7 8.9 0 16.6-6.7 17.6-15.8l9.4-84.2 15.9-63.6 13.5-.5 21.5 148.8c1.4 9.7 10.4 16.4 20.1 15s16.4-10.4 15-20.1l-22.7-157-.3-5.1c-3.9.3-8.1-.2-11.9-1.8-12.9-5.4-19-20.1-13.7-33.1l16.2-39.1-13.9-12.9 24.4 13.7-17.5 42.3z"/></SvgIcon>
      );
    }
  
    export default CircumstanceIcon;