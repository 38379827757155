import Loading from 'pages/Loading';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClimbingBoxLoader } from 'react-spinners';
import { getTickets } from 'reducers/DataReducer';
import { setActiveCitationType } from '../../reducers/CitationsReducer';

const Citation = React.lazy(() => import('../../components/Citation'));
const CitationForAll = React.lazy(() =>
  import('../../components/CitationForAll')
);

export default function CitationPage() {
  const dispatch = useDispatch();
  let { id } = useParams(); 
  const [ticket, setTicket] = useState('');
  const [ticketList, setTicketList] = useState([]);
  const [ticketLoading, setTicketLoading] = useState(true);
  const websocket = useSelector(state => state.websocket);
  useEffect(() => {
    const getTicketsInfo = async () => {
      const list = await getTickets();
      const defult = list.filter(item => item.IsDefault === true);
      const nondefult = list.filter(item => item.IsDefault === false);
      const properList = [...defult, ...nondefult];
      setTicketList(properList);
      setTicket(properList[0]);
      setTicketLoading(false);
      // console.log('citationForm', citationForm);
    };
    if (websocket.websocket) {
      setTicketLoading(true);
      getTicketsInfo();
    }
  }, [websocket]);
  // Get url param. Example: traffic, parking etc

  const { APP_DEFAULT_STATE, CLIENT_TYPE } = useSelector(
    state => state.appInfo.appEnvInfo
  );

  /**
   * Set the currently active citation page. Different citation page has different forms.
   * Use this value to filter out only needed for definition.
   */
  if (ticketLoading) {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#0b55a1'} loading={true} />
          </div>
        </div>
      </Fragment>
    );
  } else if (ticketList.length < 1) {
    return (
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <h4> NO CITATION FORM SPECIFIED FOR YOUR AGENCY</h4>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {ticket.FormType === 'TROUP' ? (
        <Citation
          ticketLoading={ticketLoading}
          ticket={ticket}
          setTicket={setTicket}
          ticketList={ticketList}
          setTicketList={setTicketList}
        />
      ) : (
        <CitationForAll
          ticketLoading={ticketLoading}
          ticket={ticket}
          setTicket={setTicket}
          ticketList={ticketList}
          setTicketList={setTicketList}
        />
      )}
    </Fragment>
  );
}
