import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  getAlertTypes,
  getAlerts,
  getPaginatedAlerts,
  setAlertRead,
  setFilter,
  clearFilter,
  setAllAlertsRead,
  getUserAlerts // added this reducer to test
} from '../../reducers/AlertsReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import Spinner from '../../global-components/Spinner';

import { IoMdAlert } from 'react-icons/io';

import { Paper, Grid, Card, Button, Box, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import { AiFillFilter } from 'react-icons/ai';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import PlateStateFilter from './PlateStateFilter';
import OlnStateFilter from './OlnStateFilter';
import AlertTypeFilter from './AlertTypeFilter';
import AlertCard from './AlertCard';

const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = (themeMode) => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles()((theme) => ({
  unread: {
    backgroundColor: '#b3b3b3'
  },
  unreadChip: {
    backgroundColor: '#ddd'
  },
  readChip: {
    backgroundColor: '#bbb'
  },
  alertIcon: {
    textAlign: 'center',
    display: 'block',
    '@media (max-width:413px)': {
      display: 'none !important'
    }
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  filters: {
    padding: 20,
    marginBottom: 20
  },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  filter: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  select: {
    margin: '0 10px 10px',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 10px 10px 0px'
    }
  },
  selectInput: {
    fontSize: '16px'
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em'
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const PagesAlert = (props) => {
  const { classes } = useStyles();
  const {
    getAlerts,
    getAlertTypes,
    setAlertRead,
    setFilter,
    clearFilter,
    loading,
    alerts,
    alertTypes,
    wsClient,
    themeMode,
    statesCodes,
    paginatedAlerts,
    getPaginatedAlerts,
    isNewNotificationAvailable,
    getUserAlerts,
    setAllAlertsRead
  } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [isBottom, setIsBottom] = useState(false);
  const [alertsState, setAlertsState] = useState(paginatedAlerts);
  const [localLoading, setLocalLoading] = useState(false);
  const [plate, setPlate] = useState('');
  const [oln, setOln] = useState('');
  const [person, setPerson] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [alertType, setAlertType] = useState('');
  const [plateState, setPlateState] = useState('');
  const [olnState, setOlnState] = useState('');
  const [readAlert, setReadAlert] = useState('unread');
  const [newNotification, setNewNotification] = useState(false);
  const [notificationFilterActive, setNotificationFilterActive] =
    useState(false);

  function handleScroll() {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 10 >= scrollHeight) {
      setIsBottom(true);
    }
  }

  // testing user alerts if receiving
  // getUserAlerts();

  function makeFiltersData(
    isAlertRead = null,
    isNewNotification = null,
    isReadFilterChanged = null
  ) {
    var filters = {};
    filters.Plate = isNewNotification && isReadFilterChanged ? '' : plate;
    filters.PlateState =
      isNewNotification && isReadFilterChanged ? '' : plateState;
    filters.OLN = isNewNotification && isReadFilterChanged ? '' : oln;
    filters.OLNState = isNewNotification && isReadFilterChanged ? '' : olnState;
    filters.Person = isNewNotification && isReadFilterChanged ? '' : person;
    filters.Vehicle = isNewNotification && isReadFilterChanged ? '' : vehicle;
    filters.AlertType =
      isNewNotification && isReadFilterChanged ? '' : alertType;
    filters.read = isAlertRead ? isAlertRead : readAlert; // i think problem starts here
    filters.notification = isNewNotification
      ? isNewNotification
      : newNotification;
    return filters;
  }

  function resetStateValues() {
    setPlate('');
    setPlateState('');
    setOln('');
    setOlnState('');
    setPerson('');
    setVehicle('');
    setAlertType('');
    setReadAlert('unread');
    setNewNotification(false);
    setNotificationFilterActive(false);
  }

  const clear = () => {
    clearFilter();
    getPaginatedAlerts(0, rowsPerPage);
    setCurrentPage(1);
  };

  const handleRowClick = async (id) => {
    setLocalLoading(true);
    await setAlertRead(id);
    // const newAlert = alertsState.filter(al => al.ptsAlertID != id);
    // setAlertsState(newAlert);
    await getPaginatedAlerts(0, rowsPerPage);
    setCurrentPage(1);
    setLocalLoading(false);
  };

  const setRead = () => {
    setAllAlertsRead();
  };

  const handleReadFilterChange = (event) => {
    setReadAlert(event.target.value);
    setFilter(makeFiltersData(event.target.value, newNotification, false));
    getPaginatedAlerts(0, rowsPerPage);
    setCurrentPage(1);
  };

  const setSelectedPlateState = (state) => {
    setPlateState(state);
  };

  const handlePlateChange = (event) => {
    setPlate(event.target.value);
  };

  const handleOlnChange = (event) => {
    setOln(event.target.value);
  };

  const setSelectedOlnState = (state) => {
    setOlnState(state);
  };

  const handlePersonChange = (event) => {
    setPerson(event.target.value);
  };

  const handleVehicleChange = (event) => {
    setVehicle(event.target.value);
  };

  const handleAlertTypeChange = (state) => {
    setAlertType(state);
  };

  const handleNewNotificationChange = (event) => {
    setNewNotification(event.target.checked);
    setNotificationFilterActive(event.target.checked);
  };

  const submitFilter = () => {
    setFilter(makeFiltersData());
    getPaginatedAlerts(0, rowsPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (wsClient.websocket) {
      getAlerts();
      getAlertTypes();
    }
  }, [wsClient]);

  useEffect(() => {
    if (!paginatedAlerts || currentPage === 0) {
      if (isNewNotificationAvailable) {
        resetStateValues();
        setFilter(makeFiltersData('unread', true, true));
        getPaginatedAlerts(currentPage, rowsPerPage);
        setCurrentPage(currentPage + 1);
      } else {
        setFilter(makeFiltersData());
        getPaginatedAlerts(currentPage, rowsPerPage);
        setCurrentPage(currentPage + 1);
      }
    }

    resetStateValues();
    clearFilter();

    window.onbeforeunload = function () {
      resetStateValues();
      clearFilter();
      window.scrollTo(0, 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isNewNotificationAvailable) {
      if (!notificationFilterActive) {
        resetStateValues();
        setFilter(makeFiltersData('unread', true, true));
      } else {
        setFilter(makeFiltersData());
      }
      getPaginatedAlerts(currentPage, rowsPerPage);
      setCurrentPage(currentPage + 1);
    }
  }, [isNewNotificationAvailable]);

  useEffect(() => {
    if (props.filters) {
      setPlate(props.filters.Plate);
      setPlateState(props.filters.PlateState);
      setOln(props.filters.OLN);
      setOlnState(props.filters.OLNState);
      setPerson(props.filters.Person);
      setVehicle(props.filters.Vehicle);
      setAlertType(props.filters.AlertType);
      setReadAlert(props.filters.read);
      setNewNotification(props.filters.notification);
      setNotificationFilterActive(props.filters.notification);
    }
  }, [props.filters]);

  useEffect(() => {
    if (isBottom) {
      if (currentPage * rowsPerPage < alerts.length) {
        getPaginatedAlerts(currentPage, rowsPerPage);
        setCurrentPage(currentPage + 1);
        setIsBottom(false);
      }
    }
  }, [isBottom, currentPage]);

  useEffect(() => {
    setAlertsState(paginatedAlerts);
  }, [paginatedAlerts]);
  return (
    <Fragment>
      <Paper square className="m-3 p-4">
        {(localLoading || loading) && <Spinner />}

        <div className={classes.searchWrap}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.select}>
            <Select
              className={classes.selectInput}
              value={readAlert}
              onChange={handleReadFilterChange}>
              <MenuItem value={'unread'}>Only Unread Bulletin</MenuItem>
              <MenuItem value={'read'}>Only Read Bulletin</MenuItem>
              <MenuItem value={'all'}>All Bulletin</MenuItem>
            </Select>
          </FormControl>

          <AlertTypeFilter
            alertTypes={alertTypes}
            selectedAlertType={alertType}
            setAlertType={handleAlertTypeChange}
          />

          <TextField
            value={person}
            onChange={handlePersonChange}
            label="Person"
            variant="outlined"
            size="small"
            className={classes.filter}
          />

          <TextField
            value={vehicle}
            onChange={handleVehicleChange}
            size="small"
            className={classes.filter}
            label="Vehicle"
            variant="outlined"
          />

          <TextField
            value={plate}
            onChange={handlePlateChange}
            size="small"
            className={classes.filter}
            label="Plate"
            variant="outlined"
          />

          <PlateStateFilter
            states={statesCodes}
            selectedPlateState={plateState}
            setSelectedPlateState={setSelectedPlateState}
          />

          <TextField
            value={oln}
            onChange={handleOlnChange}
            size="small"
            className={classes.filter}
            label="OLN"
            variant="outlined"
          />

          <OlnStateFilter
            states={statesCodes}
            selectedOlnState={olnState}
            setSelectedOlnState={setSelectedOlnState}
          />

          <FormControlLabel
            className={classes.filter}
            control={
              <Checkbox
                checked={newNotification}
                onChange={handleNewNotificationChange}
                color="primary"
              />
            }
            label="Last Bulletin Notification"
          />

          <div className={classes.filterAction}>
            <Button
              size="large"
              color="inherit"
              startIcon={<DeleteOutlineIcon />}
              onClick={() => setRead()}
              className={classes.reset}
              disabled={loading}>
              Mark all as read
            </Button>
            <Button
              size="large"
              startIcon={<DeleteOutlineIcon />}
              onClick={clear}
              className={classes.reset}
              color="inherit"
              disabled={loading}>
              Clear
            </Button>
            <div className={classes.searchBtnWrap}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AiFillFilter />}
                onClick={submitFilter}
                disabled={loading}>
                Filter
              </Button>
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </div>
      </Paper>

      {alertsState.length === 0 && (
        <Paper square className="m-3 p-4">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card className="card-box bg-neutral-warning border-1 card-border-top border-warning text-center p-3">
                <h4 style={{ marginBottom: 0, color: 'black' }}>
                  Sorry, no bulletin match your search criteria.
                </h4>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      )}

      {alertsState.length !== 0 && (
        <Paper square className="m-3 p-4">
          <Grid container spacing={4}>
            {alertsState.map((alert, idx) => {
              return (
                <Grid key={idx} item xs={12} md={6} lg={4}>
                  <AlertCard
                    alert={alert}
                    idx={idx}
                    handleRowClick={handleRowClick}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  themeMode: state.theme.mode,
  alerts: state.alerts.alerts,
  paginatedAlerts: state.alerts.paginatedAlerts,
  filters: state.alerts.filters,
  isNewNotificationAvailable: state.alerts.isNewNotificationAvailable,
  alertTypes: state.alerts.alertTypes,
  loading: state.alerts.loading,
  statesCodes: state.codes.statesCodes
});

export default connect(mapStateToProps, {
  getAlerts,
  getAlertTypes,
  getPaginatedAlerts,
  setAlertRead,
  setFilter,
  clearFilter,
  setAllAlertsRead,
  getUserAlerts // added here for reactivaty
})(PagesAlert);
