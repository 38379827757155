/* eslint-disable no-case-declarations */
import moment from 'moment-timezone';
import { enqueueSnackbar, notify } from '../reducers/NotifierReducer';
import store from '../config/configureStore';
// import Notifications from 'react-notification-system-redux';
import { history } from '../config/configureStore';
import { setSearchActiveTab } from '../reducers/UiReducer';
import tableConfigPersons from '../config/table-config-persons.json';
import tableConfigGuns from '../config/table-config-guns.json';
import tableConfigVehicles from '../config/table-config-vehicles.json';

import { showSpinner, hideSpinner } from './UiReducer';
import { CITATION_DATA } from './PersonSearchReducer';
import {
  CITATION_VEHICLE_DATA,
  FIND_OWNER,
  FIND_OWNERS
} from './VehicleSearchReducer';
import { constant, object } from 'underscore';

// Ncic
export const SET_NCIC_METADATA = 'NCIC/SET_METADATA';
export const SET_NCIC_RAWDATA = 'NCIC/SET_RAWDATA';
export const SET_NCIC_RID = 'NCIC/SET_RID';
export const SET_NCIC_ORI = 'NCIC/SET_ORI';
export const SET_NCIC_LOGIN = 'NCIC/SET_LOGIN';
export const SET_NCIC_LOGOFF = 'NCIC/SET_LOGOFF';
export const SET_NCIC_PENDING_RESPONSES = 'NCIC/SET_NCIC_PENDING_RESPONSES';
export const SET_NCIC_FINISHED_RESPONSES = 'NCIC/SET_NCIC_FINISHED_RESPONSES';
export const TOGGLE_NCIC_DETAIL = 'NCIC/TOGGLE_NCIC_DETAIL';
export const SET_NCIC_DETAIL = 'NCIC/SET_NCIC_DETAIL';

// Persons
export const SET_NCIC_RESPONSE_PERSONS_QUERY_ID =
  'NCIC/SET_RESPONSE_PERSONS_QUERY_ID';
export const SET_NCIC_PERSON_SEARCH_TYPE = 'NCIC/SET_NCIC_PERSON_SEARCH_TYPE';
export const SET_NCIC_FORM_PERSON_PICKERS = 'NCIC/SET_NCIC_FORM_PERSON_PICKERS';
export const SET_NCIC_FORM_PERSON_FILTERS = 'NCIC/SET_NCIC_FORM_PERSON_FILTERS';
export const SET_NCIC_FORM_PERSON_CHECKBOX =
  'NCIC/SET_NCIC_FORM_PERSON_CHECKBOX';
export const SET_FORM_PERSON_CHECKBOX = 'PERSONSEARCH/SET_FORM_PERSON_CHECKBOX';
export const FIND_PERSON = 'PERSONSEARCH/FIND_PERSON';
export const OPEN_PERSON_DETAILS = 'PERSONSEARCH/OPEN_DETAILS';
export const OPEN_OWNER_DETAILS = 'OPEN_OWNER_DETAILS';
export const LOADING_PERSONS = 'PERSONSEARCH/LOADING';
export const FIND_PERSONS = 'PERSONSEARCH/FIND';
export const NCIC_CHECKBOX_PREV_STATE_PERSON =
  'NCIC/NCIC_CHECKBOX_PREV_STATE_PERSON';
export const NCIC_CHECKBOX_PREV_STATE_VEHICLE =
  'NCIC/NCIC_CHECKBOX_PREV_STATE_VEHICLE';
export const NCIC_CHECKBOX_PREV_STATE_GUN = 'NCIC/NCIC_CHECKBOX_PREV_STATE_GUN';
// Vehicles
export const SET_NCIC_RESPONSE_VEHICLES_QUERY_ID =
  'NCIC/SET_RESPONSE_VEHICLES_QUERY_ID';
export const SET_NCIC_VEHICLE_SEARCH_TYPE = 'NCIC/SET_NCIC_VEHICLE_SEARCH_TYPE';
export const SET_NCIC_FORM_VEHICLE_PICKERS =
  'NCIC/SET_NCIC_FORM_VEHICLE_PICKERS';
export const SET_NCIC_FORM_VEHICLE_FILTERS =
  'NCIC/SET_NCIC_FORM_VEHICLE_FILTERS';
export const SET_NCIC_FORM_VEHICLE_CHECKBOX =
  'NCIC/SET_NCIC_FORM_VEHICLE_CHECKBOX';
export const SET_FORM_VEHICLE_CHECKBOX =
  'VEHICLESEARCH/SET_FORM_VEHICLE_CHECKBOX';
export const FIND_VEHICLE = 'VEHICLESEARCH/FIND_PERSON';
export const OPEN_VEHICLE_DETAILS = 'VEHICLESEARCH/OPEN_DETAILS';
export const LOADING_VEHICLES = 'VEHICLESEARCH/LOADING';
export const FIND_VEHICLES = 'VEHICLESEARCH/FIND';

// Boats
export const SET_NCIC_RESPONSE_BOATS_QUERY_ID =
  'NCIC/SET_RESPONSE_BOATS_QUERY_ID';

// Guns
export const SET_NCIC_RESPONSE_GUNS_QUERY_ID =
  'NCIC/SET_RESPONSE_GUNS_QUERY_ID';
export const SET_NCIC_GUN_SEARCH_TYPE = 'NCIC/SET_NCIC_GUN_SEARCH_TYPE';
export const SET_NCIC_FORM_GUN_PICKERS = 'NCIC/SET_NCIC_FORM_GUN_PICKERS';
export const SET_NCIC_FORM_GUN_FILTERS = 'NCIC/SET_NCIC_FORM_GUN_FILTERS';
export const SET_NCIC_FORM_GUN_CHECKBOX = 'NCIC/SET_NCIC_FORM_GUN_CHECKBOX';
export const SET_FORM_GUN_CHECKBOX = 'GUNSEARCH/SET_FORM_GUN_CHECKBOX';
export const FIND_GUN = 'GUNSEARCH/FIND_GUN';
export const OPEN_GUN_DETAILS = 'GUNSEARCH/OPEN_DETAILS';
export const LOADING_GUNS = 'GUNSEARCH/LOADING';
export const FIND_GUNS = 'GUNSEARCH/FIND';
// Articles
export const SET_NCIC_RESPONSE_ARTICLES_QUERY_ID =
  'NCIC/SET_RESPONSE_ARTICLES_QUERY_ID';
export const SET_NCIC_REQUEST_DONE = 'NCIC/SET_NCIC_REQUEST_DONE';
export const getMetadata = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      if (client) {
        const service = client.service('commsys');
        service.timeout = 20000;
        const metadata = await service.get('metadata');
        dispatch({ type: SET_NCIC_METADATA, payload: metadata });
      }
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const addRawData = data => {
  const _rawData = store.store.getState().ncic.rawDataHistory;
  return async dispatch => {
    const separator = `

    <b> //////////////////////////////////////////////////////////////////////////////////////// </b>
    <b>${moment(new Date()).format('MM/DD/YYYY, h:mm:ss a')}</b>
    <b> //////////////////////////////////////////////////////////////////////////////////////// </b>

    `;
    const newData = `${_rawData}${separator}${data}`;
    dispatch({ type: SET_NCIC_RAWDATA, payload: newData });
  };
};

export const clearRawData = data => {
  return async dispatch => {
    dispatch({ type: SET_NCIC_RAWDATA, payload: '' });
  };
};

export const saveNcicCredentials = (rid, ori) => {
  return async dispatch => {
    const data = { rid: rid, ori: ori };
    window.localStorage.setItem('ncic', JSON.stringify(data));
    dispatch({
      type: SET_NCIC_RID,
      payload: rid
    });
    dispatch({
      type: SET_NCIC_ORI,
      payload: ori
    });
    // if (ori || rid)
    //   dispatch(
    //     enqueueSnackbar({
    //       message: 'Data has been saved.',
    //       options: {
    //         key: new Date().getTime() + Math.random(),
    //         variant: 'success',
    //         anchorOrigin: {
    //           vertical: 'top',
    //           horizontal: 'center'
    //         }
    //       }
    //     })
    //   );
  };
};

export const ncicLogin = user => {
  const client = store.store.getState().websocket.websocket;
  const rid = store.store.getState().ncic.rid;
  const ori = store.store.getState().ncic.ori;
  return async dispatch => {
    try {
      if (rid !== '' && ori !== '') {
        const service = client.service('commsyslogin');
        service.timeout = 20000;
        user.rid = rid;
        user.ori = ori;
        await service.create(user);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const pendingResponses = n => {
  let responses = store.store.getState().ncic.pendingResponses;
  return async dispatch => {
    if (n < 0 && responses <= 0) {
      return;
    }
    responses = responses + n;
    dispatch({
      type: SET_NCIC_PENDING_RESPONSES,
      payload: responses
    });
  };
};

export const finishedResponses = n => {
  let responses = store.store.getState().ncic.finishedResponses;
  return async dispatch => {
    if (n !== 0) {
      responses = responses + n;
      dispatch({
        type: SET_NCIC_FINISHED_RESPONSES,
        payload: responses
      });
    } else {
      dispatch({
        type: SET_NCIC_FINISHED_RESPONSES,
        payload: 0
      });
    }
  };
};

export const sendRequest = data => {
  const client = store.store.getState().websocket.websocket;
  const rid = store.store.getState().ncic.rid;
  const ori = store.store.getState().ncic.ori;
  return async dispatch => {
    if (client) {
      dispatch({ type: SET_NCIC_REQUEST_DONE, payload: false });
      dispatch(pendingResponses(1));
      // dispatch(showSpinner());

      const service = client.service('commsys');
      service.timeout = 20000;
      try {
        if (rid !== '' && ori !== '') {
          data.rid = rid;
          data.ori = ori;
        } else if (ori !== '') {
          data.ori = ori;
        }
        service.create(data);
        const notification = {
          title: `Request has been sent.`,
          message: `Once the response will come you will get a notification. All the response data will be visible on this screen.`,
          position: 'tr',
          level: 'success',
          autoDismiss: 5
        };
        // dispatch(Notifications.success(notification));
      } catch (error) {
        // dispatch(hideSpinner());
        // notify();
        // dispatch(Notifications.success(notification));
      }
    }
  };
};

export const responseNotification = data => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    if (data.RequestOrigin !== 'VehicleRegistration') {
      dispatch(finishedResponses(1));
      dispatch(pendingResponses(-1));
      // const notification = {
      //   title: `Search response received.`,
      //   message: `Please open results.`,
      //   position: 'tr',
      //   level: 'success',
      //   autoDismiss: 0,
      //   action: {
      //     label: 'OPEN',
      //     callback: () => {
      //       history.push('/Search');
      //       switch (data.QueryType) {
      //         case 'DriverLicenseQuery':
      //           dispatch(setSearchActiveTab(1));
      //           break;
      //         case 'VehicleRegistrationQuery':
      //           dispatch(setSearchActiveTab(0));
      //           break;
      //         case 'GunQuery':
      //           dispatch(setSearchActiveTab(2));
      //           break;
      //         default:
      //           dispatch(setSearchActiveTab(0));
      //       }
      //     }
      //   }
      // };
    }

    // dispatch(Notifications.success(notification));
    // save info in database that response has been received
    if (client) {
      const service = client.service('cadncicquery');
      service.timeout = 20000;
      try {
        await service.patch(data.QueryID, {
          IsReceivedByUnit: true
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
};

export const processResponse = data => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    if (client) {
      dispatch({ type: SET_NCIC_REQUEST_DONE, payload: true });

      // Push raw data to the ncic history
      if (data.rawDataHistory) dispatch(addRawData(data.rawDataHistory));
      // Push message to the store
      if (data.QueryType) {
        switch (data.QueryType) {
          case 'DriverLicenseQuery':
            dispatch({
              type: SET_NCIC_RESPONSE_PERSONS_QUERY_ID,
              payload: data.QueryID
            });
            try {
              const response = await client.service('commsys-results').find({
                query: {
                  QueryID: data.QueryID,
                  filters: {},
                  rowsPerPage: tableConfigPersons.rowsPerPage,
                  orderBy: tableConfigPersons.orderBy,
                  orderDirection: tableConfigPersons.orderDirection,
                  pageNo: 0,
                  QueryType: data.QueryType
                }
              });
              const obj = {
                params: {
                  filters: {},
                  rowsPerPage: tableConfigPersons.rowsPerPage,
                  orderBy: tableConfigPersons.orderBy,
                  orderDirection: tableConfigPersons.orderDirection,
                  pageNo: 0
                },
                persons: response,
                rawData: data.RawData,
                images: data.Images
              };
              if (data.RequestOrigin === 'VehicleRegistration') {
                dispatch({
                  type: FIND_OWNERS,
                  payload: obj
                });
              } else {
                dispatch({
                  type: FIND_PERSONS,
                  payload: obj
                });
              }
            } catch (error) {
              console.log(error);
            }
            break;
          case 'VehicleRegistrationQuery':
            dispatch({
              type: SET_NCIC_RESPONSE_VEHICLES_QUERY_ID,
              payload: data
            });
            try {
              const response = await client.service('commsys-results').find({
                query: {
                  QueryID: data.QueryID,
                  filters: {},
                  rowsPerPage: tableConfigVehicles.rowsPerPage,
                  orderBy: tableConfigVehicles.orderBy,
                  orderDirection: tableConfigVehicles.orderDirection,
                  pageNo: 0,
                  QueryType: data.QueryType
                }
              });
              const obj = {
                params: {
                  filters: {},
                  rowsPerPage: tableConfigVehicles.rowsPerPage,
                  orderBy: tableConfigVehicles.orderBy,
                  orderDirection: tableConfigVehicles.orderDirection,
                  pageNo: 0
                },
                vehicles: response,
                rawData: data.RawData,
                images: data.Images
              };
              dispatch({
                type: FIND_VEHICLES,
                payload: obj
              });
            } catch (error) {
              console.log(error);
            }
            break;
          case 'GunQuery':
            dispatch({ type: SET_NCIC_RESPONSE_GUNS_QUERY_ID, payload: data });
            try {
              const response = await client.service('commsys-results').find({
                query: {
                  QueryID: data.QueryID,
                  filters: {},
                  rowsPerPage: tableConfigGuns.rowsPerPage,
                  orderBy: tableConfigGuns.orderBy,
                  orderDirection: tableConfigGuns.orderDirection,
                  pageNo: 0,
                  QueryType: data.QueryType
                }
              });
              const obj = {
                params: {
                  filters: {},
                  rowsPerPage: tableConfigGuns.rowsPerPage,
                  orderBy: tableConfigGuns.orderBy,
                  orderDirection: tableConfigGuns.orderDirection,
                  pageNo: 0
                },
                guns: response,
                rawData: data.RawData
              };
              dispatch({
                type: FIND_GUNS,
                payload: obj
              });
            } catch (error) {
              console.log(error);
            }
            break;
          case 'BoatQuery':
            dispatch({ type: SET_NCIC_RESPONSE_BOATS_QUERY_ID, payload: data });
            break;
          case 'ArticleSingleQuery':
            dispatch({
              type: SET_NCIC_RESPONSE_ARTICLES_QUERY_ID,
              payload: data
            });
            break;
          default:
            console.log(
              `No handler for this commsys query type: ${data.QueryType}.`
            );
            return null;
        }
      }
    }
  };
};

/* New ncic implementation PERSONS */
export const handlePersonDateChange = (date, name, param) => {
  const pickerValues = store.store.getState().ncic.personForm.pickers;
  return async dispatch => {
    const value = pickerValues[name]
      ? pickerValues[name]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[name] = value;
    } else {
      delete currentPickerValue[name];
    }
    dispatch({
      type: SET_NCIC_FORM_PERSON_PICKERS,
      payload: currentPickerValue
    });
  };
};

export const handlePersonFilterChange = (ev, filter) => {
  const values = store.store.getState().ncic.personForm.filters;
  return async dispatch => {
    values[filter.name] = {
      value: ev.target ? ev.target.value.toUpperCase() : ev.toUpperCase(),
      name: filter.name,
      ncic: true
    };
    dispatch({
      type: SET_NCIC_FORM_PERSON_FILTERS,
      payload: cleanFilters(values)
    });
  };
};

export const handlePersonCheckboxChange = ev => {
  return async dispatch => {
    // if (ev.target) {
    //   dispatch({
    //     type: NCIC_CHECKBOX_PREV_STATE_PERSON,
    //     payload: ev.target.checked
    //   });
    // }
    dispatch({
      type: SET_NCIC_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_VEHICLE_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_GUN_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_GUN_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
  };
};
export const handlePersonNCICFilltersAutoFill = values => {
  return dispatch => {
    if (values['BirthDate']) {
      dispatch({
        type: SET_NCIC_FORM_PERSON_PICKERS,
        payload: { BirthDate: values['BirthDate'] } || {}
      });
    } else {
      dispatch({
        type: SET_NCIC_FORM_PERSON_PICKERS,
        payload: {}
      });
    }
    delete values.BirthDate;
    dispatch({
      type: SET_NCIC_FORM_PERSON_FILTERS,
      payload: values
    });
  };
};
export const findResultsPerson = params => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      dispatch({ type: LOADING_PERSONS, payload: true });
      const persons = await client.service('commsys-results').find({
        query: {
          ...params,
          QueryID: params.QueryID
        }
      });
      dispatch({ type: LOADING_PERSONS, payload: false });
      dispatch({
        type: FIND_PERSONS,
        payload: { params, persons }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const findResultPerson = data => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    dispatch(showSpinner());
    try {
      const service = client.service('commsys-results');
      service.timeout = 20000;
      const row = await service.get(data.Id, {
        query: {
          QueryType: 'DriverLicenseQuery'
        }
      });

      if (row.VehicleQueryID) {
        dispatch({ type: FIND_OWNER, payload: row });

        dispatch({
          type: OPEN_OWNER_DETAILS,
          payload: null
        });
      } else {
        dispatch({ type: FIND_PERSON, payload: row });
        dispatch({ type: CITATION_DATA, payload: row });
        dispatch({
          type: OPEN_PERSON_DETAILS,
          payload: null
        });
      }
    } catch (error) {
      notify('No data found.', 'warning', '300px');

      // dispatch(
      //   enqueueSnackbar({
      //     message: 'No data found',
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       variant: 'warning',
      //       anchorOrigin: {
      //         vertical: 'top',
      //         horizontal: 'center'
      //       }
      //     }
      //   })
      // );
    }
    dispatch(hideSpinner());
  };
};

export const clearPersonForm = () => {
  const { APP_DEFAULT_STATE } = store.store.getState().appInfo.appEnvInfo;
  const userNcicEmail = store.store.getState().user?.userData?.user?.NCICEmail;

  return async dispatch => {
    const payload = {
      State: {
        name: 'State',
        ncic: true,
        value: APP_DEFAULT_STATE
      }
    };
    if (APP_DEFAULT_STATE === 'TX') {
      payload['ReasonCode'] = {
        name: 'ReasonCode',
        ncic: true,
        value: 'C'
      };
      payload['EmailAddress'] = {
        name: 'EmailAddress',
        ncic: true,
        value: userNcicEmail !== null ? userNcicEmail : ''
      };
    }
    dispatch({
      type: SET_NCIC_FORM_PERSON_FILTERS,
      payload: payload
    });
    dispatch({
      type: SET_NCIC_FORM_PERSON_PICKERS,
      payload: {}
    });
  };
};

/* New ncic implementation VEHICLES */
export const handleVehicleDateChange = (date, name, param) => {
  const pickerValues = store.store.getState().ncic.vehicleForm.pickers;
  return async dispatch => {
    const value = pickerValues[name]
      ? pickerValues[name]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[name] = value;
    } else {
      delete currentPickerValue[name];
    }
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_PICKERS,
      payload: currentPickerValue
    });
  };
};

export const handleVehicleFilterChange = (ev, filter) => {
  const values = store.store.getState().ncic.vehicleForm.filters;
  return async dispatch => {
    values[filter.name] = {
      value: ev.target ? ev.target.value.toUpperCase() : ev.toUpperCase(),
      name: filter.name,
      ncic: true
    };
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_FILTERS,
      payload: cleanFilters(values)
    });
  };
};

export const handleVehicleCheckboxChange = ev => {
  return async dispatch => {
    // if (ev.target) {
    //   dispatch({
    //     type: NCIC_CHECKBOX_PREV_STATE_VEHICLE,
    //     payload: ev.target.checked
    //   });
    // }
    dispatch({
      type: SET_NCIC_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_VEHICLE_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_GUN_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_GUN_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
  };
};
export const handleVehicleNCICFilltersAutoFill = values => {
  return dispatch => {
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_FILTERS,
      payload: values
    });
  };
};
export const findResultsVehicle = params => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      dispatch({ type: LOADING_VEHICLES, payload: true });
      const vehicles = await client.service('commsys-results').find({
        query: {
          ...params,
          QueryID: params.QueryID
        }
      });
      dispatch({ type: LOADING_VEHICLES, payload: false });
      dispatch({
        type: FIND_VEHICLES,
        payload: { params, vehicles }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const findResultVehicle = data => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    dispatch(showSpinner());
    try {
      const service = client.service('commsys-results');
      service.timeout = 20000;
      const row = await service.get(data.Id, {
        query: {
          QueryType: 'VehicleRegistrationQuery'
        }
      });
      dispatch({ type: FIND_VEHICLE, payload: row });
      dispatch({ type: CITATION_VEHICLE_DATA, payload: row });
      dispatch({
        type: OPEN_VEHICLE_DETAILS,
        payload: null
      });
    } catch (error) {
      notify('No data found.', 'warning', '300px');
      // dispatch(
      //   enqueueSnackbar({
      //     message: 'No data found',
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       variant: 'warning',
      //       anchorOrigin: {
      //         vertical: 'top',
      //         horizontal: 'center'
      //       }
      //     }
      //   })
      // );
    }
    dispatch(hideSpinner());
  };
};

export const quickVehicleSearcher = (
  quickSearchValue,
  isQuickSearch = false
) => {
  const user = store.store.getState().user;
  const rid = store.store.getState().ncic.rid;
  const ori = store.store.getState().ncic.ori;
  const { APP_DEFAULT_STATE } = store.store.getState().appInfo.appEnvInfo;

  let newFilterValues = {};

  let params = {
    State: {
      name: 'State',
      ncic: true,
      value: APP_DEFAULT_STATE
    }
  };

  if (isQuickSearch) {
    Object.entries(quickSearchValue).forEach(([key, value]) => {
      if (typeof value === 'string') {
        params[key] = {
          value: value?.toUpperCase(),
          name: key,
          ncic: true
        };
      } else {
        params[key] = {
          value: value,
          name: key,
          ncic: true
        };
      }
    });
  } else {
    params['LicensePlateNumber'] = {
      value: quickSearchValue.toUpperCase(),
      name: 'LicensePlateNumber',
      ncic: true
    };
  }

  const queryDefinition = {
    filterValues: { ...params },
    pickerValues: {}
  };
  newFilterValues.query = { ...params };

  newFilterValues['queryType'] = 'VehicleRegistrationQuery';
  newFilterValues['user'] = user;
  newFilterValues['queryDefinition'] = queryDefinition;
  return async dispatch => {
    dispatch(sendRequest(newFilterValues));
  };
};
export const quickPersonSearcher = (
  quickSearchValue,
  isQuickSearch = false
) => {
  const user = store.store.getState().user;
  const rid = store.store.getState().ncic.rid;
  const ori = store.store.getState().ncic.ori;
  const { APP_DEFAULT_STATE } = store.store.getState().appInfo.appEnvInfo;

  let newFilterValues = {};
  let params = {
    State: {
      name: 'State',
      ncic: true,
      value: APP_DEFAULT_STATE
    }
  };
  if (isQuickSearch) {
    Object.entries(quickSearchValue).forEach(([key, value]) => {
      params[key] = {
        value: value.toUpperCase(),
        name: key,
        ncic: true
      };
    });
  } else {
    params['OperatorLicenseNumber'] = {
      value: quickSearchValue.toUpperCase(),
      name: 'OperatorLicenseNumber',
      ncic: true
    };
  }
  // console.log('parsm', params);
  const queryDefinition = {
    filterValues: { ...params },
    pickerValues: {}
  };
  newFilterValues.query = { ...params };

  newFilterValues['queryType'] = 'DriverLicenseQuery';
  newFilterValues['user'] = user;
  newFilterValues['queryDefinition'] = queryDefinition;
  return async dispatch => {
    dispatch(sendRequest(newFilterValues));
  };
};
export const clearVehicleForm = () => {
  const {
    APP_DEFAULT_STATE,
    YEAR_REQUIRED
  } = store.store.getState().appInfo.appEnvInfo;

  return async dispatch => {
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_FILTERS,
      payload: {
        ...(YEAR_REQUIRED === true
          ? {
              LicensePlateYear: {
                name: 'LicensePlateYear',
                ncic: true,
                value: new Date().getFullYear()
              }
            }
          : ''),
        State: {
          name: 'State',
          ncic: true,
          value: APP_DEFAULT_STATE
        },
        LicensePlateTypeCode: {
          name: 'LicensePlateTypeCode',
          ncic: true,
          value: 'PC'
        }
      }
    });
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_PICKERS,
      payload: {}
    });
  };
};

/* New ncic implementation GUNS */
export const handleGunDateChange = (date, name, param) => {
  const pickerValues = store.store.getState().ncic.gunForm.pickers;
  return async dispatch => {
    const value = pickerValues[name]
      ? pickerValues[name]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[name] = value;
    } else {
      delete currentPickerValue[name];
    }
    dispatch({
      type: SET_NCIC_FORM_GUN_PICKERS,
      payload: currentPickerValue
    });
  };
};

export const handleGunFilterChange = (ev, filter) => {
  const values = store.store.getState().ncic.gunForm.filters;
  return async dispatch => {
    values[filter.name] = {
      value: ev.target ? ev.target.value.toUpperCase() : ev.toUpperCase(),
      name: filter.name,
      ncic: true
    };
    dispatch({
      type: SET_NCIC_FORM_GUN_FILTERS,
      payload: cleanFilters(values)
    });
  };
};

export const handleGunCheckboxChange = ev => {
  return async dispatch => {
    // if (ev.target) {
    //   dispatch({
    //     type: NCIC_CHECKBOX_PREV_STATE_GUN,
    //     payload: ev.target.checked
    //   });
    // }
    dispatch({
      type: SET_NCIC_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_VEHICLE_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_GUN_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_GUN_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
  };
};
export const handleGunNCICFilltersAutoFill = values => {
  return dispatch => {
    dispatch({
      type: SET_NCIC_FORM_GUN_FILTERS,
      payload: values
    });
  };
};
export const findResultsGun = params => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      dispatch({ type: LOADING_GUNS, payload: true });
      const guns = await client.service('commsys-results').find({
        query: {
          ...params,
          QueryID: params.QueryID
        }
      });
      dispatch({ type: LOADING_GUNS, payload: false });
      dispatch({
        type: FIND_GUNS,
        payload: { params, guns }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const findResultGun = data => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    dispatch(showSpinner());
    try {
      const service = client.service('commsys-results');
      service.timeout = 20000;
      const row = await service.get(data.Id, {
        query: {
          QueryType: 'GunQuery'
        }
      });
      dispatch({ type: FIND_GUN, payload: row });
      dispatch({
        type: OPEN_GUN_DETAILS,
        payload: null
      });
    } catch (error) {
      notify('No data found.', 'warning', '300px');
      // dispatch(
      //   enqueueSnackbar({
      //     message: 'No data found',
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       variant: 'warning',
      //       anchorOrigin: {
      //         vertical: 'top',
      //         horizontal: 'center'
      //       }
      //     }
      //   })
      // );
    }
    dispatch(hideSpinner());
  };
};

export const clearGunForm = () => {
  return async dispatch => {
    dispatch({
      type: SET_NCIC_FORM_GUN_FILTERS,
      payload: {}
    });
    dispatch({
      type: SET_NCIC_FORM_GUN_PICKERS,
      payload: {}
    });
  };
};

const cleanFilters = obj => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.value !== '') result[key] = val;
  });
  return result;
};
export const setNcicRequestDone = data => {
  return async dispatch => {
    dispatch({ type: SET_NCIC_REQUEST_DONE, payload: data });
  };
};
const ncicStorage =
  window.localStorage.getItem('ncic') !== null
    ? JSON.parse(window.localStorage.getItem('ncic'))
    : null;

export const setDefaultEnvFORNCIC = envInfo => {
  const { YEAR_REQUIRED, APP_DEFAULT_STATE } = envInfo;
  const userNcicEmail = store.store.getState().user?.userData?.user?.NCICEmail;
  const personForm = store.store.getState().ncic.personForm.filters;
  const vehicleForm = store.store.getState().ncic.vehicleForm.filters;

  return dispatch => {
    const personFilter = {
      State: {
        name: 'State',
        ncic: true,
        value: APP_DEFAULT_STATE
      }
    };
    if (personForm['OperatorLicenseNumber']) {
      personFilter['OperatorLicenseNumber'] = {
        name: 'OperatorLicenseNumber',
        ncic: true,
        value: personForm['OperatorLicenseNumber'].value
      };
    }
    const vehicleFilter = {
      State: {
        name: 'State',
        ncic: true,
        value: APP_DEFAULT_STATE
      },
      LicensePlateTypeCode: {
        name: 'LicensePlateTypeCode',
        ncic: true,
        value: 'PC'
      }
    };
    if (YEAR_REQUIRED) {
      vehicleFilter['LicensePlateYear'] = {
        name: 'LicensePlateYear',
        ncic: true,
        value: new Date().getFullYear()
      };
    }
    if (APP_DEFAULT_STATE === 'TX') {
      personFilter['ReasonCode'] = {
        name: 'ReasonCode',
        ncic: true,
        value: 'C'
      };
      personFilter['EmailAddress'] = {
        name: 'EmailAddress',
        ncic: true,
        value: userNcicEmail !== null ? userNcicEmail : ''
      };
    }
    if (vehicleForm['LicensePlateNumber']) {
      vehicleFilter['LicensePlateNumber'] = {
        name: 'LicensePlateNumber',
        ncic: true,
        value: vehicleForm['LicensePlateNumber'].value
      };
    }
    dispatch({
      type: SET_NCIC_FORM_PERSON_FILTERS,
      payload: personFilter
    });
    dispatch({ type: SET_NCIC_FORM_VEHICLE_FILTERS, payload: vehicleFilter });
  };
};

export default function reducer(
  state = {
    metadata: null,
    rawDataHistory: '',
    rid: ncicStorage !== null ? ncicStorage.rid : '',
    ori: ncicStorage !== null ? ncicStorage.ori : '',
    pendingResponses: 0,
    finishedResponses: 0,
    isNcicRequestDone: false,
    // Person
    personSearchType: 'local',
    personForm: {
      filters: {},
      pickers: {},
      ncic: false,
      ncicPrevState: false
    },
    personsQueryId: null,

    // Vehicle
    vehicleSearchType: 'local',
    vehicleForm: {
      filters: {},
      pickers: {},
      ncic: false,
      ncicPrevState: false
    },
    vehiclesQueryId: null,

    // Gun
    gunSearchType: 'local',
    gunForm: {
      filters: {},
      pickers: {},
      ncic: false,
      ncicPrevState: false
    },
    vehiclesQueryId: null
  },
  action
) {
  switch (action.type) {
    // Ncic
    case SET_NCIC_METADATA:
      return {
        ...state,
        metadata: action.payload
      };
    case SET_NCIC_RAWDATA:
      return {
        ...state,
        rawDataHistory: action.payload
      };
    case SET_NCIC_RID:
      return {
        ...state,
        rid: action.payload
      };
    case SET_NCIC_ORI:
      return {
        ...state,
        ori: action.payload
      };
    case SET_NCIC_PENDING_RESPONSES:
      return {
        ...state,
        pendingResponses: action.payload
      };
    case SET_NCIC_FINISHED_RESPONSES:
      return {
        ...state,
        finishedResponses: action.payload
      };
    case SET_NCIC_REQUEST_DONE:
      return {
        ...state,
        isNcicRequestDone: action.payload
      };
    // Persons
    case SET_NCIC_FORM_PERSON_PICKERS:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          pickers: action.payload
        }
      };
    case SET_NCIC_FORM_PERSON_FILTERS:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          filters: action.payload
        }
      };
    case SET_NCIC_FORM_PERSON_CHECKBOX:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          ncic: action.payload
        }
      };
    case NCIC_CHECKBOX_PREV_STATE_PERSON:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          ncicPrevState: action.payload
        }
      };
    case SET_NCIC_PERSON_SEARCH_TYPE:
      return {
        ...state,
        personSearchType: action.payload
      };
    case SET_NCIC_RESPONSE_PERSONS_QUERY_ID:
      return {
        ...state,
        personsQueryId: action.payload
      };
    // Vehicles
    case SET_NCIC_FORM_VEHICLE_PICKERS:
      return {
        ...state,
        vehicleForm: {
          ...state.vehicleForm,
          pickers: action.payload
        }
      };
    case SET_NCIC_FORM_VEHICLE_FILTERS:
      return {
        ...state,
        vehicleForm: {
          ...state.vehicleForm,
          filters: action.payload
        }
      };
    case SET_NCIC_FORM_VEHICLE_CHECKBOX:
      return {
        ...state,
        vehicleForm: {
          ...state.vehicleForm,
          ncic: action.payload
        }
      };
    case NCIC_CHECKBOX_PREV_STATE_VEHICLE:
      return {
        ...state,
        vehicleForm: {
          ...state.vehicleForm,
          ncicPrevState: action.payload
        }
      };
    case SET_NCIC_VEHICLE_SEARCH_TYPE:
      return {
        ...state,
        vehicleSearchType: action.payload
      };
    case SET_NCIC_RESPONSE_VEHICLES_QUERY_ID:
      return {
        ...state,
        vehiclesQueryId: action.payload
      };
    // Guns
    case SET_NCIC_FORM_GUN_PICKERS:
      return {
        ...state,
        gunForm: {
          ...state.gunForm,
          pickers: action.payload
        }
      };
    case SET_NCIC_FORM_GUN_FILTERS:
      return {
        ...state,
        gunForm: {
          ...state.gunForm,
          filters: action.payload
        }
      };
    case SET_NCIC_FORM_GUN_CHECKBOX:
      return {
        ...state,
        gunForm: {
          ...state.gunForm,
          ncic: action.payload
        }
      };
    case NCIC_CHECKBOX_PREV_STATE_GUN:
      return {
        ...state,
        gunForm: {
          ...state.gunForm,
          ncicPrevState: action.payload
        }
      };
    case SET_NCIC_GUN_SEARCH_TYPE:
      return {
        ...state,
        gunSearchType: action.payload
      };
    case SET_NCIC_RESPONSE_GUNS_QUERY_ID:
      return {
        ...state,
        gunsQueryId: action.payload
      };
    default:
      break;
  }
  return state;
}
