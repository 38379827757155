
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8baby100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M25 1c-3.867 0-7 3.133-7 7s3.133 7 7 7 7-3.133 7-7-3.133-7-7-7Zm0 14h-4.5c-1.664 0-2.313.559-3.406 1.344-.676.484-9.844 7.218-9.844 7.218A3 3 0 0 0 9 29c.688 0 1.305-.234 1.813-.625L18 23.187V27h.031s-2.367 5.402-2.562 5.844c-.004-.004-.028.004-.031 0a2327.43 2327.43 0 0 0-2.626 3.968c-.968 1.461-1.265 1.958.063 3.72 1.082 1.437 4.973 6.534 5.594 7.156.62.62 2.02 1.847 3.406.968 1.5-.953 1.02-2.472.594-3.281-.383-.727-4.313-5.563-4.313-5.563l3.657-3.218c.742.246 1.519.406 2.218.406H26c.695 0 1.45-.164 2.188-.406l3.687 3.218s-3.93 4.836-4.313 5.563c-.425.809-.937 2.328.563 3.281 1.387.88 2.785-.347 3.406-.968.617-.622 4.512-5.723 5.594-7.157 1.328-1.761 1.031-2.258.063-3.718-.344-.516-1.81-2.739-2.626-3.97L32 27v-3.813l7.188 5.188c.507.39 1.124.625 1.812.625a3 3 0 0 0 3-3c0-1.012-.516-1.895-1.281-2.438-.04-.027-9.137-6.734-9.813-7.218C31.813 15.559 31.164 15 29.5 15Zm-5.688 14h11.375l1.438 3.219C30.665 33.289 27.871 35 26 35h-2c-1.871 0-4.664-1.71-6.125-2.781Z"/></SvgIcon>
      );
    }
  
    export default icons8baby100Icon;