import React, { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import projectLogo from 'assets/images/icon-MOBILE.svg';
import splashScreen from 'assets/images/login-splash.jpg';
import ptsLogo from 'assets/images/PTS-logo.png';
import { Button, Card, CardContent, Container, Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  wrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  card: {
    width: 480,
    maxWidth: '95%',
    background: '#fff',
    '& img': {
      width: '100%'
    }
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#037015',
    '& img': {
      width: '150px !important',
      height: 'auto'
    }
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    '& img': {
      height: 50,
      width: 'auto !important'
    }
  }
}));

const UnexpectedError = (props) => {
  const { classes } = useStyles();

  const handleClick = () => {
    const ncic = window.localStorage.getItem('ncic');
    window.localStorage.clear();
    window.localStorage.setItem('ncic', ncic);
    window.location.reload();
  };

  return (
    <Fragment>
      <div className={`app-wrapper min-vh-100 ${classes.wrap}`}>
        <Container maxWidth="sm">
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              lg={12}
              className="d-flex flex-column align-items-center">
              <Card className={classes.card}>
                <div className={classes.branding}>
                  <img src={projectLogo} alt="MOBILE icon" />
                </div>
                {/* <div className={classes.branding2}>
                  <img src={ptsLogo} alt="PTS logo" />
                </div> */}
                <CardContent className={classes.content}>
                  <Card className="card-box bg-neutral-warning border-1 card-border-top border-warning text-center p-3">
                    <h4 style={{ marginBottom: 0, color: 'black' }}>
                      An unexpected error occurred.
                      <br />
                      Please try again.
                    </h4>
                  </Card>
                  <div className="text-center">
                    <Button
                      variant="contained"
                      color="primary"
                      className="mt-2 mb-2"
                      onClick={handleClick}>
                      TRY AGAIN
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

export default UnexpectedError;
