import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLoadScript } from '@react-google-maps/api';
import store from '../../config/configureStore';

import RenderMap from '../../components/Map';
import Loader from '../../global-components/Loader';
import { handleError } from 'reducers/ErrorReducer';
const libraries = (process.env.REACT_APP_GOOGLE_LIBRARIES || '').split(',');

const PagesMain = props => {
  const { units, events } = props;

  // const { APP_GOOGLE_LIBRARIES, APP_GOOGLE_KEY } = useSelector(
  //   state => state.appInfo.appEnvInfo
  // );
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries
  });

  if (loadError) {
    return (
      <Fragment>
        <Loader />
      </Fragment>
    );
  }

  return isLoaded && units && events ? <RenderMap /> : <Loader />;
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  themeMode: state.theme.mode,
  units: state.units.units,
  events: state.events.events
});

export default connect(mapStateToProps, {
  handleError
})(PagesMain);
