import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { OverlayView } from '@react-google-maps/api';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventAvatar from 'components/EventAvatar';
import { getEventColor } from '../../../utils/uiUtils';
import { setEventInfowindow } from '../../../reducers/MapReducer';

const positionOverlayView = (width, height) => ({ x: -width / 2, y: -height });

const avatarSize = 70;
const iconSize = avatarSize / 2.1;
const iconLeft = (avatarSize - iconSize) / 2;
const iconTop = (avatarSize - iconSize) / 4;
const borderSize = 2;
const avatarWidth = 33;

const useStyles = makeStyles()((theme) => ({
  label: {
    position: 'absolute',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    fontSize: 12,
    top: -6,
    left: '50%',
    color: theme.map.labelColor,
    transform: 'translate(-50%, 0)',
    textShadow: `0 1px 0 ${theme.map.labelBorder},
                 0 -1px 0 ${theme.map.labelBorder},
                 1px 0 0 ${theme.map.labelBorder},
                 -1px 0 0 ${theme.map.labelBorder}`,
  },
  marker: {
    position: 'relative',
    cursor: 'pointer',
  },
  iconBorderDrag: {
    position: 'absolute',
    color: theme.palette.secondary.main,
    width: avatarSize + borderSize * 2 + 4,
    height: avatarSize + borderSize * 2 + 4,
    top: -borderSize - 2,
    left: -borderSize - 2,
  },
  iconBorder: {
    position: 'absolute',
    color: theme.map.labelBorder,
    width: avatarSize + borderSize * 2,
    height: avatarSize + borderSize * 2,
    top: -borderSize,
    left: -borderSize,
  },
  icon: {
    position: 'absolute',
    width: iconSize,
    height: iconSize,
    top: iconTop,
    left: iconLeft,
    textAlign: 'center',
    fontSize: iconSize / 1.8,
    fontWeight: 600,
    '& > span': {
      position: 'absolute',
      inset: 0,
      display: 'block',
      borderRadius: '50%',
    },
    '& > div': {
      color: '#000',
      position: 'absolute',
      inset: 0,
      borderRadius: '50%',
      background: 'rgba(255, 255, 255, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  evAvatar: {
    background: theme.map.avatarBg,
    borderRadius: '50%',
    fontSize: '0.7em',
    overflow: 'hidden',
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    height: avatarWidth,
    '& > div': {
      width: avatarWidth,
      height: avatarWidth,
    },
  },
}));

const MarkersEvents = props => {
  const { events, setEventInfowindow, colors, filterKeyword } = props;

  const { classes } = useStyles();

  const onClick = event => {
    setEventInfowindow(true, event);
  };

  const renderMarker = event => {
    const { ptsEventID, EventID } = event;
    const color = getEventColor(event, colors);
    if (event.LatitudeDegree !== null && event.LongitudeDegree !== null) {
      const latitude = `${event.LatitudeSign === '-' ? event.LatitudeSign : ''
        }${event.LatitudeDegree}`;
      const longitude = `${event.LongitudeSign === '-' ? event.LongitudeSign : ''
        }${event.LongitudeDegree}`;
      const position = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      };
      return (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={ptsEventID}
          position={position}
          getPixelPositionOffset={positionOverlayView}>
          <div onClick={() => onClick(event)}>
            <div className={classes.label}>{EventID}</div>
            <div className={classes.marker}>
              <LocationOnIcon className={classes.iconBorder} />
              <LocationOnIcon style={{ color, fontSize: avatarSize, position: 'relative' }} />
              <div className={classes.icon}>
                <span style={{ backgroundColor: color }} />
                <EventAvatar event={event} className={classes.evAvatar} color={'#000'} />
              </div>
            </div>
          </div>
        </OverlayView>
      );
    } else {
      return null;
    }
  };

  if (events && events.length > 0) {
    return events.map((event, index) => {
      if (filterKeyword !== '') {
        if (
          event.CallTypeDescription?.toLowerCase().includes(filterKeyword) ||
          event.EventID?.toLowerCase().includes(filterKeyword) ||
          event.Status?.toLowerCase().includes(filterKeyword) ||
          event.FullLocationAddress?.toLowerCase().includes(filterKeyword)
        ) {
          return renderMarker(event);
        } else {
          return null;
        }
      } else {
        return renderMarker(event);
      }
    });
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  events: state.events.events,
  filterKeyword: state.events.filterKeyword,
  colors: state.codes.colorsCodes
});

export default connect(mapStateToProps, { setEventInfowindow })(MarkersEvents);
