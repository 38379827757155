
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8fasttrack100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M37.496 3a4.5 4.5 0 1 0 .01 9.002A4.5 4.5 0 0 0 37.496 3zM23 10c-.397 0-.765.12-1.076.318l-.037.006-6.93 4.975v.002A1.99 1.99 0 0 0 14 17a2 2 0 0 0 2 2 1.98 1.98 0 0 0 1.285-.479l.004.006 5.992-4.308 4.41 1.398-4.933 8.043.002.01A3.97 3.97 0 0 0 22 26c0 1.546.884 2.87 2.166 3.535l.002.012 7.586 4.68-1.695 8.744A2.502 2.502 0 0 0 32.5 46a2.5 2.5 0 0 0 2.453-2.04h.002l2.027-10.672-.01-.025c.012-.088.028-.173.028-.264 0-.584-.254-1.103-.652-1.469l-.008-.023-5.403-4.79 4.672-9.234-.005-.02c.245-.434.396-.93.396-1.464a2.999 2.999 0 0 0-1.807-2.75l-.002-.008-2.507-1.394-8.164-1.782-.024.004A1.98 1.98 0 0 0 23 10zM9.213 16.383a1.997 1.997 0 0 0-1.436.533L4.6 19.866a2.003 2.003 0 0 0-.108 2.825l4.444 4.79h.002a1.985 1.985 0 0 0 1.466.638c.506 0 .987-.188 1.358-.533l3.18-2.95c.391-.362.617-.858.638-1.392a1.986 1.986 0 0 0-.533-1.435l-4.443-4.788a1.988 1.988 0 0 0-1.391-.638zm28.002 2.36-2.242 4.429 1.207 2.668.011.008A2 2 0 0 0 38 27h7a2 2 0 1 0 0-4h-6l-1.785-4.258zm-16.695 9.67L18.242 32H10.5A2.497 2.497 0 0 0 8 34.5c0 1.383 1.117 2.5 2.5 2.5h8.434a3.995 3.995 0 0 0 3.261-1.688l2.285-3.222c-.829-.511-1.425-.883-1.433-.887a5.975 5.975 0 0 1-2.527-2.789z"/></SvgIcon>
      );
    }
  
    export default icons8fasttrack100Icon;