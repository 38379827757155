import { enqueueSnackbar, notify } from '../reducers/NotifierReducer';
import store from '../config/configureStore';
import tableConfig from '../config/table-config-guns.json';

import { showSpinner, hideSpinner } from './UiReducer';
import {
  SET_FORM_PERSON_CHECKBOX,
  SET_NCIC_FORM_PERSON_CHECKBOX
} from './PersonSearchReducer';
import {
  SET_FORM_VEHICLE_CHECKBOX,
  SET_NCIC_FORM_VEHICLE_CHECKBOX
} from './VehicleSearchReducer';

// Gun Search
export const FIND_GUNS = 'GUNSEARCH/FIND';
export const LOADING_GUNS = 'GUNSEARCH/LOADING';
export const LOADING_GUNS_ERROR = 'GUNSEARCH/LOADING_ERROR';

export const FIND_GUN = 'GUNSEARCH/FIND_GUN';
export const OPEN_DETAILS = 'GUNSEARCH/OPEN_DETAILS';
export const CLOSE_DETAILS = 'GUNSEARCH/CLOSE_DETAILS';

export const CLEAR_RESULTS = 'GUNSEARCH/CLEAR_RESULTS';

export const SET_FORM_GUN_PICKERS = 'NCIC/SET_FORM_GUN_PICKERS';
export const SET_FORM_GUN_FILTERS = 'NCIC/SET_FORM_GUN_FILTERS';
export const SET_FORM_GUN_CHECKBOX = 'GUNSEARCH/SET_FORM_GUN_CHECKBOX';
export const SET_NCIC_FORM_GUN_CHECKBOX = 'NCIC/SET_NCIC_FORM_GUN_CHECKBOX';
export const SET_NCIC_RESPONSE_GUNS_QUERY_ID =
  'NCIC/SET_RESPONSE_GUNS_QUERY_ID';

export const SET_NCIC_FINISHED_RESPONSES = 'NCIC/SET_NCIC_FINISHED_RESPONSES';
export const SET_NCIC_PENDING_RESPONSES = 'NCIC/SET_NCIC_PENDING_RESPONSES';

export const findGuns = params => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    try {
      const service = client.service('search-firearm');
      service.timeout = 20000;
      dispatch({ type: SET_NCIC_RESPONSE_GUNS_QUERY_ID, payload: null });
      dispatch({ type: LOADING_GUNS, payload: true });
      const guns = await service.find({ query: params });
      dispatch({ type: FIND_GUNS, payload: { params, guns } });
      dispatch({ type: LOADING_GUNS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_GUNS_ERROR });
      notify('No data found.', 'warning', '300px');
      // dispatch(
      //   enqueueSnackbar({
      //     message: 'No data found.',
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       variant: 'warning',
      //       anchorOrigin: {
      //         vertical: 'top',
      //         horizontal: 'center'
      //       }
      //     }
      //   })
      // );
    }
  };
};

export const sortGuns = orderBy => {
  return async dispatch => {
    const changeDirection = direction => (direction === 'ASC' ? 'DESC' : 'ASC');
    const guns = store.store.getState().gunSearch.guns;
    const { filters, pageNo, rowsPerPage } = guns;
    const orderDirection =
      guns.orderBy === orderBy ? changeDirection(guns.orderDirection) : 'ASC';
    const params = { filters, pageNo, orderBy, rowsPerPage, orderDirection };
    dispatch(findGuns(params));
  };
};

export const findGun = ptsFirearmID => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async dispatch => {
    if (ptsFirearmID === null) {
      dispatch({
        type: CLOSE_DETAILS,
        payload: null
      });
    } else {
      dispatch(showSpinner());
      try {
        const service = client.service('search-firearm');
        service.timeout = 20000;
        const row = await service.get(ptsFirearmID);
        dispatch({ type: FIND_GUN, payload: row });
        dispatch({
          type: OPEN_DETAILS,
          payload: null
        });
      } catch (error) {
        // dispatch(
        //   enqueueSnackbar({
        //     message: 'No data found.',
        //     options: {
        //       key: new Date().getTime() + Math.random(),
        //       variant: 'warning',
        //       anchorOrigin: {
        //         vertical: 'top',
        //         horizontal: 'center'
        //       }
        //     }
        //   })
        // );
        notify('No data found.', 'warning', '300px');
      }
      dispatch(hideSpinner());
    }
  };
};

/* New ncic implementation */
export const handleDateChange = (date, dbName, param) => {
  const pickerValues = store.store.getState().gunSearch.gunForm.pickers;
  return async dispatch => {
    const value = pickerValues[dbName]
      ? pickerValues[dbName]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }
    dispatch({
      type: SET_FORM_GUN_PICKERS,
      payload: currentPickerValue
    });
  };
};

export const handleFilterChange = (ev, filter) => {
  const values = store.store.getState().gunSearch.gunForm.filters;
  return async dispatch => {
    values[filter.dbName] = {
      type: filter.type,
      search: ev.target ? ev.target.value : ev
    };
    dispatch({
      type: SET_FORM_GUN_FILTERS,
      payload: cleanFilters(values)
    });
  };
};

export const handleCheckboxChange = ev => {
  return async dispatch => {
    dispatch({
      type: SET_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_VEHICLE_CHECKBOX,
      payload: ev.target.checked
    });
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_CHECKBOX,
      payload: ev.target.checked
    });
    dispatch({
      type: SET_FORM_GUN_CHECKBOX,
      payload: ev.target.checked
    });
    dispatch({
      type: SET_NCIC_FORM_GUN_CHECKBOX,
      payload: ev.target.checked
    });
  };
};

export const handleGunFilltersAutoFill = values => {
  return dispatch => {
    dispatch({
      type: SET_FORM_GUN_FILTERS,
      payload: values
    });
  };
};
export const clearGunForm = () => {
  return async dispatch => {
    dispatch({
      type: SET_FORM_GUN_FILTERS,
      payload: {}
    });
    dispatch({
      type: SET_FORM_GUN_PICKERS,
      payload: {}
    });
  };
};

export const clearResults = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_RESULTS,
      payload: null
    });
    dispatch({ type: SET_NCIC_RESPONSE_GUNS_QUERY_ID, payload: null });
    dispatch({
      type: SET_NCIC_FINISHED_RESPONSES,
      payload: 0
    });
    dispatch({
      type: SET_NCIC_PENDING_RESPONSES,
      payload: 0
    });
  };
};

const cleanFilters = obj => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
};

export default function reducer(
  state = {
    // Gun
    guns: {
      rows: [],
      rawData: '',
      count: 5000,
      pageNo: 0,
      search: '',
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      loading: false,
      loaded: false,
      error: false
    },
    gunForm: {
      filters: {},
      pickers: {},
      ncic: false
    },
    gunDetails: {
      data: null,
      ptsFirearmID: null,
      isShowing: false
    }
  },
  action
) {
  switch (action.type) {
    // Gun
    case LOADING_GUNS:
      return {
        ...state,
        guns: {
          ...state.guns,
          loading: action.payload,
          loaded: action.payload ? false : state.guns.loaded,
          error: false
        }
      };
    case LOADING_GUNS_ERROR:
      return {
        ...state,
        guns: {
          ...state.guns,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_GUNS:
      return {
        ...state,
        guns: {
          rows: action.payload.guns.data,
          rawData: action.payload.rawData ? action.payload.rawData : '',
          ...action.payload.params,
          count: action.payload.guns.count,
          loaded: true,
          error: false
        }
      };
    case FIND_GUN:
      return {
        ...state,
        gunDetails: { ...state.gunDetails, data: action.payload }
      };
    case OPEN_DETAILS:
      return {
        ...state,
        gunDetails: { ...state.gunDetails, isShowing: true }
      };
    case CLOSE_DETAILS:
      return {
        ...state,
        gunDetails: { ...state.gunDetails, isShowing: false }
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        guns: {
          rows: [],
          rawData: '',
          count: 5000,
          pageNo: 0,
          search: '',
          rowsPerPage: tableConfig.rowsPerPage,
          orderBy: tableConfig.orderBy,
          orderDirection: tableConfig.orderDirection,
          loading: false,
          loaded: false,
          error: false
        }
      };
    case SET_FORM_GUN_PICKERS:
      return {
        ...state,
        gunForm: {
          ...state.gunForm,
          pickers: action.payload
        }
      };
    case SET_FORM_GUN_FILTERS:
      return {
        ...state,
        gunForm: {
          ...state.gunForm,
          filters: action.payload
        }
      };
    case SET_FORM_GUN_CHECKBOX:
      return {
        ...state,
        gunForm: {
          ...state.gunForm,
          ncic: action.payload
        }
      };
    default:
      break;
  }
  return state;
}
