import { toast } from 'react-toastify';

export const SNACKBAR_ENQUEUE = 'SNACKBAR/SNACKBAR_ENQUEUE';
export const SNACKBAR_CLOSE = 'SNACKBAR/SNACKBAR_CLOSE';
export const SNACKBAR_REMOVE = 'SNACKBAR/SNACKBAR_REMOVE';

/** Shows snackbar notification, availabe options: info (default), success, error and warning,  */
export const notify = (
  message,
  variant = 'info',
  width = 400,
  className = '',
  autoClose = 10000
) => {
  toast[variant](message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose,
    delay: 0,
    style: { maxWidth: width },
    hideProgressBar: true,
    theme: 'colored',

    className: 'small-toast'
  });
};

export const closeSnackbar = key => {
  return dispatch => {
    dispatch({
      type: SNACKBAR_CLOSE,
      dismissAll: !key, // dismiss all if no key has been defined
      key
    });
  };
};

export const removeSnackbar = key => {
  return dispatch => {
    dispatch({
      type: SNACKBAR_REMOVE,
      key
    });
  };
};

export default function reducer(
  state = {
    // Notifier
    notifications: []
  },
  action
) {
  switch (action.type) {
    // Notifier
    case SNACKBAR_ENQUEUE:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };
    case SNACKBAR_CLOSE:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };
    case SNACKBAR_REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };
    default:
      return state;
  }
}
