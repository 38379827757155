import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { checkForUpdates, getApiVer } from 'reducers/AppInfoReducer';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles()(theme => ({
  wrap: {
    padding: 20
  },
  table: {
    '& td': {
      padding: '2px 10px'
    }
  },
  errors: {
    '& button': {
      marginRight: 8
    }
  }
}));

function PageDebugInfo(props) {
  const { classes } = useStyles();
  const {
    apiVersion,
    compilationNo,

    availBuildNo,
    appVersion,
    availBuildTime,
    buildNo,
    buildTime
  } = props.appInfo;

  useEffect(() => {
    props.getApiVer();

    props.checkForUpdates();
    // eslint-disable-next-line
  }, [props.websocket]);

  const crash2 = () => {
    console.log(window.a.notExisting2);
  };

  const sendError1 = () => {
    console.log('Sending error 1 - should be visible in Sentry');
    try {
      console.log(window.b.SendError1);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  const captureMessage = ev => {
    Sentry.captureMessage('This is a message we want to pass', function(
      err,
      eventId
    ) {
      console.log('Capture event data');
    });
  };

  const sendMessage = () => {
    Sentry.captureException('This is some message passed instead of error');
  };

  const sendObject = () => {
    // this will be visible in additional data
    Sentry.captureException({ someKey: 'some value' });
  };

  const sendErrorWithContext = () => {
    // not really sending the error
    console.log('Sending error with context');
    console.log({ aaa: 'bbb' });
    Sentry.setContext('Context name', {
      Context: 'values',
      ApiVer: '1.3.15',
      CadApiVer: '1.44',
      CAD_ver: '1.35',
      'Some other': '44.67'
    });
    try {
      console.log(window.b.ErrWithContext);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  const sendErrorWithContext2 = () => {
    console.log('Sending error with context 2');
    Sentry.setTag('page_locale', 'de-at');
    Sentry.setUser({
      email: 'greg@gmail.com',
      id: 32432,
      username: 'Greg',
      is_address: '194.34.54.54'
    });
    Sentry.setExtra({
      aaa: 'bbbX',
      ccc: {
        ooo: {
          kkk: 'kkkX'
        }
      }
    });
    Sentry.addBreadcrumb({
      type: 'default', // default, debut, error, navigation, query, info ...
      category: 'auth',
      message: 'Authenticated user name@gmail.com',
      level: 'info',
      timestamp: 12345678
    });
    Sentry.captureException(new Error('Error Message'), {
      tags: {
        // Tags at the top of the page
        selection: 'articles'
      },
      level: 'info', // error, warning, info, debug
      environment: 'stage'
      // extra: { // Additional data
      //   aaa: 'bbb',
      //   ccc: {
      //     ooo: {
      //       kkk: 'kkk'
      //     }
      //   }
      // },
      // user: {
      //   email: 'greg@gmail.com',
      //   id: 32432,
      //   username: 'Greg',
      //   is_address: '194.34.54.54'
      // },
      // contexts: { // none
      //   rrr: 'kkk'
      // },
      // level: 'notification',
      // fingerprint: 'asdf'
    });
  };

  return (
    <div className={classes.wrap}>
      <h3>Debug info</h3>
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>App version:</th>
            <td>{appVersion}</td>
          </tr>
          <tr>
            <th>API version:</th>
            <td>{apiVersion}</td>
          </tr>
          <tr>
            <th>API CAD module version:</th>
            <td>{}</td>
          </tr>
          <tr>
            <th>Compilation no:</th>
            <td>{compilationNo}</td>
          </tr>
          <tr>
            <th>Build no:</th>
            <td>{buildNo}</td>
          </tr>
          <tr>
            <th>Available build no:</th>
            <td>{availBuildNo}</td>
          </tr>
          <tr>
            <th>Build time:</th>
            <td>{buildTime}</td>
          </tr>
          <tr>
            <th>Available build time:</th>
            <td>{availBuildTime}</td>
          </tr>
        </tbody>
      </table>
      <div className={classes.errors}>
        <Button
          color="primary"
          variant="outlined"
          onClick={crash2}
          arialabel="Aria Crash 2">
          Crash 2
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={sendError1}
          arialabel="Aria Error 1">
          Send error 1
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={captureMessage}
          arialabel="Aria">
          captureMessage
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={sendMessage}
          arialabel="">
          sendMessage
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={sendObject}
          arialabel="">
          sendObject
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={sendErrorWithContext}
          arialabel="">
          send context with error
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={sendErrorWithContext2}
          arialabel="">
          Err with context 2
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    appInfo: state.appInfo,
    websocket: state.websocket
  };
};

export default connect(mapStateToProps, {
  checkForUpdates,

  getApiVer
})(PageDebugInfo);
