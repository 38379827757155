import React, { Suspense, Fragment, useState, useEffect } from 'react';
import store from './config/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, Routes, Route } from 'react-router-dom';
import Spinner from './global-components/Spinner';
import { AnimatePresence, motion } from 'framer-motion';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
// import Notifications from 'react-notification-system-redux';
import { LicenseInfo } from '@mui/x-license-pro';
import { toast, ToastContainer } from 'react-toastify';

import * as serviceWorker from './serviceWorker';

import AddToHomescreen from 'react-add-to-homescreen';

import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';

import { setWebsocketConnection } from './reducers/WebsocketReducer';
import {
  setUserAuthenticated,
  setUserData,
  newUserLocation,
  setIsPasswordValid
} from './reducers/UserReducer';
import { getServerTimeSettings } from './reducers/TimeReducer';

import {
  clearFilter as clearAlertFilters,
  getAlerts
} from './reducers/AlertsReducer';
import {
  getDispositions,
  getAgencies,
  getDivision,
  getZones,
  getUnitActionCodes,
  getAddresses
} from './reducers/DataReducer';
import {
  getCodeColors,
  getCodeCallTypes,
  getCodeAgencies
} from './reducers/CodeReducer';
import { getEvents } from './reducers/EventsReducer';
import { getUnits } from './reducers/UnitsReducer';

// import { ThemeProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import IdleTimerContainer from 'components/IdleTimerContainer';

import { ClimbingBoxLoader } from 'react-spinners';

import { DayTheme, NightTheme } from './theme';

// Layout Blueprints
import { MinimalLayout, MainLayout, PagesLayout } from './layout-blueprints';
import { checkPassExpiration } from 'reducers/UserReducer';

// Pages
import PagesMain from './pages/PagesMain';
import PagesLogin from './pages/PagesLogin';
import PagesSearch from './pages/PagesSearch';
import PagesCitation from './pages/PagesCitation';
import PagesWarning from './pages/PagesWarning';
import PagesAlert from './pages/PagesAlert';
import ResetPassword from 'pages/ResetPassword';
import ForcePasswordReset from 'pages/ForcePasswordReset';
import Loading from 'pages/Loading';
import PageDebugInfo from './pages/PageDebugInfo';

import { getTimeFormatted } from 'reducers/SettingsReducer';
import {
  subscribePermissions,
  unsubscribePermissions,
  getUserPermissions
} from 'reducers/PermissionsReducer';
import { setEnvInfo } from 'reducers/AppInfoReducer';
import { Button } from '@mui/material';
const ProtectedRoute = ({ isAuthenticated, children }) => {
  const myUnit = store.store.getState().user.userUnit;
  const location = useLocation();
  const isUnitSeleteted = store.store.getState().user.isUnitSeleteted;
  if (isAuthenticated === true && myUnit && isUnitSeleteted) {
    return children;
  } else {
    return (
      <Navigate
        to={{
          pathname: '/Login',
          state: { from: location }
        }}
      />
    );
  }
};

const LoginRoute = ({
  setIsPasswordValid: setIsPasswordValid,
  component: Component,
  isAuthenticated,
  isWarningAccepted,
  ...rest
}) => {
  const location = useLocation();
  if (
    isAuthenticated === true &&
    store.store.getState().user.userUnit &&
    store.store.getState().user.isUnitSeleteted
  ) {
    return (
      <Navigate
        to={{
          pathname: '/Main',
          state: { from: location }
        }}
      />
    );
  } else if (
    isAuthenticated === true &&
    !store.store.getState().user.userUnit
  ) {
    setIsPasswordValid(false);
    return <Component {...rest} />;
  } else if (isWarningAccepted) {
    return <Component {...rest} />;
  } else {
    return (
      <Navigate
        to={{
          pathname: '/Warning',
          state: { from: location }
        }}
      />
    );
  }
};

const WarningRoute = ({
  component: Component,
  isAuthenticated,
  isWarningAccepted,
  ...rest
}) => {
  // console.log('props', props);
  const location = useLocation();
  if (isAuthenticated === true) {
    return (
      <Navigate
        to={{
          pathname: '/Main',
          state: { from: location }
        }}
      />
    );
  } else if (!isWarningAccepted) {
    return <Component />;
  } else {
    return (
      <Navigate
        to={{
          pathname: '/Login',
          state: { from: location }
        }}
      />
    );
  }
};

const AllRoutes = (props) => {
  const location = useLocation();
  const {
    isWarningAccepted,
    isAuthenticated,
    setWebsocketConnection,
    setUserAuthenticated,
    setUserData,
    setLoggedInUserData,
    // notifications,
    newUserLocation,
    clearAlertFilters,
    setIsPasswordValid,
    passExpirationDays,
    showPassChange,
    wsClient,
    getDispositions,
    getAgencies,
    getDivision,
    getZones,
    getUnitActionCodes,
    getAddresses,
    getAlerts,
    getCodeColors,
    getCodeCallTypes,
    getCodeAgencies,
    getEvents,
    getUnits,
    userUnit,
    isUnitSeleteted
    // user
  } = props;

  const { showSpinner } = props.ui;

  const [getoWatchState, setState] = useState(false);
  const dispatch = useDispatch();

  // console.log('env');
  const envInfo = useSelector(state => state.appInfo.appEnvInfo);
  const { FEATURES_CITATIONS, APP_DEFAULT_STATE, AVL, APP_XGRID_KEY } = envInfo;
  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // window.indexedDB.databases().then(db => {
    //   console.log('db', db);
    // });

    const updateApp = registration => {
      const ncic = window.localStorage.getItem('ncic');
      window.localStorage.clear();
      window.localStorage.setItem('ncic', ncic);
      window.indexedDB.deleteDatabase('localforage');
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event) => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    };
    const UpdateNotificationMessage = (registration) => {
      return (
        <div style={{ textAlign: 'left' }}>
          <p
            style={{
              fontWeight: 'bold',
              color: 'rgb(94, 164, 0)',
              paddingBottom: '2px',
              marginBottom: '0px',
              fontSize: '14px'
            }}>
            Application Update Available!
          </p>
          <p style={{ lineHeight: 'normal' }}>
            New release of CAD MOBILE Application has been released.It is highly
            recomended to launch the new version!!
          </p>
          <button
            style={{
              padding: '6px 20px',
              borderRadius: '2px',
              background: 'rgb(94, 164, 0)',
              border: 'none',
              color: 'rgb(255, 255, 255)',
              fontWeight: 'bold',
              outline: 'none'
            }}
            onClick={() => updateApp(registration)}>
            LUNCH!
          </button>
        </div>
      );
    };
    const openNotification = (registration) => {
      toast(UpdateNotificationMessage(registration), {
        autoClose: false,
        style: {
          maxWidth: '315px',
          backgroundColor: 'rgb(240, 245 ,234)',
          textAlign: 'left !important',
          padding: '4px'
        },
        position: 'top-center'
      });
    };

    serviceWorker.register({
      onSuccess: () => console.log('service worker registered!'),
      onUpdate: (reg) => openNotification(reg)
    });
  }, []);

  React.useEffect(() => {
    if (!wsClient.websocket || !isAuthenticated) return;
    props.checkPassExpiration();
    props.subscribePermissions();
    props.getUserPermissions();
    props.getServerTimeSettings();
    return () => {
      props.unsubscribePermissions();
    };
    // eslint-disable-next-line
  }, [wsClient, isAuthenticated]);

  useEffect(() => {
    dispatch(getTimeFormatted());
  }, [wsClient]);

  useEffect(() => {
    if (isAuthenticated === true) {
      if (AVL) {
        const hasGeolocationSupport = 'geolocation' in navigator;
        const hasWatchPositionSupport =
          'watchPosition' in navigator.geolocation;
        if (hasGeolocationSupport && hasWatchPositionSupport) {
          navigator.geolocation.watchPosition(
            (position) => {
              const location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                altitude: position.coords.altitude,
                accuracy: position.coords.accuracy,
                timestamp: new Date()
              };

              newUserLocation(location);
            },
            (err) => {
              console.log('error', err);
            },
            { enableHighAccuracy: true }
          );

          // if (geoWatch) {
          //   setState(true);
          //   navigator.geolocation.clearWatch(geoWatch);
          // }
        }
      }
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    async function establishConnection() {
      const client = feathers();
      client.configure(
        socketio(
          io(process.env.REACT_APP_API_URL, {
            transports: ['websocket', 'polling'],
            perMessageDeflate: false,
            timeout: 20000,
            requestTimeout: 20000
          })
        )
      );
      getAppBuildInfoData(client);
      client.configure(auth({ storageKey: 'auth' }));
      try {
        const user = await client.reAuthenticate();
        setWebsocketConnection(client);
        setUserData(user);
        setUserAuthenticated(true);
      } catch (error) {
        if (error.code === 401) {
          setUserAuthenticated(false);
          setUserData(null);
          setWebsocketConnection(client);
        } else {
          console.log(error);
        }
        setWebsocketConnection(client);
      }
      client.io.on('connect', async (reason) => {
        // console.log('connect');
        // console.log('connect', reason);
        // console.log('reauthenticate');
        getAppBuildInfoData(client);
        try {
          const user = await client.reAuthenticate();
          setWebsocketConnection(client);
          setUserData(user);
          setUserAuthenticated(true);
        } catch (error) {
          if (error.code === 401) {
            setUserAuthenticated(false);
            setUserData(null);
            setWebsocketConnection(client);
          } else {
            console.log(error);
          }
          setWebsocketConnection(client);
        }
      });
      client.io.on('disconnect', (reason) => {
        //console.log('disconnect');
      });
    }
    establishConnection();
  }, [
    setWebsocketConnection,
    setUserAuthenticated,
    setLoggedInUserData,
    setUserData
  ]);
  React.useEffect(() => {
    if (location.pathname !== '/Alerts') {
      clearAlertFilters();
    }
  }, [clearAlertFilters, location]);
  useEffect(() => {
    if (!userUnit || !userUnit.ptsUnitID) {
      setUserAuthenticated(false);
    }
  }, [userUnit]);

  React.useEffect(() => {
    if (!wsClient.websocket || !isAuthenticated) return;
    getCodeColors();
    getCodeCallTypes();
    getEvents();
    getUnits();
    getAlerts();
    getDispositions();
    getUnitActionCodes();
    getAgencies();
    getDivision();
    getZones();
    getAddresses();
    getCodeAgencies();
    // eslint-disable-next-line
  }, [wsClient, isAuthenticated]);
  React.useEffect(() => {
    if (APP_XGRID_KEY) {
      LicenseInfo.setLicenseKey(APP_XGRID_KEY);
      // console.log(store.store.getState().appinfo);
    }
  }, [APP_XGRID_KEY]);
  const getAppBuildInfoData = async (client) => {
    const service = client.service('cdr');
    service.timeout = 30000;
    try {
      const data = await service.find({
        query: {
          Path: 'MOBILE.env'
        }
        //
      });
      dispatch(setEnvInfo(data));
      // console.log('data', data);
      // console.log('window', window);
    } catch (error) {
      console.log(error);
    }
  };
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
      width: '100%',
      height: '100%'
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#0b55a1'} loading={true} />
          </div>
        </div>
      </Fragment>
    );
  };

  const themeMode = () => {
    let theme = DayTheme;
    if (props.mode === 'night') {
      theme = NightTheme;
    }
    return theme;
  };

  const handleAddToHomescreenClick = () => {
    alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
  };
  console.log('Theme', themeMode());
  return (
    <ThemeProvider theme={themeMode()}>
      {showSpinner && <Spinner />}
      <SnackbarProvider>
        <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
        <Notifier />
        <ToastContainer />
        {/* <Notifications notifications={notifications} /> */}
        <IdleTimerContainer />
        <AnimatePresence>
          <Suspense fallback={<SuspenseLoading />}>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Navigate to="/Warning" />} />
              <Route
                path="/Warning"
                element={
                  <MinimalLayout>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <WarningRoute
                        path="/Warning"
                        component={PagesWarning}
                        isAuthenticated={isAuthenticated}
                        isWarningAccepted={isWarningAccepted}
                      />
                    </motion.div>
                  </MinimalLayout>
                }
              />

              <Route
                path="/Login"
                element={
                  <MinimalLayout>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <LoginRoute
                        path="/Login"
                        component={PagesLogin}
                        isAuthenticated={isAuthenticated}
                        isWarningAccepted={isWarningAccepted}
                        setIsPasswordValid={setIsPasswordValid}
                      />
                    </motion.div>
                  </MinimalLayout>
                }
              />

              <Route
                path="/Reset-password/:hash"
                element={
                  <MinimalLayout>
                    <ResetPassword isAuthenticated={isAuthenticated} />
                  </MinimalLayout>
                }
              />

              {showPassChange && (
                <Route
                  path="/"
                  element={
                    <MinimalLayout>
                      <ForcePasswordReset
                        passExpirationDays={passExpirationDays}
                      />
                    </MinimalLayout>
                  }
                />
              )}
              {passExpirationDays === null && (
                <Route
                  path="/"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <MinimalLayout>
                        <Loading />
                      </MinimalLayout>
                    </ProtectedRoute>
                  }
                />
              )}
              <Route
                path="/debug-info"
                element={
                  <MinimalLayout>
                    <PageDebugInfo
                      component={Loading}
                      isAuthenticated={isAuthenticated}
                    />
                  </MinimalLayout>
                }
              />
              <Route
                path="/Main"
                element={
                  <ProtectedRoute
                    path="/Main"
                    isAuthenticated={isAuthenticated}>
                    <MainLayout>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <PagesMain />
                      </motion.div>
                    </MainLayout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/Search"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <PagesLayout>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <PagesSearch />
                      </motion.div>
                    </PagesLayout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/Alerts"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <PagesLayout>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <PagesAlert />
                      </motion.div>
                    </PagesLayout>
                  </ProtectedRoute>
                }
              />
              {FEATURES_CITATIONS && FEATURES_CITATIONS == true && (
                <Route
                  path="/Ecitations/:type"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <PagesLayout>
                        <motion.div
                          initial="initial"
                          animate="in"
                          exit="out"
                          variants={pageVariants}
                          transition={pageTransition}>
                          <PagesCitation />
                        </motion.div>
                      </PagesLayout>
                    </ProtectedRoute>
                  }
                />
              )}
            </Routes>
          </Suspense>
        </AnimatePresence>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  network: state.offline,
  mode: state.theme.mode,
  // notifications: state.notifications,
  isAuthenticated: state.user.isAuthenticated,
  userUnit: state.user.userUnit,
  isWarningAccepted: state.user.isWarningAccepted,
  passExpirationDays: state.user.passExpirationDays,
  showPassChange: state.user.showPassChange,
  isUnitSeleteted: state.user.isUnitSeleteted,
  ui: state.ui,
  wsClient: state.websocket
});

export default connect(mapStateToProps, {
  setWebsocketConnection,
  setUserAuthenticated,
  setUserData,
  newUserLocation,
  clearAlertFilters,
  setIsPasswordValid,
  checkPassExpiration,
  subscribePermissions,
  unsubscribePermissions,
  getUserPermissions,
  getServerTimeSettings,
  getDispositions,
  getAgencies,
  getDivision,
  getZones,
  getUnitActionCodes,
  getAddresses,
  getAlerts,
  getCodeColors,
  getCodeCallTypes,
  getCodeAgencies,
  getEvents,
  getUnits
})(AllRoutes);
