import { createTheme } from '@mui/material';

import typography from './typography';

import vars from '../assets/core/_variables-mui.scss';
console.log('vars');
export const DayTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: vars.primary
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2
    },
    secondary: {
      main: vars.secondary
    },
    error: {
      main: vars.red
    },
    success: {
      main: vars.green
    },
    warning: {
      main: vars.orange
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)'
    },
    AlertButton: {
      main: 'rgb(235, 173, 26)'
    },
    bg: vars.bgLight,
    bgElements: vars.bgElementsLight,
    colorFont: '#000',
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  shape: {
    borderRadius: '0.5rem'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem !important',
          lineHeight: 1.43,
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px',
          color: '#000'
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px'
        },
        arrow: {
          color: vars.second
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            // works
            backgroundColor: '#fff',
            filter: 'brightness(87%)'
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            // works
            backgroundColor: '#fff',
            filter: 'brightness(93%)'
          }
        }
      }
    }
  },

  typography,
  map: {
    labelColor: '#000',
    labelBorder: '#fff',
    iconColor: '#242f3e',
    avatarBg: '#dedfe3'
  }
});

export const NightTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: vars.primary
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2
    },
    secondary: {
      main: vars.secondary
    },
    error: {
      main: vars.red
    },
    success: {
      main: vars.green
    },
    warning: {
      main: vars.orange
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)'
    },
    AlertButton: {
      main: 'rgb(135, 173, 26)'
    },
    bg: vars.bgDark,
    bgElements: vars.bgElementsDark,
    colorFont: '#fff',
    contrastThreshold: 3,
    tonalOffset: 0.1
  },

  shape: {
    borderRadius: '0.5rem'
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem !important',
          lineHeight: 1.43,
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#424242',
          color: 'fff',
          backgroundImage: 'none !important'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px',
          color: 'white'
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #778eeb !important'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#778eeb !important'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px'
        },
        arrow: {
          color: vars.second
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: '#778eeb !important'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#778eeb !important'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            // works
            backgroundColor: '#424242',
            filter: 'brightness(1.75)'
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            // works
            backgroundColor: '#424242',
            filter: 'brightness(1.25)'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '80px '
        }
      }
    }
  },

  typography,

  map: {
    labelColor: '#fff',
    labelBorder: '#000',
    iconColor: '#242f3e',
    avatarBg: '#5b5b5b'
  }
});
