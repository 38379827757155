import React from 'react';
import { IoIosCar } from 'react-icons/io';
import AssistantIcon from '@mui/icons-material/Assistant';
import { makeStyles } from 'tss-react/mui';
import { CalculateLastTimeDif } from 'utils/functions';
import ErrorIcon from '@mui/icons-material/Error';

const avatarSize = 50;
const iconSize = avatarSize / 1.65;
const iconLeft = (avatarSize - iconSize) / 2;
const iconTop = (avatarSize - iconSize) / 2.6;
const borderSize = 2;

const useStyles = makeStyles()(theme => ({
  label: {
    position: 'absolute',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    fontSize: 12,
    top: -9,
    left: '50%',
    color: theme.map.labelColor,
    transform: 'translate(-50%, 0)',
    textShadow: `0 1px 0 ${theme.map.labelBorder},
                 0 -1px 0 ${theme.map.labelBorder},
                 1px 0 0 ${theme.map.labelBorder},
                 -1px 0 0 ${theme.map.labelBorder}`
  },
  marker: {
    position: 'relative',
    cursor: 'pointer'
  },
  iconBorder: {
    position: 'absolute',
    color: theme.map.labelBorder,
    width: avatarSize + borderSize * 2,
    height: avatarSize + borderSize * 2,
    top: -borderSize,
    left: -borderSize
  },
  selected: {
    position: 'absolute',
    color: theme.palette.secondary.main,
    width: avatarSize + borderSize * 4,
    height: avatarSize + borderSize * 4,
    top: -borderSize * 2,
    left: -borderSize * 2
  },
  icon: {
    position: 'absolute',
    width: iconSize,
    height: iconSize,
    top: iconTop,
    left: iconLeft,
    textAlign: 'center',
    fontSize: iconSize,
    fontWeight: 600,
    '& > span': {
      position: 'absolute',
      inset: 0,
      display: 'block'
    },
    '& > div': {
      color: '#000',
      position: 'absolute',
      inset: 0,
      borderRadius: '15%',
      background: 'rgba(255, 255, 255, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}));

function UnitMarker(props) {
  const { classes } = useStyles();
  const { color, unit, selected, onUnitClick = () => {} } = props;

  return (
    <div onClick={ev => onUnitClick(ev, unit)}>
      <div className={classes.label}>{unit.Unit}</div>
      <div className={classes.marker}>
        <AssistantIcon
          className={selected ? classes.selected : classes.iconBorder}
        />
        <AssistantIcon
          style={{ color, fontSize: avatarSize, position: 'relative' }}
        />
        <div className={classes.icon}>
          <span style={{ backgroundColor: color }} />
          {CalculateLastTimeDif(unit.LastChangedLocationTime, unit.Flags) && (
            <div
              style={{
                position: 'absolute',
                top: '3px',
                right: '27px',
                zIndex: '2'
              }}>
              <ErrorIcon color="error" />
            </div>
          )}
          <div>
            <IoIosCar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnitMarker;
