import React, { useState, useRef, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Grid, Badge, Card, Button, Box, Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
    padding: '10px 10px'
  },
  itemWrap: {
    fontWeight: 500,
    fontSize: '0.95rem'
  },
  itemLabel: {
    fontWeight: 200
  },
  customBadge: {
    'MuiBadge-badge': {
      width: '200px'
    }
  }
}));

function Info(props) {
  const { data, themeMode } = props;
  const { classes } = useStyles();

  data.ncicData = data.NcicResponseData
    ? JSON.parse(data.NcicResponseData)
    : null;

  // console.log('data', data);
  let displayType = 'local';
  const [details, setDetails] = useState(false);

  if (data.ptsFirearmID === null && data.ncicData !== null)
    displayType = 'ncic';
  if (data.ptsFirearmID !== null && data.ncicData && data.ncicData !== null)
    displayType = 'combined';

  // Universal Gun data
  let SerialNumber =
    displayType === 'ncic'
      ? data.ncicData.data.GunSerialNumber
      : data.GunSerialNumber || data.SerialNumber;
  let MakeModel =
    displayType === 'ncic'
      ? data.ncicData.data.GunMake
      : `${data.MakeDesc || data.MakeCode || ''} ${
          data.ModelDesc || data.ModelCode || ''
        }`;

  // Gun Items
  const gunItems = [];

  const Badges = (props) => {
    function NcicBadge(props) {
      if (props.warnings && props.warnings !== null) {
        return props.warnings.map((item, id) => {
          if (item.Banner) {
            return (
              <div key={id} style={{ marginBottom: '10px' }}>
                <Badge
                  overlap="rectangular"
                  className="customBagde2"
                  badgeContent={item.Banner.toUpperCase()}
                  color="error"></Badge>
              </div>
            );
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    }

    return (
      <Fragment>
        <NcicBadge warnings={props.warnings} />
      </Fragment>
    );
  };

  function RenderLocalCard() {
    if (displayType === 'local' || displayType === 'combined') {
      // Gun data
      let MakeCode = data.MakeCode !== null ? data.MakeCode : 'Unknown';
      let ModelCode = data.ModelCode !== null ? data.ModelCode : 'Unknown';
      let CaliberCode =
        data.CaliberCode !== null ? data.CaliberCode : 'Unknown';
      let CategoryCode =
        data.FirearmCategoryCode !== null
          ? data.FirearmCategoryCode
          : 'Unknown';
      let IsAutomatic = data.IsAutomatic === true ? 'Yes' : 'No';
      let Description = data.Description !== null ? data.Description : '';

      return (
        <Card className="mb-4 p-4">
          <div className="card-header-alt d-flex justify-content-between">
            <div>
              <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                {MakeModel}
              </h6>
              <p className="text-black-50 mb-0">{SerialNumber}</p>
              <div style={{ marginLeft: '115px', marginBottom: '10px' }}>
                <Badges warnings={data?.ncicData?.warnings || ''} />
              </div>
            </div>
            <Box className="d-flex align-items-center">
              <Avatar alt="Avatar">L</Avatar>
            </Box>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div
            style={{ justifyContent: 'space-between' }}
            className="d-block d-md-flex align-items-center my-2">
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Make</span>
              <div className="m-1">{MakeCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Model</span>
              <div className="m-1">{ModelCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Caliber</span>
              <div className="m-1">{CaliberCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Category</span>
              <div className="m-1">{CategoryCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">IsAutomatic</span>
              <div className="m-1">{IsAutomatic}</div>
            </span>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div className="d-block d-md-flex align-items-center my-2">
            {Description}
          </div>
        </Card>
      );
    } else {
      return null;
    }
  }

  function RenderNcicCard() {
    if (displayType === 'ncic' || displayType === 'combined') {
      // Gun data
      let MakeCode =
        data.ncicData.data && data.ncicData.data.GunMake !== null
          ? data.ncicData.data.GunMake
          : 'Unknown';
      let ModelCode =
        data.ncicData.data && data.ncicData.data.GunModel !== null
          ? data.ncicData.data.GunModel
          : 'Unknown';
      let CaliberCode =
        data.ncicData.data && data.ncicData.data.GunCaliber !== null
          ? data.ncicData.data.GunCaliber
          : 'Unknown';
      let CategoryCode =
        data.ncicData.data && data.ncicData.data.GunTypeCode !== null
          ? data.ncicData.data.GunTypeCode
          : 'Unknown';
      let IsAutomatic =
        data.ncicData.data && data.ncicData.data.IsAutomatic === true
          ? 'Yes'
          : 'No';
      let Description =
        data.ncicData.data && data.ncicData.data.Description !== null
          ? data.ncicData.data.Description
          : '';

      return (
        <Card className="mb-4 p-4">
          <div className="card-header-alt d-flex justify-content-between">
            <div>
              <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                {MakeModel}
              </h6>
              <p className="text-black-50 mb-0">{SerialNumber}</p>
              <div style={{ marginLeft: '115px', marginBottom: '10px' }}>
                <Badges warnings={data?.ncicData?.warnings} />
              </div>
            </div>
            <Box className="d-flex align-items-center">
              <Avatar alt="Avatar">N</Avatar>
            </Box>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div
            style={{ justifyContent: 'space-between' }}
            className="d-block d-md-flex align-items-center my-2">
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Make</span>
              <div className="m-1">{MakeCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Model</span>
              <div className="m-1">{ModelCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Caliber</span>
              <div className="m-1">{CaliberCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">Category</span>
              <div className="m-1">{CategoryCode}</div>
            </span>
            <span className="align-items-center">
              <span className="m-1 badge badge-secondary">IsAutomatic</span>
              <div className="m-1">{IsAutomatic}</div>
            </span>
          </div>
          <div
            className="divider"
            style={{ marginLeft: '0', marginRight: '0' }}
          />
          <div className="d-block d-md-flex align-items-center my-2">
            {Description}
          </div>
        </Card>
      );
    } else {
      return null;
    }
  }

  function RenderGunItem(value, idx) {
    const handleCardClick = (e, card) => {
      setDetails(card);
    };

    if (value.data !== null) {
      if (details === false) {
        return (
          <Grid item xs={12} md={6} key={idx}>
            <Card
              className="card card-box card-box-hover-rise card-box-hover align-box-row align-items-start p-2"
              onClick={(e) => handleCardClick(e, value)}>
              <div>
                <div className="display-3">{value.data.length}</div>
                <div className="divider mt-2 mb-3 border-2 w-25 rounded" />
                <div className="font-weight-bold font-size-sm text-uppercase">
                  {value.label}
                </div>
              </div>
              <div className="ml-auto card-hover-indicator align-self-center">
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  className="font-size-xl"
                />
              </div>
            </Card>
          </Grid>
        );
      } else {
        return null;
      }
    }
  }

  function cardClose() {
    setDetails(false);
  }

  return (
    <Grid
      container
      className={classes.root}
      spacing={2}
      sx={{ marginTop: '0px !important' }}>
      <Grid item xs={12} sm={12}>
        {RenderLocalCard()}
        {RenderNcicCard()}
        <Grid container className={classes.root} spacing={2}>
          {gunItems.map((data, idx) => {
            return RenderGunItem(data, idx);
          })}
        </Grid>
        {details !== false && <Fragment></Fragment>}
      </Grid>
    </Grid>
  );
}

export default Info;
