
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8privacypolicy100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M7 3v46h27.96c-2.5-2.32-5.41-6.17-6.5-12H15v-2h13.16c-.1-.95-.16-1.95-.16-3v-1H15v-2h15.57c2.42-.54 3.71-1.13 4.84-1.69C36.69 26.67 38.03 26 40 26c1.19 0 2.15.24 3 .58V15.6L30.4 3H7zm23 2.4L40.6 16H30V5.4zM15 23h20v2H15v-2zm25 5c-1.5 0-2.5.5-3.7 1.104-1.2.603-2.7 1.307-5.5 1.91l-.8.2v.804c0 12.859 9.2 17.58 9.5 17.78L40 50l.5-.2c.4-.2 9.5-5.323 9.5-17.78v-.805l-.8-.201c-2.8-.603-4.3-1.306-5.5-1.909C42.5 28.502 41.5 28 40 28z"/></SvgIcon>
      );
    }
  
    export default icons8privacypolicy100Icon;