import store from '../config/configureStore';

import { getUnits, processUnits, updateUnits } from './UnitsReducer';
import { getEvents, processEvents, updateEvents } from './EventsReducer';
import zhCN from 'date-fns/esm/locale/zh-CN/index';
import { SET_ZONES_EVENTS, SET_ZONES_UNITS } from './DataReducer';

// FILTERS
export const SET_HELP_BAR = 'USER/SET_HELP_BAR';

const IS_OPEN = 'SETTINGS/IS_DIALOG_OPEN';
const AGENCY_FILTER = 'SETTINGS/AGENCY_FILTER';
const AGENCY_FILTER_UNIT = 'SETTINGS/AGENCY_FILTER_UNIT';
const AGENCY_TYPE_FILTER = 'SETTINGS/AGENCY_TYPE_FILTER';
const SET_TIME_FORMAT = 'SETTINGS/SET_TIME_FORMAT';

const DIVISION_FILTER = 'SETTINGS/DIVISION_FILTER';
const DIVISION_FILTER_UNIT = 'SETTINGS/DIVISION_FILTER_UNIT';
const ZONES_FILTER = 'SETTINGS/ZONES_FILTER';
const ZONES_FILTER_UNIT = 'SETTINGS/ZONES_FILTER_UNIT';

export const SET_AGENCY_BASED_UNIT_SETTING =
  'SETTINGS/SET_AGENCY_BASED_UNIT_SETTING';
//Alert Tone
export const SET_ALERT_TONE = 'SETTINGS/SET_ALERT_TONE';
export const SET_SEARCH_VIEW = 'SETTINGS/SET_SEARCH_VIEW';
// MAP
export const SET_MAP_DEFAULT_VIEW = 'SETTINGS/SET_MAP_DEFAULT_VIEW';

// VIEW TYPE
export const SET_VIEW_TYPE = 'SETTINGS/SET_VIEW_TYPE';
export const SET_QUICK_SEARCH_TYPE = 'SETTINGS/SET_QUICK_SEARCH_TYPE';
export const SET_MY_EVENTS = 'SETTINGS/SET_MY_EVENTS';
export const toggleDialog = () => {
  const _open = store.store.getState().settings.isOpen;
  return dispatch => {
    dispatch({ type: IS_OPEN, payload: !_open });
  };
};

export const setAgencyFilterSelect = value => {
  const agencies = store.store.getState().data.agencies;
  const f = store.store.getState().settings.agencies;
  let filter;
  if (f === null) {
    filter = [];
    agencies.forEach(agency => {
      filter.push(agency);
    });
  } else {
    filter = [...store.store.getState().settings.agencies];
  }
  return dispatch => {
    filter = value;
    dispatch({ type: AGENCY_FILTER, payload: filter });
    dispatch(getUnits());
    dispatch(getEvents());
  };
};
export const setAgencyUnitFilterSelect = value => {
  const agencies = store.store.getState().data.agencies;
  const f = store.store.getState().settings.agenciesUnit;
  let filter;
  if (f === null) {
    filter = [];
    agencies.forEach(agency => {
      filter.push(agency);
    });
  } else {
    filter = [...store.store.getState().settings.agenciesUnit];
  }
  return dispatch => {
    filter = value;
    dispatch({ type: AGENCY_FILTER_UNIT, payload: filter });
    dispatch(getUnits());
    dispatch(getEvents());
  };
};

export const setAgencyFilter = (event, agency, isChecked) => {
  const agencies = store.store.getState().data.agencies;
  const f = store.store.getState().settings.agencies;

  let filter;
  return async dispatch => {
    if (f === null) {
      filter = [];
      agencies.forEach(agency => {
        filter.push(agency);
      });
    } else {
      filter = [...store.store.getState().settings.agencies];
    }
    if (filter.length === 0) {
      filter.push(agency);
      dispatch({ type: AGENCY_FILTER, payload: filter });
      // dispatch(getUnits());
      dispatch(filterZonesEventBasedOnAgencies(filter));
      dispatch(getEvents());
    } else {
      const isExist = filter.findIndex(f => {
        return f.AgencyID === agency.AgencyID;
      });
      if (isExist === -1) {
        filter.push(agency);
        dispatch({ type: AGENCY_FILTER, payload: filter });
        // dispatch(getUnits());
        dispatch(filterZonesEventBasedOnAgencies(filter));
        dispatch(getEvents());
      } else {
        filter.splice(isExist, 1);
        dispatch({ type: AGENCY_FILTER, payload: filter });
        // dispatch(getUnits());
        dispatch(filterZonesEventBasedOnAgencies(filter));
        dispatch(getEvents());
      }
    }
  };
};
export const setAgencyEventFilterInitial = agencies => {
  const events = store.store.getState().events.events;
  return async dispatch => {
    dispatch({ type: AGENCY_FILTER, payload: agencies });
    // dispatch(getUnits());
    dispatch(filterZonesEventBasedOnAgencies(agencies));
    if (events.length > 0) {
      // console.log('evets q', events);
      dispatch(processEvents(events));
    }

    dispatch(getEvents());
  };
};
export const setAgencyUnitFilter = (event, agency, isChecked) => {
  const agencies = store.store.getState().data.agencies;

  const f = store.store.getState().settings.agenciesUnit;

  let filter;
  return async dispatch => {
    if (f === null) {
      filter = [];
      agencies.forEach(agency => {
        filter.push(agency);
      });
    } else {
      filter = [...store.store.getState().settings.agenciesUnit];
    }
    if (filter.length === 0) {
      filter.push(agency);
      dispatch({ type: AGENCY_FILTER_UNIT, payload: filter });
      dispatch(filterZonesUnitBasedOnAgencies(filter));
      dispatch(getUnits());
    } else {
      const isExist = filter.findIndex(f => {
        return f.AgencyID === agency.AgencyID;
      });
      if (isExist === -1) {
        filter.push(agency);
        dispatch({ type: AGENCY_FILTER_UNIT, payload: filter });
        dispatch(filterZonesUnitBasedOnAgencies(filter));
        dispatch(getUnits());
      } else {
        filter.splice(isExist, 1);
        dispatch({ type: AGENCY_FILTER_UNIT, payload: filter });
        dispatch(filterZonesUnitBasedOnAgencies(filter));
        dispatch(getUnits());
      }
    }
  };
};
export const setAgencyUnitFilterInitial = agencies => {
  const units = store.store.getState().units.units;
  return async dispatch => {
    dispatch({ type: AGENCY_FILTER_UNIT, payload: agencies });
    dispatch(filterZonesUnitBasedOnAgencies(agencies));
    if (units.length > 0) {
      dispatch(processUnits(units));
    }
    dispatch(getUnits());
  };
};
const filterZonesUnitBasedOnAgencies = agencies => {
  const zones = store.store.getState().data.zones;
  return async dispatch => {
    const fZone = zones.filter(z => {
      if (agencies.some(ag => ag.AgencyID == z.AgencyID)) {
        return true;
      } else {
        return false;
      }
    });
    // console.log('fZones', fZone);
    dispatch({ type: SET_ZONES_UNITS, payload: fZone });
  };
};
const filterZonesEventBasedOnAgencies = agencies => {
  const zones = store.store.getState().data.zones;
  return async dispatch => {
    const fZone = zones.filter(z => {
      if (agencies.some(ag => ag.AgencyID == z.AgencyID)) {
        return true;
      } else {
        return false;
      }
    });
    // console.log('fZones', fZone);
    dispatch({ type: SET_ZONES_EVENTS, payload: fZone });
  };
};
export const setdivisionUnitFilter = (event, selectedDiv, isChecked) => {
  const divisions = store.store.getState().data.divisions;
  const div = store.store.getState().settings.divisionsUnit;
  let filter;
  return async dispatch => {
    if (div === null) {
      filter = [];
      divisions.forEach(agency => {
        filter.push(agency);
      });
    } else {
      filter = [...div];
    }
    if (filter.length === 0) {
      filter.push(selectedDiv);
      dispatch({ type: DIVISION_FILTER_UNIT, payload: filter });
      dispatch(getUnits());
    } else {
      const isExist = filter.findIndex(f => {
        return f.Code === selectedDiv.Code;
      });
      if (isExist === -1) {
        filter.push(selectedDiv);
        dispatch({ type: DIVISION_FILTER_UNIT, payload: filter });
        dispatch(getUnits());
      } else {
        filter.splice(isExist, 1);
        dispatch({ type: DIVISION_FILTER_UNIT, payload: filter });
        dispatch(getUnits());
      }
    }
  };
};

export const setZoneFilter = (event, selectedZone, isChecked) => {
  const zones = store.store.getState().data.zones;
  const filterZones = store.store.getState().settings.zones;

  let filter;
  return async dispatch => {
    if (filterZones === null) {
      filter = [];
      zones.forEach(z => {
        filter.push(z);
      });
    } else {
      filter = [...filterZones];
    }
    if (filter.length === 0) {
      filter.push(selectedZone);
      dispatch({ type: ZONES_FILTER, payload: filter });
      dispatch(getEvents());
    } else {
      const isExist = filter.findIndex(f => {
        return f.ZoneCode === selectedZone.ZoneCode;
      });
      if (isExist === -1) {
        filter.push(selectedZone);
        dispatch({ type: ZONES_FILTER, payload: filter });
        dispatch(getEvents());
      } else {
        filter.splice(isExist, 1);
        dispatch({ type: ZONES_FILTER, payload: filter });
        dispatch(getEvents());
      }
    }
  };
};

export const setZoneUnitFilter = (event, selectedZone, isChecked) => {
  const zones = store.store.getState().data.zones;
  const filterZones = store.store.getState().settings.zonesUnit;

  let filter;
  return async dispatch => {
    if (filterZones === null) {
      filter = [];
      zones.forEach(z => {
        filter.push(z);
      });
    } else {
      filter = [...filterZones];
    }
    if (filter.length === 0) {
      filter.push(selectedZone);
      dispatch({ type: ZONES_FILTER_UNIT, payload: filter });
      dispatch(getUnits());
    } else {
      const isExist = filter.findIndex(f => {
        return f.ZoneCode === selectedZone.ZoneCode;
      });
      if (isExist === -1) {
        filter.push(selectedZone);
        dispatch({ type: ZONES_FILTER_UNIT, payload: filter });
        dispatch(getUnits());
      } else {
        filter.splice(isExist, 1);

        dispatch({ type: ZONES_FILTER_UNIT, payload: filter });
        dispatch(getUnits());
      }
    }
  };
};
export const setAgencyFilterAll = (filterType, itemType = 'agency') => {
  const agencies = store.store.getState().data.agencies;
  const divisions = store.store.getState().data.divisions;
  const zones = store.store.getState().data.zones;
  let filter;
  const arr =
    itemType === 'agency'
      ? agencies
      : itemType == 'division'
      ? divisions
      : zones;
  return dispatch => {
    filter = [];
    arr.forEach(agency => {
      filter.push(agency);
    });
    if (filterType == 'event') {
      if (itemType == 'agency') {
        dispatch({ type: AGENCY_FILTER, payload: filter });
        dispatch(filterZonesEventBasedOnAgencies(filter));
      } else if (itemType == 'zone') {
        dispatch({ type: ZONES_FILTER, payload: filter });
      }

      dispatch(getEvents());
    } else if (filterType == 'unit') {
      if (itemType == 'agency') {
        dispatch({ type: AGENCY_FILTER_UNIT, payload: filter });
        dispatch(filterZonesUnitBasedOnAgencies(filter));
      } else if (itemType == 'division') {
        dispatch({ type: DIVISION_FILTER_UNIT, payload: filter });
      } else if (itemType == 'zone') {
        dispatch({ type: ZONES_FILTER_UNIT, payload: filter });
      }
      dispatch(getUnits());
    }
  };
};

export const setAgencyFilterNull = (filterType, itemType) => {
  let filter;
  return dispatch => {
    filter = [];
    if (filterType == 'event') {
      if (itemType == 'agency') {
        dispatch({ type: AGENCY_FILTER, payload: filter });
        dispatch(filterZonesEventBasedOnAgencies(filter));
      } else if (itemType == 'zone') {
        dispatch({ type: ZONES_FILTER, payload: filter });
      }

      dispatch(getEvents());
    } else if (filterType == 'unit') {
      if (itemType == 'agency') {
        dispatch({ type: AGENCY_FILTER_UNIT, payload: filter });
        dispatch(filterZonesUnitBasedOnAgencies(filter));
      } else if (itemType == 'division') {
        dispatch({ type: DIVISION_FILTER_UNIT, payload: filter });
      } else if (itemType == 'zone') {
        dispatch({ type: ZONES_FILTER_UNIT, payload: filter });
      }
      dispatch(getUnits());
    }
  };
};

export const setAgencyTypeFilter = value => {
  return dispatch => {
    dispatch({ type: AGENCY_TYPE_FILTER, payload: value });
  };
};
export const setSearchView = value => {
  return dispatch => {
    dispatch({ type: SET_SEARCH_VIEW, payload: value });
  };
};
export const setViewType = value => {
  return dispatch => {
    dispatch({ type: SET_VIEW_TYPE, payload: value });
  };
};
export const setAlertTone = value => {
  return dispatch => {
    dispatch({ type: SET_ALERT_TONE, payload: value });
  };
};
export const setQuickSearchType = value => {
  return dispatch => {
    dispatch({ type: SET_QUICK_SEARCH_TYPE, payload: value });
  };
};
export const setHelpBar = value => {
  return dispatch => {
    dispatch({ type: SET_HELP_BAR, payload: value });
  };
};
export const getTimeFormatted = value => {
  const client = store.store.getState().websocket.websocket;

  return async dispatch => {
    try {
      const service = client.service('time-format');

      service.timeout = 20000;
      const data = await service.get('teste');
      // console.log(data);
      dispatch({ type: SET_TIME_FORMAT, payload: data.ValueBoolean });
    } catch (error) {
      // console.log('error occur', error);
    }
  };
};
export default function reducer(
  state = {
    // Dialog
    isOpen: false,
    // Filters
    agencies: null,
    agenciesUnit: null,
    divisions: null,
    divisionsUnit: null,
    zones: null,
    zonesUnit: null,
    agencyTypes: 7,
    // Map
    mapDefaultView: null,
    // View type
    viewType: 'complex',
    quickSearchType: 'person',
    alertTone: true,
    isFormat24: true,
    helpBar: false,
    searchView: 'regular',
    agencyBasedUnitSetting: false,
    myEvents: []
  },
  action
) {
  switch (action.type) {
    case IS_OPEN:
      return {
        ...state,
        isOpen: action.payload
      };
    case AGENCY_FILTER:
      return {
        ...state,
        agencies: action.payload
      };
    case AGENCY_FILTER_UNIT:
      return {
        ...state,
        agenciesUnit: action.payload
      };
    case DIVISION_FILTER:
      return {
        ...state,
        divisions: action.payload
      };
    case DIVISION_FILTER_UNIT:
      return {
        ...state,
        divisionsUnit: action.payload
      };
    case ZONES_FILTER:
      return {
        ...state,
        zones: action.payload
      };
    case ZONES_FILTER_UNIT:
      return {
        ...state,
        zonesUnit: action.payload
      };
    case SET_HELP_BAR:
      return { ...state, helpBar: action.payload };
    case SET_TIME_FORMAT:
      return {
        ...state,
        isFormat24: action.payload
      };

    case AGENCY_TYPE_FILTER:
      return {
        ...state,
        agencyTypes: action.payload
      };
    // Map
    case SET_MAP_DEFAULT_VIEW:
      return {
        ...state,
        mapDefaultView: action.payload
      };
    case SET_SEARCH_VIEW:
      return {
        ...state,
        searchView: action.payload
      };
    // View type
    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload
      };
    case SET_QUICK_SEARCH_TYPE:
      return {
        ...state,
        quickSearchType: action.payload
      };
    case SET_ALERT_TONE:
      return {
        ...state,
        alertTone: action.payload
      };
    case SET_AGENCY_BASED_UNIT_SETTING:
      return {
        ...state,
        agencyBasedUnitSetting: action.payload
      };
    case SET_MY_EVENTS:
      return {
        ...state,
        myEvents: action.payload
      };
    default:
      break;
  }
  return state;
}
