import { normalize, schema } from 'normalizr';
import _ from 'underscore';
import store from '../config/configureStore';
import { setUserAuthenticated, setUserData } from './UserReducer';
export const SET_DIVISIONS = 'DATA/SET_DIVISIONS';
export const SET_ZONES = 'DATA/SET_ZONES';
export const SET_ZONES_EVENTS = 'DATA/SET_ZONES_EVENTS';
export const SET_ZONES_UNITS = 'DATA/SET_ZONES_UNITS';
export const SET_DISPOSITIONS = 'DATA/SET_DISPOSITIONS';
export const SET_AGENCIES = 'DATA/SET_AGENCIES';
export const SET_UNIT_ACTION_CODES = 'DATA/SET_UNIT_ACTION_CODES';
export const SET_NORMALIZED_UNIT_ACTION_CODES =
  'DATA/SET_NORMALIZED_UNIT_ACTION_CODES';
export const SET_NORMALIZED_CATEGORY_UNIT_ACTION_CODES =
  'DATA/SET_NORMALIZED_CATEGORY_UNIT_ACTION_CODES';
export const SET_UNIT_ACTION_MODIFIERS = 'DATA/SET_UNIT_ACTION_MODIFIERS';
export const SET_PERSON_SEARCH_HISTORY = 'DATA/SET_PERSON_SEARCH_HISTORY';
export const SET_VEHICLE_SEARCH_HISTORY = 'DATA/SET_VEHICLE_SEARCH_HISTORY';
export const SET_HISTORY_LOADING = 'DATA/SET_HISTORY_LOADING';
export const getDispositions = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('dispositions');
      service.timeout = 20000;
      const dispositions = await service.find({
        query: {
          $limit: 100,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_DISPOSITIONS, payload: dispositions });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getAgencies = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('ptsagencies');
      service.timeout = 20000;
      const agencies = await service.find({
        query: {
          $limit: 100,
          $select: ['ptsPlaceID','Description','Flags','IsDeleted','Deleted','DeletedBy','DeletedIP','Created','CreatedBy','Updated','UpdatedBy','UpdatedIP','ROWGUID','AgencyID','AgencyType'],
          IsDeleted: false
        }
      });
      dispatch({ type: SET_AGENCIES, payload: agencies.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getDivision = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codedivision');
      service.timeout = 20000;
      const division = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });

      dispatch({ type: SET_DIVISIONS, payload: division.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getZones = () => {
  const client = store.store.getState().websocket.websocket;
  const agencies = store.store.getState().settings.agencies;
  const agenciesUnit = store.store.getState().settings.agenciesUnit;

  return async dispatch => {
    try {
      const service = client.service('codezones');
      service.timeout = 20000;
      const zone = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      // console.log(data);

      dispatch({ type: SET_ZONES, payload: zone.data });
      if (agencies !== null) {
        const zoneEvent = zone?.data.filter(z => {
          if (agencies?.some(a => a.AgencyID == z.AgencyID)) {
            return true;
          } else {
            return false;
          }
        });
        dispatch({ type: SET_ZONES_EVENTS, payload: zoneEvent });
      } else {
        dispatch({ type: SET_ZONES_EVENTS, payload: zone.data });
      }
      if (agenciesUnit !== null) {
        const zoneUnit = zone?.data.filter(z => {
          if (agenciesUnit?.some(a => a.AgencyID == z.AgencyID)) {
            return true;
          } else {
            return false;
          }
        });
        dispatch({ type: SET_ZONES_UNITS, payload: zoneUnit });
      } else {
        dispatch({ type: SET_ZONES_UNITS, payload: zone.data });
      }
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getUnitActionCodes = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('unit-actions');
      service.timeout = 20000;
      const unitActionsCodes = await service.find();
      if (unitActionsCodes.length > 0) {
        const unitActionsCodeSchema = new schema.Entity(
          'unitActionCodes',
          {},
          { idAttribute: 'Code' }
        );
        const normalizedunitsActionsCodes = normalize(unitActionsCodes, [
          unitActionsCodeSchema
        ]).entities.unitActionCodes;
        dispatch({
          type: SET_NORMALIZED_UNIT_ACTION_CODES,
          payload: normalizedunitsActionsCodes
        });
      }
      if (unitActionsCodes.length > 0) {
        const unitActionsCodeSchemaCategory = new schema.Entity(
          'unitActionCodes',
          {},
          { idAttribute: 'Category' }
        );
        const normalizedunitsActionsCodesCategory = normalize(
          unitActionsCodes,
          [unitActionsCodeSchemaCategory]
        ).entities.unitActionCodes;
        dispatch({
          type: SET_NORMALIZED_CATEGORY_UNIT_ACTION_CODES,
          payload: normalizedunitsActionsCodesCategory
        });
      }
      dispatch({ type: SET_UNIT_ACTION_CODES, payload: unitActionsCodes });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getUnitActionModifiers = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codeunitactionmodifier');
      service.timeout = 20000;
      const unitActionsModifiers = await service.find();
      if (unitActionsModifiers.data.length > 0) {
        dispatch({
          type: SET_UNIT_ACTION_MODIFIERS,
          payload: unitActionsModifiers.data
        });
      }
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getAddresses = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('ptsaddresslistview');
      service.timeout = 20000;
      const addresses = await service.find({
        query: {
          FullAddressText: 'wa',
          $limit: 10,
          $skip: 2,
          $sort: {
            createdAt: -1
          }
        }
      });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getCourts = async () => {
  const client = store.store.getState().websocket.websocket;
  try {
    const service = client.service('citation-court');
    service.timeout = 20000;
    const courtDetails = await service.find({
      query: {
        $sort: {
          Court_Name: 1
        }
      }
    });
    return courtDetails.data;
    // setCourts(courtDetails.data);
  } catch (err) {
    console.log(err);
    return [];
  }
};
export const getOffense = async () => {
  const client = store.store.getState().websocket.websocket;

  try {
    const service = client.service('offenses');
    service.timeout = 20000;
    const data = await service.find({ query: { $limit: 1000 } });
    const sortedData = _.chain(data.data)
      .sortBy('Description')
      .value();

    // console.log('all off', data2);
    const finalData = [
      {
        violations: '',
        violation_value: '',
        acode: ''
      },
      ...sortedData
    ];

    return sortedData;
    // setPersonHistory(personList);
    // setVehicleHistory(vehicleList);
  } catch (err) {
    console.log(err);
    return [];
  }
};
export const getTickets = async () => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user;
  try {
    const AgencyID = user.userData.user.AgencyID;
    const service = client.service('default-agency');
    service.timeout = 20000;
    const formDetails = await service.find({ query: { agencyID: AgencyID } });
    const defaultOne = formDetails.filter(
      f => f.IsDefault == true && f.FileName.length > 0
    );
    const others = formDetails.filter(
      f => f.IsDefault == false && f.FileName.length > 0
    );

    const list = [...defaultOne, ...others];
    // console.log('citationForm', citationForm);
    return list;
  } catch (err) {
    console.log(err);
    return [];
  }
};
export const getHistory = () => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user;
  return async dispatch => {
    try {
      dispatch({ type: SET_HISTORY_LOADING, payload: true });
      const routingId = user.userData.user.rowguid;
      const service = client.service('citation-history');
      service.timeout = 20000;
      const data = await service.find({ query: { RoutingID: routingId } });
      const personList = [];
      const vehicleList = [];

      data.forEach(dt => {
        const person =
          (dt.personResponse || dt.personStateResponse) &&
          JSON.parse(dt.personResponse || dt.personStateResponse);
        const vehicle =
          (dt.vehicleData || dt.vehicleStateResponse) &&
          JSON.parse(dt.vehicleData || dt.vehicleStateResponse);
        if (person?.data) {
          personList.push(person.data);
        }
        if (vehicle?.data) {
          vehicleList.push(vehicle.data);
        }
      });
      dispatch({ type: SET_PERSON_SEARCH_HISTORY, payload: personList });
      dispatch({ type: SET_VEHICLE_SEARCH_HISTORY, payload: vehicleList });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_HISTORY_LOADING, payload: false });
    }
  };
};

export default function reducer(
  state = {
    // Dispositions
    dispositions: [],
    agencies: [],
    divisions: [],
    zonesForUnits: [],
    zonesForEvents: [],
    zones: [],
    agenciesTypes: [],
    unitActionCodes: [],
    normalizedUnitActionCodes: [],
    normalizedUnitActionCodesCategory: [],
    unitActionModifiers: [],
    personSearchHistory: [],
    vehicleSearchHistory: [],
    historyLoading: false
  },
  action
) {
  switch (action.type) {
    // Dispositions
    case SET_DISPOSITIONS:
      return {
        ...state,
        dispositions: action.payload
      };
    // Dispositions
    case SET_AGENCIES:
      return {
        ...state,
        agencies: action.payload
      };
    case SET_DIVISIONS:
      return {
        ...state,
        divisions: action.payload
      };
    case SET_ZONES:
      return {
        ...state,
        zones: action.payload
      };
    case SET_ZONES_EVENTS:
      return {
        ...state,
        zonesForEvents: action.payload
      };
    case SET_ZONES_UNITS:
      return {
        ...state,
        zonesForUnits: action.payload
      };
    // Unit action codes
    case SET_UNIT_ACTION_CODES:
      return {
        ...state,
        unitActionCodes: action.payload
      };
    case SET_NORMALIZED_UNIT_ACTION_CODES:
      return {
        ...state,
        normalizedUnitActionCodes: action.payload
      };
    case SET_NORMALIZED_CATEGORY_UNIT_ACTION_CODES:
      return {
        ...state,
        normalizedUnitActionCodesCategory: action.payload
      };
    // Unit action modifiers
    case SET_UNIT_ACTION_MODIFIERS:
      return {
        ...state,
        unitActionModifiers: action.payload
      };
    case SET_PERSON_SEARCH_HISTORY:
      return {
        ...state,
        personSearchHistory: action.payload
      };
    case SET_VEHICLE_SEARCH_HISTORY:
      return {
        ...state,
        vehicleSearchHistory: action.payload
      };
    case SET_HISTORY_LOADING:
      return {
        ...state,
        historyLoading: action.payload
      };
    default:
      break;
  }
  return state;
}
