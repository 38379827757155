
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8pills100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100" height="100"><path d="M148.5 45.813c-44.725 0-81.5 36.388-81.5 81.109V248h163V126.922c0-44.724-36.775-81.11-81.5-81.11zm223.1 25.732A73.057 73.057 0 0 0 298.932 138H444.26a73.058 73.058 0 0 0-72.66-66.455zM299.262 154a72.95 72.95 0 0 0 144.668 0H299.262zM67 264v121.078C67 429.8 103.69 466 148.414 466h.305C193.442 466 230 429.8 230 385.078V264H67zm118.234 25.283A8 8 0 0 1 193 297.28v87.8a8 8 0 0 1-16 0v-87.8a8 8 0 0 1 8.234-7.996zm186.366 5.233a72.413 72.413 0 0 0-46.8 17l102.768 102.771A72.964 72.964 0 0 0 371.6 294.516zm-57.88 28.548a72.951 72.951 0 0 0 6.28 96.018 72.951 72.951 0 0 0 96.021 6.283l-102.3-102.3z"/></SvgIcon>
      );
    }
  
    export default icons8pills100Icon;