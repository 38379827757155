
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8checklock100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="100" height="100"><path d="M15 2c-3.854 0-7 3.146-7 7v2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8.947A8.939 8.939 0 0 1 14 23a9 9 0 0 1 9-9c1.054 0 2.06.19 3 .523V13a2 2 0 0 0-2-2h-2V9c0-3.728-2.963-6.731-6.645-6.928A1 1 0 0 0 15 2zm0 2c2.774 0 5 2.226 5 5v2H10V9c0-2.774 2.226-5 5-5zm8 12a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm3 4a.999.999 0 0 1 .707 1.707l-4 4a.997.997 0 0 1-1.414 0l-2-2a.999.999 0 1 1 1.414-1.414L22 23.586l3.293-3.293A.996.996 0 0 1 26 20z"/></SvgIcon>
      );
    }
  
    export default icons8checklock100Icon;