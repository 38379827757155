import AgenciesFilter from 'layout-components/TopBar/agenciesFilter';
import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, IconButton, Tooltip } from '@mui/material';

const FilterButton = props => {
  const [anchorElAgencies, setAnchorElAgencies] = React.useState(null);

  const handleAgenciesFilterClick = event => {
    setAnchorElAgencies(event.currentTarget);
  };
  const handleAgeciesFilterClose = () => {
    setAnchorElAgencies(null);
  };

  return (
    <>
      <IconButton
        size="small"
        style={{ color: '#0b55a1' }}
        aria-controls="agencies-filter"
        aria-haspopup="true"
        onClick={event => handleAgenciesFilterClick(event)}>
        <FilterListIcon size="small" color="primary" />
      </IconButton>
      <AgenciesFilter
        anchorEl={anchorElAgencies}
        separate={props.type}
        handleAgeciesFilterClose={handleAgeciesFilterClose}
      />
    </>
  );
};

export default FilterButton;
