import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Geocode from 'react-geocode';
import MomentUtils from '@date-io/moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Fab
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Slide from '@mui/material/Slide';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { displayDateTime } from 'reducers/TimeReducer';
import { MdLocationSearching } from 'react-icons/md';
import NewTripDialog from '../DialogNewTrip';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { warning } from 'react-notification-system-redux';
import moment from 'moment';

import {
  toggleDialog,
  unitStatusChange,
  setNotify
} from '../../reducers/UnitStatusReducer';
import {
  getUnitActionCodes,
  getUnitActionModifiers
} from '../../reducers/DataReducer';
import { getCodeState } from '../../reducers/CodeReducer';
import { toggleNewTripDialog } from '../../reducers/UiReducer';

import EventFilter from './EventFilter';
import StatusFilter from './StatusFilter';
import PlateStateFilter from './PlateStateFilter';
import OlnStateFilter from './OlnStateFilter';
import ModifiersFilter from './ModifiersFilter';
import AddressFilter from './AddressFilter';
import { useNavigate } from 'react-router-dom';
import FavouriteLocation from './FavouriteLocation';
import { newUserLocationFromChannel } from 'reducers/UserReducer';
// import { json } from 'body-parser';
import { toast } from 'react-toastify';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh'
  },
  itemWrap: {
    fontWeight: 500
  },
  itemLabel: {
    fontWeight: 200
  },
  formSection: {
    paddingBottom: '10px'
  },
  color: {
    background: theme.palette.bgElements
  },
  statusWindowTitle: {
    flex: '0 0 auto',
    margin: '0',
    padding: '16px 24px'
  },
  notificationDesc: {
    fontStyle: 'italic'
  },
  notificationContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '0',
    margin: '0',
    listStyle: 'none'
  },
  notificationContentItem: {
    flex: '1 50%',
    paddingBottom: '2px'
  },
  notificationActionWrapper: {
    margin: '0px',
    padding: '0px',
    cursor: 'pointer',
    color: 'rgb(90, 83, 67)',
    fontSize: '13px'
  },
  notificationActionBtn: {
    background: 'rgb(235, 173, 26)',
    '&:hover': {
      backgroundColor: 'rgb(235, 173, 26)'
    },
    borderRadius: '2px',
    padding: '6px 20px',
    fontWeight: 'bold',
    margin: '10px 0px 0px',
    border: '0px',
    color: 'rgb(255, 255, 255)',
    fontSize: '13px'
  },
  notificationContentTitle: {
    fontWeight: 'bold'
  },
  notificationContentValue: {
    textAlign: 'right'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogUnitStatus(props) {
  const theme = useTheme();
  const { classes } = useStyles();
  const {
    network,
    wsClient,
    isOpen,
    location,
    toggleDialog,
    events,
    myEvents,
    getUnitActionCodes,
    getCodeState,
    unitActionCodes,
    normalizedUnitActionCodes,
    statesCodes,
    unitActionModifiers,
    unitStatusChange,
    setNotify,
    notify,
    notification,
    myUnit,
    newTripDialogState,
    toggleNewTripDialog,
    getUnitActionModifiers
  } = props;
  // const { APP_GOOGLE_KEY } = useSelector(state => state.appInfo.appEnvInfo);
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);

  const makeNotification = (alert) => {
    const alertDataArray = Object.entries(alert);
    var itemCount = 0;
    const title = alertDataArray[0][1]
      ? `${alertDataArray[1][1]} - ${alertDataArray[0][1]}`
      : alertDataArray[1][1];

    return (
      <div>
        <h6 style={{ fontWeight: 'bold' }}>{title}</h6>
        {alertDataArray[9] && (
          <div className={classes.notificationDesc}>{alertDataArray[9][1]}</div>
        )}
        <div className="divider my-1" />
        <div className={classes.notificationActionWrapper}>
          <div className={classes.notificationContent}>
            {alertDataArray.map((alert, idx) => {
              if (idx > 1 && idx < 9 && alert[1] !== null && alert[1] !== '') {
                itemCount = itemCount + 1;
                if (alert[0] === 'Beginning' || alert[0] === 'Ending')
                  alert[1] = displayDateTime(alert[1]);

                if (alert[0] === 'OLNState') alert[0] = 'OLN State';
                if (alert[0] === 'PlateState') alert[0] = 'Plate State';

                return (
                  <div
                    className={classes.notificationContentItem}
                    style={{
                      textAlign: itemCount % 2 === 0 ? 'right' : 'left'
                    }}>
                    <div className={classes.notificationContentTitle}>
                      {alert[0]}
                    </div>
                    <div>{alert[1]}</div>
                  </div>
                );
              }
            })}
          </div>
          <Button
            onClick={() => navigate('/Alerts')}
            className={classes.notificationActionBtn}>
            Go to the Bulletin!
          </Button>
        </div>
      </div>
    );
  };
  const [event, setEvent] = useState('');
  const [status, setStatus] = useState('');
  const [locationString, setLocationString] = useState('');

  const [plate, setPlate] = useState('');
  const [oln, setOln] = useState('');

  const [plateState, setPlateState] = useState('');
  const [olnState, setOlnState] = useState('');

  const [mileage, setMileage] = useState('');

  const [modifiers, setModifiers] = useState([]);

  const [statusNotes, setStatusNotes] = useState('');

  const [destinationID, setDestinationID] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationButtonVisible, setLocationButtonVisible] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  React.useEffect(() => {
    if (wsClient.websocket && isOpen) {
      setLocationLoading(true);
      getLastLocation();
    }
  }, [wsClient.websocket, isOpen]);
  const getLastLocation = async () => {
    try {
      const service = wsClient.websocket.service('unit-coordinates');
      service.timeout = 20000;
      const locationInfo = await service.get(myUnit.ptsUnitID);

      if (locationInfo?.length < 1) {
        setLocationButtonVisible(false);
      } else {
        setLocationButtonVisible(true);
        dispatch(newUserLocationFromChannel(locationInfo[0]));
      }
    } catch (error) {
      console.log('err', error);
    } finally {
      setLocationLoading(false);
    }
  };
  useEffect(() => {
    if (myUnit.ptsLastActionID && isOpen) {
      const {
        LastActionPlateState,
        LastActionPlate,
        LastActionOLNState,
        LastActionOLN,
        LastActionNotes,
        LastActionptsEventID,
        Location,
        Code
      } = myUnit;
      const events = JSON.parse(myUnit?.UnitStatuses || '[]');
      if (events.length > 0) {
        const lastEvent = events.filter(
          (ev) => ev.ptsEventID === LastActionptsEventID
        );
        if (lastEvent && lastEvent[0]) {
          // console.log('lastEvent[0]', lastEvent[0]?.Category?.toUpperCase());
          if (lastEvent[0]?.Category?.toUpperCase() !== 'INSERVICE') {
            setEvent(lastEvent[0].EventID);
          } else {
            setEvent('');
          }
        }

        setLocationString(Location || '');
        setPlate(LastActionPlate || '');
        setOln(LastActionOLN || '');
        setPlateState(LastActionPlateState || '');
        setOlnState(LastActionOLNState || '');

        setStatusNotes(LastActionNotes || '');
      }
    }
  }, [isOpen]);
  const resetForm = () => {
    setEvent('');
    setStatus('');
    setLocationString('');
    setPlate('');
    setOln('');
    setPlateState('');
    setOlnState('');
    setLocationString('');
    setStatusNotes('');
    setModifiers([]);
    setMileage('');
  };

  const handleNewTripClick = () => {
    setAnchorEl(null);
    toggleNewTripDialog();
  };

  const handleClose = () => {
    toggleDialog();
    // resetForm();
  };

  const submitForm = (e) => {
    e.preventDefault();

    const statusRow = normalizedUnitActionCodes[status];
    if (statusRow && statusRow.PromptForNewTrip === true) {
      toggleNewTripDialog();
      return;
    } else {
      statusChange();
    }
  };

  const statusChange = () => {
    const _plate = plate !== '' ? plate : null;
    const _plateState = plateState !== '' ? plateState : null;
    const _oln = oln !== '' ? oln : null;
    const _olnState = olnState !== '' ? olnState : null;
    const _notes = statusNotes !== '' ? statusNotes : null;
    unitStatusChange(
      status,
      event,
      locationString,
      _plate,
      _plateState,
      _oln,
      _olnState,
      _notes,
      null,
      modifiers,
      mileage,
      null,
      destinationID
    );
    resetForm();
  };

  const statusChangeWithTrip = () => {
    let tripNumber = 1;
    const myUnitHistory =
      myUnit && myUnit.UnitStatuses ? JSON.parse(myUnit.UnitStatuses) : [];
    if (myUnitHistory.length > 0) {
      const lastActionStatus = myUnitHistory[0].UnitStatus;
      const lastActionTripNumber = myUnitHistory[0].TripNumber;
      if (lastActionStatus === status) {
        if (lastActionTripNumber && lastActionTripNumber !== null) {
          tripNumber = lastActionTripNumber + 1;
        }
      }
    }
    const _plate = plate !== '' ? plate : null;
    const _plateState = plateState !== '' ? plateState : null;
    const _oln = oln !== '' ? oln : null;
    const _olnState = olnState !== '' ? olnState : null;
    const _notes = statusNotes !== '' ? statusNotes : null;
    unitStatusChange(
      status,
      event,
      locationString,
      _plate,
      _plateState,
      _oln,
      _olnState,
      _notes,
      tripNumber,
      modifiers,
      mileage,
      null,
      destinationID
    );
    resetForm();
  };

  const setSelectedEvent = (event) => {
    setEvent(event);
  };

  const setSelectedStatus = (status) => {
    setStatus(status);
  };

  const setSelectedPlateState = (state) => {
    setPlateState(state);
  };

  const setSelectedOlnState = (state) => {
    setOlnState(state);
  };

  const setSelectedModifier = (modifiers) => {
    setModifiers(modifiers);
  };

  const handleLocationAutocomplete = (event) => {
    if (location !== null) {
      Geocode.fromLatLng(location.latitude, location.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setLocationString(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const handleLocationChange = (event) => {
    setLocationString(event.target.value);
  };

  const handlePlateChange = (event) => {
    setPlate(event.target.value);
  };

  const handleOlnChange = (event) => {
    setOln(event.target.value);
  };

  const handleMileageChange = (event) => {
    setMileage(event.target.value);
  };

  const handleStatusNotesChange = (event) => {
    setStatusNotes(event.target.value);
  };

  const handleOnPlaceValueChanged = (place) => {
    setDestinationID(place.ptsPlaceID);
  };

  React.useEffect(() => {
    if (wsClient.websocket) {
      getUnitActionCodes();
      getCodeState();
      getUnitActionModifiers();
    }
  }, [
    getUnitActionCodes,
    getCodeState,
    getUnitActionModifiers,
    wsClient.websocket
  ]);

  React.useEffect(() => {
    if (notify && notification) {
      // dispatch(warning(makeNotification(notification)));
      toast(makeNotification(notification), {
        autoClose: false,
        style: { width: '300px' },
        className: 'custom-bg-warning',
        onClose: () => {
          console.log('onClose fired created poison');
        }
      });
      setNotify(false);
    }
  }, [notify, notification, dispatch, makeNotification, setNotify]);

  //console log are for DIP

  //here ends the console log area

  return (
    <Fragment>
      <Dialog
        TransitionComponent={Transition}
        open={isOpen}
        onClose={handleClose}
        scroll="paper"
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth={'sm'}>
        <div className={classes.statusWindowTitle} id="scroll-dialog-title">
          <Typography variant="h4">Unit Status</Typography>
        </div>
        <DialogContent
          dividers={true}
          className={classes.body}
          id="unit-status-dialog">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="status-form" onSubmit={submitForm}>
              <EventFilter
                eventList={events}
                myEvents={myEvents}
                selectedEvent={event}
                setSelectedEvent={setSelectedEvent}
              />
              <StatusFilter
                statusList={unitActionCodes}
                selectedStatus={status}
                setSelectedStatus={setSelectedStatus}
                location={location}
              />
              <FavouriteLocation
                locationString={locationString}
                setLocationString={setLocationString}
                network={network}
                locationButtonVisible={locationButtonVisible}
                locationLoading={locationLoading}
                handleLocationAutocomplete={handleLocationAutocomplete}
              />
              {/* <FormControl fullWidth className={classes.formSection}>
                <TextField
                  variant="outlined"
                  label="Location"
                  className={classes.filter}
                  onChange={handleLocationChange}
                  value={locationString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Fab
                          disabled={network.online === false ? true : false}
                          onClick={handleLocationAutocomplete}
                          size="small"
                          color="primary">
                          <MdLocationSearching style={{ fontSize: '1.3rem' }} />
                        </Fab>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl> */}
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    value={plate}
                    onChange={handlePlateChange}
                    fullWidth
                    label="Plate"
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        plate !== '' ? (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => setPlate('')}
                              component="span">
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) : null
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PlateStateFilter
                    states={statesCodes}
                    selectedPlateState={plateState}
                    setSelectedPlateState={setSelectedPlateState}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    value={oln}
                    onChange={handleOlnChange}
                    fullWidth
                    label="OLN"
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        oln !== '' ? (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => setOln('')}
                              component="span">
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) : null
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OlnStateFilter
                    states={statesCodes}
                    selectedOlnState={olnState}
                    setSelectedOlnState={setSelectedOlnState}
                  />
                </Grid>
              </Grid>
              <FormControl fullWidth className={classes.formSection}>
                <TextField
                  type="number"
                  variant="outlined"
                  label="Mileage"
                  className={classes.filter}
                  onChange={handleMileageChange}
                  value={mileage}
                  InputProps={{
                    endAdornment:
                      mileage !== '' ? (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setMileage('')}
                            component="span">
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : null
                  }}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formSection}>
                <TextField
                  multiline={true}
                  minRows="2"
                  variant="outlined"
                  label="Notes"
                  className={classes.filter}
                  onChange={handleStatusNotesChange}
                  value={statusNotes}
                  InputProps={{
                    endAdornment:
                      statusNotes !== '' ? (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setStatusNotes('')}
                            component="span">
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : null
                  }}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formSection}>
                <AddressFilter
                  ptsPlaces={true}
                  ptsAddresses={false}
                  googleAddresses={false}
                  onPlaceValueSet={handleOnPlaceValueChanged}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formSection}>
                <ModifiersFilter
                  modifiers={unitActionModifiers}
                  selectedModifiers={modifiers}
                  setSelectedModifier={setSelectedModifier}
                />
              </FormControl>
            </form>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            form="status-form">
            Save
          </Button>
          <Button onClick={handleClose} color="inherit">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {newTripDialogState && (
        <NewTripDialog
          close={handleNewTripClick}
          no={statusChange}
          yes={statusChangeWithTrip}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  network: state.offline,
  wsClient: state.websocket,
  isOpen: state.unitStatus.isOpen,
  notify: state.unitStatus.notify,
  notification: state.unitStatus.notification,
  events: state.events.events,
  statesCodes: state.codes.statesCodes,
  myEvents: state.settings.myEvents,
  unitActionCodes: state.data.unitActionCodes,
  unitActionModifiers: state.data.unitActionModifiers,
  normalizedUnitActionCodes: state.data.normalizedUnitActionCodes,
  location: state.user.location,
  newTripDialogState: state.ui.newTripDialogState,
  myUnit: state.user.userUnit
});

export default connect(mapStateToProps, {
  toggleDialog,
  getUnitActionCodes,
  getCodeState,
  unitStatusChange,
  setNotify,
  toggleNewTripDialog,
  getUnitActionModifiers
})(DialogUnitStatus);
