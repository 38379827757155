import { enqueueSnackbar, notify } from '../reducers/NotifierReducer';
import store from '../config/configureStore';
import tableConfig from '../config/table-config-vehicles.json';

import { showSpinner, hideSpinner } from './UiReducer';
import {
  SET_FORM_PERSON_CHECKBOX,
  SET_NCIC_FORM_PERSON_CHECKBOX
} from './PersonSearchReducer';
import {
  SET_FORM_GUN_CHECKBOX,
  SET_NCIC_FORM_GUN_CHECKBOX
} from './GunSearchReducer';

// Vehicle Search
export const CITATION_VEHICLE_FLAG = 'CITATION_VEHICLE_FLAG';
export const CITATION_VEHICLE_DATA = 'CITATION_VEHICLE_DATA';
export const CLEAR_CITATION_DETAILS = 'CLEAR_CITATION_DETAILS';
export const FIND_VEHICLES = 'VEHICLESEARCH/FIND';
export const FIND_OWNERS = 'OWNERSEARCH/FIND';
export const LOADING_VEHICLES = 'VEHICLESEARCH/LOADING';
export const LOADING_VEHICLES_ERROR = 'VEHICLESEARCH/LOADING_ERROR';

export const FIND_VEHICLE = 'VEHICLESEARCH/FIND_PERSON';
export const FIND_OWNER = 'OWNERSEARCH/FIND_OWNER';
export const OPEN_DETAILS = 'VEHICLESEARCH/OPEN_DETAILS';
export const OPEN_OWNER_DETAILS = 'VEHICLESEARCH/OPEN_OWNER_DETAILS';
export const CLOSE_OWNER_DETAILS = 'VEHICLESEARCH/CLOSE_OWNER_DETAILS';
export const CLOSE_DETAILS = 'VEHICLESEARCH/CLOSE_DETAILS';

export const CLEAR_RESULTS = 'VEHICLESEARCH/CLEAR_RESULTS';
export const VEHICLE_QUICK_SEARCH = 'VEHICLESEARCH/VEHICLE_QUICK_SEARCH';
export const SET_FORM_VEHICLE_PICKERS = 'NCIC/SET_FORM_VEHICLE_PICKERS';
export const SET_FORM_VEHICLE_FILTERS = 'NCIC/SET_FORM_VEHICLE_FILTERS';
export const SET_FORM_VEHICLE_CHECKBOX =
  'VEHICLESEARCH/SET_FORM_VEHICLE_CHECKBOX';
export const SET_NCIC_FORM_VEHICLE_CHECKBOX =
  'NCIC/SET_NCIC_FORM_VEHICLE_CHECKBOX';
export const SET_NCIC_RESPONSE_VEHICLES_QUERY_ID =
  'NCIC/SET_RESPONSE_VEHICLES_QUERY_ID';

export const SET_NCIC_FINISHED_RESPONSES = 'NCIC/SET_NCIC_FINISHED_RESPONSES';
export const SET_NCIC_PENDING_RESPONSES = 'NCIC/SET_NCIC_PENDING_RESPONSES';

export const findVehicles = params => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    try {
      const service = client.service('search-vehicle');
      service.timeout = 20000;
      dispatch({ type: SET_NCIC_RESPONSE_VEHICLES_QUERY_ID, payload: null });
      dispatch({ type: LOADING_VEHICLES, payload: true });
      const vehicles = await service.find({ query: params });
      dispatch({ type: FIND_VEHICLES, payload: { params, vehicles } });
      dispatch({ type: LOADING_VEHICLES, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_VEHICLES_ERROR });
      notify('No data found.', 'warning', '300px');
    }
  };
};

export const sortVehicles = orderBy => {
  return async dispatch => {
    const changeDirection = direction => (direction === 'ASC' ? 'DESC' : 'ASC');
    const vehicles = store.store.getState().vehicleSearch.vehicles;
    const { filters, pageNo, rowsPerPage } = vehicles;
    const orderDirection =
      vehicles.orderBy === orderBy
        ? changeDirection(vehicles.orderDirection)
        : 'ASC';
    const params = { filters, pageNo, orderBy, rowsPerPage, orderDirection };
    dispatch(findVehicles(params));
  };
};

export const findVehicle = ptsVehicleID => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async dispatch => {
    if (ptsVehicleID === null) {
      dispatch({
        type: CLOSE_DETAILS,
        payload: null
      });
    } else {
      dispatch(showSpinner());
      try {
        const service = client.service('search-vehicle');
        service.timeout = 20000;
        const row = await service.get(ptsVehicleID);

        dispatch({ type: FIND_VEHICLE, payload: row });
        dispatch({ type: CITATION_VEHICLE_DATA, payload: row });
        dispatch({
          type: OPEN_DETAILS,
          payload: null
        });
      } catch (error) {
        notify('No data found.', 'warning', '300px');
        // dispatch(
        //   enqueueSnackbar({
        //     message: 'No data found.',
        //     options: {
        //       key: new Date().getTime() + Math.random(),
        //       variant: 'warning',
        //       anchorOrigin: {
        //         vertical: 'top',
        //         horizontal: 'center'
        //       }
        //     }
        //   })
        // );
      }
      dispatch(hideSpinner());
    }
  };
};
export const setVehicleCitaionFlag = flag => {
  return dispatch => {
    dispatch({ type: CITATION_VEHICLE_FLAG, payload: flag });
  };
};
/* New ncic implementation */
export const handleDateChange = (date, dbName, param) => {
  const pickerValues = store.store.getState().vehicleSearch.vehicleForm.pickers;
  return async dispatch => {
    const value = pickerValues[dbName]
      ? pickerValues[dbName]
      : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }
    dispatch({
      type: SET_FORM_VEHICLE_PICKERS,
      payload: currentPickerValue
    });
  };
};

export const handleFilterChange = (ev, filter) => {
  const values = store.store.getState().vehicleSearch.vehicleForm.filters;

  return async dispatch => {
    values[filter.dbName] = {
      type: filter.type,
      search: ev.target ? ev.target.value.toUpperCase() : ev.toUpperCase()
    };

    dispatch({
      type: SET_FORM_VEHICLE_FILTERS,
      payload: cleanFilters(values)
    });
  };
};
export const handleVehicleFilltersAutoFill = values => {
  return dispatch => {
    dispatch({
      type: SET_FORM_VEHICLE_FILTERS,
      payload: values
    });
  };
};
export const handleVehicleQuickSearch = vehicleInfo => {
  return async dispatch => {
    const params = {};
    if (vehicleInfo.LicensePlateNumber) {
      params['v.PlateNumber'] = {
        type: 'string',
        search: vehicleInfo.LicensePlateNumber.toUpperCase()
      };
    }
    if (vehicleInfo.LicensePlateYear) {
      params['v.Year'] = {
        type: 'string',
        search: vehicleInfo.LicensePlateYear
      };
    }
    if (vehicleInfo.State) {
      params['v.PlateState'] = {
        type: 'string',
        search: vehicleInfo.State.toUpperCase()
      };
    }
    const options = {
      pageNo: 0,
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      filters: params
    };
    dispatch({
      type: VEHICLE_QUICK_SEARCH,
      payload: params
    });
    dispatch(findVehicles(options));
  };
};
export const handleCheckboxChange = ev => {
  return async dispatch => {
    dispatch({
      type: SET_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_NCIC_FORM_PERSON_CHECKBOX,
      payload: ev?.target ? ev.target.checked : ev
    });
    dispatch({
      type: SET_FORM_VEHICLE_CHECKBOX,
      payload: ev.target.checked
    });
    dispatch({
      type: SET_NCIC_FORM_VEHICLE_CHECKBOX,
      payload: ev.target.checked
    });
    dispatch({
      type: SET_FORM_GUN_CHECKBOX,
      payload: ev.target.checked
    });
    dispatch({
      type: SET_NCIC_FORM_GUN_CHECKBOX,
      payload: ev.target.checked
    });
  };
};

export const clearVehicleForm = () => {
  const {
    APP_DEFAULT_STATE,
    YEAR_REQUIRED
  } = store.store.getState().appInfo.appEnvInfo;
  return async dispatch => {
    const vehicleFilter = {
      ['v.PlateState']: {
        type: 'select',
        search: APP_DEFAULT_STATE
      }
    };
    if (YEAR_REQUIRED) {
      vehicleFilter['v.Year'] = {
        type: 'string',
        search: new Date().getFullYear()
      };
    }

    dispatch({ type: SET_FORM_VEHICLE_FILTERS, payload: vehicleFilter });
    dispatch({
      type: SET_FORM_VEHICLE_PICKERS,
      payload: {}
    });
  };
};

export const clearResults = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_RESULTS,
      payload: null
    });
    dispatch({ type: SET_NCIC_RESPONSE_VEHICLES_QUERY_ID, payload: null });
    dispatch({
      type: SET_NCIC_FINISHED_RESPONSES,
      payload: 0
    });
    dispatch({
      type: SET_NCIC_PENDING_RESPONSES,
      payload: 0
    });
  };
};

const cleanFilters = obj => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
};
export const clearCitationVehicleDetails = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CITATION_DETAILS });
  };
};
export const setDefaultEnvFORVehicle = envInfo => {
  const { YEAR_REQUIRED, APP_DEFAULT_STATE } = envInfo;
  return dispatch => {
    const vehicleFilter = {
      ['v.PlateState']: {
        type: 'select',
        search: APP_DEFAULT_STATE
      }
    };
    if (YEAR_REQUIRED) {
      vehicleFilter['v.Year'] = {
        type: 'string',
        search: new Date().getFullYear()
      };
    }

    dispatch({ type: SET_FORM_VEHICLE_FILTERS, payload: vehicleFilter });
  };
};
export default function reducer(
  state = {
    // Vehicle
    vehicles: {
      rows: [],
      rawData: '',
      count: 5000,
      pageNo: 0,
      search: '',
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      loading: false,
      loaded: false,
      error: false
    },
    owners: {
      rows: [],
      rawData: '',
      images: '[]',
      loading: false,
      loaded: false,
      error: false
    },
    ownerDetails: {
      data: null,
      ptsPersonID: null,
      isShowing: false
    },
    vehicleForm: {
      filters: {},
      pickers: {}
    },
    vehicleDetails: {
      data: null,
      ptsVehicleID: null,
      isShowing: false
    },
    citationDetails: {
      data: null,
      ptsVehicleID: null,
      isShowing: false,
      flag: false
    }
  },
  action
) {
  switch (action.type) {
    // Vehicle
    case LOADING_VEHICLES:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          loading: action.payload,
          loaded: action.payload ? false : state.vehicles.loaded,
          error: false
        }
      };
    case LOADING_VEHICLES_ERROR:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_VEHICLES:
      return {
        ...state,
        vehicles: {
          rows: action.payload.vehicles.data,
          rawData: action.payload.rawData ? action.payload.rawData : '',
          ...action.payload.params,
          count: action.payload.vehicles.count,
          images: [],
          loaded: true,
          error: false
        }
      };

    case FIND_VEHICLE:
      return {
        ...state,
        vehicleDetails: { ...state.vehicleDetails, data: action.payload }
      };
    case FIND_OWNERS:
      return {
        ...state,
        owners: {
          rows: action.payload.persons.data,
          rawData: action.payload.rawData ? action.payload.rawData : '',
          images: action.payload.images ? action.payload.images : '[]',
          ...action.payload.params,

          loaded: true,
          error: false
        }
      };
    case FIND_OWNER:
      return {
        ...state,
        ownerDetails: { ...state.ownerDetails, data: action.payload }
      };
    case CITATION_VEHICLE_FLAG:
      return {
        ...state,
        citationDetails: { ...state.citationDetails, flag: action.payload }
      };
    case CITATION_VEHICLE_DATA:
      return {
        ...state,
        citationDetails: { ...state.citationDetails, data: action.payload }
      };
    case CLEAR_CITATION_DETAILS:
      return {
        ...state,
        citationDetails: {
          data: null,
          ptsVehicleID: null,
          isShowing: false,
          flag: false
        }
      };
    case OPEN_DETAILS:
      return {
        ...state,
        vehicleDetails: { ...state.vehicleDetails, isShowing: true }
      };
    case CLOSE_DETAILS:
      return {
        ...state,
        vehicleDetails: { ...state.vehicleDetails, isShowing: false }
      };
    case OPEN_OWNER_DETAILS:
      return {
        ...state,
        ownerDetails: { ...state.ownerDetails, isShowing: true }
      };
    case CLOSE_OWNER_DETAILS:
      return {
        ...state,
        ownerDetails: { ...state.ownerDetails, isShowing: false }
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        vehicles: {
          rows: [],
          rawData: '',
          count: 5000,
          pageNo: 0,
          search: '',
          rowsPerPage: tableConfig.rowsPerPage,
          orderBy: tableConfig.orderBy,
          orderDirection: tableConfig.orderDirection,
          loading: false,
          loaded: false,
          error: false
        },
        owners: {
          rows: [],
          rawData: '',
          images: '[]',
          loading: false,
          loaded: false,
          error: false
        },
        ownerDetails: {
          data: null,
          ptsPersonID: null,
          isShowing: false
        }
      };
    case SET_FORM_VEHICLE_PICKERS:
      return {
        ...state,
        vehicleForm: {
          ...state.vehicleForm,
          pickers: action.payload
        }
      };
    case SET_FORM_VEHICLE_FILTERS:
      return {
        ...state,
        vehicleForm: {
          ...state.vehicleForm,
          filters: action.payload
        }
      };
    case VEHICLE_QUICK_SEARCH:
      return {
        ...state,
        vehicleForm: {
          ...state.vehicleForm,
          filters: action.payload
        }
      };
    case SET_FORM_VEHICLE_CHECKBOX:
      return {
        ...state,
        vehicleForm: {
          ...state.vehicleForm,
          ncic: action.payload
        }
      };
    default:
      break;
  }
  return state;
}
