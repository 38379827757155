
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8lockedinside100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M41.97 3a1 1 0 0 0-.179.021l-13.18 2.825a1 1 0 1 0 .42 1.957L41 5.238v40.524l-12-2.57V31a1 1 0 1 0-2 0v13a1 1 0 0 0 .791.979l14 3A1 1 0 0 0 43 47V4a1 1 0 0 0-1.03-1zM22 7a4.5 4.5 0 0 0 0 9 4.5 4.5 0 0 0 0-9zm-3.305 10.023c-1.403-.07-2.8.583-3.597 1.858l-5 8c-1.171 1.874-.506 4.007 1.271 5.318.544.402 5.711 4.715 5.711 4.715l-.967 6.746a2.4 2.4 0 0 0 4.752.68l1-7a2.373 2.373 0 0 0-.328-1.578l-.096-.157-3.648-5.945 3.248-5.195 3.348 1.955c.529.338 1.085.58 1.67.58h4.93a2.502 2.502 0 0 0 2.372-1.709l1-3a2.5 2.5 0 0 0-4.744-1.582L29.187 22h-2.292l-5.569-3.807c-.154-.156-1.326-1.193-2.63-1.17zm18.29 6.963A1 1 0 0 0 36 25v6a1 1 0 1 0 2 0v-6a1 1 0 0 0-1.016-1.014zM9.58 33.32l-.19 3.85-3.64 5.46c-.73 1.1-.43 2.59.67 3.32a2.4 2.4 0 0 0 3.33-.66l4-6c.16-.24.28-.53.34-.83l.04-.21.2-1.01c-2-1.68-3.88-3.23-4.15-3.43a9.81 9.81 0 0 1-.6-.49z"/></SvgIcon>
      );
    }
  
    export default icons8lockedinside100Icon;