/** Settings stored in local storage */

export function getLocalSettings(setting) {
  const defaultLocalSettings = {
    // workstationID: '',
    // mapLat: 38.8937091,
    // mapLng: -77.0846157,
    // mapZoom: 14,
    // mapEventOpen: false,
    // mapEventWidth: 580,
    // mapUnitOpen: false,
    // mapUnitWidth: 580,
    changePassReminder: {},
  };

  const savedSettings = localStorage.getItem('localSettings');
  let settings;
  if (savedSettings) {
    const parsedSettings = JSON.parse(savedSettings);
    settings = { ...defaultLocalSettings, ...parsedSettings };
  } else {
    const newSettings = { ...defaultLocalSettings };
    localStorage.setItem('localSettings', JSON.stringify(newSettings));
    settings = newSettings;
  }
  if (setting) return settings[setting];
  return settings
}

export function saveLocalSettings(settings) {
  const currentSettings = getLocalSettings();
  const newSettings = { ...currentSettings, ...settings };
  localStorage.setItem('localSettings', JSON.stringify(newSettings));
}