
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function CarCrashIcon(props) {
      return (
        <SvgIcon {...props} viewBox="0 0 50 50"><path d="m34.03 23.34.02-.05c1.17-3.04 2.16-5.56 2.41-6.05.64-1.23 2.52-1.87 3.76-2.29l.1-.04c.02-.01 1.91-.82 7.68-.9v-2c-5.85.08-8.02.87-8.46 1.06-1.55.53-3.86 1.32-4.86 3.26-.67 1.31-5.11 13.15-5.62 14.49l-.06.17V44c0 1.65 1.35 3 3 3h4c1.65 0 3-1.35 3-3v-2h9V27H37c-1.7 0-2.9-.4-3.3-1-.47-.69.03-1.92.27-2.52l.06-.14zM35 31h4c1.66 0 3 1.34 3 3s-1.34 3-3 3c-1.68 0-2.19-.43-3.92-1.42C33.92 34.93 33 34.31 33 33c0-1.1.9-2 2-2zm-7.19-2.53C28 26 25 29 25 29s-.16-3.58-1-4c-2-1-4.44 2.06-4.44 2.06L15 21c-.94-1.26-2.43-2-4-2H2v23h13.09c.48 2.84 2.95 5 5.91 5s5.43-2.16 5.91-5H27V30.63l.13-.34.06-.17c.16-.41.6-1.56.65-1.71-.01-.01-.02.01-.03.06zM21 45c-2.22 0-4-1.78-4-4 0-2.22 1.78-4 4-4s4 1.78 4 4c0 2.22-1.78 4-4 4zm10.707-28.707L27.414 12l3.293-3.293a1 1 0 0 0-.903-1.688l-4.475.895-2.622-2.622a1 1 0 0 0-1.656.391l-.599 1.795-6.922-4.326a1.001 1.001 0 0 0-1.378 1.378l4.637 7.419L14.2 15.4A.999.999 0 0 0 15 17h4.323l1.748 4.372a1.002 1.002 0 0 0 1.858 0l1.708-4.271 6.222.889a1 1 0 0 0 .848-1.697z"/></SvgIcon>
      );
    }
  
    export default CarCrashIcon;