import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import ErrorIcon from '@mui/icons-material/Error';
import { Draggable } from 'react-beautiful-dnd';
import ClickNHold from 'react-click-n-hold';

import { makeStyles } from 'tss-react/mui';

import { setUnitsDrawerDesktopState } from '../../reducers/UiReducer';
import { setUnitInfowindow, actualEvent } from '../../reducers/MapReducer';
import { toggleDialog as ToggleUnitDialog } from '../../reducers/UnitStatusReducer';
import {
  unitsSortBy,
  unitsSortOrder,
  pinUnit,
  toggleExpanded
} from '../../reducers/UnitsReducer';

import { getUnitColor } from '../../utils/uiUtils';

import { displayDateTime } from 'reducers/TimeReducer';
import { CalculateLastTimeDif } from 'utils/functions';

const avatarWidth = 44;
const iconWidth = 35;

const useStyles = makeStyles()((theme) => ({
  eventCard: {
    borderWidth: '5px',
    cursor: 'pointer'
  },
  listItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  btop: {
    borderTop: 'solid 1px rgba(7, 9, 25, 0.125)'
  },
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dedfe3',
      borderRadius: '15%',
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#000',
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4
    }
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block'
    }
  }
}));

const UnitItem = (props) => {
  const {
    unit,
    index,
    setUnitInfowindow,
    unitInfowindow,
    pinUnit,
    colors,
    expanded,
    toggleExpanded,
    actualEvent
  } = props;

  const { classes, cx } = useStyles();

  const zoneSimplifier = (zone) => {
    if (!zone) return '';
    let zoneToReturn = zone.replace(/_/g, ' ');
    return zoneToReturn;
  };

  const onUnitClick = (unit) => {
    setUnitInfowindow(true, unit);
  };

  const onIconClick = (e, unit) => {
    e.stopPropagation();
    pinUnit(unit);
  };

  const onUnitHold = (e, unit) => {
    // console.log(event);
  };

  const handleEventClick = (e, event) => {
    e.stopPropagation();
    actualEvent(event);
  };

  const onExpandClick = (e, unit) => {
    e.stopPropagation();
    toggleExpanded(unit);
  };

  let boxShadow =
    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px';
  if (
    unitInfowindow.open === true &&
    unitInfowindow.data &&
    unitInfowindow.data.ptsUnitID === unit.ptsUnitID
  ) {
    boxShadow = '-4px 0 0 -1px #0153a3';
  }
  const mobile = unit.Flags && unit.Flags.trim() === 'M' ? true : false;
  const details =
    unit.Location !== null ||
    unit.LastActionOLN !== null ||
    unit.LastActionPlateState !== null ||
    unit.LastActionNotes
      ? true
      : false;

  return (
    <Draggable
      key={unit.ptsUnitID}
      draggableId={unit.ptsUnitID.toString()}
      index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <ClickNHold time={1} onClickNHold={(e) => onUnitHold(e, unit)}>
            <Paper
              onClick={() => onUnitClick(unit)}
              className={cx(
                'card-box',
                'card-border-top',
                'card-border-bottom',
                'mb-2',
                'pb-1',
                'badge-wrapper',
                classes.eventCard
              )}
              sx={{
                borderColor: getUnitColor(unit, colors),
                boxShadow: boxShadow,
                backgroundImage: 'none !important'
              }}>
              <div className="card-header--title">
                <div {...provided.dragHandleProps}>
                  <div
                    style={{
                      display: 'flex',

                      justifyContent: 'space-between',
                      padding: '5px 5px 0px 5px'
                    }}>
                    <div>
                      <b>{unit.Unit}</b>
                    </div>

                    <div style={{ lineHeight: '15px' }}>
                      <small>
                        <b>{zoneSimplifier(unit.Zone).toString()}</b>
                      </small>

                      <p>{displayDateTime(unit.LastActionOccurred)}</p>
                    </div>
                    {CalculateLastTimeDif(
                      unit.LastChangedLocationTime,
                      unit.Flags
                    ) && (
                      <div
                      // style={{
                      //   position: 'absolute',
                      //   top: '3px',
                      //   right: '27px',
                      //   zIndex: '2'
                      // }}
                      >
                        <ErrorIcon color="error" />
                      </div>
                    )}
                    <div
                      className="badge"
                      style={{
                        background: getUnitColor(unit, colors)
                      }}>
                      {unit.UnitStatus}
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </ClickNHold>
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  unitInfowindow: state.map.unitInfowindow,
  colors: state.codes.colorsCodes
});

export default connect(mapStateToProps, {
  setUnitsDrawerDesktopState,
  setUnitInfowindow,
  ToggleUnitDialog,
  unitsSortBy,
  unitsSortOrder,
  pinUnit,
  toggleExpanded,
  actualEvent
})(UnitItem);
