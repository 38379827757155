
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8gasstation100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M9.313 0C6.34 0 4 2.34 4 5.313V42h31V5.312C35 2.34 32.66 0 29.687 0Zm1.593 5h17.188c1 0 1.906.906 1.906 1.906v10.188c0 1-.906 1.906-1.906 1.906H10.906C9.906 19 9 18.094 9 17.094V6.906C9 5.906 9.906 5 10.906 5Zm26.813.281L36.28 6.72 42 12.406v8.188c0 1.136.473 2.316 1.281 3.125L48 28.406V36c0 2.242-1.758 4-4 4s-4-1.758-4-4v-5c0-2.656-1.648-4.848-4-5.656v2.187c1.2.684 2 1.961 2 3.469v5c0 3.363 2.637 6 6 6s6-2.637 6-6V19.406c0-1.136-.473-2.316-1.281-3.125ZM3 44a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h33c.555 0 1-.445 1-1v-4c0-.555-.445-1-1-1Z"/></SvgIcon>
      );
    }
  
    export default icons8gasstation100Icon;