
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8sexworker100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M35 2a1 1 0 0 0-1 1v7.326c-.649-.368-1.495-.386-2.18.147L26 15h-9.424c-.344 0-.684.035-1.017.105-.963.2-1.812.757-2.483 1.477-1.254 1.344-4.024 4.312-5.139 5.461-.745.769-1.172 1.776-.693 2.734l.01.02a2.16 2.16 0 0 0 3.228.762L15 21l1 4-3.832 5.445A1 1 0 0 0 13 32h3v13.01A1.99 1.99 0 0 0 17.99 47h.02A1.99 1.99 0 0 0 20 45.01v-.235a2 2 0 0 0 2.414-.36l6-6a2 2 0 0 0 .25-2.524L26.07 32H28a1 1 0 0 0 .832-1.555L25 25l1-4 8-7v33a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-3zM21 3a5 5 0 0 0-4.969 4.494c-.78-.35-1.89-.693-2.967-.36-1.846.573-3.07 2.845-3.07 2.845s2.376 1.443 4.223.87c.858-.265 1.511-.899 1.974-1.501A5 5 0 0 0 21 13a5 5 0 0 0 0-10zm-1 29h1.268a2 2 0 0 0 .068.11l3.09 4.636L20 41.172V32z"/></SvgIcon>
      );
    }
  
    export default icons8sexworker100Icon;