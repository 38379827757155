import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import TablePagination from '@mui/material/TablePagination';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import Spinner from '../../../../global-components/Spinner';

import { Badge, Avatar } from '@mui/material';

import { findResultGun } from '../../../../reducers/NcicReducer';

const stylesDay = {
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

const useStyles = makeStyles()(theme => ({
  tableWrap: {
    overflow: 'auto',
    margin: '0 auto',
    position: 'relative'
  },
  header: {
    cursor: 'pointer',
    fontSize: '2em'
  },
  headerItem: {
    fontSize: '1em !important'
  },
  tableCell: {},
  spinner: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#999'
    }
  },
  warrantRow: {
    backgroundColor: theme.palette.error.main
  }
}));

const Badges = props => {
  if (props.ncicData !== null && props.ncicData.warnings.length > 0) {
    return props.ncicData.warnings.map((item, id) => {
      if (item.Detected > 0) {
        return (
          <div style={{ marginBottom: '10px' }} key={id}>
            <Badge
              overlap="rectangular"
              key={id}
              style={{ left: '45px' }}
              className="customBagde2"
              badgeContent={item.Banner.toUpperCase()}
              color="error"></Badge>
          </div>
        );
      } else {
        return null;
      }
    });
  } else {
    return null;
  }
};

const Table = props => {
  const { classes, cx } = useStyles();
  const { data, config, sort, themeMode } = props;
  const { orderBy, rowsPerPage, orderDirection, filters, loading } = data;
  const [headCells, setHeadCells] = useState(getHeadCells(data.rows, config));

  useEffect(() => {
    setHeadCells(getHeadCells(data.rows, config));
  }, [config, data]);

  const handlePageChange = (ev, pageNo) => {
    let options = {
      pageNo,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    if (props.ncicQueryId === null) {
      props.getData(options);
    } else {
      options.QueryID = props.ncicQueryId;
      props.getResultsData(options);
    }
  };

  const handleChangeRowsPerPage = (ev, info) => {
    const rowsPerPage = parseInt(info.props.value);
    let options = {
      pageNo: 0,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection
    };
    if (props.ncicQueryId === null) {
      props.getData(options);
    } else {
      options.QueryID = props.ncicQueryId;
      props.getResultsData(options);
    }
  };

  const handleRowClick = row => {
    if (row.QueryId) {
      props.findResultGun(row);
    } else {
      props.rowClick(row);
    }
  };

  return (
    <Fragment>
      <div className="table-responsive">
        <table
          className={cx(
            'table',
            'table-hover',
            themeMode === 'night' && 'table-pts-night',
            themeMode === 'day' && 'table-pts-day',
            'text-nowrap',
            'mb-0'
          )}>
          <thead>
            <tr>
              {headCells.map((row, idx) => {
                if (row.isDisplayed === true) {
                  return (
                    <Fragment key={row.label}>
                      <th
                        className={classes.headerItem}
                        // onClick={() => sort(row.dbName)}
                        title={row.title}>
                        <span>
                          {row.label}
                          {row.dbName === orderBy &&
                            orderDirection === 'ASC' && <ArrowDownwardIcon />}
                          {row.dbName === orderBy &&
                            orderDirection === 'DESC' && <ArrowUpwardIcon />}
                        </span>
                      </th>
                    </Fragment>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((row, idx) => {
              let cols = [];
              Object.entries(row).forEach(([key, val]) => {
                if (key !== 'key') {
                  if (config.display[key]) {
                    const dataType = config.display[key].type;
                    const dataName = config.display[key].dbName;
                    const isDisplayed = config.display[key].isDisplayed;
                    if (isDisplayed === true) {
                      if (val && dataType === 'date' && moment(val).isValid()) {
                        if (val.substr(val.length - 1) === 'Z') {
                          const editedDate = val.slice(0, -1);
                          cols.push({
                            text: moment(editedDate).format('MM/DD/YYYY'),
                            seq: config.display[key].seq,
                            name: dataName
                          });
                        } else {
                          cols.push({
                            text: moment(val).format('MM/DD/YYYY'),
                            seq: config.display[key].seq,
                            name: dataName
                          });
                        }
                      } else {
                        cols.push({
                          text: val,
                          seq: config.display[key].seq,
                          name: dataName
                        });
                      }
                    }
                  }
                }
              });
              if (cols.length > 0 && cols[0].seq !== undefined) {
                cols = cols.sort((a, b) => a.seq - b.seq);
              }
              const _parsedNcicData =
                row.NcicResponseData && row.NcicResponseData !== null
                  ? JSON.parse(row.NcicResponseData)
                  : null;
              if (row.ptsFirearmID === null) {
                row['_parsedNcicData'] = _parsedNcicData;
                row['SerialNumber'] = _parsedNcicData.data.GunSerialNumber;
                row['MakeCode'] = _parsedNcicData.data.GunMake;
                row['ModelCode'] = _parsedNcicData.data.GunModel;
                row['CaliberCode'] = _parsedNcicData.data.GunCaliber;
              }
              let searchType = 'L';
              if (!row.ptsFirearmID && _parsedNcicData !== null) {
                searchType = 'N';
              } else if (row.ptsFirearmID && _parsedNcicData !== null) {
                searchType = 'LN';
              }
              if (row.ptsFirearmID !== null) {
                return (
                  <tr key={idx} onClick={() => handleRowClick(row)}>
                    {cols.map((obj, idx) => {
                      if (obj.name === 'SerialNumber') {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={obj.text}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                <div style={{ paddingRight: '15px' }}>
                                  <Avatar alt="Avatar">{searchType}</Avatar>
                                </div>
                                <div>
                                  <div>
                                    <span
                                      style={{
                                        fontWeight: '500',
                                        fontSize: '18px'
                                      }}>
                                      {obj.text}
                                    </span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      {row.Description}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </Fragment>
                        );
                      } else if (obj.name === 'Warnings') {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell}>
                              <div style={{ marginBottom: '10px' }}>
                                <Badges ncicData={_parsedNcicData} />
                              </div>
                            </td>
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={obj.text}>
                              <span>{obj.text}</span>
                            </td>
                          </Fragment>
                        );
                      }
                    })}
                  </tr>
                );
              } else {
                return (
                  <tr key={idx} onClick={() => handleRowClick(row)}>
                    {cols.map((obj, idx) => {
                      if (obj.name === 'FullName') {
                        return (
                          <Fragment key={idx}>
                            <td
                              className={classes.tableCell}
                              title={row._parsedNcicData.data.Name.toUpperCase()}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                <div style={{ paddingRight: '15px' }}>
                                  <Avatar alt="Avatar">{searchType}</Avatar>
                                </div>
                                <div>
                                  <div>
                                    <span
                                      style={{
                                        fontWeight: '500',
                                        fontSize: '18px'
                                      }}>
                                      {row._parsedNcicData.data.Name.toUpperCase()}
                                    </span>
                                  </div>
                                  <div style={{ color: '#83848b' }}>
                                    <span style={{ fontWeight: '500' }}>
                                      {row.Description}
                                    </span>
                                    {/* <span> {row.StyleDesc}</span> */}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </Fragment>
                        );
                      } else if (obj.name === 'Warnings') {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell}>
                              <div style={{ marginBottom: '10px' }}>
                                <Badges ncicData={_parsedNcicData} />
                              </div>
                            </td>
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment key={idx}>
                            <td className={classes.tableCell} title={obj.text}>
                              <span>{obj.text}</span>
                            </td>
                          </Fragment>
                        );
                      }
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={config.pageSizeOptions}
        component="div"
        count={data.count}
        rowsPerPage={data.rowsPerPage}
        page={data.pageNo}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {loading && <Spinner />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  ncicQueryId: state.ncic.personsQueryId
});

export default connect(mapStateToProps, { findResultGun })(Table);

// ======================= HELPER FUNCTIONS ====================

function getHeadCells(dataObj, config) {
  const result = [];
  if (dataObj.length === 0) return [];
  const row = dataObj[0];
  Object.keys(row).forEach(key => {
    const option = config.display[key];
    if (option) {
      if (key !== 'key') {
        result.push({
          name: key,
          dbName: option.dbName,
          label: option ? option.label : key,
          width: getColWidth(key, config),
          title: option.title ? option.title : option.label,
          seq: option.seq,
          isDisplayed: option.isDisplayed
        });
      }
    }
  });

  if (result.length > 0 && result[0].seq !== undefined) {
    return result.sort((a, b) => a.seq - b.seq);
  }

  return result;
}

function getTableWidth(config) {
  return Object.values(config.display).reduce((sum, val) => val.width + sum, 0);
}

function getColWidth(key, config) {
  return `${Math.round(
    (config.display[key].width / getTableWidth(config)) * 10000
  ) / 100}%`;
}
