import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SearchPersons from '../../components/Search/Persons';
import ClosedEvents from '../../components/Search/ClosedEvents';
import SearchVehicles from '../../components/Search/Vehicles';
import SearchBoats from '../../components/Search/Boats';
import SearchGuns from '../../components/Search/Guns';
import SearchArticles from '../../components/Search/Articles';
import NcicHistory from '../../components/Search/Ncic';
import Loader from '../../global-components/Loader';

import { Paper, Tabs, Tab, Typography, Box, Badge } from '@mui/material';

import { setSearchActiveTab } from '../../reducers/UiReducer';
import {
  getMetadata,
  handleGunCheckboxChange,
  handlePersonCheckboxChange,
  handleVehicleCheckboxChange,
  ncicLogin,
  saveNcicCredentials
} from '../../reducers/NcicReducer';

import searchFiltersConfigPersons from '../../config/search-filters-config-persons.json';
import searchFiltersConfigVehicles from '../../config/search-filters-config-vehicles.json';
import searchFiltersConfigGuns from '../../config/search-filters-config-guns.json';
import searchFiltersConfigClosedEvents from '../../config/search-filters-config-closed-events.json';
import searchFiltersConfigWarrants from '../../config/search-filter-config-warrants.json';
import tableConfigPersons from '../../config/table-config-persons.json';
import tableConfigVehicles from '../../config/table-config-vehicles.json';
import tableConfigGuns from '../../config/table-config-guns.json';
import tableConfigClosedEvents from '../../config/table-config-closed-events.json';
import tableConfigWarrants from '../../config/table-config-warrants';
import DialogEvent from 'components/Map/DialogEvent';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import Warrants from 'components/Search/Warrants';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const PagesSearch = (props) => {
  const {
    wsClient,
    user,
    activeTab,
    setSearchActiveTab,
    metadata,
    getMetadata,
    ncicLogin,
    ncicCheckboxPrevPerson,
    ncicCheckboxPrevGun,
    ncicCheckboxPrevVehicle
  } = props;
  const persmissions = useSelector((state) => state.permissions.globals);
  const [canExecNcic, setCanExecNcic] = useState('wait');
  const dispatch = useDispatch();
  const { APP_DEFAULT_STATE } = useSelector(
    (state) => state.appInfo.appEnvInfo
  );

  const handleTabChange = (event, newValue) => {
    setSearchActiveTab(newValue);
  };

  React.useEffect(() => {
    setCanExecNcic(getAccessPermission('globals', 'Execute NCIC Queries'));
  }, [persmissions]);
  useEffect(() => {
    if (
      persmissions['Execute NCIC Queries'] !== undefined &&
      canExecNcic !== 'wait' &&
      persmissions['Execute NCIC Queries'] == false
    ) {
      // dispatch(saveNcicCredentials('', ''));
      // console.log('coming');
      dispatch(handleVehicleCheckboxChange(false));
      dispatch(handlePersonCheckboxChange(false));
      dispatch(handleGunCheckboxChange(false));
    }
  }, [persmissions, canExecNcic]);
  useEffect(() => {
    getMetadata();
  }, [getMetadata, wsClient]);

  useEffect(() => {
    if (wsClient.websocket !== null) {
      if (
        user?.userData?.user?.NCICUsername !== null &&
        user?.userData?.user?.NCICUsername !== null
      ) {
        if (APP_DEFAULT_STATE == 'MS') {
          ncicLogin(user);
        }
        if (APP_DEFAULT_STATE == 'LA') {
          ncicLogin(user);
        }
        if (APP_DEFAULT_STATE == 'TX') {
          ncicLogin(user);
        }
      }
    }
  }, [wsClient]);

  if (metadata !== null) {
    return (
      <div>
        <Paper
          square
          sx={{
            position: '-webkit-sticky',
            position: 'sticky',
            top: 80,
            zIndex: 999
          }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
            // centered={true}

            variant="scrollable"
            scrollButtons="auto"
            aria-label="search tabs">
            <Tab
              label="Vehicles"
              sx={{ minWidth: '160px !important', minHeight: '48px' }}
            />
            <Tab
              label="Names"
              sx={{ minWidth: '160px !important', minHeight: '48px' }}
            />

            {/* <Tab label="Boats" /> */}
            <Tab
              label="Guns"
              sx={{ minWidth: '160px !important', minHeight: '48px' }}
            />
            {/* <Tab label="Articles" /> */}
            <Tab
              label="Events"
              sx={{ minWidth: '160px !important', minHeight: '48px' }}
            />
            <Tab label="Warrants" />
            {canExecNcic == true && (
              <Tab
                label="NCIC/State Hist"
                sx={{ minWidth: '160px !important', minHeight: '48px' }}
              />
            )}
          </Tabs>
        </Paper>
        <TabPanel value={activeTab} index={0}>
          <SearchVehicles
            localformdefinition={searchFiltersConfigVehicles}
            localtableconfig={tableConfigVehicles}
            ncicformdefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.VehicleRegistrationQuery
                : null
            }
            ncicresponsedefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.VehicleRegistrationQuery
                : null
            }
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <SearchPersons
            localformdefinition={searchFiltersConfigPersons}
            localtableconfig={tableConfigPersons}
            ncicformdefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.DriverLicenseQuery
                : null
            }
            ncicresponsedefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.DriverLicenseQuery
                : null
            }
          />
        </TabPanel>

        {/* <TabPanel value={activeTab} index={2}>
          <SearchBoats
            formdefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.BoatQuery
                : null
            }
            responsedefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.BoatQuery
                : null
            }
          />
        </TabPanel> */}
        <TabPanel value={activeTab} index={2}>
          <SearchGuns
            localformdefinition={searchFiltersConfigGuns}
            localtableconfig={tableConfigGuns}
            ncicformdefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.GunQuery
                : null
            }
            ncicresponsedefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.GunQuery
                : null
            }
          />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <ClosedEvents
            localformdefinition={searchFiltersConfigClosedEvents}
            localtableconfig={tableConfigClosedEvents}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <Warrants
            localformdefinition={searchFiltersConfigWarrants}
            localtableconfig={tableConfigWarrants}
          />
        </TabPanel>
        {/* <TabPanel value={activeTab} index={4}>
          <SearchArticles
            formdefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.ArticleSingleQuery
                : null
            }
            responsedefinition={
              metadata !== null
                ? metadata.systems[Object.entries(metadata.systems)[0][0]]
                    .transactions.ArticleSingleQuery
                : null
            }
          />
        </TabPanel> */}
        {canExecNcic == true && (
          <TabPanel value={activeTab} index={5}>
            <NcicHistory />
          </TabPanel>
        )}
      </div>
    );
  } else {
    return (
      <Fragment>
        <Loader />
      </Fragment>
    );
  }
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  user: state.user,
  themeMode: state.theme.mode,
  activeTab: state.ui.searchActiveTab,
  metadata: state.ncic.metadata,
  ncicCount: state.ncic.rawDataHistoryCount,
  ncicCheckboxPrevGun: state.ncic.gunForm.ncic,
  ncicCheckboxPrevVehicle: state.ncic.vehicleForm.ncic,
  ncicCheckboxPrevPerson: state.ncic.personForm.ncic
});

export default connect(mapStateToProps, {
  setSearchActiveTab,
  getMetadata,
  ncicLogin
})(PagesSearch);
