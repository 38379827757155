
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8cartheft100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="100" height="100"><path d="M0 3v2h6c.898 0 1.707.605 1.906 1.406l1.5 5.188c.2.699-.3 1.312-1 1.312C6.707 12.805 2.801 12.5 0 12.5V23h7v2c0 .602.398 1 1 1h3c.602 0 1-.398 1-1V14.187c0-.8-.113-1.582-.313-2.28l-1.78-6C9.304 4.206 7.8 3 6 3Zm17.5 1A2.502 2.502 0 0 0 15 6.5C15 7.879 16.121 9 17.5 9S20 7.879 20 6.5 18.879 4 17.5 4Zm3.531 4c-.39.031-.789.2-1.125.5L17 11.688l-4-.094c-.602 0-1.094.398-1.094 1 0 .5.399 1 1 1l3.781.219c.602 0 1.313-.227 1.813-.626l1.5-1.374L22.188 16l-2.5 2.188c-.5.601-.493 1.523-.094 2.125l3.5 5.093c.3.5 1 .582 1.5.282.5-.301.707-1 .406-1.5l-2.906-4.5 2.812-2c1-.801 1.29-2.18.688-3.282l-2.907-5.5c-.375-.625-1.003-.953-1.656-.906ZM8 16c1.102 0 2 .898 2 2 0 1.102-.898 2-2 2-1.102 0-2-.898-2-2 0-1.102.898-2 2-2Zm11.094 4.406a4.153 4.153 0 0 0 0 3.094l.718 1.813c.102.398.477.593.875.593.602.102 1.106-.52.907-1.218l-.407-1.188Z"/></SvgIcon>
      );
    }
  
    export default icons8cartheft100Icon;