
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8leaningagainstwall100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M24 2c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm8 0v17a5 5 0 0 0-10 0v8.293l-3.693 7.389a3.145 3.145 0 0 0-.168.418l-2.934 8.796c-.51 1.528.171 3.28 1.664 3.883a3 3 0 0 0 3.979-1.83l2.824-8.476 1.139-1.391c-1.502-1.132-2.207-3.124-1.58-5.004L25 25.77V21a1 1 0 0 1 2 0v5.094l-1.871 5.615a2.499 2.499 0 1 0 4.742 1.58l2-6c.086-.254.129-.52.129-.789V48h8V2h-8zM19.053 28.719l-4.926 3.94c-.452.36-.79.846-.973 1.394l-3 9a3.001 3.001 0 0 0 3.295 3.914 5.24 5.24 0 0 1-.14-3.701l2.931-8.797a4.95 4.95 0 0 1 .27-.666l.033.015c.231-.543.612-1.308 1.187-2.457l1.323-2.642z"/></SvgIcon>
      );
    }
  
    export default icons8leaningagainstwall100Icon;