import React, { Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {
  Hidden,
  Drawer,
  List,
  IconButton,
  InputLabel,
  FormControl,
  Select,
  Badge,
  Zoom,
  Fab,
  Card,
  TextField
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

import { makeStyles } from 'tss-react/mui';

import { setEventsDrawerMobileState } from '../../reducers/UiReducer';
import { setEventInfowindow } from '../../reducers/MapReducer';
import {
  eventsSortBy,
  eventsSortOrder,
  eventsDragReorder,
  eventsFilterKeywordChange
} from '../../reducers/EventsReducer';
import { toggleDialog } from '../../reducers/SettingsReducer';

import EventItem from '../DrawerEventsDesktop/Event';
import EventItem2 from '../DrawerEventsDesktopSimple/Event';
import FilterButton from 'layout-components/DrawerEventsDesktop/FilterButton';

const drawerWidth = 300;
const drawerHeight = 80;

const useStyles = makeStyles()(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },
    zIndex: 98
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
    padding: '0px 6px',
    overflow: 'hidden'
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements
  },
  topHeaderBar: {
    position: 'fixed',
    backgroundColor: theme.palette.bgElements,
    zIndex: 99,
    width: drawerWidth - 10
  },
  select: {
    margin: '10px'
  }
}));

const DrawerEventsMobile = props => {
  const {
    state,
    setEventsDrawerMobileState,
    events,
    sortBy,
    sortOrder,
    eventsSortBy,
    eventsSortOrder,
    eventsDragReorder,
    stateAgencies,
    isEmpty,
    toggleDialog,
    eventsFilterKeywordChange,
    filterKeyword,
    viewType
  } = props;

  const { classes } = useStyles();
  const topRef = useRef();
  const [isButtonVisible, setButtonVisible] = React.useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const handleScroll = () => {
    topRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const handleListScroll = e => {
    let element = e.target;
    // At the bootom of the list
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //   console.log('show button');
    // }
    // After start scrolling
    if (element.scrollTop > 100) {
      setButtonVisible(true);
    } else {
      setButtonVisible(false);
    }
  };

  const renderEvents = () => {
    if (stateAgencies?.length == 0 && events.length == 0) {
      return (
        <div style={{ paddingTop: '50px' }}>
          <Card className="card-box bg-neutral-light border-1 border-dark text-center">
            <div className="py-2 px-2">
              <p className="opacity-6 font-size-md mb-2 mt-2">
                <b>No agencies selected</b>
                <br />
                You can select agencies from <b>Agency Filter</b> Option
              </p>
            </div>
          </Card>
        </div>
      );
    } else if (events && events.length > 0) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableEvents">
            {provided => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  maxHeight: `${
                    showIcon
                      ? window.innerHeight - 220
                      : window.innerHeight - 125
                  }px`,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  paddingRight: '10px',
                  scrollbarWidth: 'thin',
                  marginTop: `${showIcon ? -10 : -20}px`
                }}
                onScroll={handleListScroll}>
                <p ref={topRef}></p>
                {events.map((event, index) => {
                  if (filterKeyword !== '') {
                    if (
                      event.CallTypeDescription?.toLowerCase().includes(
                        filterKeyword
                      ) ||
                      event.EventID?.toLowerCase().includes(filterKeyword) ||
                      event.Status?.toLowerCase().includes(filterKeyword) ||
                      event.FullLocationAddress?.toLowerCase().includes(
                        filterKeyword
                      )
                    ) {
                      return viewType == 'complex' ? (
                        <EventItem
                          key={index}
                          event={event}
                          index={index}
                          pined={event.Pined}
                          expanded={event.expanded}
                        />
                      ) : (
                        <EventItem2
                          key={index}
                          event={event}
                          index={index}
                          pined={event.Pined}
                          expanded={event.expanded}
                        />
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return viewType == 'complex' ? (
                      <EventItem
                        key={index}
                        event={event}
                        index={index}
                        pined={event.Pined}
                        expanded={event.expanded}
                      />
                    ) : (
                      <EventItem2
                        key={index}
                        event={event}
                        index={index}
                        pined={event.Pined}
                        expanded={event.expanded}
                      />
                    );
                  }
                })}
                {provided.placeholder}
                <Zoom in={isButtonVisible}>
                  <Fab
                    style={{
                      position: 'fixed',
                      bottom: '10px',
                      left: '10px',
                      zIndex: '100'
                    }}
                    onClick={handleScroll}
                    color="primary"
                    size="large"
                    aria-label="scroll back to top">
                    <KeyboardArrowUp />
                  </Fab>
                </Zoom>
              </List>
            )}
          </Droppable>
        </DragDropContext>
      );
    } else {
      return (
        <div style={{ paddingTop: '50px' }}>
          {isEmpty ? (
            <Card className="card-box bg-neutral-light border-1 border-dark text-center">
              <div className="py-2 px-2">
                <p className="opacity-6 font-size-md mb-2 mt-2">
                  <b>No events found</b>
                </p>
              </div>
            </Card>
          ) : (
            <Fragment>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Fragment>
          )}
        </div>
      );
    }
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    eventsDragReorder(destination, source, draggableId);
  };

  const drawer = (
    <div>
      <div className={classes.topHeaderBar} style={{ position: 'relative' }}>
        <div className="divider my-1" style={{ background: '#0b55a1' }} />
        <div
          className="text-center"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              alignSelf: 'center',
              marginLeft: '10px',
              color: '#0b55a1',
              fontSize: '1.1rem'
            }}
            className="font-weight-bold font-size-md mb-0">
            {events !== null ? (
              <Badge
                overlap="rectangular"
                style={{ marginRight: '10px' }}
                badgeContent={events.length}
                color="primary"></Badge>
            ) : (
              <Badge
                overlap="rectangular"
                style={{ marginRight: '10px' }}
                badgeContent={0}
                color="primary"></Badge>
            )}{' '}
            Events
          </div>
          <div style={{ display: 'flex' }}>
            <FilterButton type="event" />
            <Badge
              variant="dot"
              badgeContent={1}
              invisible={filterKeyword == ''}
              color="error"
              overlap="circular">
              <IconButton
                size="small"
                style={{ color: '#0b55a1' }}
                onClick={() => setShowIcon(!showIcon)}>
                {showIcon ? (
                  <ExpandLessIcon fontSize="medium" color="primary" />
                ) : (
                  <ExpandMoreIcon fontSize="medium" color="primary" />
                )}
              </IconButton>
            </Badge>
            <IconButton
              style={{ color: '#0b55a1' }}
              onClick={() => setEventsDrawerMobileState()}
              size="small"
              className="btn-inverse">
              <MenuOpenRoundedIcon />
            </IconButton>
          </div>
        </div>
        <div className="divider my-1" style={{ background: '#0b55a1' }} />
        {showIcon ? (
          <div style={{ textAlign: 'center' }}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.select}>
              <InputLabel
                htmlFor="unit-sort-options"
                className={classes.selectLabel}>
                Sort By
              </InputLabel>
              <Select
                native
                value={sortBy}
                onChange={ev => eventsSortBy(ev.target.value)}
                label="Sort By"
                inputProps={{ id: 'unit-sort-options' }}>
                <option value="EventID">Event ID</option>
                <option value="Status">Status</option>
                <option value="CallType">Call Type</option>
                <option value="UpdateDate">Status Time</option>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.select}>
              <InputLabel
                htmlFor="unit-sort-options"
                className={classes.selectLabel}>
                Order
              </InputLabel>
              <Select
                native
                value={sortOrder}
                onChange={ev => eventsSortOrder(ev.target.value)}
                label="Order"
                inputProps={{ id: 'unit-sort-options' }}>
                <option value="DESC">DESC</option>
                <option value="ASC">ASC</option>
              </Select>
            </FormControl>
            <FormControl>
              <TextField
                style={{ width: '241px' }}
                variant="outlined"
                size="small"
                type="search"
                label="Filter events"
                placeholder="Filer events"
                size="small"
                value={filterKeyword}
                onChange={eventsFilterKeywordChange}
                // onBlur={() => setShowIcon(!showIcon)}
              />
            </FormControl>
          </div>
        ) : null}
      </div>
      {renderEvents()}
    </div>
  );

  return (
    <Fragment>
      <Hidden mdUp>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          open={state}
          anchor="left"
          classes={{
            paper: classes.drawerPaper
          }}>
          <div>
            <div className={classes.toolbar} />
            {drawer}
          </div>
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  state: state.ui.eventsDrawerMobileState,
  events: state.events.events,
  sortBy: state.events.sortBy,
  sortOrder: state.events.sortOrder,
  filterKeyword: state.events.filterKeyword,
  themeMode: state.theme.mode,
  eventInfowindow: state.map.eventInfowindow,
  stateAgencies: state.settings.agencies,
  isEmpty: state.events.isEmpty,
  viewType: state.settings.viewType
});

export default connect(mapStateToProps, {
  setEventsDrawerMobileState,
  eventsFilterKeywordChange,
  setEventInfowindow,
  eventsSortBy,
  eventsSortOrder,
  eventsDragReorder,
  toggleDialog
})(DrawerEventsMobile);
