import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { setUserAuthenticated, setIsPasswordValid } from 'reducers/UserReducer';
import Snackbar from '@mui/material/Snackbar';

function IdleTimerContainer(props) {
  const envInfo = useSelector((state) => state.appInfo.appEnvInfo);
  const timeout = parseInt(envInfo.APP_IDLE_TIMEOUT, 10) || 1800000;
  const { setUserAuthenticated, isAuthenticated, setIsPasswordValid } = props;
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // console.log('remaining : ', remaining)
    if (remaining < 60000 && isAuthenticated) setOpen(true);
    else setOpen(false);
  }, [remaining]);

  const handleOnIdle = () => {
    setUserAuthenticated(false);
    setIsPasswordValid(false);
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: timeout,
    onIdle: handleOnIdle
  });

  const handleClose = () => setOpen(false);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        message={
          remaining < 60000
            ? `Session timeout! You will be logged out in ${Math.ceil(
                remaining / 1000
              )}`
            : ''
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps, {
  setUserAuthenticated,
  setIsPasswordValid
})(IdleTimerContainer);
