import store from '../config/configureStore';
import { setUserAuthenticated, setUserData } from './UserReducer';

export const SET_CODE_SEX = 'CODE/SET_CODE_SEX';
export const SET_CODE_CITIES = 'CODE/SET_CODE_CITIES';
export const SET_CODE_HAIRCOLOR = 'CODE/SET_CODE_HAIRCOLOR';
export const SET_CODE_EYECOLOR = 'CODE/SET_CODE_EYECOLOR';
export const SET_CODE_RACE = 'CODE/SET_CODE_RACE';
export const SET_CODE_STATE = 'CODE/SET_CODE_STATE';
export const SET_CODE_VEHICLE_MAKES = 'CODE/SET_CODE_VEHICLE_MAKES';
export const SET_CODE_VEHICLE_MODELS = 'CODE/SET_CODE_VEHICLE_MODELS';
export const SET_CODE_VEHICLE_STYLES = 'CODE/SET_CODE_VEHICLE_STYLES';
export const SET_CODE_VEHICLE_COLOR = 'CODE/SET_CODE_VEHICLE_COLOR';
export const SET_CODE_FIREARM_CALIBERS = 'CODE/SET_CODE_FIREARM_CALIBERS';
export const SET_CODE_ITEM_MAKES = 'CODE/SET_CODE_ITEM_MAKES';
export const SET_CODE_ITEM_MODELS = 'CODE/SET_CODE_ITEM_MODELS';
export const SET_CODE_PLATE_TYPES = 'CODE/SET_CODE_PLATE_TYPES';
export const SET_CODE_COLORS = 'CODE/SET_CODE_COLORS';
export const SET_CODE_EVENT_TYPES = 'CODE/SET_CODE_EVENT_TYPES';
export const SET_CODE_CALL_TYPES = 'CODE/SET_CODE_CALL_TYPES';
export const SET_CODE_AGENCIES = 'CODE/SET_CODE_AGENCIES';
export const SET_CODE_RANKS = 'CODE/SET_CODE_RANKS';
export const SET_CODE_ETHNICITY = 'CODE/SET_CODE_ETHNICITY';

export const getCodeSex = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codesex');
      service.timeout = 20000;
      const codes = await service.find();
      dispatch({ type: SET_CODE_SEX, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeEventType = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codecadtypes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_EVENT_TYPES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeCallTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('dictionary');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 100,
          IsDeleted: false,
          options: ['CallTypes']
        }
      });
      dispatch({
        type: SET_CODE_CALL_TYPES,
        payload: data.dictionary.CallTypes
      });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeAgencies = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('dictionary');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 100,
          IsDeleted: false,
          options: ['Agencies']
        }
      });
      dispatch({ type: SET_CODE_AGENCIES, payload: data.dictionary.Agencies });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeHairColor = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codehaircolor');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
          $sort: {
            Description: 1
          }
        }
      });
      dispatch({ type: SET_CODE_HAIRCOLOR, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeCities = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codecities');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_CITIES, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getCodeEyeColor = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codeeyecolor');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_EYECOLOR, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getCodeRace = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('coderace');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $limit: 100,
          IsDeleted: false
        }
      });
      service.timeout = 20000;
      dispatch({ type: SET_CODE_RACE, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeState = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codestates');
      service.timeout = 20000;
      const states = await service.find({
        query: {
          $limit: 100,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_STATE, payload: states.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeVehicleStyles = () => {
  const client = store?.store?.getState()?.websocket?.websocket;
  return async dispatch => {
    try {
      const service = client?.service('codevehiclestyles');
      service.timeout = 20000;
      const makes = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
          $sort: {
            Description: 1
          }
        }
      });

      dispatch({ type: SET_CODE_VEHICLE_STYLES, payload: makes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getCodeVehicleColor = () => {
  const client = store?.store?.getState()?.websocket?.websocket;
  return async dispatch => {
    try {
      const service = client?.service('codevehiclecolors');

      service.timeout = 20000;
      const makes = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_VEHICLE_COLOR, payload: makes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getCodeVehicleMakes = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codevehiclemakes');
      service.timeout = 20000;
      const makes = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_VEHICLE_MAKES, payload: makes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeVehicleMakesModels = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codevehiclemakesmodels');
      service.timeout = 20000;
      const models = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_VEHICLE_MODELS, payload: models.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeFirearmCaliber = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codefirearmcaliber');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_FIREARM_CALIBERS, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeItemMake = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codeitemmakes');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_ITEM_MAKES, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeItemModel = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codeitemmodels');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_ITEM_MODELS, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodePlateTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codeplatetypes');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_PLATE_TYPES, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeColors = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codecolors');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      let _codes = { default: {}, categories: {}, units: {}, events: {} };
      for (let i = 0; i < codes.data.length; i++) {
        switch (codes.data[i].Category) {
          case 'DEFAULT':
            _codes.default[codes.data[i]['ColorKey']] =
              codes.data[i]['ColorValue'];
            break;
          case 'ACTION_CATEGORY':
            _codes.categories[codes.data[i]['ColorKey']] =
              codes.data[i]['ColorValue'];
            break;
          case 'EVENT':
            _codes.events[codes.data[i]['ColorKey']] =
              codes.data[i]['ColorValue'];
            break;
          case 'UNIT_ACTION':
            _codes.units[codes.data[i]['ColorKey']] =
              codes.data[i]['ColorValue'];
            break;
          default:
            console.log(`there is no handler for ${codes.data[i].Category} `);
        }
      }
      dispatch({ type: SET_CODE_COLORS, payload: _codes });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getCodeRanks = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('coderanks');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_RANKS, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getCodeEthnicity = () => {
  const client = store.store.getState().websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('codeethnicity');
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false
        }
      });
      dispatch({ type: SET_CODE_ETHNICITY, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export default function reducer(
  state = {
    colorsCodes: { default: {}, categories: {}, units: {}, events: {} },
    sexCodes: [],
    raceCodes: [],
    hairColorCodes: [],
    eyeColorCodes: [],
    statesCodes: [],
    vehicleMakesCodes: [],
    vehicleModelsCodes: [],
    vehicleStylesCodes: [],
    firearmCaliberCodes: [],
    itemMakesCodes: [],
    itemModelsCodes: [],
    plateTypesCodes: [],
    eventTypeCodes: [],
    CallTypes: [],
    Agencies: [],
    vehicleColorCodes: [],
    codeCities: [],
    codeRanks: [],
    ethnicityCodes: []
  },
  action
) {
  // console.log('action: ',action)
  switch (action.type) {
    case SET_CODE_SEX:
      return {
        ...state,
        sexCodes: action.payload
      };
    case SET_CODE_RACE:
      return {
        ...state,
        raceCodes: action.payload
      };
    case SET_CODE_CITIES:
      return {
        ...state,
        codeCities: action.payload
      };
    case SET_CODE_STATE:
      return {
        ...state,
        statesCodes: action.payload
      };
    case SET_CODE_VEHICLE_MAKES:
      return {
        ...state,
        vehicleMakesCodes: action.payload
      };
    case SET_CODE_VEHICLE_MODELS:
      return {
        ...state,
        vehicleModelsCodes: action.payload
      };
    case SET_CODE_VEHICLE_STYLES:
      return {
        ...state,
        vehicleStylesCodes: action.payload
      };
    case SET_CODE_VEHICLE_COLOR:
      return {
        ...state,
        vehicleColorCodes: action.payload
      };
    case SET_CODE_FIREARM_CALIBERS:
      return {
        ...state,
        firearmCaliberCodes: action.payload
      };
    case SET_CODE_HAIRCOLOR:
      return {
        ...state,
        hairColorCodes: action.payload
      };
    case SET_CODE_EYECOLOR:
      return {
        ...state,
        eyeColorCodes: action.payload
      };
    case SET_CODE_ITEM_MAKES:
      return {
        ...state,
        itemMakesCodes: action.payload
      };
    case SET_CODE_ITEM_MODELS:
      return {
        ...state,
        itemModelsCodes: action.payload
      };
    case SET_CODE_PLATE_TYPES:
      return {
        ...state,
        plateTypesCodes: action.payload
      };
    case SET_CODE_COLORS:
      return {
        ...state,
        colorsCodes: action.payload
      };
    case SET_CODE_EVENT_TYPES:
      return {
        ...state,
        eventTypeCodes: action.payload
      };
    case SET_CODE_CALL_TYPES:
      return {
        ...state,
        CallTypes: action.payload
      };
    case SET_CODE_AGENCIES:
      return {
        ...state,
        Agencies: action.payload
      };
    case SET_CODE_RANKS:
      return {
        ...state,
        codeRanks: action.payload
      };
    case SET_CODE_ETHNICITY:
      return {
        ...state,
        ethnicityCodes: action.payload
      };
    default:
      break;
  }
  return state;
}
