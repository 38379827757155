
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8doorlock100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M10 9c-2.75 0-5 2.25-5 5v20.5c0 1.375.535 2.7 1.41 3.727C7.285 39.252 8.55 40 10 40h30c2.75 0 5-2.25 5-5V22.123a5.002 5.002 0 0 0-1.877-3.904l-.074-.059-15.469-8.158A4.986 4.986 0 0 0 24.598 9H10zm0 2h14.598c.68 0 1.341.233 1.873.658l.074.059L38.459 18H7v-4c0-1.668 1.332-3 3-3zm22.79 12h2.894c1.274 0 2.316.96 2.316 2.28v7.199C38 33.799 36.958 35 35.684 35h-2.895c-.309 0-.849-.156-1.389-.506-1.192-.773-2.537-1.77-3.668-2.594a1.808 1.808 0 0 1-.671-.9H14.104L12 29.465V28.35L14.104 27h12.908c.029-.33.243-.76.72-1.111 1.108-.817 2.607-1.768 3.668-2.504.53-.367 1.08-.385 1.39-.385zM34 26a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0v-4a1 1 0 0 0-1-1z"/></SvgIcon>
      );
    }
  
    export default icons8doorlock100Icon;