
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8watertransportation100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="100" height="100"><path d="M30 3v7H18.68l-3 7H11v11.72l4-1.44V21h3.32l3-7h21.36l3 7H49v6.28l4 1.44V17h-4.68l-3-7H34V3h-4zm-7 14v4h4v-4h-4zm7 0v4h4v-4h-4zm7 0v4h4v-4h-4zm-7 7L5 33l9.4 17.23c.52-.22.87-.42.94-.46l1.04-.67L18 48.07l1.62 1.03 1.04.66c.17.11 2.16 1.24 4.34 1.24 2.18 0 4.16-1.13 4.34-1.23l1.04-.67L32 48.07l1.62 1.03 1.04.66c.17.11 2.16 1.24 4.34 1.24 2.18 0 4.16-1.13 4.34-1.23l1.04-.67L46 48.07l1.62 1.03 1.04.66c.06.05.41.24.94.47L59 33l-25-9h-4zm-9 12c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm22 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM18 51.627l-1.076.687C16.897 52.331 14.193 54 11 54c-3.201 0-5.903-1.673-5.93-1.69l-2.146 3.376C3.072 55.78 6.6 58 11 58c3.008 0 5.608-1.038 7-1.725 1.392.687 3.992 1.725 7 1.725s5.608-1.038 7-1.725c1.392.687 3.992 1.725 7 1.725s5.608-1.038 7-1.725c1.392.687 3.992 1.725 7 1.725 4.399 0 7.928-2.22 8.076-2.314l-2.152-3.372C58.897 52.331 56.193 54 53 54c-3.201 0-5.903-1.674-5.924-1.686L46 51.627l-1.076.687C44.897 52.331 42.193 54 39 54c-3.201 0-5.903-1.674-5.924-1.686L32 51.627l-1.076.687C30.897 52.331 28.193 54 25 54c-3.201 0-5.903-1.674-5.924-1.686L18 51.627z"/></SvgIcon>
      );
    }
  
    export default icons8watertransportation100Icon;