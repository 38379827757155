
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function ArsonIcon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path d="M190.5 454.9c-45.3-149.4 50.5-204 50.5-204-6.7 79.7 38.6 141.8 38.6 141.8 16.7-5 48.5-28.5 48.5-28.5 0 28.5-16.8 90.6-16.8 90.6S370 409.4 388.5 334c18.5-75.5-35.2-151.2-35.2-151.2 3.2 53.4-14.8 105.9-50.2 146.1 1.8-2 3.3-4.3 4.4-6.8 6.4-12.7 16.6-45.8 10.6-122.3C309.7 92.3 212.3 47 212.3 47c8.4 65.5-16.8 80.6-75.7 204.8-58.9 124.2 53.9 203.1 53.9 203.1z"/></SvgIcon>
      );
    }
  
    export default ArsonIcon;