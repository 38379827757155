
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8help100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M25 2C12.318 2 2 12.318 2 25s10.318 23 23 23 23-10.318 23-23S37.682 2 25 2zm1.797 34.935c0 .216-.144.358-.358.358h-2.726c-.217 0-.359-.143-.359-.358v-3.084c0-.215.143-.358.359-.358h2.726c.215 0 .358.144.358.358v3.084zm3.155-13.667-2.403 3.3c-.717.968-.933 1.47-.933 2.689v1.147c0 .215-.143.358-.358.358h-2.367c-.215.004-.358-.14-.358-.355v-1.47c0-1.436.322-2.188 1.075-3.229l2.404-3.3c1.254-1.721 1.684-2.546 1.684-3.766 0-2.044-1.434-3.335-3.479-3.335-2.008 0-3.299 1.219-3.729 3.407-.036.215-.179.323-.395.287l-2.259-.395c-.216-.036-.323-.179-.288-.395.539-3.443 3.014-5.703 6.744-5.703 3.872 0 6.49 2.546 6.49 6.097 0 1.722-.608 2.977-1.828 4.663z"/></SvgIcon>
      );
    }
  
    export default icons8help100Icon;