
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8idnotverified100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M25 2a3 3 0 0 0-3 3v4h6V5a3 3 0 0 0-3-3Zm0 3a1 1 0 1 1 .001 1.999A1 1 0 0 1 25 5ZM13 6c-1.652 0-3 1.348-3 3v32c0 1.652 1.348 3 3 3h17.844c1.55 3.52 5.07 6 9.156 6 5.512 0 10-4.488 10-10s-4.488-10-10-10V9c0-1.652-1.348-3-3-3h-7v4a1 1 0 0 1-1 1h-8c-.55 0-1-.45-1-1V6Zm11.969 10c.98 0 1.734.262 2.031.781 2.863.399 1.977 4.254 1.563 5.5.152.094.406.38.343.907-.117.98-.496 1.23-.75 1.25-.097.914-.676 1.984-1 2.156 0 .652-.031 1.16.032 1.875.652 1.75 4.93 1.582 5.687 4.562a9.62 9.62 0 0 0-.844.969H17c0-.156.016-.297.031-.438l-.031-.03c.387-3.5 5.094-3.22 5.781-5.063a.253.253 0 0 1 .063-.125c.05-.653.031-1.133.031-1.75-.324-.172-.934-1.242-1.031-2.157-.254-.019-.664-.269-.782-1.25-.062-.527.192-.812.344-.906-.86-3.312-.363-6.21 3.563-6.281ZM40 32c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8Zm-3.094 3.969c-.043.008-.086.02-.125.031a.994.994 0 0 0-.781.703c-.105.367.004.758.281 1.016L38.563 40l-2.282 2.281a1.014 1.014 0 0 0 0 1.438c.399.398 1.04.398 1.438 0L40 41.437l2.281 2.282c.399.398 1.04.398 1.438 0a1.014 1.014 0 0 0 0-1.438L41.437 40l2.282-2.281a.996.996 0 0 0 .215-1.172.996.996 0 0 0-1.059-.547 1.008 1.008 0 0 0-.594.281L40 38.563l-2.281-2.282a1.004 1.004 0 0 0-.813-.312Z"/></SvgIcon>
      );
    }
  
    export default icons8idnotverified100Icon;