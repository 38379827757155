
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function icons8noise100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M2.75 1.813a1.143 1.143 0 0 0-.125.03.994.994 0 0 0-.781.704c-.106.367.004.758.281 1.015l44.313 44.313a1.014 1.014 0 0 0 1.437 0 1.014 1.014 0 0 0 0-1.438l-6.281-6.28.406.156V7.593L16.937 15.5 3.563 2.125a1.004 1.004 0 0 0-.812-.313ZM46.5 5A2.502 2.502 0 0 0 44 7.5v33c0 1.379 1.121 2.5 2.5 2.5s2.5-1.121 2.5-2.5v-33C49 6.121 47.879 5 46.5 5ZM12.781 16.813l-4.937 1.562c.093.363.156.734.156 1.125v7c0 .43-.074.824-.188 1.219l5.032 1.843-.563 1.344-.031.032c-.883 2.28.285 4.867 2.563 5.75l4.062 1.593c2.281.883 4.871-.281 5.75-2.562l.625-1.563L32.938 37ZM3.5 17A2.502 2.502 0 0 0 1 19.5v7C1 27.879 2.121 29 3.5 29S6 27.879 6 26.5v-7C6 18.121 4.879 17 3.5 17Zm11.219 13.25 8.625 3.219L22.75 35a2.422 2.422 0 0 1-3.156 1.406l-4.063-1.562a2.454 2.454 0 0 1-1.406-3.157c.004-.011-.004-.019 0-.03Z"/></SvgIcon>
      );
    }
  
    export default icons8noise100Icon;