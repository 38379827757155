
    import React from 'react';
    import SvgIcon from '@mui/material/SvgIcon';
    
    function RunawayjuvenileIcon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path d="M182 153.4h131.9c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3H182c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3zM64 214.8h131.9c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3H64c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3zm35.4 64.6c0 3.5 2.8 6.3 6.3 6.3h131.9c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3H105.7c-3.5 0-6.3 2.8-6.3 6.3zm75.5 72.9H56.4c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3h118.5c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3zm213.3-159.5c18.9 0 34.3-15.4 34.3-34.3s-15.4-34.3-34.3-34.3-34.3 15.4-34.3 34.3 15.4 34.3 34.3 34.3z"/><path d="M444.6 182.9c-19.7 27.6-46 30.8-74.4 13.9-1.9-1.1-10.5-5.9-12.4-7-45.6-27.1-91.2-16.5-121.8 26.3-13 18.2 17.1 35.5 29.9 17.5 15.8-22.1 35.9-28.5 57.8-21.5-11.2 19.5-21.1 38.9-35 66.5-13.9 27.6-44.5 49.6-74 32.4-21.2-12.4-40.4 18.1-19.2 30.5 40.2 23.5 87.3 9.1 111.4-21.5.8.4 1.7.8 2.7 1.2 19.7 6.8 45.4 25.1 53.3 31.5 7.8 6.5 21.3 39.4 29.3 55.9 9.7 20.1 41.1 5.5 31.3-14.7-9.1-18.8-24.3-56.2-36.2-65.7-9.5-7.6-28-21.7-43.7-29.8 10.6-20.6 21.7-40.9 33.3-60.9 37.1 11.4 72.4-2 97.5-37.2 13.1-18.2-16.9-35.4-29.8-17.4z"/></SvgIcon>
      );
    }
  
    export default RunawayjuvenileIcon;